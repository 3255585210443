/**
 * @ngdoc directive
 * @name sp.common.directive:spCreditCardFormFields
 * @restrict E
 * @requires $rootScope
 * @requires spBraintreeDeviceData
 *
 * @description
 * Renders credit card form fields for card number, expiration month, expiration year, and the CVV code.
 *
 * @param {object} resource A resource that has credit card properties
 * @param {boolean=} isNotEditable If true, will render the credit card form fields as read-only
 * @param {boolean=} removeNameOnCardField If true, will remove the nameOnCard form field
 * @param {string=} cvvCodeProperty Overrides the cvvCode property
 */
export default [
    '$rootScope',
    'spBraintreeDeviceData',
    function spCreditCardFormFieldsDirective($rootScope, spBraintreeDeviceData) {
        return {
            restrict: 'E',
            templateUrl: $rootScope.getDirectiveTemplateUrl(
                'newcommon',
                'sp-credit-card-form-fields'
            ),
            scope: {
                resource: '=',
                isNotEditable: '=?',
                removeNameOnCardField: '=?',
                cvvCodeProperty: '@?'
            },
            link: function onLink($scope) {
                $scope.now = moment();
                $scope.expirationMonthsAsOptions = getExpirationMonthsAsOptions();
                $scope.expirationYearsAsOptions = getExpirationYearsAsOptions();

                spBraintreeDeviceData.get().then(function withDeviceData(deviceData) {
                    $scope.resource.deviceData = deviceData;
                });

                function getExpirationMonthsAsOptions() {
                    var expirationMonthsAsOptions = [];

                    for (var month = 1; month <= 12; month++) {
                        var monthAsString = month < 10 ? '0' + month : month.toString();

                        expirationMonthsAsOptions.push({
                            text: monthAsString,
                            value: monthAsString
                        });
                    }

                    return expirationMonthsAsOptions;
                }

                function getExpirationYearsAsOptions() {
                    var FUTURE_YEAR_COUNT = 15;
                    var expirationYearsAsOptions = [];
                    var currentYear = moment().year();

                    for (var year = currentYear; year <= currentYear + FUTURE_YEAR_COUNT; year++) {
                        expirationYearsAsOptions.push({
                            text: year.toString().slice(-2),
                            value: year
                        });
                    }

                    return expirationYearsAsOptions;
                }
            }
        };
    }
];
