/* eslint-disable func-names */

export default [
    '$rootScope',
    '$window',
    function clientWidthFacade($rootScope, $window) {
        var windowElement = angular.element($window);
        var lastWidth;

        function setClientWidth() {
            var width = windowElement.width();

            if (width === lastWidth) {
                return;
            }

            lastWidth = width;

            $rootScope.clientWidth = lastWidth;
        }

        windowElement.on('resize', function() {
            $rootScope.$apply(setClientWidth);
        });

        setClientWidth();
    }
];
