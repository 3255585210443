const CAROUSEL_EVENTS = {
    CAROUSEL_POSITION_UPDATED: 'carousel-position-updated'
};
const CONTRACT_SIGNATURE_EVENTS = {
    BYPASS_DATA_STORE: 'common-contract-signature-bypass-data-store',
    CREATE: 'common-contract-signature-create',
    CREATED: 'common-contract-signature-created',
    CREATION_ERROR_CONFLICT: 'common-contract-signature-creation-error-conflict',
    CREATION_ERROR_OTHER: 'common-contract-signature-creation-error-other',
    REQUIRE_CONSENT: 'common-contract-signature-require-consent',
    SET_SIGNATURE: 'common-contract-signature-set-signature'
};
const DATE_PICKER_EVENTS = {
    DATE_PICKED: 'common-date-picker-date-picked'
};
const GATEWAY_STATUS_EVENTS = {
    SET_STATUS: 'common-set-gateway-status',
    SET_STATUS_COMPLETE: 'common-set-gateway-status-complete',
    SET_STATUS_ERROR: 'common-set-gateway-status-error'
};
const SIGNATURE_CAPTURE_MODAL_EVENTS = {
    CLEAR_SIGNATURE: 'common-signature-capture-modal-clear-signature'
};
const SIGNATURE_EVENTS = {
    CREATE: 'common-signature-create',
    CREATED: 'common-signature-created'
};
const SP_API_HTTP_EVENTS = {
    BAD_REQUEST: 'common-http-error-bad-request',
    CONFLICT: 'common-http-error-conflict',
    INTERNAL: 'common-http-error-internal',
    SERVICE_UNAVAILABLE: 'common-http-error-service-unavailable',
    TIMEOUT: 'common-http-error-timeout',
    TOO_MANY_REQUESTS: 'common-http-error-too-many-requests',
    UPLOAD_PROGRESS_COMPLETE: 'sp-api-http-upload-progress-complete'
};
const SUGGESTION_EVENTS = {
    LIST: 'common-suggestion-list',
    LISTED: 'common-suggestion-listed'
};
const TITLE_BAR_EVENTS = {
    TITLE_TEXT_EDITED: 'common-title-bar-title-text-edited'
};
const TRACK_EVENT = 'common-track';

export {
    CAROUSEL_EVENTS,
    CONTRACT_SIGNATURE_EVENTS,
    DATE_PICKER_EVENTS,
    GATEWAY_STATUS_EVENTS,
    SIGNATURE_CAPTURE_MODAL_EVENTS,
    SIGNATURE_EVENTS,
    SP_API_HTTP_EVENTS,
    SUGGESTION_EVENTS,
    TITLE_BAR_EVENTS,
    TRACK_EVENT
};
