import spToggleFormElementTemplate from '../../templates/components/sp-toggle-form-element.nghtml';

/**
 * @ngdoc component
 * @name sp.common.component:spToggleFormElement
 * @restrict E
 *
 * @description
 * A form element (input, select, or textarea) that can be toggled on and off by a switch.
 *
 * @example
 <pre>
    <sp-toggle-form-element
        sp-toggle-form-element-is-toggled=" toggleMe "
        sp-toggle-form-element-label-text="spToggleFormElement Label Text"
        sp-toggle-form-element-sub-text="spToggleFormElement Sub-Text"
        sp-toggle-form-element-learn-more-link="https://google.com"
        sp-toggle-form-element-is-required-when-toggled=" true ">

        <input
            id="sp-toggle-form-element-example"
            type="text"
            name="spToggleFormElementExample"
            ng-required=" true ">

        <additional-info>
            Here's some additional information you may need.
        </additional-info>

    </sp-toggle-form-element>
 </pre>
 *
 * @param {boolean} spToggleFormElementIsToggled A boolean used to track whether the switch is toggled on or off
 * @param {string} spToggleFormElementLabelText The label for the switch
 * @param {string=} spToggleFormElementLearnMoreLink A helper link that opens a new window
 * @param {string=} spToggleFormElementSubText The subtext for the switch
 * @param {boolean=} spToggleFormElementIsRequiredWhenToggled A boolean to show a required-asterisk (*) when the switch is toggled on
 * @param {boolean=} spToggleFormElementIsDisabled A boolean to drive the disabled state of the switch
 * @param {string=} spToggleFormElementWarningText A warning text to be displayed adjacent to the header text
 */

export default {
    template: spToggleFormElementTemplate,
    bindings: {
        isToggled: '=spToggleFormElementIsToggled',
        labelText: '@spToggleFormElementLabelText',
        learnMoreLink: '@?spToggleFormElementLearnMoreLink',
        subText: '@?spToggleFormElementSubText',
        isRequiredWhenToggled: '=?spToggleFormElementIsRequiredWhenToggled',
        isDisabled: '<?spToggleFormElementIsDisabled',
        warningText: '@?spToggleFormElementWarningText'
    },
    transclude: {
        additionalInfo: '?additionalInfo',
        additionalText: '?additionalText'
    }
};
