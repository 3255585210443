export default [
    function SPPhotoProvider() {
        this.$get = [
            '$rootScope',
            'spAppData',
            function SPPhotoServiceFactory($rootScope, spAppData) {
                return new SPPhotoService($rootScope, spAppData);

                function SPPhotoService($rootScope, spAppData) {
                    var self = this;
                    var urls = spAppData.get('urls');
                    var photoSizes = {
                        m: {
                            key: 'm',
                            bounds: {
                                width: 500,
                                height: 500
                            }
                        },
                        l: {
                            key: 'l',
                            bounds: {
                                width: 1000,
                                height: 1000
                            }
                        },
                        xl: {
                            key: 'xl',
                            bounds: {
                                width: 1500,
                                height: 1500
                            }
                        },
                        '2x': {
                            key: '2x',
                            bounds: {
                                width: 2000,
                                height: 2000
                            }
                        },
                        '3x': {
                            key: '3x',
                            bounds: {
                                width: 3000,
                                height: 3000
                            }
                        }
                    };

                    $rootScope.SPPhoto = this;

                    this.getUrl = function getUrl(photo, size) {
                        if (photo.externalUrl) {
                            return photo.externalUrl;
                        }

                        if (photo.legacy && (size === '2x' || size === '3x')) {
                            size = 'xl';
                        }

                        if (typeof photoSizes[size] !== 'object') {
                            return;
                        }

                        var photoSize = photoSizes[size];

                        /*
                        // If Photo object denotes to filter in black and white,
                        // use a different Photo URL.
                        */
                        if (photo.filterBlackAndWhite && !window.Modernizr.cssfilters) {
                            // NOTE: "2" value denotes the Black and White filter.
                            return (
                                urls.photoFiltered +
                                'photo/' +
                                photo.key +
                                '/' +
                                photoSize.key +
                                '/2/' +
                                photo.id +
                                '.jpg'
                            );
                        }

                        return (
                            urls.photo + photo.key + '/' + photoSize.key + '/' + photo.id + '.jpg'
                        );
                    };

                    this.getBoundsForSize = function getBoundsForSize(size, customBounds) {
                        // This allows you to pass in your own bounding box
                        if (typeof customBounds === 'object') {
                            return customBounds;
                        }

                        var photoSize = photoSizes[size];

                        if (customBounds === true) {
                            return {
                                height: photoSize.bounds.height,
                                width: photoSize.bounds.width
                            };
                        }

                        var boundsFactor = 1.5; // 1.5x

                        return {
                            height: photoSize.bounds.height / boundsFactor,
                            width: photoSize.bounds.width / boundsFactor
                        };
                    };

                    this.getPhotoDimensions = function getPhotoDimensions(
                        photo,
                        size,
                        customBounds
                    ) {
                        var bounds = self.getBoundsForSize(size, customBounds);
                        var photoDimensions = {
                            width: photo.width,
                            height: photo.height
                        };

                        if (
                            typeof bounds.width === 'number' &&
                            photoDimensions.width > bounds.width
                        ) {
                            // Photo is wider than container, make it shorter
                            photoDimensions.height =
                                (bounds.width / photoDimensions.width) * photoDimensions.height;
                            photoDimensions.width = bounds.width;
                        }

                        if (
                            typeof bounds.height === 'number' &&
                            photoDimensions.height > bounds.height
                        ) {
                            // Photo is too tall, make it more narrow
                            photoDimensions.width =
                                (bounds.height / photoDimensions.height) * photoDimensions.width;
                            photoDimensions.height = bounds.height;
                        }

                        return window.SPObject.map(photoDimensions, Math.floor);
                    };

                    this.getWidth = function getWidth(photo, size, customBounds) {
                        return self.getPhotoDimensions(photo, size, customBounds).width;
                    };

                    this.getHeight = function getHeight(photo, size, customBounds) {
                        return self.getPhotoDimensions(photo, size, customBounds).height;
                    };

                    this.getMaxCrop = function getMaxCrop(
                        photo,
                        bounds,
                        boundsName,
                        disableRotating
                    ) {
                        bounds = normalizeBounds(photo, bounds, disableRotating);

                        var boundsRatio = bounds.width / bounds.height;
                        var photoRatio = photo.width / photo.height;

                        if (boundsRatio > photoRatio) {
                            // Full Width
                            return createMaxCrop(
                                100,
                                (photoRatio / boundsRatio) * 100,
                                bounds,
                                photo,
                                boundsName
                            );
                        }

                        return createMaxCrop(
                            (boundsRatio / photoRatio) * 100,
                            100,
                            bounds,
                            photo,
                            boundsName
                        );

                        function normalizeBounds(photo, bounds, disableRotating) {
                            var WIDE = 0x1;
                            var TALL = 0x2;
                            var photoMode;
                            var boundsMode;

                            if (photo.width > photo.height) {
                                photoMode = WIDE;
                            } else {
                                photoMode = TALL;
                            }

                            if (bounds.width > bounds.height) {
                                boundsMode = WIDE;
                            } else {
                                boundsMode = TALL;
                            }

                            if (boundsMode !== photoMode && !disableRotating) {
                                // If the bounding mode does not match up with the photo mode,
                                // then invert the bounding mode
                                var newWidth = bounds.height;
                                var newHeight = bounds.width;

                                bounds.width = newWidth;
                                bounds.height = newHeight;
                            }

                            return bounds;
                        }

                        function createMaxCrop(
                            widthPercentage,
                            heightPercentage,
                            bounds,
                            photo,
                            boundsName
                        ) {
                            var xOffset = (100 - widthPercentage) / 2;
                            var yOffset = (100 - heightPercentage) / 2;
                            var newCrop = new Crop(
                                xOffset,
                                yOffset,
                                xOffset + widthPercentage,
                                yOffset + heightPercentage,
                                bounds,
                                photo,
                                boundsName
                            );

                            newCrop.isDefault = true;

                            return newCrop;
                        }
                    };

                    this.getCrop = function getCrop(photo, bounds, cropData, boundsName) {
                        return new Crop(
                            cropData.start.x,
                            cropData.start.y,
                            cropData.end.x,
                            cropData.end.y,
                            bounds,
                            photo,
                            boundsName
                        );
                    };

                    this.canFavoritePhoto = function canFavoritePhoto() {
                        return true;
                    };

                    function Crop(xStart, yStart, xEnd, yEnd, bounds, photo, boundsName) {
                        this.bounds = bounds;
                        this.boundsName = boundsName;
                        this.isDefault = false;
                        this.isChanged = false;

                        // all values are in percentages between 0 and 100
                        this.start = {};
                        this.end = {};

                        this.setCrop = function setCrop(left, top, width, height) {
                            var PRECISION_POWER = Math.pow(10, 5);

                            this.isDefault = false;
                            this.isChanged = true;
                            this.start.x = Math.floor(left * PRECISION_POWER) / PRECISION_POWER;
                            this.start.y = Math.floor(top * PRECISION_POWER) / PRECISION_POWER;
                            this.end.x =
                                Math.floor((left + width) * PRECISION_POWER) / PRECISION_POWER;
                            this.end.y =
                                Math.floor((top + height) * PRECISION_POWER) / PRECISION_POWER;
                        };

                        this.setCrop(xStart, yStart, xEnd - xStart, yEnd - yStart);

                        this.isChanged = false;
                        this.isRotated = false;

                        this.getLeft = function getLeft() {
                            return this.start.x;
                        };

                        this.getTop = function getTop() {
                            return this.start.y;
                        };

                        this.getWidth = function getWidth(isRotated) {
                            if (isRotated) {
                                return this.defaultCrop.end.x - this.defaultCrop.start.x;
                            }

                            return this.end.x - this.start.x;
                        };

                        this.getHeight = function getHeight(isRotated) {
                            if (isRotated) {
                                return this.defaultCrop.end.y - this.defaultCrop.start.y;
                            }

                            return this.end.y - this.start.y;
                        };

                        this.getRatio = function getRatio() {
                            return (
                                (photo.width * this.getWidth()) / (photo.height * this.getHeight())
                            );
                        };

                        this.rotate = function rotate() {
                            var newCrop = {};
                            var remainingDimension;
                            var photoAspectRatio = photo.width / photo.height;
                            var cropAspectRatio = 1 / this.getRatio(); // the inverse of the getRatio result yields the AR of the rotation

                            if (cropAspectRatio > photoAspectRatio) {
                                // crop is more landscape than the photo
                                // width is known
                                newCrop.x = 0;
                                newCrop.width = 100; // percent
                                // compute the remainder of height in percent
                                remainingDimension =
                                    (100 * (cropAspectRatio - photoAspectRatio)) / cropAspectRatio;
                                // center the crop
                                newCrop.y = remainingDimension / 2;
                                newCrop.height = 100 - remainingDimension;
                            } else {
                                // crop is more portrait than the photo
                                // height is known
                                newCrop.y = 0;
                                newCrop.height = 100; // percent
                                // compute the remainder of width in percent
                                remainingDimension =
                                    (100 * (photoAspectRatio - cropAspectRatio)) / photoAspectRatio;
                                // center the crop
                                newCrop.x = remainingDimension / 2;
                                newCrop.width = 100 - remainingDimension;
                            }

                            this.isRotated = !this.isRotated;

                            this.setCrop(newCrop.x, newCrop.y, newCrop.width, newCrop.height);

                            if (this.isRotated) {
                                this.defaultCrop = {
                                    end: {
                                        x: this.end.x,
                                        y: this.end.y
                                    },
                                    start: {
                                        x: this.start.x,
                                        y: this.start.y
                                    }
                                };
                            }
                        };

                        this.getBoundsAsArray = function getBoundsAsArray() {
                            return [0, 0, this.bounds.width, this.bounds.height, this.bounds.depth];
                        };

                        this.getCsv = function getCsv() {
                            return [this.start.x, this.start.y, this.end.x, this.end.y].join(',');
                        };
                    }
                }
            }
        ];
    }
];
