import bowser from 'bowser';

/**
 * @ngdoc directive
 * @name sp.common.directive:spFormSelect
 * @requires $compile
 * @requires $parse
 * @requires $timeout
 * @requires $document
 * @requires $window
 * @requires translateFilter
 * @requires ngModel
 * @restrict A
 *
 * @description
 * Creates the ShootProof-view of a select element. On medium-sized screens and up, displays an
 * spDownDownList of the options. Otherwise, it allows the select element to behave normally.
 * Uses bowser.
 *
 * @param {string} spFormSelectOptionsVariable Name of the variable on scope that contains an array filtered through spOptions
 * @param {string=} spFormSelectDefaultOptionText Sets the unselected display text for the select. Defaults to an empty string
 * @param {string=} spFormSelectDefaultOptionIsSelectable If "false", will make the default option un-selectable
 * @param {string=} isFilterable If "true", will provide a text input to filter the drop down list's options
 */
export default [
    '$compile',
    '$parse',
    '$timeout',
    '$document',
    '$window',
    'translateFilter',
    function spFormSelectDirective(
        $compile,
        $parse,
        $timeout,
        $document,
        $window,
        translateFilter
    ) {
        return {
            require: 'ngModel',
            restrict: 'A',
            link: function onLink($scope, $element, $attrs, ngModelController) {
                var defaultOptionIsSelectable = true;
                var $labelElement = $compile(
                    '<label ' +
                        'for="' +
                        $attrs.id +
                        '">' +
                        '<sp-svg ' +
                        'hash="iconArrowDown">' +
                        '</sp-svg>' +
                        '</label>'
                )($scope);
                var $dropDownListElement = $compile(
                    '<sp-drop-down-list ' +
                        'items=" getItems() " ' +
                        'on-select=" onSelect " ' +
                        'is-filterable=" isFilterable() " ' +
                        'is-default-option-selectable=" isDefaultOptionSelectable() ">' +
                        '</sp-drop-down-list>'
                )($scope);

                $scope.getItems = function getItems() {
                    return $parse($attrs.spFormSelectOptionsVariable)($scope);
                };

                $scope.onSelect = function onSelect(selectedOption) {
                    if (selectedOption) {
                        ngModelController.$setViewValue(selectedOption.value);
                        ngModelController.$render();
                    }

                    $element[0].blur();

                    $element.addClass('sp-form-select-drop-down-list-item-selected');
                    $element.removeClass('sp-form-select-filterable-drop-down-list-is-open');
                };

                $scope.isFilterable = function isFilterable() {
                    const IS_FILTERABLE_THRESHOLD = 7;
                    const isFilterableAttr = ($attrs.isFilterable || '').trim();
                    const items = $scope.getItems() || [];
                    const itemsAtThreshold = items.length >= IS_FILTERABLE_THRESHOLD;

                    return (
                        isFilterableAttr === 'true' ||
                        (isFilterableAttr !== 'false' && itemsAtThreshold)
                    );
                };

                $scope.isDefaultOptionSelectable = function isDefaultOptionSelectable() {
                    return defaultOptionIsSelectable;
                };

                $scope.$watch('getItems()', function assignDefaultOption(options) {
                    if (!options) {
                        return;
                    }

                    if ($attrs.spFormSelectDefaultOptionText) {
                        if (options.length === 0 || !options[0].isDefaultOption) {
                            options.unshift({
                                text: translateFilter($attrs.spFormSelectDefaultOptionText),
                                isDefaultOption: true
                            });
                        }

                        if ($attrs.spFormSelectDefaultOptionIsSelectable === 'false') {
                            defaultOptionIsSelectable = false;
                        }
                    }
                });

                $element.on('mousedown', function onMouseDown($event) {
                    if (!bowser.tablet && !bowser.mobile) {
                        var element = $element[0];

                        $event.preventDefault();

                        if ($document[0].activeElement === element) {
                            element.blur();
                        } else {
                            $timeout(function focusElement() {
                                element.focus();
                            });
                        }
                    }
                });

                $element.on('focus', function onFocus($event) {
                    $element.removeClass('sp-form-select-drop-down-list-item-selected');

                    if (!bowser.tablet && !bowser.mobile) {
                        $event.preventDefault();

                        if ($scope.isFilterable()) {
                            $element.addClass('sp-form-select-filterable-drop-down-list-is-open');

                            $dropDownListElement.find('input[type="text"]')[0].focus();
                        }
                    }
                });

                $dropDownListElement.on('mousedown', function onDropDownListMouseDown($event) {
                    $event.preventDefault();
                });

                $element.on('keydown', function onKeyDown($event) {
                    var UP = 38;
                    var DOWN = 40;

                    switch ($event.which) {
                        case UP:
                        case DOWN:
                            $event.preventDefault();
                    }

                    $dropDownListElement.triggerHandler($event, $event.which);
                });

                $element.after($dropDownListElement).after($labelElement);

                if (!bowser.tablet && !bowser.mobile) {
                    $element.addClass('sp-form-select-enable-sp-drop-down-list');
                }
            }
        };
    }
];
