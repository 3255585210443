/* eslint-disable func-names, no-unused-vars */

var directiveName = 'spWatch';

export default [
    '$timeout',
    function spWatchDirective($timeout) {
        return {
            restrict: 'AE',
            link: function spWatchController($scope, element, $attributes) {
                var onUpdateExpr = $attributes[directiveName + 'OnUpdate'];

                $scope.$watch(
                    $attributes[directiveName],
                    function(newValue, oldValue) {
                        if (newValue === oldValue) {
                            return;
                        }

                        $scope.$eval(onUpdateExpr);
                    },
                    true
                );
            }
        };
    }
];
