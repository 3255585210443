/**
 * @ngdoc directive
 * @name sp.common.directive:spSidePanel
 * @requires $rootScope
 * @requires sp.common.service:spModal
 * @restrict E
 *
 * @description
 * Create a side panel modal that may be opened with `sp.common.service:spModal`.
 *
 * @param {string} name Name of modal
 * @param {boolean=} hasBack Dictates whether or not to display the back arrow.
 * @param {string} heading Text for the main, top-heading label
 * @param {string=} headingSuper Text for the super-heading label
 * @param {array<object>} actions Array of objects describing footer-buttons
 * @param {string} actions.text Translation to be displayed on the button
 * @param {function} actions.click Function to execute on button click
 * @param {boolean=} actions.show Will the button be shown. Defaults to false.
 * @param {boolean=} actions.primary Indicates if the button is the primary action
 * @param {boolean=} actions.disabled Indicates if the button is disabled
 * @param {string=} actions.type Adds a "type" attribute to the button; helps in
 *     determining if the button should be a "reset" or "submit" type, for example

 * @param {function=} onBack Invoked when the back arrow is clicked.
 * @param {function=} onOpen Invoked when the side panel opens
 * @param {function=} onClose Invoked when the side panel is closed
 * @param {string=} headerSvg svg rendered in the header
 * @param {array<string>=} preLoadingEvents List of event strings that must be
 *     listened for before loading can complete
 */
export default [
    '$rootScope',
    'spModal',
    function spSidePanelDirective($rootScope, spModal) {
        return {
            restrict: 'E',
            scope: {
                name: '=',
                hasBack: '=?',
                heading: '=',
                headingSuper: '=?',
                actions: '=',
                onBack: '=?',
                onOpen: '=?',
                onClose: '=?',
                headerSvg: '=?',
                preLoadingEvents: '=?'
            },
            templateUrl: $rootScope.getDirectiveTemplateUrl('newcommon', 'sp-side-panel'),
            transclude: {
                footerContent: '?footerContent',
                headingSubContent: '?headingSubContent',
                subFooterContent: '?subFooterContent'
            },
            link: function spSidePanelController($scope) {
                $scope.isOpen = false;
                $scope.isLoading = false;

                $scope.close = function close() {
                    if ($scope.onClose) {
                        $scope.onClose();
                    }

                    $scope.isOpen = false;
                };

                spModal.register($scope);
            }
        };
    }
];
