export default [
    '$provide',
    function getImageUrlDecorator($provide) {
        $provide.decorator('$rootScope', [
            '$delegate',
            'spAppData',
            function decorate($delegate, spAppData) {
                var staticUrl = spAppData.get('urls').static;

                $delegate.getImageUrl = function getImageUrl(module, filename) {
                    return staticUrl + '/' + module + '/images/' + filename;
                };

                return $delegate;
            }
        ]);
    }
];
