const BLANK = '░░░░░';
const STATUS = {
    ARCHIVED: 'archived',
    CANCELED: 'canceled',
    COMPLETED: 'completed',
    DRAFT: 'draft',
    EXPIRED: 'expired',
    WAITING_COUNTERSIGN: 'awaiting-studio-countersign',
    WAITING_MULTIPLE_SIGNATURE: 'awaiting-client-multiple-signature',
    WAITING_SIGNATURE: 'awaiting-client-signature'
};
const CSS_CLASS_TO_STATUS_MAP = {
    [STATUS.CANCELED]: 'sp-contract-status-canceled',
    [STATUS.COMPLETED]: 'sp-contract-status-contract-completed',
    [STATUS.DRAFT]: 'sp-contract-status-draft',
    [STATUS.EXPIRED]: 'sp-contract-status-contract-expired',
    [STATUS.WAITING_COUNTERSIGN]: 'sp-contract-status-ready-to-countersign',
    [STATUS.WAITING_MULTIPLE_SIGNATURE]: 'sp-contract-status-awaiting-client-signature',
    [STATUS.WAITING_SIGNATURE]: 'sp-contract-status-awaiting-client-signature'
};

export default {
    BLANK,
    STATUS,
    getCSSClassForStatusText
};

function getCSSClassForStatusText({ contractStatus }) {
    return CSS_CLASS_TO_STATUS_MAP[contractStatus];
}
