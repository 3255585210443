/**
 * @ngdoc directive
 * @name sp.common.directive:spSwitch
 * @requires $rootScopt
 * @restrict E
 * @requires ngModel
 *
 * @description
 * Creates a checkbox in the style of a ShootProof switch.
 *
 * <pre>
    <sp-switch
        check-id="long-form-id-of-switch"
        ng-model=" object.modelData "
        label-text="Display Name">
    </sp-switch>
 * </pre>
 *
 * @param {string} checkId The id associated with the checkbox and its associated label
 * @param {boolean} checkValue The true/false value of the switch associated with the
 * checkbox input's ngModel.
 * @param {string} labelText The text to display in the label next to the switch
 * @param {string=} spSwitchLabelSvg Renders the given svg before the label text
 * @param {*=} spSwitchValue Sets the `ngModel` to the provided value when toggled
 * @param {*=} spSwitchFalseValue Sets the `ngModel` to the provided value when not toggled
 * @param {string=} onChange Function to invoke when the switch is toggled
 * @param {boolean=} isDisabled Designate the disabled state of the switch
 */
export default [
    '$rootScope',
    function spSwitchDirective($rootScope) {
        return {
            restrict: 'E',
            require: '^ngModel',
            scope: {
                checkId: '@',
                labelText: '@',
                labelSvg: '@?spSwitchLabelSvg',
                value: '=?spSwitchValue',
                falseValue: '=?spSwitchFalseValue',
                onChange: '&?',
                isDisabled: '<?'
            },
            templateUrl: $rootScope.getDirectiveTemplateUrl('newcommon', 'sp-switch'),
            link: function spSwitchController($scope, $element, $attrs, ngModelController) {
                $scope.updateModel = function updateModel(toggled) {
                    var trueValue = angular.isDefined($scope.value) ? $scope.value : true;
                    var falseValue = angular.isDefined($scope.falseValue)
                        ? $scope.falseValue
                        : false;

                    ngModelController.$setViewValue(toggled ? trueValue : falseValue);
                    ngModelController.$render();

                    if ($scope.onChange) {
                        $scope.onChange();
                    }
                };

                $scope.$watch(
                    function getModelValue() {
                        return ngModelController.$modelValue;
                    },
                    function setToggled(modelValue) {
                        $scope.toggled = !!modelValue;
                    }
                );
            }
        };
    }
];
