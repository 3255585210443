/* eslint-disable func-names, eqeqeq, padding-line-between-statements */

export default [
    '$rootScope',
    '$route',
    function SPRouteService($rootScope, $route) {
        var routeData;

        $rootScope.$on('$routeChangeSuccess', function() {
            routeData = $route.current.spRoute;

            if (typeof routeData !== 'object') {
                routeData = {};
            }

            $rootScope.$broadcast('SPRoute:DataUpdate', routeData);
        });

        this.hasRouteData = function hasRouteData() {
            return typeof routeData === 'object';
        };

        this.getRouteData = function getRouteData() {
            if (typeof routeData !== 'object') {
                return false;
            }

            return routeData;
        };

        this.getControllerName = function getControllerName() {
            if (typeof $route.current.$$route !== 'object') {
                return $route.current.controller;
            }

            return $route.current.$$route.controller;
        };

        this.isControllerName = function isControllerName(controllerName) {
            return this.getControllerName() === controllerName;
        };

        this.getRouteById = function getRouteById(id) {
            for (var key in $route.routes) {
                if ($route.routes[key].spRouteId == id) {
                    return $route.routes[key];
                }
            }

            return false;
        };

        function assembleRoute(route, paramValues) {
            var routePath = route.originalPath;
            var params = routePath.match(/:[A-Za-z-_]+/g);

            angular.forEach(params, function(param) {
                var key = param.substring(1);
                routePath = routePath.replace(param, paramValues[key]);
            });

            if (routePath[0] == '/') {
                routePath = routePath.substring(1);
            }

            return routePath;
        }

        this.getCurrentUri = function getCurrentUri() {
            return assembleRoute($route.current, $route.current.params);
        };

        this.getAssembledRouteUri = function getAssembledRouteUri(route) {
            return assembleRoute(route, $route.current.params);
        };
    }
];
