export default [
    'SPDataObjectsProvider',
    function AlbumConfig(SPDataObjectsProvider) {
        SPDataObjectsProvider.defineDataObject('Album', function Album(data) {
            this.id = data.id;
            this.name = data.n;
            this.requiresPassword = data.p;
            this.coverPhoto = data.c;
            this.thumbnail = data.th;
            this.photoCount = data.xp;
            this.albumCount = data.xa;
            this.parentAlbumId = data.t;
            this.isSecret = data.s;
        });
    }
];
