/* eslint-disable func-names, no-redeclare */

var directiveName = 'spNumberInput';

export default [
    '$filter',
    function spNumberInputDirective($filter) {
        return {
            require: 'ngModel',
            restrict: 'A',
            link: function spNumberInputController($scope, $element, $attributes, ngModel) {
                if (!ngModel) {
                    return;
                }

                var options = {
                    min: false,
                    max: false,
                    filter: 'number'
                };

                if ($attributes[directiveName + 'Min']) {
                    options.min = Number($attributes[directiveName + 'Min']);
                }

                if ($attributes[directiveName + 'Max']) {
                    options.max = Number($attributes[directiveName + 'Max']);
                }

                if (typeof $attributes[directiveName + 'Filter'] === 'string') {
                    options.filter = $attributes[directiveName + 'Filter'];
                }

                var filter = function filter(value) {
                    return value;
                };

                if (options.filter.length) {
                    var newFilter = $filter(options.filter);

                    if (typeof newFilter === 'function') {
                        filter = newFilter;
                    }
                }

                ngModel.$formatters.push(function(value) {
                    return filter(value);
                });

                ngModel.$parsers.unshift(function(value) {
                    var value = value.replace(/[^0-9\-,]/g, '');
                    var integerValue = parseInt(value.replace(/[^0-9-]/g, ''), 10);

                    if (typeof options.min === 'number' && integerValue < options.min) {
                        value = integerValue = options.min;
                    }

                    if (typeof options.max === 'number' && integerValue > options.max) {
                        value = integerValue = options.max;
                    }

                    ngModel.$viewValue = filter(integerValue);
                    ngModel.$render();

                    return integerValue;
                });
            }
        };
    }
];
