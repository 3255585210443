export default function spSelectMinValidatorDirective() {
    return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
            ngMin: '='
        },
        link: function spSelectMinValidatorController($scope, $element, $attrs, ngModelController) {
            $scope.$watch('ngMin', () => ngModelController.$validate());

            initialize();

            function initialize() {
                ngModelController.$validators.min = minValidator;
            }

            function minValidator(value) {
                if (value) {
                    return parseInt(value) >= $scope.ngMin;
                }

                return true;
            }
        }
    };
}
