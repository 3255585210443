/**
 * @ngdoc service
 * @name sp.common.service:spSlideRegistry
 * @requires $templateCache
 * @requires $templateRequest
 *
 * @description
 * Used for modals that are sequential. Tracks their list of
 * slides and gives them the ability to easily move between
 * them.
 */
export default [
    '$templateCache',
    '$templateRequest',
    function spSlideRegistryService($templateCache, $templateRequest) {
        return new SpSlideRegistry();

        function SpSlideRegistry() {
            /**
             * @ngdoc property
             * @name sp.common.service:spSlideRegistry#slides
             * @propertyOf sp.common.service:spSlideRegistry
             * @returns {object} All slide definitions
             */
            this.slides = {};

            /**
             * @ngdoc property
             * @name sp.common.service:spSlideRegistry#currentSlide
             * @propertyOf sp.common.service:spSlideRegistry
             * @returns {object} The current slide
             */
            this.currentSlide = null;

            /**
             * @ngdoc property
             * @name sp.common.service:spSlideRegistry#history
             * @propertyOf sp.common.service:spSlideRegistry
             * @returns {array} Slide history stored through usage of #goTo
             */
            this.history = [];

            /**
             * @ngdoc function
             * @name sp.common.service:spSlideRegistry#setSlides
             * @methodOf sp.common.service:spSlideRegistry
             *
             * @description
             * Sets the object describing all slides for the modal. Can
             * also optionally set the current slide at the same time.
             *
             * @param {object} slides List of slides in the modal
             * @param {string=} slides.templateUrl Location of the template file
             * @param {string=} slides.template HTML template. Will add `.templateName` to the slide
             *     which can be used with `ngInclude`
             * @param {string=} currentSlideName Sets the current slide
             *
             * @returns {SpSlideRegistry} A copy of SpSlideRegistry
             */
            this.setSlides = function setSlides(slides, currentSlideName = null) {
                this.slides = slides;

                angular.forEach(slides, (slide, key) => {
                    const { template, templateUrl } = slide;

                    if (template) {
                        slide.templateName = key;

                        $templateCache.put(slide.templateName, template);
                    }

                    if (templateUrl) {
                        $templateRequest(templateUrl, true);
                    }
                });

                if (currentSlideName) {
                    this.setCurrentSlide(currentSlideName);
                }

                return angular.copy(this);
            };

            /**
             * @ngdoc function
             * @name sp.common.service:spSlideRegistryy#getCurrentSlide
             * @methodOf sp.common.service:spSlideRegistry
             *
             * @description
             * Returns the current slide.
             *
             * @returns {object} Current slide object
             */
            this.getCurrentSlide = function getCurrentSlide() {
                return this.currentSlide;
            };

            /**
             * @ngdoc function
             * @name sp.common.service:spSlideRegistry#setCurrentSlide
             * @methodOf sp.common.service:spSlideRegistry
             *
             * @description
             * Sets the current slide
             *
             * @param {object} slideName Name of the slide to set current slide to
             *
             * @returns {object} The current slide
             */
            this.setCurrentSlide = function setCurrentSlide(slideName) {
                this.currentSlide = this.slides[slideName];

                return this.currentSlide;
            };

            /**
             * @ngdoc function
             * @name sp.common.service:spSlideRegistry#isCurrentSlide
             * @methodOf sp.common.service:spSlideRegistry
             *
             * @description
             * Returns if the slide name is the current slide
             *
             * @returns {boolean} True if slide name is current slide's name
             */
            this.isCurrentSlide = function isCurrentSlide(slideName) {
                return this.currentSlide === this.slides[slideName];
            };

            /**
             * @ngdoc function
             * @name sp.common.service:spSlideRegistry#goTo
             * @methodOf sp.common.service:spSlideRegistry
             *
             * @description
             * Sets the current slide to be the given slide.
             * Automatically pushes old side onto history unless
             * told otherwise.
             *
             * @param {string} slideName Name of the slide to go to.
             * @param {boolean=} ignoreHistory Indicates whether to
             *                      push the current slide onto history.
             *                      Defaults to false.
             *
             * @returns {object} The current slide
             */
            this.goTo = function goTo(slideName, ignoreHistory) {
                if (!ignoreHistory) {
                    this.history.push(this.currentSlide);
                }

                this.setCurrentSlide(slideName);

                return this.currentSlide;
            };

            /**
             * @ngdoc function
             * @name sp.common.service:spSlideRegistry#goToPrevious
             * @methodOf sp.common.service:spSlideRegistry
             *
             * @description
             * Sets the current slide the most recent slide in history.
             * Pops that slide off of the history array.
             *
             * @returns {object} The current slide
             */
            this.goToPrevious = function goToPrevious() {
                this.currentSlide = this.history.pop();

                return this.currentSlide;
            };
        }
    }
];
