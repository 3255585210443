export default [
    '$provide',
    function showSupportPhoneDecorator($provide) {
        $provide.decorator('$rootScope', [
            '$delegate',
            'spAppData',
            function decorate($delegate, spAppData) {
                var studioData = spAppData.get('studioData');

                $delegate.showSupportPhone = function showSupportPhone() {
                    return studioData.showSupportPhone;
                };

                return $delegate;
            }
        ]);
    }
];
