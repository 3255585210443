/**
 * @ngdoc directive
 * @name sp.common.directive:spBoundedScroll
 * @restrict A
 *
 * @description
 * Adds visual cues to a scrollable element to show that it can be scrolled.
 */
export default [
    function spBoundedScrollDirective() {
        return {
            restrict: 'A',
            link: function spBoundedScrollController($scope, $element) {
                var element = $element[0];
                var $topScrollBound = angular.element(
                    '<div ' +
                        'class="sp-bounded-scroll-bound sp-bounded-scroll-bound-top">' +
                        '</div>'
                );
                var $bottomScrollBound = angular.element(
                    '<div ' +
                        'class="sp-bounded-scroll-bound sp-bounded-scroll-bound-bottom">' +
                        '</div>'
                );

                $scope.$watch(function setIfScrollableAndAssignTopScrollBoundBottom() {
                    setTimeout(function setAfterRender() {
                        $topScrollBound.css({ bottom: element.clientHeight + 'px' });

                        if (element.scrollHeight > element.clientHeight) {
                            $element.addClass('sp-bounded-scroll-scrollable');
                        } else {
                            $element.removeClass('sp-bounded-scroll-scrollable');
                        }
                    });
                });

                $element.on('scroll', function adjustBounds() {
                    if (element.scrollTop > 0) {
                        $element.addClass('sp-bounded-scroll-scrolled');
                    } else {
                        $element.removeClass('sp-bounded-scroll-scrolled');
                    }

                    if (element.scrollTop + element.clientHeight === element.scrollHeight) {
                        $element.addClass('sp-bounded-scroll-scrolled-to-bottom');
                    } else {
                        $element.removeClass('sp-bounded-scroll-scrolled-to-bottom');
                    }
                });

                $element.after($topScrollBound);
                $element.after($bottomScrollBound);
            }
        };
    }
];
