export default [
    '$provide',
    function spModalDecorator($provide) {
        $provide.decorator('$rootScope', [
            '$delegate',
            'spModal',
            function decorate($delegate, spModal) {
                $delegate.spModal = spModal;

                return $delegate;
            }
        ]);
    }
];
