import React, { createContext, useContext, useState } from 'react';

export interface IBrandThemeEventLogo {
    height: number;
    width: number;
    url: string;
}
export interface IBrandTheme {
    backgroundOptionId: number;
    darkPrimaryColor: string;
    darkPrimaryColorHover: string;
    eventLogo?: IBrandThemeEventLogo | null;
    fontPairingId: number;
    primaryColor: string;
    primaryColorHover: string;
}
export interface IBrandThemeContext {
    brandTheme: IBrandTheme;
}

export const BrandThemeContext = createContext<IBrandThemeContext>({
    brandTheme: {
        backgroundOptionId: 0,
        darkPrimaryColor: '',
        darkPrimaryColorHover: '',
        fontPairingId: 0,
        primaryColor: '',
        primaryColorHover: '',
        eventLogo: undefined
    }
});
interface IBrandThemeProviderProps {
    children?: React.ReactNode;
    brandTheme: IBrandTheme;
}

export const BrandThemeProvider: React.FC<IBrandThemeProviderProps> = ({
    brandTheme,
    ...props
}) => {
    const [storedBrandTheme] = useState(brandTheme);

    return <BrandThemeContext.Provider value={{ brandTheme: storedBrandTheme }} {...props} />;
};

interface IWithBrandThemeProps {
    spAppData: SpAngularJs.ISpAppData;
}

export const withBrandTheme = <T extends object>(
    Component: React.ComponentType<T & IWithBrandThemeProps>
): React.FC<T & IWithBrandThemeProps> => {
    const WithBrandThemeComponent: React.FC<T & IWithBrandThemeProps> = (props) => {
        const brandTheme = props.spAppData.get<IBrandTheme>('themeData');

        return (
            <BrandThemeProvider brandTheme={brandTheme}>
                <Component {...props} />
            </BrandThemeProvider>
        );
    };

    return WithBrandThemeComponent;
};

export function useBrandTheme(): IBrandThemeContext {
    const { brandTheme } = useContext(BrandThemeContext);

    return { brandTheme };
}
