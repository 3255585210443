import bowser from 'bowser';
import spFormSelectTemplate from '../../templates/components/sp-form-select.nghtml';
import isFunction from 'lodash.isfunction';
import memoize from 'lodash.memoize';
import { keyboardKeys as KEYBOARD_KEYS } from 'Sp/Utility';

const STYLE_CLASSES = {
    ENABLE_DROP_DOWN: 'sp-form-select-enable-sp-drop-down-list',
    ITEM_IS_SELECTED: 'sp-form-select-drop-down-list-item-selected',
    LIST_IS_OPEN: 'sp-form-select-filterable-drop-down-list-is-open',
    TREE_ITEM_IS_SELECTED: 'sp-form-select-tree-item-is-selected'
};
const FOCUSABLE_ELEMENTS = {
    ROOT: 'root',
    DROP_DOWN: 'drop_down',
    DROP_DOWN_INPUT: 'drop_down_input',
    SELECT: 'select'
};

/**
 * @ngdoc component
 * @name sp.common.component:spFormSelect
 * @requires $scope
 * @requires $timeout
 * @requires sp.common.service.spFocus
 * @requires translateFilter
 *
 * @description
 * Creates the ShootProof-view of a select element. On medium-sized screens and up, displays an
 * spDownDownList of the options. Otherwise, it allows the select element to behave normally.
 * Uses bowser.
 *
 * @param {string=} defaultOptionIsSelectable If "false", will make the default option un-selectable
 * @param {string=} defaultOptionText Sets the unselected display text for the select. Defaults to
 *     an empty string
 * @param {string=} filterable If "true", will provide a text input to filter the drop down list's
 *     options
 * @param {boolean=} isDisabled If true, disable the select dropdown
 * @param {boolean=} isRequired If true, select component is marked as required
 * @param {string=} name Include a name for the select element in order for error handling to work appropriately
 * @param {function} onChange Callback function to invoke upon selecting an option
 * @param {string} options Name of the variable on scope that contains an array filtered through
 *     spOptions
 * @param {boolean=} translateOptions Indicator to determine whether the provided option text values
 *     should be translated
 * @param {array<object>=} tree An array of objects with a `children` property. Should correlate to
 *     the provided `options`
 * @param {object=} treeConfig The configuration for use in rendering the provided `tree`
 * @param {object=} treeConfig.iconConfig Configures icons to be displayed for each object
 * @param {string} treeConfig.iconConfig.hasProperty Property to check to render the SVG
 * @param {string} treeConfig.iconConfig.svgHash SVG to render
 * @param {string} treeConfig.primaryTextProperty Property of the object to display in the option
 * @param {function} treeConfig.secondaryTextFunction Renders the result as secondary text for each
 *     option. Is passed the entire object
 * @param {string} treeConfig.valueProperty Property of the object to set when selected
 * @param {object=} viewModel Bound value to populate the select element (ng-model pass through)
 */
export default {
    bindings: {
        defaultOptionIsSelectable: '<?',
        defaultOptionText: '<?',
        filterable: '<?',
        isDisabled: '<?',
        isRequired: '<?',
        name: '@?',
        onChange: '&?',
        options: '<',
        translateOptions: '<?',
        tree: '<?',
        treeConfig: '<?',
        viewModel: '='
    },
    template: spFormSelectTemplate,
    controller: [
        '$scope',
        '$timeout',
        'spFocus',
        'translateFilter',
        function spFormSelectController($scope, $timeout, spFocus, translateFilter) {
            const $ctrl = this;
            const IS_FILTERABLE_THRESHOLD = 7;
            const { $id } = $scope;
            const FOCUSABLE_ELEMENT_IDS = {
                [FOCUSABLE_ELEMENTS.ROOT]: `sp-form-select-root-${$id}`,
                [FOCUSABLE_ELEMENTS.DROP_DOWN]: `sp-form-select-drop-down-${$id}`,
                [FOCUSABLE_ELEMENTS.DROP_DOWN_INPUT]: `sp-form-select-drop-down-input-${$id}`,
                [FOCUSABLE_ELEMENTS.SELECT]: `sp-form-select-${$id}`
            };
            let isOpen = false;

            $ctrl.$id = $id;
            $ctrl.FOCUSABLE_ELEMENTS = FOCUSABLE_ELEMENTS;
            $ctrl.FOCUSABLE_ELEMENT_IDS = FOCUSABLE_ELEMENT_IDS;
            $ctrl.elementClasses = {};
            $ctrl.selectedTreeNode = [];

            $ctrl.$doCheck = $doCheck;
            $ctrl.getItems = memoize(getItems);
            $ctrl.isFilterable = memoize(isFilterable);
            $ctrl.onBlur = onBlur;
            $ctrl.onFocus = onFocus;
            $ctrl.onKeyDown = onKeyDown;
            $ctrl.onMouseDown = onMouseDown;
            $ctrl.onSelect = onSelect;
            $ctrl.translateOptionTextFilter = memoize(translateOptionTextFilter);

            initialize();

            function $doCheck() {
                if ($ctrl.selectedTreeNode.length > 0) {
                    $ctrl.viewModel = $ctrl.selectedTreeNode[0][$ctrl.treeConfig.valueProperty];
                    $ctrl.selectedTreeNode = [];

                    if ($ctrl.onChange) {
                        $timeout($ctrl.onChange);
                    }

                    onBlur();
                }
            }

            function initialize() {
                initializeStyleClasses();

                if (isDesktopView()) {
                    toggleStyleClass(STYLE_CLASSES.ENABLE_DROP_DOWN, true);
                }
            }

            function initializeStyleClasses() {
                $ctrl.elementClasses = {
                    [STYLE_CLASSES.ENABLE_DROP_DOWN]: false,
                    [STYLE_CLASSES.ITEM_IS_SELECTED]: false,
                    [STYLE_CLASSES.LIST_IS_OPEN]: false,
                    [STYLE_CLASSES.TREE_ITEM_IS_SELECTED]: false
                };
            }

            function isDesktopView() {
                return !bowser.tablet && !bowser.mobile;
            }

            function getItems(options = $ctrl.options) {
                const { defaultOptionText } = $ctrl;

                if (!options) {
                    return [];
                }

                if (options.length && $ctrl.translateOptions) {
                    options = options.map((option) => {
                        return {
                            ...option,
                            text: translateFilter(option.text)
                        };
                    });
                }

                if (defaultOptionText) {
                    if (options.length === 0 || !options[0].isDefaultOption) {
                        options.unshift({
                            text: translateFilter(defaultOptionText),
                            isDefaultOption: true
                        });
                    }
                }

                return options;
            }

            function isFilterable(items = getItems()) {
                const { filterable } = $ctrl;

                if ($ctrl.hasOwnProperty('filterable')) {
                    return Boolean(filterable);
                }

                return items.length >= IS_FILTERABLE_THRESHOLD;
            }

            function onBlur() {
                isOpen = false;

                if (isDesktopView()) {
                    toggleStyleClass(STYLE_CLASSES.ITEM_IS_SELECTED, true);
                    toggleStyleClass(STYLE_CLASSES.LIST_IS_OPEN, false);
                }

                toggleStyleClass(STYLE_CLASSES.TREE_ITEM_IS_SELECTED, true);
            }

            function onFocus($event) {
                toggleStyleClass(STYLE_CLASSES.ITEM_IS_SELECTED, false);
                toggleStyleClass(STYLE_CLASSES.TREE_ITEM_IS_SELECTED, false);

                if (isDesktopView()) {
                    $event.preventDefault();
                    $event.stopPropagation();

                    if (isFilterable()) {
                        toggleStyleClass(STYLE_CLASSES.LIST_IS_OPEN, true);

                        $timeout(() =>
                            spFocus.focus(FOCUSABLE_ELEMENT_IDS[FOCUSABLE_ELEMENTS.DROP_DOWN_INPUT])
                        );

                        return;
                    }

                    spFocus.focus(FOCUSABLE_ELEMENT_IDS[FOCUSABLE_ELEMENTS.DROP_DOWN]);
                }
            }

            function onKeyDown($event) {
                switch ($event.which) {
                    case KEYBOARD_KEYS.UP_ARROW:
                    case KEYBOARD_KEYS.DOWN_ARROW:
                        $event.preventDefault();
                }

                spFocus.triggerEventHandler(
                    FOCUSABLE_ELEMENT_IDS[FOCUSABLE_ELEMENTS.DROP_DOWN],
                    $event,
                    $event.which
                );
            }

            function onMouseDown($event) {
                if (isDesktopView()) {
                    const selectElementId = FOCUSABLE_ELEMENT_IDS[FOCUSABLE_ELEMENTS.SELECT];

                    isOpen ? spFocus.blur(selectElementId) : spFocus.focus(selectElementId);

                    $event.preventDefault();
                }

                isOpen = !isOpen;
            }

            function onSelect(selectedOption) {
                const selectedValue = isDesktopView() ? selectedOption.value : selectedOption;

                $ctrl.viewModel = selectedValue;

                if ($scope.$parent.$parent && $scope.$parent.$parent.form) {
                    $scope.$parent.$parent.form.$setDirty();
                }

                if (isFunction(selectedValue) && !$ctrl.onChange) {
                    selectedValue();

                    return;
                }

                if ($ctrl.onChange) {
                    $timeout($ctrl.onChange);
                }

                $ctrl.onBlur();
            }

            function toggleStyleClass(className, value = null) {
                let currentValue = value;

                if (value === null) {
                    currentValue = !$ctrl.elementClasses[className];
                }

                $ctrl.elementClasses = {
                    ...$ctrl.elementClasses,
                    [className]: currentValue
                };
            }

            function translateOptionTextFilter(item) {
                const { text, value, isDefaultOption } = item;

                if (!isDefaultOption && $ctrl.translateOptions) {
                    const newItem = {
                        text: translateFilter(text),
                        value
                    };

                    return newItem;
                }

                return true;
            }
        }
    ]
};
