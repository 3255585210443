/**
 * @ngdoc directive
 * @name sp.client.common.directives:spLogo
 * @restrict E
 *
 * @description
 * Renders a logo or text relating to the brand
 *
 * @param {object} logo A logo
 * @param {string} logo.url A url for the logo image. Will calculate best fit when present
 * @param {object} brand The logo's brand
 * @param {boolean} linkable Will go to the contact info page of the brand when clicked if true
 */
export default [
    '$location',
    '$rootScope',
    'SPPhoto',
    function spLogoDirective($location, $rootScope, SPPhoto) {
        return {
            restrict: 'E',
            scope: {
                logo: '=',
                brand: '=',
                linkable: '&'
            },
            link: function spLogoController($scope, $element) {
                const linkable = $scope.linkable() === true;
                if (linkable) {
                    let infoLocationUrl = null;

                    if ($rootScope.isAboutContactVisible()) {
                        infoLocationUrl = 'info/contact';
                    } else if ($rootScope.isAboutMessageVisible()) {
                        infoLocationUrl = 'info/message';
                    } else if ($rootScope.isAboutPricingVisible()) {
                        infoLocationUrl = 'info/pricing';
                    }

                    if (infoLocationUrl !== null) {
                        $element.css('cursor', 'pointer').on('click', () => {
                            $scope.$apply(() => {
                                $location.url(infoLocationUrl);
                            });
                        });
                    }
                }

                if ($scope.logo.url) {
                    angular
                        .element('<img>')
                        .attr('src', $scope.logo.url)
                        .attr('alt', $scope.brand)
                        .addClass('brand-logo-img')
                        .appendTo($element);
                } else {
                    angular
                        .element('<h5>')
                        .addClass('theme-color-primary')
                        .html($scope.brand)
                        .appendTo($element);
                }

                $scope.$watch('$parent.clientWidth', (clientWidth) => {
                    if ($scope.logo.url) {
                        let boundBox;

                        if (clientWidth > 1024) {
                            boundBox = {
                                width: 300,
                                height: 225
                            };
                        } else {
                            boundBox = {
                                width: 220,
                                height: 165
                            };
                        }

                        $scope.dimensions = SPPhoto.getPhotoDimensions(
                            $scope.logo,
                            false,
                            boundBox
                        );

                        $element.width($scope.dimensions.width).height($scope.dimensions.height);
                    } else {
                        $element.height('auto');
                    }
                });
            }
        };
    }
];
