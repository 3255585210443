import spFormElementTemplate from '../../templates/directives/sp-form-element.nghtml';

/**
 * @ngdoc directive
 * @name sp.common.directive:spFormElement
 * @restrict E
 *
 * @description
 * Should wrap *all* ShootProof form elements. Handles uniformity of labels and error text.
 *
 * @param {object=} form Angular form to use a the parent form
 * @param {string} labelText Text for the label that can display above the element
 * @param {string=} labelSubText Text for the label that can display underneath the main labelText
 *     for the element
 * @param {string=} afterText Description text for the label that can display below the element
 * @param {string=} errorText Text for the label that can display underneath the element when there
 *     is a form submission error
 * @param {string=} spFormElementValidationFieldText Text to be used instead of the label text for
 *     validation error messages
 * @param {string=} validationType Indicates default type of validation. Options are: select
 */
export default [
    function spFormElementDirective() {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                form: '=?',
                labelText: '@',
                labelSubText: '@?',
                afterText: '@?',
                errorText: '@?',
                validationFieldText: '@?spFormElementValidationFieldText',
                validationType: '@?'
            },
            template: spFormElementTemplate,
            link: function onLink($scope, $element) {
                var $transcludedFieldElement;
                var isRadioGroup = false;

                $scope.form = findClosestForm();

                $scope.getMax = function getMax() {
                    return $transcludedFieldElement.attr('ng-max');
                };

                $scope.getMaxLength = function getMaxLength() {
                    return $transcludedFieldElement.attr('ng-maxlength');
                };

                $scope.getMin = function getMin() {
                    return $transcludedFieldElement.attr('ng-min');
                };

                $scope.getMinLength = function getMinLength() {
                    return $transcludedFieldElement.attr('ng-minlength');
                };

                $scope.getCurrencySymbol = function getCurrencySymbol() {
                    return $transcludedFieldElement.attr('currency-symbol');
                };

                $scope.hasFormErrors = function hasFormErrors() {
                    var formField = $scope.form[$scope.name];

                    if (!formField) {
                        return false;
                    }

                    return (
                        (formField.$touched || $scope.form.$submitted) &&
                        Object.keys(formField.$error).length > 0
                    );
                };

                $scope.getValidationErrorMessage = function getValidationErrorMessage(errorType) {
                    var transcludedFieldElementType = getFieldElementType();

                    if (
                        errorType === transcludedFieldElementType &&
                        transcludedFieldElementType !== 'url'
                    ) {
                        return 'common.formElement.validationErrorMessages.invalid';
                    }

                    var validationErrorMessages = {
                        cardNumber: 'common.formElement.validationErrorMessages.invalid',
                        checkbox: {
                            required: 'common.formElement.validationErrorMessages.checked'
                        },
                        date: {
                            max: 'common.formElement.validationErrorMessages.date.max',
                            min: 'common.formElement.validationErrorMessages.date.min'
                        },
                        duplicateValue: 'common.formElement.validationErrorMessages.duplicate',
                        email: 'common.formElement.validationErrorMessages.invalid',
                        invalidEmailList:
                            'common.formElement.validationErrorMessages.invalidEmailList',
                        exceedMaximumRecipients:
                            'common.formElement.validationErrorMessages.exceedMaximumRecipients',
                        max: 'common.formElement.validationErrorMessages.max',
                        maxlength: 'common.formElement.validationErrorMessages.maxlength',
                        min: 'common.formElement.validationErrorMessages.min',
                        minlength: 'common.formElement.validationErrorMessages.minlength',
                        pattern: 'common.formElement.validationErrorMessages.invalid',
                        required: 'common.formElement.validationErrorMessages.required',
                        select: {
                            required: 'common.formElement.validationErrorMessages.select.required'
                        },
                        url: 'common.formElement.validationErrorMessages.url'
                    };
                    var validationErrorMessagesForTranscludedFieldElement =
                        validationErrorMessages[transcludedFieldElementType];
                    var validationErrorMessage;

                    if (
                        validationErrorMessagesForTranscludedFieldElement &&
                        validationErrorMessagesForTranscludedFieldElement[errorType]
                    ) {
                        validationErrorMessage =
                            validationErrorMessagesForTranscludedFieldElement[errorType];
                    } else {
                        validationErrorMessage = validationErrorMessages[errorType];
                    }

                    return validationErrorMessage;
                };

                var unwatchForTranscludedFieldElement = $scope.$watch(
                    function watchForTranscludedFieldElement() {
                        return getTranscludedFieldElement();

                        function getTranscludedFieldElement() {
                            var formFields = ['input', 'select', 'textarea'];

                            for (var i = 0; i < formFields.length; i++) {
                                var $transcludedFieldElement = $element.find(formFields[i]);

                                if ($transcludedFieldElement.length > 0) {
                                    return $transcludedFieldElement;
                                }
                            }
                        }
                    },
                    function withElement($element) {
                        $transcludedFieldElement = $element;

                        if ($transcludedFieldElement) {
                            var FORM_HAS_FOCUSED_FIELD_CLASS = 'sp-form-element-focused';
                            var transcludedField = $transcludedFieldElement[0];

                            $scope.labelFor = transcludedField.id;
                            $scope.name = transcludedField.name;
                            $scope.isCurrency =
                                $transcludedFieldElement.attr('ng-currency') !== undefined;
                            isRadioGroup = getFieldElementType() === 'radio';

                            if (!$scope.labelFor) {
                                var unwatchForId = $scope.$watch(
                                    function watchForId() {
                                        return $transcludedFieldElement.attr('id');
                                    },
                                    function setLabelFor(id) {
                                        if (id) {
                                            $scope.labelFor = id;

                                            unwatchForId();
                                        }
                                    }
                                );
                            }

                            if (!$scope.name) {
                                var unwatchForName = $scope.$watch(
                                    function watchForName() {
                                        return $transcludedFieldElement.attr('name');
                                    },
                                    function setName(name) {
                                        if (name) {
                                            $scope.name = name;

                                            unwatchForName();
                                        }
                                    }
                                );
                            }

                            $scope.$watch(
                                function watchTranscludedFieldElementRequiredAttribute() {
                                    return $transcludedFieldElement.attr('required');
                                },
                                function setIsRequired(required) {
                                    var isRequired = required === 'required';

                                    $scope.isRequired = isRequired;
                                }
                            );

                            if (!isRadioGroup) {
                                $scope.$watch(
                                    function watchTranscludedFieldElementDisabledAttribute() {
                                        return $transcludedFieldElement.attr('disabled');
                                    },
                                    function setIsDisabled(disabled) {
                                        var isDisabled = disabled === 'disabled';

                                        $scope.isDisabled = isDisabled;

                                        $element.attr('disabled', isDisabled ? 'disabled' : null);
                                    }
                                );
                            }

                            $transcludedFieldElement.on('focus', function setFormFocus() {
                                $scope.form.$$element.addClass(FORM_HAS_FOCUSED_FIELD_CLASS);
                            });

                            $transcludedFieldElement.on('blur', function unsetFormFocus() {
                                $scope.form.$$element.removeClass(FORM_HAS_FOCUSED_FIELD_CLASS);
                            });

                            unwatchForTranscludedFieldElement();
                        }
                    }
                );

                function findClosestForm(scope) {
                    if (!scope) {
                        scope = $scope;
                    }

                    const { $ctrl, form } = scope;

                    if (form) {
                        return form;
                    }

                    if ($ctrl && $ctrl.form) {
                        return $ctrl.form;
                    }

                    return findClosestForm(scope.$parent);
                }

                function getFieldElementType() {
                    return (
                        $scope.validationType ||
                        $transcludedFieldElement.attr('type') ||
                        $transcludedFieldElement[0].tagName.toLowerCase()
                    );
                }
            }
        };
    }
];
