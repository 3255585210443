export const AFFILIATE_PROGRAM_SUMMARY_TYPE = 'affiliate-program-summary';
export const ACCOUNT_CLOSURE_REQUEST_TYPE = 'account-closure-request';
export const BRAND_HOMEPAGE_ABOUT_IMAGE_TYPE = 'brand-homepage-about-image';
export const BRAND_HOMEPAGE_TYPE = 'brand-homepage';
export const BRAND_THEME_TYPE = 'brand-theme';
export const BRAND_TYPE = 'brand';
export const CONTACT_TAG_TYPE = 'contact-tag';
export const CONTACT_TYPE = 'contact';
export const CONTRACT_TYPE = 'contract';
export const CONTRACT_SIGNATURE_TYPE = 'contract-signature';
export const CURATED_EMAIL_AUTOMATION_GROUP_TYPE = 'curated-email-automation-group';
export const CURATED_EMAIL_TEMPLATE_COLLECTION_TYPE = 'curated-email-template-collection';
export const DASHBOARD_MESSAGE_TYPE = 'dashboard-message';
export const DIGITAL_RULE_TYPE = 'digital-rule';
export const DPA_TYPE = 'dpa';
export const EMAIL_AUTOMATION_TYPE = 'email-automation';
export const EMAIL_AUTOMATION_GROUP_TYPE = 'email-automation-group';
export const EMAIL_TEMPLATE_TYPE = 'email-template';
export const EMAIL_TEMPLATE_TYPE_TYPE = 'email-template-type';
export const EMAIL_TYPE = 'email';
export const EVENT_ALBUM_CHILDREN_TYPE = 'children';
export const EVENT_ALBUM_COLLECTION_TYPE = 'event-album-collection';
export const EVENT_ALBUM_PHOTO_TYPE = 'event-album-photo';
export const EVENT_ALBUM_TYPE = 'event-album';
export const EVENT_COLLECTION_TYPE = 'event-collection';
export const EVENT_CONTACT_TYPE = 'event-contact';
export const EVENT_CONTACT_DEFAULTS_TYPE = 'event-contact-defaults';
export const EVENT_DEFAULTS_TYPE = 'event-defaults';
export const EVENT_PASSWORD_ROTATION_TYPE = 'event-password-rotation';
export const EVENT_PHOTO_TYPE = 'event-photo';
export const EVENT_PHOTO_COLLECTION_TYPE = 'event-photo-collection';
export const EVENT_TYPE = 'event';
export const EVENT_VOLUME_QR_CODE_TYPE = 'event-qr-code';
export const EVENT_VOLUME_QR_COLLECTION_TYPE = 'event-qr-code-collection';
export const EVENT_VOLUME_QR_TYPE = 'qr-code';
export const EVENT_VOLUME_QR_PDF_TYPE = 'event-qr-codes-pdf';
export const GALLERY_ACTIVITY_TYPE = 'gallery-activity-summary';
export const GATEWAY_STATUS_TYPE = 'gateway-status';
export const INVOICE_CREDIT_CARD_TYPE = 'invoice-credit-card';
export const INVOICE_DISCOUNT_TEMPLATE_TYPE = 'invoice-discount-template';
export const INVOICE_DISCOUNT_TYPE = 'invoice-discount';
export const INVOICE_INSTALLMENT_TYPE = 'invoice-installment';
export const INVOICE_ITEM_TEMPLATE_TYPE = 'invoice-item-template';
export const INVOICE_ITEM_TYPE = 'invoice-item';
export const INVOICE_PAYMENT_PAYMENT_INTENT_TYPE = 'invoice-payment-payment-intent';
export const INVOICE_PAYMENT_SETUP_INTENT_TYPE = 'invoice-payment-setup-intent';
export const INVOICE_PAYMENT_TYPE = 'invoice-payment';
export const INVOICE_REFUND_TYPE = 'invoice-refund';
export const INVOICE_TEMPLATE_TYPE = 'invoice-template';
export const INVOICE_TYPE = 'invoice';
export const KNOWLEDGE_BASE_ARTICLE_LIST_API_PATH = '/knowledge-base-article';
export const LAB_CATALOG_PRODUCT_TYPE = 'lab-catalog-product';
export const LAB_CATALOG_SHIPPING_OPTION_TYPE = 'lab-catalog-shipping-option';
export const LAB_CATALOG_TYPE = 'lab-catalog';
export const LAB_ORDER_SUMMARY_TYPE = 'lab-order-summary';
export const MARKET_ORDER_PROMO_TYPE = 'market-order-promo';
export const MERCHANT_ACCOUNT_TYPE = 'merchant-account';
export const ME_TYPE = 'me';
export const NOTIFICATION_TYPE = 'notification';
export const ONBOARDING_TYPE = 'onboarding';
export const ORDER_ITEM_TYPE = 'order-item';
export const ORDER_PAYMENT_TYPE = 'order-payment';
export const ORDER_TYPE = 'order';
export const PAYMENT_INTENT_TYPE = 'payment-intent';
export const PLAYLIST_TYPE = 'playlist';
export const PRICE_SHEET_ELEMENT_IMAGE_TYPE = 'price-sheet-element-image';
export const PRICE_SHEET_ELEMENT_IMAGE_COLLECTION_TYPE = 'price-sheet-element-image-collection';
export const PRICE_SHEET_GROUP_TYPE = 'price-sheet-group';
export const PRICE_SHEET_GROUP_COLLECTION_TYPE = 'price-sheet-group-collection';
export const PRICE_SHEET_ITEM_COLLECTION_TYPE = 'price-sheet-item-collection';
export const PRICE_SHEET_ITEM_IMAGE_TYPE = 'price-sheet-item-image';
export const PRICE_SHEET_ITEM_PACKAGE_TYPE = 'price-sheet-item-package';
export const PRICE_SHEET_ITEM_TYPE = 'price-sheet-item';
export const PRICE_SHEET_ITEM_CHILD_TYPE = 'price-sheet-item-child';
export const PRICE_SHEET_DIGITAL_ITEM_TYPE = 'price-sheet-item-digital';
export const PRICE_SHEET_SHIPPING_OPTION_TYPE = 'price-sheet-shipping-option';
export const PRICE_SHEET_SUBGROUP_COLLECTION_TYPE = 'price-sheet-subgroup-collection';
export const PRICE_SHEET_SUBGROUP_TYPE = 'price-sheet-subgroup';
export const PRICE_SHEET_TYPE = 'price-sheet';
export const SALES_HISTORY_TYPE = 'sales-history';
export const SIGNATURE_TYPE = 'signature';
export const SONG_ARTIST_TYPE = 'song-artist';
export const SONG_INSTRUMENT_TYPE = 'song-instrument';
export const SONG_MOOD_TYPE = 'song-mood';
export const SONG_PLAN_TYPE = 'song-plan';
export const SONG_STYLE_TYPE = 'song-style';
export const SONG_THEME_TYPE = 'song-theme';
export const SONG_TYPE = 'song';
export const SP_PAY_GATEWAY_TYPE = 'sp-pay-gateway';
export const STUDIO_MUSIC_PLAN_TYPE = 'studio-music-plan';
export const USER_MFA_CODE_TYPE = 'user-mfa-code';
export const USER_MFA_TYPE = 'user-mfa';
export const USER_MFA_VERIFICATION_TYPE = 'user-mfa-verification';
export const VOLUME_SORT_TYPE = 'event-volume-sort';
export const WATERMARK_TYPE = 'watermark';

export default {
    AFFILIATE_PROGRAM_SUMMARY_TYPE,
    ACCOUNT_CLOSURE_REQUEST_TYPE,
    BRAND_HOMEPAGE_ABOUT_IMAGE_TYPE,
    BRAND_HOMEPAGE_TYPE,
    BRAND_THEME_TYPE,
    BRAND_TYPE,
    CONTACT_TAG_TYPE,
    CONTACT_TYPE,
    CONTRACT_TYPE,
    CONTRACT_SIGNATURE_TYPE,
    CURATED_EMAIL_AUTOMATION_GROUP_TYPE,
    CURATED_EMAIL_TEMPLATE_COLLECTION_TYPE,
    DASHBOARD_MESSAGE_TYPE,
    DIGITAL_RULE_TYPE,
    DPA_TYPE,
    EMAIL_AUTOMATION_TYPE,
    EMAIL_AUTOMATION_GROUP_TYPE,
    EMAIL_TEMPLATE_TYPE,
    EMAIL_TEMPLATE_TYPE_TYPE,
    EMAIL_TYPE,
    EVENT_ALBUM_CHILDREN_TYPE,
    EVENT_ALBUM_COLLECTION_TYPE,
    EVENT_ALBUM_PHOTO_TYPE,
    EVENT_ALBUM_TYPE,
    EVENT_COLLECTION_TYPE,
    EVENT_CONTACT_TYPE,
    EVENT_CONTACT_DEFAULTS_TYPE,
    EVENT_DEFAULTS_TYPE,
    EVENT_PHOTO_TYPE,
    EVENT_PHOTO_COLLECTION_TYPE,
    EVENT_TYPE,
    EVENT_VOLUME_QR_CODE_TYPE,
    EVENT_VOLUME_QR_COLLECTION_TYPE,
    EVENT_VOLUME_QR_TYPE,
    EVENT_VOLUME_QR_PDF_TYPE,
    GALLERY_ACTIVITY_TYPE,
    GATEWAY_STATUS_TYPE,
    INVOICE_CREDIT_CARD_TYPE,
    INVOICE_DISCOUNT_TEMPLATE_TYPE,
    INVOICE_DISCOUNT_TYPE,
    INVOICE_INSTALLMENT_TYPE,
    INVOICE_ITEM_TEMPLATE_TYPE,
    INVOICE_ITEM_TYPE,
    INVOICE_PAYMENT_PAYMENT_INTENT_TYPE,
    INVOICE_PAYMENT_SETUP_INTENT_TYPE,
    INVOICE_PAYMENT_TYPE,
    INVOICE_REFUND_TYPE,
    INVOICE_TEMPLATE_TYPE,
    INVOICE_TYPE,
    KNOWLEDGE_BASE_ARTICLE_LIST_API_PATH,
    LAB_CATALOG_PRODUCT_TYPE,
    LAB_CATALOG_SHIPPING_OPTION_TYPE,
    LAB_CATALOG_TYPE,
    LAB_ORDER_SUMMARY_TYPE,
    MARKET_ORDER_PROMO_TYPE,
    MERCHANT_ACCOUNT_TYPE,
    ME_TYPE,
    NOTIFICATION_TYPE,
    ONBOARDING_TYPE,
    ORDER_ITEM_TYPE,
    ORDER_PAYMENT_TYPE,
    ORDER_TYPE,
    PAYMENT_INTENT_TYPE,
    PLAYLIST_TYPE,
    PRICE_SHEET_ITEM_PACKAGE_TYPE,
    PRICE_SHEET_ELEMENT_IMAGE_TYPE,
    PRICE_SHEET_ELEMENT_IMAGE_COLLECTION_TYPE,
    PRICE_SHEET_GROUP_TYPE,
    PRICE_SHEET_GROUP_COLLECTION_TYPE,
    PRICE_SHEET_ITEM_COLLECTION_TYPE,
    PRICE_SHEET_ITEM_IMAGE_TYPE,
    PRICE_SHEET_ITEM_TYPE,
    PRICE_SHEET_ITEM_CHILD_TYPE,
    PRICE_SHEET_DIGITAL_ITEM_TYPE,
    PRICE_SHEET_SHIPPING_OPTION_TYPE,
    PRICE_SHEET_SUBGROUP_COLLECTION_TYPE,
    PRICE_SHEET_SUBGROUP_TYPE,
    PRICE_SHEET_TYPE,
    SALES_HISTORY_TYPE,
    SIGNATURE_TYPE,
    SONG_ARTIST_TYPE,
    SONG_INSTRUMENT_TYPE,
    SONG_MOOD_TYPE,
    SONG_PLAN_TYPE,
    SONG_STYLE_TYPE,
    SONG_THEME_TYPE,
    SONG_TYPE,
    SP_PAY_GATEWAY_TYPE,
    STUDIO_MUSIC_PLAN_TYPE,
    USER_MFA_CODE_TYPE,
    USER_MFA_TYPE,
    USER_MFA_VERIFICATION_TYPE,
    VOLUME_SORT_TYPE,
    WATERMARK_TYPE
};
