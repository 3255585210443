/**
 * @ngdoc service
 * @name sp.common.service:spBraintreeDeviceDataProvideř
 *
 * @description
 * Provider for braintree's device data.
 */
export default function spBraintreeDeviceDataProvider() {
    var isDeferringDeviceDataCreation;

    /**
     * @ngdoc method
     * @name deferDeviceDataCreation
     * @methodOf sp.common.service:spBraintreeDeviceDataProvideř
     *
     * @description
     * Sets the flag to defer device data create.
     */
    this.deferDeviceDataCreation = function deferDeviceDataCreation() {
        isDeferringDeviceDataCreation = true;
    };

    this.$get = [
        '$q',
        'braintreeWeb',
        /**
         * @ngdoc service
         * @name sp.common.service:spBraintreeDeviceData
         * @requires $q
         * @requires braintreeWeb
         *
         * @description
         * Provides a way to get device data from the BraintreeWeb Data Collector.
         */
        function spBraintreeDeviceDataService($q, braintreeWeb) {
            var maybeGetDeviceData = $q.defer();
            var maybeAuthorizeClient = $q.defer();

            maybeAuthorizeClient.promise.then(function withBraintreeAuthorization(authorization) {
                if (authorization) {
                    braintreeWeb.client.create(
                        {
                            authorization: authorization
                        },
                        function withClient(error, client) {
                            if (error) {
                                return resolveDeviceDataWithNull();
                            }

                            braintreeWeb.dataCollector.create(
                                {
                                    client: client,
                                    kount: true
                                },
                                function withDataCollector(error, dataCollector) {
                                    if (error) {
                                        return resolveDeviceDataWithNull();
                                    }

                                    maybeGetDeviceData.resolve(dataCollector.deviceData);
                                }
                            );
                        }
                    );
                } else {
                    resolveDeviceDataWithNull();
                }
            });

            if (!isDeferringDeviceDataCreation) {
                maybeAuthorizeClient.resolve(braintreeWeb.authorization);
            }

            return {
                /**
                 * @ngdoc method
                 * @name get
                 * @methodOf sp.common.service:spBraintreeDeviceData
                 *
                 * @description
                 * Gets device data from the BraintreeWeb Data Collector. Will resolve with `null` if
                 * Studio is not configured with a Braintree-based payment gateway.
                 *
                 * @returns {Promise} Resolves with device data. Will never reject
                 */
                get: function get() {
                    return maybeGetDeviceData.promise;
                },
                /**
                 * @ngdoc method
                 * @name setAuthorization
                 * @methodOf sp.common.service:spBraintreeDeviceData
                 *
                 * @description
                 * Sets the braintreeToken and as a result kicks off deviceData creation.
                 *
                 * @param {string} authorization A security token for authorization
                 */
                setAuthorization: function setAuthorization(authorization) {
                    maybeAuthorizeClient.resolve(authorization);
                }
            };

            function resolveDeviceDataWithNull() {
                maybeGetDeviceData.resolve(null);
            }
        }
    ];
}
