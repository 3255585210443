/**
 * @ngdoc filter
 * @name sp.common.filter:hasRequiredFields
 *
 * @description
 * Lets us know if a form has a required field. Can be told to let us know when we have a
 * required field and the field has been touched (or the form has been submitted) and the field is
 * invalid.
 *
 * @param {form} form An `ngForm`
 * @param {boolean=} checkTouchedAndInvalid If true, will check for required fields (or a submitted
 * form) for missing values
 *
 * @returns {boolean} Either if the form has a required field or the form has required fields that
 * have been touched (or the form has been submitted) with missing values.
 */
export default [
    function hasRequiredFieldsFilter() {
        return function hasRequiredFields(form, checkTouchedAndInvalid) {
            let formProperties = Object.keys(form).filter((formKey) => {
                return form.hasOwnProperty(formKey);
            });

            for (let i = 0; i < formProperties.length; i++) {
                const formProperty = formProperties[i];

                if (isNotAnAngularFormProperty(formProperty)) {
                    const { $validators, $touched, $invalid } = form[formProperty];

                    if ($validators && $validators.required) {
                        if (checkTouchedAndInvalid) {
                            if ((form.$submitted || $touched) && $invalid) {
                                return true;
                            }
                        } else {
                            return true;
                        }
                    }
                }
            }

            function isNotAnAngularFormProperty(formProperty) {
                return formProperty.indexOf('$') !== 0;
            }
        };
    }
];
