/**
 * @ngdoc directive
 * @name sp.common.directive:spFormInputGroupText
 * @requires $rootScope
 * @requires $compile
 * @restrict A
 *
 * @description
 * Adds a customizable text label attached to an input element. Uses bowser.
 */
export default [
    '$rootScope',
    '$compile',
    function spFormInputGroupTextDirective($rootScope, $compile) {
        return {
            restrict: 'A',
            scope: {
                spFormInputGroupTextValue: '@'
            },
            require: 'ngModel',
            link: function spFormInputLabelController($scope, $element, $attrs) {
                var $textLabel = $compile(
                    '<label ' +
                        'for="' +
                        $attrs.id +
                        '">' +
                        '<span ' +
                        'class="input-group-text">' +
                        $scope.spFormInputGroupTextValue +
                        '</span>' +
                        '</label>'
                )($scope);

                $element.after($textLabel);
            }
        };
    }
];
