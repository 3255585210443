import { keyboardKeys } from 'Sp/Utility';

/**
 * @ngdoc directive
 * @name sp.studio.common.directive:spInputAutofill
 * @requires $compile
 * @requires $filter
 * @requires $timeout
 * @restrict A
 *
 * @description
 * Renders an autofill list from the passed in suggestions that renders below the input field.
 *
 * @param {array} spInputAutofillSuggestions An array of suggestions to list by their name property in the autofill list
 * @param {function} spInputAutofillOnSelect Invoked when a suggestion is selected from the autofill list
 * @param {function=} spInputAutofillSuggestionDisplayFn Sets how each suggestion is rendered if provided
 * @param {boolean=} spInputAutofillAllowCustomInput If true, will filter the autofill list by the ngModel value
 * @param {boolean=} spInputAutofillPreventFilter If true, does not filter the autofill list
 */
export default [
    '$compile',
    '$filter',
    '$timeout',
    function spInputAutofillDirective($compile, $filter, $timeout) {
        return {
            restrict: 'A',
            require: 'ngModel',
            scope: {
                spInputAutofillSuggestions: '=',
                spInputAutofillOnSelect: '=',
                spInputAutofillSuggestionDisplayFn: '=?',
                spInputAutofillAllowCustomInput: '=?',
                spInputAutofillPreventFilter: '=?'
            },
            link: function spInputAutofillController($scope, $element, $attrs, ngModel) {
                const template = `
                    <ul
                        class="sp-input-autofill">

                        <li
                            ng-repeat=" suggestion in getSuggestions() "
                            ng-class=" { selected: $index === selectedIndex } "
                            ng-click=" selectSuggestion(suggestion) ">

                            <span
                                ng-bind-html=" getSuggestionDisplayText(suggestion) ">
                            </span>

                        </li>

                    </ul>
                `;
                let $autofillList;
                let isFocused = false;

                $scope.getSuggestionDisplayText = getSuggestionDisplayText;
                $scope.getSuggestions = getSuggestions;
                $scope.selectSuggestion = selectSuggestion;

                initialize();

                function initialize() {
                    $autofillList = $compile(template)($scope);

                    $autofillList.on('mouseleave', function onElementMouseLeave() {
                        if (!isFocused) {
                            $autofillList.removeClass('sp-input-autofill-visible');
                        }
                    });
                    $element.after($autofillList);
                    $element.attr('autocomplete', 'off');
                    $element.on('blur', hideAutofillList);
                    $element.on('focus', showAutofillList);
                    $element.on('keydown', processKeyDownEvent);
                }

                function getSuggestionDisplayText(suggestion) {
                    return $scope.spInputAutofillSuggestionDisplayFn
                        ? $scope.spInputAutofillSuggestionDisplayFn(suggestion)
                        : suggestion.name || suggestion;
                }

                function getSuggestions() {
                    let suggestions;

                    if ($scope.spInputAutofillPreventFilter) {
                        suggestions = $scope.spInputAutofillSuggestions;
                    } else if (ngModel.$modelValue) {
                        suggestions = getFilteredSuggestions();
                    }

                    return suggestions || [];

                    function getFilteredSuggestions() {
                        const filteredSuggestions = $filter('filter')(
                            $scope.spInputAutofillSuggestions,
                            ngModel.$modelValue
                        );

                        return filteredSuggestions;
                    }
                }

                function hideAutofillList() {
                    $timeout(() => {
                        $autofillList.removeClass('sp-input-autofill-visible');
                    }, 250);

                    isFocused = false;
                }

                function processKeyDownEvent($event) {
                    if (getSuggestions().length === 0 && !$scope.spInputAutofillAllowCustomInput) {
                        return;
                    }

                    $scope.$apply(() => {
                        switch ($event.which) {
                            case keyboardKeys.UP_ARROW:
                                if ($scope.selectedIndex > 0) {
                                    $scope.selectedIndex--;
                                } else {
                                    $scope.selectedIndex = null;
                                }

                                $event.preventDefault();

                                break;
                            case keyboardKeys.DOWN_ARROW:
                                if ($scope.selectedIndex === null) {
                                    $scope.selectedIndex = 0;
                                } else if ($scope.selectedIndex < getSuggestions().length - 1) {
                                    $scope.selectedIndex++;
                                }

                                $event.preventDefault();

                                break;
                            case keyboardKeys.RETURN:
                                selectSuggestion();
                                $event.preventDefault();

                                break;
                            case keyboardKeys.TAB:
                                selectSuggestion();

                                if (
                                    $scope.spInputAutofillAllowCustomInput &&
                                    $scope.selectedIndex === null
                                ) {
                                    break;
                                }

                                $event.preventDefault();

                                break;
                        }
                    });
                }

                function selectSuggestion(clickedSuggestion) {
                    const suggestions = getSuggestions();
                    let suggestion;

                    if (clickedSuggestion) {
                        suggestion = clickedSuggestion;
                    } else if ($scope.selectedIndex >= 0) {
                        suggestion = suggestions[$scope.selectedIndex];
                    }

                    // suggestion will be undefined if it fails form checks such as minlength
                    if (suggestion) {
                        $scope.spInputAutofillOnSelect(suggestion);
                    }

                    if ($scope.spInputAutofillAllowCustomInput) {
                        $scope.selectedIndex = null;
                    }

                    $element[0].blur();
                }

                function showAutofillList() {
                    $autofillList.addClass('sp-input-autofill-visible');

                    $scope.selectedIndex = $scope.spInputAutofillAllowCustomInput ? null : 0;
                    isFocused = true;
                }
            }
        };
    }
];
