/**
 * @ngdoc directive
 * @name sp.common.directive:spPercentMask
 * @restrict A
 *
 * @description
 * It formats a input text value as a percentage.
 * <pre>
    <input
        type="text"
        sp-percent-mask
        sp-percent-mask-fixed-digits=" 3 "
        ng-min=" 0 "
        ng-max=" 100 "
    />
 * </pre>
 *
 * @param {float} ngMin Minimum value for an input element
 * @param {float} ngMax Maximum value for an input element
 * @param {number} spPercentMaskFixedDigits Represents number of fixed digits
 *     after the decimal point to preserve
 *
 * @example
    <example module="sp.common">
        <file name="script.js">
            angular.module('sp.common').controller('Controller', function Controller($scope) {
                $scope.percentValue = 50;
            });
        </file>
        <file name="index.html">
            <div ng-controller="Controller">
                <input
                    type="text"
                    ng-model=" percentValue "
                    sp-percent-mask
                    sp-percent-mask-fixed-digits=" 3 "
                    ng-min=" 0 "
                    ng-max=" 100 "
                />
            </div>
        </file>
    </example>
 */
import Decimal from 'decimal.js';

export default [
    function spPercentMaskDirective() {
        return {
            restrict: 'A',
            require: 'ngModel',
            scope: {
                ngMin: '=',
                ngMax: '=',
                spPercentMaskFixedDigits: '='
            },
            link: function spPercentMaskController($scope, $element, $attrs, ngModelController) {
                $element.on('focus', function maskOff() {
                    if (ngModelController.$viewValue) {
                        ngModelController.$setViewValue(
                            ngModelController.$viewValue.replace('%', '')
                        );
                        ngModelController.$render();
                    }
                });

                $element.on('blur', function maskOn() {
                    ngModelController.$setViewValue(
                        ngModelController.$viewValue
                            ? round(ngModelController.$viewValue) + '%'
                            : null
                    );
                    ngModelController.$render();
                });

                $scope.$watch('ngMin + ngMax', () => ngModelController.$validate());

                initialize();

                function initialize() {
                    ngModelController.$formatters.push(formatter);
                    ngModelController.$parsers.push(parser);

                    ngModelController.$validators.max = maxValidator;
                    ngModelController.$validators.min = minValidator;
                }

                function formatter(value) {
                    return value || value === 0 ? round(value) + '%' : null;
                }

                function maxValidator(value) {
                    if (!angular.isNumber(value)) {
                        return true;
                    }

                    return round(value) <= $scope.ngMax;
                }

                function minValidator(value) {
                    if (!angular.isNumber(value)) {
                        return true;
                    }

                    return round(value) >= $scope.ngMin;
                }

                function parser(value) {
                    return value || value === 0 ? round(value) : null;
                }

                function round(value) {
                    return parseFloat(
                        Decimal(parseFloat(value) || 0).toFixed(
                            $scope.spPercentMaskFixedDigits || 0
                        )
                    );
                }
            }
        };
    }
];
