/* eslint-disable func-names, no-unreachable */

export default [
    '$rootElement',
    '$cacheFactory',
    function spAppDocumentClassDirective($rootElement, $cacheFactory) {
        var $cache = $cacheFactory('spAppDocumentClass');

        function updateClass(key, className) {
            var existingClass = $cache.get(key);

            if (typeof existingClass !== 'undefined') {
                $rootElement.removeClass(existingClass);
            }

            $cache.put(key, className);
            $rootElement.addClass(className);
        }

        function getFontOptionClassForPairingId(fontPairingId) {
            switch (fontPairingId) {
                default:
                case 1:
                    return 'theme-font-option-1';
                case 2:
                    return 'theme-font-option-2';
                case 3:
                    return 'theme-font-option-3';
                case 4:
                    return 'theme-font-option-4';
                case 5:
                    return 'theme-font-option-5';
                case 6:
                    return 'theme-font-option-6';
            }

            return undefined;
        }

        function getThemeOptionClassForBackgroundOptionId(backgroundOptionId) {
            switch (backgroundOptionId) {
                default:
                case 1:
                    return 'theme-light';
                case 2:
                    return 'theme-dark';
            }

            return undefined;
        }

        if (
            navigator.userAgent.match(/iPhone/i) ||
            navigator.userAgent.match(/iPod/i) ||
            navigator.userAgent.match(/iPad/i)
        ) {
            updateClass('device-ios', 'ios');
        } else {
            updateClass('device-ios', 'not-ios');
        }

        if (navigator.userAgent.match(/MSIE/i) || navigator.userAgent.match(/Trident/i)) {
            updateClass('device-ie', 'ie');
        }

        return {
            restrict: 'A',
            link: function spAppDocumentClassController($scope) {
                $scope.$watch('themeData.fontPairingId', function(value) {
                    updateClass('font-option', getFontOptionClassForPairingId(value));
                });

                $scope.$watch('themeData.backgroundOptionId', function(value) {
                    updateClass('theme-option', getThemeOptionClassForBackgroundOptionId(value));
                });
            }
        };
    }
];
