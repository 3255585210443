import spSignatureTemplate from '../../templates/components/sp-signature.nghtml';

/**
 * @ngdoc directive
 * @name sp.common.directive:spSignature
 * @restrict E
 *
 * @description
 * Renders a signature svg.
 *
 * @param {object} spSignatureSignature An svg signature
 * @param {string} spSignatureSignature.svgViewbox The viewBox attribute value
 * @param {string} spSignatureSignature.signaturePaths The d attribute value for an svg path
 */
export default {
    template: spSignatureTemplate,
    bindings: {
        signature: '<spSignatureSignature'
    }
};
