import spRadioButtonTemplate from '../../templates/components/sp-radio-button.nghtml';

/**
 * @ngdoc component
 * @name sp.common.component:spRadioButton
 *
 * @description
 * Displays a single radio button with the additional ability to:
 * - Display additional information describing the radio button that does not disappear
 *     on selection
 * - Display specific content when selected.
 *
 * @param {string} radioGroup The name of the group to which the radio button belongs
 * @param {string} radioLabel Radio button's label text
 * @param {object} radioModel ngModel of the radio button
 * @param {string} radioValue Value of the radio button
 * @param {function=} onRadioChange Called when the radio model changes
 */
export default {
    template: spRadioButtonTemplate,
    bindings: {
        radioGroup: '@',
        radioLabel: '@',
        radioModel: '=',
        radioValue: '<',
        onRadioChange: '&?'
    },
    transclude: {
        detailInfo: '?spRadioButtonDetailInfo',
        selectedContent: '?spRadioButtonSelectedContent'
    }
};
