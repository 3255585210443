export default [
    function SPSupportProvider() {
        function SPSupport() {
            this.isBrowserSupported = function isBrowserSupported() {
                return (
                    window.Modernizr.svg &&
                    window.Modernizr.fontface &&
                    window.Modernizr.backgroundsize
                );
            };
        }

        this.$get = [
            function SPSupportFactory() {
                return new SPSupport();
            }
        ];
    }
];
