export default [
    function spConvertToNumberDirective() {
        return {
            require: 'ngModel',
            link: function spConvertToNumberController($scope, $element, $attributes, ngModel) {
                if (!ngModel) {
                    return;
                }

                ngModel.$parsers.push(function(value) {
                    return parseInt(value, 10);
                });

                ngModel.$formatters.push(function(val) {
                    return '' + val;
                });
            }
        };
    }
];
