/* eslint-disable func-names, padding-line-between-statements */

export default [
    '$rootScope',
    '$routeParams',
    '$location',
    '$route',
    function spLocalNavigationFacade($rootScope, $routeParams, $location, $route) {
        $rootScope.spLocalNavigation = function spLocalNavigation(urlString) {
            var currentLocation = $location.path();
            var newLocation = $location.path(urlString).path();

            $location.replace();

            if (currentLocation !== newLocation) {
                var currentRoute = $route.current;

                var removeLocationChangeHandler = $rootScope.$on(
                    '$locationChangeSuccess',
                    function() {
                        removeLocationChangeHandler();

                        var newParams = $route.current.params;

                        $route.current = currentRoute;
                        $route.current.params = newParams;

                        for (var key in $routeParams) {
                            if ($routeParams.hasOwnProperty(key)) {
                                delete $routeParams[key];
                            }
                        }

                        angular.extend($routeParams, $route.current.params);

                        $rootScope.$broadcast('SPLocalNavigationUpdate');
                    }
                );
            }
        };
    }
];
