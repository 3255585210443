import angularTranslateModule from 'angular-translate';

var spTranslateModule = angular.module('sp.translate', [angularTranslateModule]);

import spAppDataProvider from './providers/spAppData';

spTranslateModule.provider('spAppData', spAppDataProvider);

spTranslateModule.config([
    '$translateProvider',
    'spAppDataProvider',
    function configureTranslations($translateProvider, spAppDataProvider) {
        var translations = spAppDataProvider.get('translations');
        var language = spAppDataProvider.get('language');

        if (!translations || !language) {
            return;
        }

        $translateProvider.translations(language, translations);

        // Because the Event defines its language, we only provide the target
        // language's strings, and we set it as the preferred language.
        $translateProvider.preferredLanguage(language);
        $translateProvider.useSanitizeValueStrategy('sceParameters');
    }
]);

export default spTranslateModule;
