/**
 * @ngdoc directive
 * @name sp.common.directive:spFormInputSearch
 * @requires $compile
 * @restrict A
 *
 * @description
 * Adds a magnifying glass icon to its attached input element.
 * <pre>
    <input
        sp-form-input-search
        type="text"
        placeholder="Enter search here" />
 * </pre>
 */
export default [
    '$compile',
    function spFormInputSearchDirective($compile) {
        return {
            restrict: 'A',
            link: function spFormInputSearchController($scope, $element, $attrs) {
                const searchSvg = $compile('<button><sp-svg hash="iconSearch"></sp-svg></button>')(
                    $scope
                );
                const label = angular.element(`<label for="${$attrs.id}">`).append(searchSvg);

                $element.after(label);
            }
        };
    }
];
