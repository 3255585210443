/* eslint-disable func-names */

export default [
    function SPDataObjectsProvider() {
        var dataObjects = {};

        this.defineDataObject = function defineDataObject(objectName, objectDefinition) {
            dataObjects[objectName] = objectDefinition;
        };

        function dataObjectFactory(objectType, data, arg2, arg3, arg4) {
            if (typeof dataObjects[objectType] !== 'function') {
                // @@STUB: This should result in an exception
                return false;
            }

            var ChildDataObject = dataObjects[objectType];

            ChildDataObject.prototype = new SPDataObject(objectType);

            return new ChildDataObject(data, arg2, arg3, arg4);
        }

        function SPDataObject(objectType) {
            this.getType = function getType() {
                return objectType;
            };

            this.checkType = function checkType(typeToCheck) {
                return objectType === typeToCheck;
            };

            /****
             ***** This is used to disable buttons when a request is in progress
             ****/
            this.actionsPending = [];

            this.isActionPending = function isActionPending(action) {
                return this.actionsPending.indexOf(action) !== -1;
            };

            this.setActionPending = function setActionPending(action) {
                if (typeof action !== 'string') {
                    return false;
                }

                if (this.isActionPending(action)) {
                    return false;
                }

                this.actionsPending.push(action);

                return true;
            };

            this.unsetActionPending = function unsetActionPending(action) {
                if (typeof action !== 'string') {
                    return false;
                }

                var index = this.actionsPending.indexOf(action);

                if (index === -1) {
                    return false;
                }

                this.actionsPending.splice(index, 1);

                return true;
            };

            this.dataObjectFactory = function(objectType, data, arg2, arg3, arg4) {
                return dataObjectFactory(objectType, data, arg2, arg3, arg4);
            };
        }

        function SPDataObjects() {
            this.factory = function factory(objectType, data, arg2, arg3, arg4) {
                return dataObjectFactory(objectType, data, arg2, arg3, arg4);
            };
        }

        this.$get = function $get() {
            return new SPDataObjects();
        };
    }
];
