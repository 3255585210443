export default [
    function spDeviceService() {
        return {
            deviceIsIOs
        };

        function deviceIsIOs() {
            return (
                /iPad|iPhone|iPod/.test(navigator.userAgent) ||
                /iPad|iPhone|iPod/.test(navigator.platform) ||
                // iPad on iOS 13 detection
                (navigator.userAgent.includes('Mac') && 'ontouchend' in window) ||
                (navigator.platform.includes('Mac') && navigator.maxTouchPoints > 1)
            );
        }
    }
];
