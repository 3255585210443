/**
 * @ngdoc directive
 * @name sp.common.directive:spDuplicate
 * @requires $parse
 * @restrict A
 *
 * @description
 * This validator validates the value of the ngModel against an array of values of which it should
 * not be valid for.
 *
 * @param {array<string>} spDuplicate A list of strings that will result in an invalid form field
 * @param {boolean=} spDuplicateIgnoreCase Will ignore case when comparing the list of strings
 *     against the value
 */
export default [
    '$parse',
    function spDuplicateDirective($parse) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function spDuplicateController($scope, $element, $attrs, ngModel) {
                var isCaseInsensitive = $attrs.spDuplicateIgnoreCase;

                ngModel.$parsers.unshift(function withValue(value) {
                    var valueToCompare = value;

                    if (valueToCompare && isCaseInsensitive) {
                        valueToCompare = valueToCompare.toLowerCase();
                    }

                    var duplicates = getDuplicates();
                    var valid = duplicates.indexOf(valueToCompare) === -1;

                    ngModel.$setValidity('duplicateValue', valid);

                    return valid ? value : undefined;
                });

                ngModel.$formatters.unshift(function withValue(value) {
                    var valueToCompare = value;

                    if (valueToCompare && isCaseInsensitive) {
                        valueToCompare = valueToCompare.toLowerCase();
                    }

                    var duplicates = getDuplicates();

                    if (duplicates.length > 0) {
                        ngModel.$setValidity(
                            'duplicateValue',
                            duplicates.indexOf(valueToCompare) === -1
                        );
                    } else {
                        ngModel.$setValidity('duplicateValue', true);
                    }

                    return value;
                });

                function getDuplicates() {
                    var duplicates = $parse($attrs.spDuplicate)($scope) || [];

                    if (isCaseInsensitive) {
                        duplicates = duplicates.map(function withDuplicate(duplicate) {
                            return String(duplicate).toLowerCase();
                        });
                    }

                    return duplicates;
                }
            }
        };
    }
];
