import STUDIO_PRICESHEET_GROUPS from 'Sp/PriceSheet/Group';
import PRICESHEET_ITEM_EVENTS from 'Sp/Angular/Events/Studio/PriceSheet/Item';
import Api from 'Sp/Api';
import Decimal from 'decimal.js';

/**
 * @ngdoc service
 * @name sp.newcommon.service:spPackage
 *
 * @description
 * Common services related to price sheet packages
 */
export default [
    '$q',
    'spEventBus',
    'spPriceSheetItemSubgroupFilter',
    'translateFilter',
    function spPackageService($q, spEventBus, spPriceSheetItemSubgroupFilter, translateFilter) {
        const DIGITAL_ALL_PHOTOS_COUNT = 1;
        const service = this;

        return Object.assign(service, {
            getInitialPackageItemSelectionState,
            getPackageItemCount,
            getPackageItemDetailsLabel,
            getPackageItemNameLabel,
            getPackageItemQuantity,
            getPackageItemQuantityLabel,
            getPriceSheetPackageGroup,
            getUseSingleImageIndicator,
            isShootProofFulfilledAllAlbumDownloadsDigitalItem,
            isShootProofFulfilledAllGalleryDownloadsDigitalItem,
            isShootProofFulfilledIndividualDownloadDigitalItem,
            isShootProofFulfilledDigitalItem,
            retrievePriceSheetPackages,
            totalCostIfIsPurchasableShootProofFulfilledDigitalItem
        });

        /**
         * @ngdoc method
         * @name getPackageItemDetailsLabel
         * @methodOf sp.newcommon.service:spPackage
         *
         * @description
         * Returns the item details for a package item.
         *
         * @param {object} item Price sheet item in package
         * @returns {string} Item details content
         */
        function getPackageItemDetailsLabel(item) {
            if (isShootProofFulfilledDigitalItem(item)) {
                return translateFilter(
                    'studio.priceSheet.packageWorkspace.shootProofFulfilledDigitals'
                );
            } else if (isSelfFulfilledDigitalItem(item)) {
                return translateFilter('studio.priceSheet.packageWorkspace.selfFulfilledDigitals');
            }

            return item.name;
        }

        /**
         * @ngdoc method
         * @name getPackageItemNameLabel
         * @methodOf sp.newcommon.service:spPackage
         *
         * @description
         * Returns the displayed item name for a package item.
         *
         * @param {object} item Price sheet item in package
         * @param {object} priceSheet Price sheet which contains the package
         * @returns {string} Name of the item
         */
        function getPackageItemNameLabel(item, priceSheet) {
            if (isPhysicalItem(item)) {
                return (
                    spPriceSheetItemSubgroupFilter(item, priceSheet).name ||
                    translateFilter('studio.priceSheet.packageWorkspace.ungroupedItemName')
                );
            }

            return item.name;
        }

        /**
         * @ngdoc method
         * @name getPackageItemQuantityLabel
         * @methodOf sp.newcommon.service:spPackage
         *
         * @description
         * Returns a label representing the item quantity.
         *
         * @param {object} item Price sheet item
         * @returns {number|string} Item quantity value
         */
        function getPackageItemQuantityLabel(item) {
            if (isShootProofFulfilledDigitalItem(item)) {
                const { individualPhotoDownload = {} } = item;

                if (individualPhotoDownload.quantity) {
                    return individualPhotoDownload.quantity;
                }

                return translateFilter('studio.priceSheet.packageWorkspace.allDigitalLabel');
            }

            return item.quantity;
        }

        /**
         * @ngdoc method
         * @name getPackageItemQuantity
         * @methodOf sp.newcommon.service:spPackage
         *
         * @description
         * Determines the quantity of a price sheet item on a package.
         *
         * @param {object} item Price sheet item
         * @param {boolean} includeAllPhotosDigitals Represents whether to
         *     include "All Photos" digitals in the item count
         */
        function getPackageItemQuantity(item, includeAllPhotosDigitals = true) {
            if (isPhysicalItem(item) || isSelfFulfilledDigitalItem(item)) {
                return item.quantity || 1;
            }

            return getShootProofFulfilledDigitalItemQuantity(item, includeAllPhotosDigitals);
        }

        /**
         * @ngdoc method
         * @name getInitialPackageItemSelectionState
         * @methodOf sp.newcommon.service:spPackage
         *
         * @description
         * Return an empty collection represeting each "entry" within a package
         * child item, where the entry conunt matches the quantity for the item
         * type. This allows us to maintain the selection state and other
         * metadata related to package items.
         *
         * @param {object} item Price sheet item object
         * @returns {Array<object>} List containing empty objects whose length
         * matches the quantity of a package item
         */
        function getInitialPackageItemSelectionState(item) {
            let length;

            if (isPhysicalItem(item) || isSelfFulfilledDigitalItem(item)) {
                length = item.quantity || 1;
            } else {
                const { individualPhotoDownload = {} } = item;

                if (individualPhotoDownload.quantity) {
                    length = individualPhotoDownload.quantity;
                }
            }

            if (length) {
                return Array.from({ length }).fill({});
            }

            return [];
        }

        /**
         * @ngdoc method
         * @name getPackageItemCount
         * @methodOf sp.newcommon.service:spPackage
         *
         * @description
         * Calculates the package item count given a set of items. Physical and
         * self-fulfilled digital items utilize the item quantity.
         *
         * @param {object} items Price sheet items under a package
         * @param {boolean} includeAllPhotosDigitals Represents whether to
         *     include "All Photos" digitals in the item count
         */
        function getPackageItemCount(items, includeAllPhotosDigitals = true) {
            return items.reduce(
                (count, item) => count + getPackageItemQuantity(item, includeAllPhotosDigitals),
                0
            );
        }

        /**
         * @ngdoc method
         * @name getPriceSheetPackages
         * @methodOf sp.newcommon.service:spPackage
         *
         * @description
         * Unpacks the packages associated with the price sheet from the packages item group.
         *
         * @param {object} priceSheet Price sheet object
         */
        function getPriceSheetPackageGroup(priceSheet = {}) {
            const { priceSheetGroups = [] } = priceSheet;
            const packageGroup = priceSheetGroups.find(
                (itemGroup) => itemGroup.groupType === STUDIO_PRICESHEET_GROUPS.TYPE.PACKAGES
            );

            if (!packageGroup) {
                return [];
            }

            return packageGroup;
        }

        function getShootProofFulfilledDigitalItemQuantity(
            digitalItem,
            includeAllPhotosDigitals = true
        ) {
            const { individualPhotoDownload = {}, allAlbumPhotosDownload = {} } = digitalItem;

            if (individualPhotoDownload.quantity) {
                return individualPhotoDownload.quantity;
            } else if (allAlbumPhotosDownload.quantity) {
                return 1;
            }

            return includeAllPhotosDigitals ? DIGITAL_ALL_PHOTOS_COUNT : 0;
        }

        function isPhysicalItem({ type }) {
            return type === Api.PRICE_SHEET_ITEM_TYPE;
        }

        function isSelfFulfilledDigitalItem({ isDigitalDownload, type }) {
            return type === Api.PRICE_SHEET_DIGITAL_ITEM_TYPE && !isDigitalDownload;
        }

        /**
         * @ngdoc method
         * @name isShootProofFulfilledAllAlbumDownloadsDigitalItem
         * @methodOf sp.newcommon.service:spPackage
         *
         * @description
         * Checks if a price sheet item is a ShootProof fulfilled All Album Downloads digital
         * item.
         *
         * @param {object} item Price sheet item in package
         * @returns {boolean} If the given price sheet item is a ShootProof fulfilled digital item
         */
        function isShootProofFulfilledAllAlbumDownloadsDigitalItem(item) {
            const isShootProofDigitalItem = isShootProofFulfilledDigitalItem(item);

            if (!isShootProofDigitalItem) {
                return false;
            }

            const { allAlbumPhotosDownload } = item;
            const isAllAlbumPhotosDownload = allAlbumPhotosDownload.quantity > 0;

            return isShootProofDigitalItem && isAllAlbumPhotosDownload;
        }

        /**
         * @ngdoc method
         * @name isShootProofFulfilledAllGalleryDownloadsDigitalItem
         * @methodOf sp.newcommon.service:spPackage
         *
         * @description
         * Checks if a price sheet item is a ShootProof fulfilled All Gallery Downloads digital
         * item.
         *
         * @param {object=} item Price sheet item in package
         * @returns {boolean} If the given price sheet item is a ShootProof fulfilled digital item
         */
        function isShootProofFulfilledAllGalleryDownloadsDigitalItem(item = {}) {
            const isShootProofDigitalItem = isShootProofFulfilledDigitalItem(item);

            if (!isShootProofDigitalItem) {
                return false;
            }

            const { allGalleryPhotosDownload } = item;
            const isAllGalleryPhotosDownload = allGalleryPhotosDownload.quantity > 0;

            return isAllGalleryPhotosDownload;
        }

        /**
         * @ngdoc method
         * @name isShootProofFulfilledIndividualDownloadDigitalItem
         * @methodOf sp.newcommon.service:spPackage
         *
         * @description
         * Checks if a price sheet item is a ShootProof fulfilled Individual download digital item
         *
         * @param {object} item Price sheet item in package
         * @returns {boolean} Indicator representing if the item is an individual download
         */
        function isShootProofFulfilledIndividualDownloadDigitalItem(item) {
            const isShootProofDigitalItem = isShootProofFulfilledDigitalItem(item);

            if (!isShootProofDigitalItem) {
                return false;
            }

            const { individualPhotoDownload } = item;
            const { isDigitalDownloadForSale } = individualPhotoDownload;

            return isDigitalDownloadForSale;
        }

        /**
         * @ngdoc method
         * @name totalCostIfIsPurchasableShootProofFulfilledDigitalItem
         * @methodOf sp.newcommon.service:spPackage
         *
         * @description
         * Checks if a price sheet item is a ShootProof fulfilled and returns the total cost for
         * the current quantity of the given item
         *
         * @param {object} item Price sheet item in package
         * @returns {number} Total cost for the current passed in item
         */
        function totalCostIfIsPurchasableShootProofFulfilledDigitalItem(item) {
            const {
                allGalleryPhotosDownload,
                allAlbumPhotosDownload,
                individualPhotoDownload
            } = item;

            if (isShootProofFulfilledAllGalleryDownloadsDigitalItem(item)) {
                return allGalleryPhotosDownload.price;
            } else if (isShootProofFulfilledAllAlbumDownloadsDigitalItem(item)) {
                return allAlbumPhotosDownload.price;
            } else if (
                isShootProofFulfilledDigitalItem(item) &&
                individualPhotoDownload.quantity > 0
            ) {
                return Decimal(individualPhotoDownload.quantity).times(individualPhotoDownload.price);
            }
        }

        /**
         * @ngdoc method
         * @name isShootProofFulfilledDigitalItem
         * @methodOf sp.newcommon.service:spPackage
         *
         * @description
         * Checks if a price sheet item is a ShootProof fulfilled digital item.
         *
         * @param {object} item Price sheet item in package
         * @returns {boolean} If the given price sheet item is a ShootProof fulfilled digital item
         */
        function isShootProofFulfilledDigitalItem({ isDigitalDownload, type }) {
            return type === Api.PRICE_SHEET_DIGITAL_ITEM_TYPE && isDigitalDownload;
        }

        /**
         * @ngdoc method
         * @name retrievePriceSheetPackages
         * @methodOf sp.newcommon.service:spPackage
         *
         * @description
         * Pulls all packages associated with the current price sheet.
         *
         * @param {object} priceSheet Price sheet object
         */
        function retrievePriceSheetPackages(priceSheetId, packageGroupId) {
            const deferred = $q.defer();

            spEventBus.once(PRICESHEET_ITEM_EVENTS.LISTED, onPriceSheetPackageItemsGot);
            spEventBus.emit(PRICESHEET_ITEM_EVENTS.LIST, priceSheetId, {
                filterPriceSheetGroupId: packageGroupId
            });

            return deferred.promise;

            function onPriceSheetPackageItemsGot(priceSheetPackageGroupItems) {
                deferred.resolve(priceSheetPackageGroupItems);
            }
        }

        /**
         * @ngdoc method
         * @name getUseSingleImageIndicator
         * @methodOf sp.newcommon.service:spPackage
         *
         * @description
         * Determines the current state of the single pose indicator. The
         * computed value useSingleImage should take precedence over the
         * existing allowedPhotoCount property which is not modified until the
         * resource is patched
         *
         * @param {object} package Package data
         * @returns {boolean} Represents whether the indicator is enabled
         */
        function getUseSingleImageIndicator({ allowedPhotoCount, useSingleImage }) {
            if (typeof useSingleImage !== 'undefined') {
                return useSingleImage;
            }

            return allowedPhotoCount === 1;
        }
    }
];
