/* eslint-disable func-names */

export default [
    function spCapitalizeFirstFilter() {
        return function(input) {
            if (typeof input !== 'string') {
                return input;
            }

            return input[0].toUpperCase() + input.substring(1);
        };
    }
];
