const CCV_PROVIDER_TAGS = {
    AMEX: ['34', '37'],
    DISCOVER: [
        '6011',
        '622126',
        '622127',
        '622128',
        '622129',
        '62213',
        '62214',
        '62215',
        '62216',
        '62217',
        '62218',
        '62219',
        '6222',
        '6223',
        '6224',
        '6225',
        '6226',
        '6227',
        '6228',
        '62290',
        '62291',
        '622920',
        '622921',
        '622922',
        '622923',
        '622924',
        '622925',
        '644',
        '645',
        '646',
        '647',
        '648',
        '649',
        '65'
    ],
    MASTERCARD: [
        '51',
        '52',
        '53',
        '54',
        '55',
        '2221',
        '2222',
        '2223',
        '2224',
        '2225',
        '2226',
        '2227',
        '2228',
        '2229',
        '223',
        '224',
        '225',
        '226',
        '227',
        '228',
        '229',
        '23',
        '24',
        '25',
        '26',
        '2700',
        '2710',
        '2720'
    ],
    VISA: ['4']
};

export default {
    getCardType
};

function getCardType(creditCardNumber) {
    let cardType = null;

    if (!creditCardNumber) {
        return cardType;
    }

    switch (creditCardNumber[0]) {
        case '3':
            if (isValidCardNumber(creditCardNumber, CCV_PROVIDER_TAGS.AMEX)) {
                cardType = 'amex';
            }

            break;
        case '6':
            if (isValidCardNumber(creditCardNumber, CCV_PROVIDER_TAGS.DISCOVER)) {
                cardType = 'discover';
            }

            break;
        case '2':
        case '5':
            if (isValidCardNumber(creditCardNumber, CCV_PROVIDER_TAGS.MASTERCARD)) {
                cardType = 'mastercard';
            }

            break;
        case '4':
            if (isValidCardNumber(creditCardNumber, CCV_PROVIDER_TAGS.VISA)) {
                cardType = 'visa';
            }

            break;
    }

    return cardType;
}

function isValidCardNumber(creditCardNumber, ccvProviderTags) {
    return ccvProviderTags.reduce((isMatch, ccvProviderTag) => {
        return isMatch || creditCardNumber.indexOf(ccvProviderTag) === 0;
    }, false);
}
