import '../../common/js/jquery-2.1.4';
import angular from 'angular';
import spCommonModule from '../../newcommon/js/sp.common';
import angularCookiesModule from 'angular-cookies';

const spClientCommonModule = angular.module('SPCommonModule', [
    angularCookiesModule,
    spCommonModule.name,
]);

import SPDataObjectsProvider from './js/providers/SPDataObjects';
import SPPhotoProvider from './js/providers/SPPhoto';
import SPSupportProvider from './js/providers/SPSupport';

spClientCommonModule.provider('SPDataObjects', SPDataObjectsProvider);
spClientCommonModule.provider('SPPhoto', SPPhotoProvider);
spClientCommonModule.provider('SPSupport', SPSupportProvider);

import AlbumConfig from './js/configs/Album';
import BoundConfig from './js/configs/Bound';
import BoundItemConfig from './js/configs/BoundItem';
import CartItemConfig from './js/configs/CartItem';
import PackageConfig from './js/configs/Package';
import PackageToCartConfig from './js/configs/PackageToCart';
import PhotoConfig from './js/configs/Photo';
import TagConfig from './js/configs/Tag';

spClientCommonModule.config(AlbumConfig);
spClientCommonModule.config(BoundConfig);
spClientCommonModule.config(BoundItemConfig);
spClientCommonModule.config(CartItemConfig);
spClientCommonModule.config(PhotoConfig);
spClientCommonModule.config(PackageConfig);
spClientCommonModule.config(PackageToCartConfig);
spClientCommonModule.config(TagConfig);
spClientCommonModule.config([
    'spAppDataProvider',
    'spApiHttpProvider',
    function configureSpApiHttp(spAppDataProvider, spApiHttpProvider) {
        const { apiRoot } = spAppDataProvider.get('urls');

        spApiHttpProvider.setApiRoot(apiRoot);
        spApiHttpProvider.setContentTypeHeader('application/vnd.shootproof+json');
    }
]);

import spAuthorizationTokenService from './js/services/spAuthorizationToken';
import spCartAdapterService from './js/services/spCartAdapter';
import spHiddenAlbumService from './js/services/spHiddenAlbum';
import SPCartDataService from './js/services/SPCartData';
import SPDataSourcePrototypeService from './js/services/SPDataSourcePrototype';
import SPModalService from './js/services/SPModal';
import SPRouteService from './js/services/SPRoute';
import spDeviceService from './js/services/spDevice';

spClientCommonModule.service('spAuthorizationToken', spAuthorizationTokenService);
spClientCommonModule.service('spCartAdapter', spCartAdapterService);
spClientCommonModule.factory('spHiddenAlbum', spHiddenAlbumService);
spClientCommonModule.service('SPCartData', SPCartDataService);
spClientCommonModule.factory('SPDataSourcePrototype', SPDataSourcePrototypeService);
spClientCommonModule.service('SPModal', SPModalService);
spClientCommonModule.service('SPRoute', SPRouteService);
spClientCommonModule.service('spDevice', spDeviceService);

import clientWidthFacade from './js/facades/clientWidth';
import deviceHasTouchEventsFacade from './js/facades/deviceHasTouchEvents';
import getPartialURLFacade from './js/facades/getPartialURL';
import getViewControllerNameFacade from './js/facades/getViewControllerName';
import spLocalNavigationFacade from './js/facades/spLocalNavigation';
import trustAsHtmlFacade from './js/facades/trustAsHtml';

spClientCommonModule.run(clientWidthFacade);
spClientCommonModule.run(deviceHasTouchEventsFacade);
spClientCommonModule.run(getPartialURLFacade);
spClientCommonModule.run(getViewControllerNameFacade);
spClientCommonModule.run(spLocalNavigationFacade);
spClientCommonModule.run(trustAsHtmlFacade);

import sp1KPlusFilter from './js/filters/sp1KPlus';
import spQuotesFilter from './js/filters/spQuotes';

spClientCommonModule.filter('sp1KPlus', sp1KPlusFilter);
spClientCommonModule.filter('spQuotes', spQuotesFilter);

import spDataConsentComponent from './js/components/spDataConsent';

spClientCommonModule.component('spDataConsent', spDataConsentComponent);

import spAllowContextMenuDirective from './js/directives/spAllowContextMenu';
import spAppDocumentClassDirective from './js/directives/spAppDocumentClass';
import spBackgroundImageOnLoadDirective from './js/directives/spBackgroundImageOnLoad';
import spContentDirective from './js/directives/spContent';
import spDelayRemoveDirective from './js/directives/spDelayRemove';
import spDisableContextMenuDirective from './js/directives/spDisableContextMenu';
import spExpandableTextDirective from './js/directives/spExpandableText';
import spImageOnLoadDirective from './js/directives/spImageOnLoad';
import spKeyboardDirective from './js/directives/spKeyboard';
import spLocalNavigationDirective from './js/directives/spLocalNavigation';
import spLogoDirective from './js/directives/spLogo';
import spPhotoFocalPointDirective from './js/directives/spPhotoFocalPoint';
import spPointSlideDirective from './js/directives/spPointSlide';
import spSafariAutofillDirective from './js/directives/spSafariAutofill';
import spScrollFixedTopDirective from './js/directives/spScrollFixedTop';
import spTriggeredTimeoutDirective from './js/directives/spTriggeredTimeout';
import spWatchDirective from './js/directives/spWatch';

spClientCommonModule.directive('spAllowContextMenu', spAllowContextMenuDirective);
spClientCommonModule.directive('spAppDocumentClass', spAppDocumentClassDirective);
spClientCommonModule.directive('spBackgroundImageOnLoad', spBackgroundImageOnLoadDirective);
spClientCommonModule.directive('spContent', spContentDirective);
spClientCommonModule.directive('spDelayRemove', spDelayRemoveDirective);
spClientCommonModule.directive('spDisableContextMenu', spDisableContextMenuDirective);
spClientCommonModule.directive('spExpandableText', spExpandableTextDirective);
spClientCommonModule.directive('spImageOnLoad', spImageOnLoadDirective);
spClientCommonModule.directive('spKeyboard', spKeyboardDirective);
spClientCommonModule.directive('spLocalNavigation', spLocalNavigationDirective);
spClientCommonModule.directive('spLogo', spLogoDirective);
spClientCommonModule.directive('spPhotoFocalPoint', spPhotoFocalPointDirective);
spClientCommonModule.directive('spPointSlide', spPointSlideDirective);

const isChrome = navigator.userAgent.indexOf('Chrome') > -1;
const isSafari = !isChrome && navigator.userAgent.indexOf('Safari') > -1;

if (isSafari) {
    spClientCommonModule.directive('input', spSafariAutofillDirective);
    spClientCommonModule.directive('textarea', spSafariAutofillDirective);
    spClientCommonModule.directive('select', spSafariAutofillDirective);
}

spClientCommonModule.directive('spScrollFixedTop', spScrollFixedTopDirective);
spClientCommonModule.directive('spTriggeredTimeout', spTriggeredTimeoutDirective);
spClientCommonModule.directive('spWatch', spWatchDirective);

import spConvertToNumberDirective from './js/directives/validators/spConvertToNumber';
import spNumberInputDirective from './js/directives/validators/spNumberInput';
import spValidDirective from './js/directives/validators/spValid';

spClientCommonModule.directive('spConvertToNumber', spConvertToNumberDirective);
spClientCommonModule.directive('spNumberInput', spNumberInputDirective);
spClientCommonModule.directive('spValid', spValidDirective);

import AuthorizationTokenDataStore from './js/data_stores/AuthorizationToken';

spClientCommonModule.run(AuthorizationTokenDataStore);

export default spClientCommonModule.name;
