/* eslint-disable func-names, eqeqeq */

export default [
    '$routeParams',
    '$scope',
    '$location',
    'SPBrandData',
    function BrandCategoryController($routeParams, $scope, $location, SPBrandData) {
        var categoryId = parseInt($routeParams.categoryId, 10);
        var pageNum = 1;

        $scope.pagination = {
            hasMore: false,
            loading: false
        };

        // If we can't find the Category by its ID value, just
        // move back to the home page.
        var matches = $scope.categories.filter(function(category) {
            return category.id == categoryId;
        });

        if (!matches.length) {
            $location.url('');

            return;
        }

        $scope.category = matches[0];

        $scope.loadMore = function loadMore() {
            pageNum++;
            getEvents(pageNum);
        };

        getEvents(pageNum);

        function getEvents(pageNum) {
            $scope.pagination.loading = true;

            SPBrandData.getBrandEvents(
                pageNum,
                $scope.category.id,
                function(response) {
                    if ($scope.events) {
                        $scope.events = $scope.events.concat(response.events);
                    } else {
                        $scope.events = response.events;
                    }

                    $scope.pagination.hasMore =
                        response.meta.currentPage < response.meta.totalPages;
                    $scope.pagination.loading = false;

                    pageNum = response.meta.currentPage;
                },
                function() {
                    $scope.pagination.loading = false;
                }
            );
        }
    }
];
