import Decimal from 'decimal.js';
import InvoiceDiscount from 'Sp/Invoice/Discount';
import moment from 'moment';

const INSTALLMENT_STATUS = {
    PAID_IN_FULL: 'paid-in-full',
    PARTIALLY_PAID: 'partially-paid',
    UNPAID: 'unpaid'
};
const RETAINER_LABEL = {
    DEPOSIT: 'deposit',
    NON_REFUNDABLE_PAYMENT: 'non-refundable-payment',
    RETAINER: 'retainer'
};
const STATUS = {
    ARCHIVED: 'archived',
    CANCELED: 'canceled',
    PAID_IN_FULL: 'paid-in-full',
    PARTIALLY_PAID: 'partially-paid',
    UNPAID: 'unpaid'
};
const PAYMENT_INACTIVE_STATUSES = [STATUS.ARCHIVED, STATUS.CANCELED, STATUS.PAID_IN_FULL];

export default {
    INSTALLMENT_STATUS,
    RETAINER_LABEL,
    STATUS,
    getAmountDueForInstallment,
    getCSSClassForInstallmentStatusText,
    getCSSClassForStatusText,
    getDiscountTotal,
    getDueTotal,
    getGrandTotal,
    getFirstInstallmentWithBalance,
    getInstallmentForCurrentPayPeriod,
    getItemSalesTax,
    getItemTotalPrice,
    getOverdueBalance,
    getPaidTotal,
    getSalesTaxTotal,
    getSubtotal,
    getTaxableSubtotal,
    hasPercentDiscount,
    isOverdue,
    recalculateInstallments
};

function getAmountAsPrice(amount) {
    // Use standard rounding (e.g. Math.round) for invoice calculations
    const roundedNumber = Decimal(amount).toDecimalPlaces(2, Decimal.ROUND_HALF_CEIL);

    return parseFloat(roundedNumber);
}

function getAmountDueForInstallment(invoice, installment) {
    switch (installment.invoiceInstallmentStatus) {
        case INSTALLMENT_STATUS.UNPAID:
            return getAmountAsPrice(installment.amount);
        case INSTALLMENT_STATUS.PARTIALLY_PAID:
            return getAmountAsPrice(getNonUnpaidAmount() - invoice.paidTotal);
    }

    return 0;

    function getNonUnpaidAmount() {
        return invoice.installments.reduce(function sumNonUnpaidAmount(amount, installment) {
            if (installment.invoiceInstallmentStatus !== INSTALLMENT_STATUS.UNPAID) {
                amount += installment.amount;
            }

            return getAmountAsPrice(amount);
        }, 0);
    }
}

function getCSSClassForInstallmentStatusText({ invoiceInstallmentStatus }) {
    switch (invoiceInstallmentStatus) {
        case INSTALLMENT_STATUS.PAID_IN_FULL:
            return 'sp-invoice-installment-status-paid';
        case INSTALLMENT_STATUS.PARTIALLY_PAID:
            return 'sp-invoice-installment-status-partially-paid';
        case INSTALLMENT_STATUS.UNPAID:
            return 'sp-invoice-installment-status-unpaid';
    }
}

function getCSSClassForStatusText(invoice) {
    if (isOverdue(invoice)) {
        return 'sp-invoice-status-overdue';
    }

    switch (invoice.invoiceStatus) {
        case STATUS.CANCELED:
            return 'sp-invoice-status-canceled';
        case STATUS.PAID_IN_FULL:
            return 'sp-invoice-status-paid';
        case STATUS.PARTIALLY_PAID:
            return 'sp-invoice-status-partially-paid';
        case STATUS.UNPAID:
            return 'sp-invoice-status-unpaid';
    }
}

/**
 * Returns discount as a percentage to determine per-item or subtotal
 * calculations. Flat rates are coerced to percentages for return
 */
function getDiscountPercent(invoice) {
    if (!invoice.discount) {
        return 0;
    }

    const subtotal = getSubtotal(invoice);
    let { value: discountPercent } = invoice.discount;

    if (!hasPercentDiscount(invoice)) {
        discountPercent = Decimal(discountPercent)
            .dividedBy(subtotal)
            .times(100);
    }

    return discountPercent;
}

function getDiscountTotal(invoice) {
    if (!invoice.discount) {
        return 0;
    }

    if (!hasPercentDiscount(invoice)) {
        return invoice.discount.value;
    }

    const discountPercent = getDiscountPercent(invoice);
    const subtotal = getSubtotal(invoice);
    const discountTotal = Decimal(subtotal)
        .times(discountPercent)
        .dividedBy(100);

    return getAmountAsPrice(discountTotal);
}

function getDueTotal(invoice) {
    return getAmountAsPrice(getGrandTotal(invoice) - getPaidTotal(invoice));
}

function getGrandTotal(invoice) {
    const discountTotal = getDiscountTotal(invoice);
    const salesTaxTotal = getSalesTaxTotal(invoice);
    const subtotal = getSubtotal(invoice);
    const grandTotal = getAmountAsPrice(subtotal - discountTotal + salesTaxTotal);

    if (grandTotal < 0) {
        return 0;
    }

    return grandTotal;
}

function getFirstInstallmentWithBalance(invoice) {
    const firstInstallmentWithBalance = invoice.installments.find(({ invoiceInstallmentStatus }) =>
        [INSTALLMENT_STATUS.PARTIALLY_PAID, INSTALLMENT_STATUS.UNPAID].includes(
            invoiceInstallmentStatus
        )
    );
    const balanceDue = getAmountDueForInstallment(invoice, firstInstallmentWithBalance);

    return {
        ...firstInstallmentWithBalance,
        balanceDue
    };
}

function getInstallmentForCurrentPayPeriod({ installments }) {
    const today = moment().startOf('day');

    return installments.filter((installment) => today.isSameOrBefore(installment.dueDate))[0];
}

function getItemDiscountTotal(invoice, itemSubtotal) {
    const discountTotal = getDiscountTotal(invoice);
    const subtotal = getSubtotal(invoice);
    const itemDiscountTotal = Decimal(itemSubtotal)
        .dividedBy(subtotal)
        .times(discountTotal);

    return itemDiscountTotal;
}

function getItemSalesTax({ salesTaxPercent }, { isTaxable, itemPrice, quantity }) {
    if (!isTaxable) {
        return 0;
    }

    const salesTaxDecimal = Decimal(salesTaxPercent || 0).dividedBy(100);

    return getAmountAsPrice(
        Decimal(itemPrice || 0)
            .times(quantity || 0)
            .times(salesTaxDecimal)
    );
}

function getItemTotalPrice({ itemPrice, quantity }) {
    return getAmountAsPrice(itemPrice * quantity || 0);
}

function getItemsSubtotal({ items = [] }, onlyIncludeTaxableItems = false) {
    return items
        .filter(({ isTaxable }) => (onlyIncludeTaxableItems ? isTaxable : true))
        .reduce((subtotal, item) => subtotal + getItemSubtotal(item), 0);
}

function getItemSubtotal({ itemPrice, quantity }) {
    return itemPrice * quantity;
}

function getOverdueBalance(invoice) {
    if (!invoice.installments) {
        return 0;
    }

    const today = moment().startOf('day');

    return invoice.installments.reduce((overdueBalance, installment) => {
        if (today.isAfter(installment.dueDate)) {
            if (installment.invoiceInstallmentStatus !== INSTALLMENT_STATUS.PAID_IN_FULL) {
                overdueBalance += getAmountDueForInstallment(invoice, installment);
            }
        }

        return getAmountAsPrice(overdueBalance);
    }, 0);
}

function getPaidTotal({ paidTotal = 0 }) {
    return paidTotal;
}

function getSalesTaxTotal(invoice) {
    const salesTaxDecimal = Decimal(invoice.salesTaxPercent || 0).dividedBy(100);
    const taxableSubtotal = getTaxableSubtotal(invoice);

    return getAmountAsPrice(salesTaxDecimal.times(taxableSubtotal));
}

function getSubtotal(invoice) {
    return getItemsSubtotal(invoice);
}

function getTaxableSubtotal(invoice) {
    const { items = [] } = invoice;
    const taxableSubtotal = items.reduce((subtotal, item) => {
        if (!item.isTaxable) {
            return subtotal;
        }

        const itemSubtotal = getItemSubtotal(item);
        const itemDiscount = getItemDiscountTotal(invoice, itemSubtotal);
        const itemSubtotalWithDiscount = Decimal(itemSubtotal).minus(itemDiscount);

        return Decimal(subtotal).plus(itemSubtotalWithDiscount);
    }, Decimal(0));

    if (taxableSubtotal.lessThan(0)) {
        return 0;
    }

    return getAmountAsPrice(taxableSubtotal);
}

function hasPercentDiscount({ discount }) {
    if (!discount) {
        return false;
    }

    const { discountType } = discount;

    return discountType === InvoiceDiscount.DISCOUNT_TYPE.PERCENT;
}

function isOverdue(invoice) {
    if (PAYMENT_INACTIVE_STATUSES.indexOf(invoice.invoiceStatus) >= 0) {
        return false;
    }

    return getOverdueBalance(invoice) > 0;
}

function recalculateInstallments(installments, retainerPercent, grandTotal) {
    const installmentsMeta = installments.reduce(
        (installmentsMeta, installment) => {
            const { isFixed, isRetainer, invoiceInstallmentStatus } = installment;
            const isUnpaid = invoiceInstallmentStatus === INSTALLMENT_STATUS.UNPAID;

            if (isFixed || !isUnpaid) {
                const isUnpaidPercentageRetainer = isUnpaid && isRetainer && retainerPercent;

                if (isUnpaidPercentageRetainer) {
                    const retainerAmount = getAmountAsPrice(
                        Decimal(grandTotal)
                            .times(retainerPercent)
                            .dividedBy(100)
                    );

                    installment.amount = retainerAmount;
                }

                installmentsMeta.fixedTotal += installment.amount;
            } else {
                installmentsMeta.percentageInstallments.push(installment);
            }

            return installmentsMeta;
        },
        { fixedTotal: 0, percentageInstallments: [] }
    );
    const { length: editablePercentageInstallmentsCount } = installmentsMeta.percentageInstallments;
    const amountToDistribute = grandTotal - installmentsMeta.fixedTotal;
    const remainderOfEvenDistribution =
        Math.round(amountToDistribute * 100) % editablePercentageInstallmentsCount;
    const amountToEvenlyDistribute =
        amountToDistribute - getAmountAsPrice(remainderOfEvenDistribution / 100);
    const lastIndexToApplyRemainder = remainderOfEvenDistribution - 1;

    installmentsMeta.percentageInstallments.forEach((installment, index) => {
        installment.amount = getAmountAsPrice(
            Decimal(amountToEvenlyDistribute)
                .dividedBy(editablePercentageInstallmentsCount)
                .plus(index <= lastIndexToApplyRemainder ? 0.01 : 0)
        );
    });
}
