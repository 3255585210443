export default {
    HELP: '/v2/:brandId/support/submit',
    NOTIFICATIONS: '/v2/:brandId/notification',

    GALLERY_ACTIVITY: {
        RECENT_ACTIVITY: '/v2/:brandId/report/recentgalleryactivity',
        VISITOR_REPORT: '/v2/:brandId/report/galleryvisitor'
    },

    /**
     * @deprecated
     */
    GATEWAY_WARNING: '/gateway-warning',
    GATEWAY_WARNING_STATUS: '/v2/:brandId/brand/gatewaywarningprompt'
};
