import React from 'react';
import { Box, styled } from 'sp-ui';
import BrandLogo from './BrandLogo';
import Nav from './Nav';
import { IAppDataBrandData } from './types';

interface IHeaderProps {
    /**
     * AngularJS location object
     */
    $location: SpAngularJs.ILocation;

    /**
     * AngularJS route object
     */
    $route: SpAngularJs.IRoute;

    /**
     * Translation filter object from AngularJS
     */
    translateFilter: SpAngularJs.ITranslateFilter;
    spAppData: SpAngularJs.ISpAppData;
}

const HeaderContainer = styled(Box)`
    width: 100%;
    padding: 0 20px 40px 20px;
    top: 0;
    position: static;
    text-align: center;
    margin: auto;
    p {
        font-family: 'OpenSans Regular';
        color: #a5a5a5;
    }
`;

const Header: React.FC<IHeaderProps> = ({ $location, $route, translateFilter, spAppData }) => {
    const brandData = spAppData.get<IAppDataBrandData>('brandData');
    const contactFormEnabled = brandData.homepage.enableContactForm;
    const showNavOnV3Page = brandData.aboutText || contactFormEnabled;

    return (
        <HeaderContainer data-testid="brand-header">
            <BrandLogo spAppData={spAppData} />
            {(contactFormEnabled || showNavOnV3Page) && (
                <Nav
                    $location={$location}
                    $route={$route}
                    translateFilter={translateFilter}
                    spAppData={spAppData}
                />
            )}
        </HeaderContainer>
    );
};

export default Header;
