/**
 * @ngdoc directive
 * @name sp.common.directive:spFormInputAdd
 * @requires $compile
 * @restrict A
 *
 * @description
 * Adds a plus (+) icon to its attached input element.
 * <pre>
    <input
        sp-form-input-add=" optionalExpressionToRunWhenIconClicked "
        type="text"
        placeholder="Add a new tag" />
 * </pre>
 */
export default [
    '$compile',
    '$parse',
    function spFormInputAddDirective($compile, $parse) {
        return {
            restrict: 'A',
            link: function spFormInputAddController($scope, $element, $attrs) {
                $scope.labelClick = function labelClick($event) {
                    if ($attrs.spFormInputAdd) {
                        var expression = $parse($attrs.spFormInputAdd);

                        $scope.$evalAsync(expression($scope, { $event: $event }));
                    }
                };

                var $plusLabel = $compile(
                    '<label ' +
                        'ng-click=" labelClick($event) " ' +
                        'for="' +
                        $attrs.id +
                        '">' +
                        '<sp-svg ' +
                        'hash="iconPlus">' +
                        '</sp-svg>' +
                        '</label>'
                )($scope);

                $element.after($plusLabel);
            }
        };
    }
];
