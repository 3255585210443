/**
 * @ngdoc directive
 * @name sp.common.directive:spIncrementSelector
 * @requires $compile
 * @restrict A
 *
 * @description
 * Renders a number input form field as an increment selector.
 */
export default [
    '$compile',
    function spIncrementSelectorDirective($compile) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function spIncrementSelectorController(
                $scope,
                $element,
                $attrs,
                ngModelController
            ) {
                const NEGATIVE_SYMBOL = '-';
                const ARROW_DOWN = 'ArrowDown';
                const ARROW_UP = 'ArrowUp';
                const MIN_VALUE = parseInt($attrs.ngMin, 10) || 0;
                let maxValue = parseInt($attrs.ngMax, 10) || null;

                $scope.isAtLowerBounds = true;
                $scope.isAtUpperBounds = MIN_VALUE === maxValue;

                $scope.decrement = decrement;
                $scope.increment = increment;

                const $decrementButton = $compile(
                    `<div
                        class="sp-increment-selector-button center-align"
                        ng-disabled=" isAtLowerBounds "
                        ng-click=" decrement() ">
                        <sp-svg
                        hash="iconMinus">
                        </sp-svg>
                    </div>`
                )($scope);
                const $incrementButton = $compile(
                    `<div
                        class="sp-increment-selector-button center-align"
                        ng-disabled=" isAtUpperBounds "
                        ng-click=" increment() ">
                        <sp-svg
                        hash="iconPlus">
                        </sp-svg>
                    </div>`
                )($scope);

                $element.on('keydown', function handleKeyDown(event) {
                    const key = event.key;
                    const constrainToBounds =
                        (key === ARROW_DOWN && $scope.isAtLowerBounds) ||
                        (key === ARROW_UP && $scope.isAtUpperBounds);

                    if (key === NEGATIVE_SYMBOL || constrainToBounds) {
                        event.preventDefault();
                    }
                });

                $element.parent().prepend($decrementButton);
                $element.after($incrementButton);

                $scope.$watch(() => ngModelController.$modelValue, handleModelChange);
                $scope.$watch(() => $attrs.ngMax, resetMaxConstraint);

                function increment() {
                    if ($scope.isAtUpperBounds) {
                        return;
                    }

                    adjustModelValueBy(1);

                    $element.triggerHandler('blur');
                }

                function decrement() {
                    if ($scope.isAtLowerBounds) {
                        return;
                    }

                    adjustModelValueBy(-1);

                    $element.triggerHandler('blur');
                }

                function adjustModelValueBy(amount) {
                    const modelValue = getModelValue();

                    ngModelController.$setViewValue(modelValue + amount);
                    ngModelController.$render();
                }

                function getModelValue() {
                    return ngModelController.$modelValue || 0;
                }

                function resetMaxConstraint(newValue) {
                    maxValue = Number(newValue);

                    $scope.isAtUpperBounds = maxValue !== null && getModelValue() === maxValue;
                }

                function handleModelChange(newValue, oldValue) {
                    if (newValue === undefined && oldValue === undefined) {
                        return;
                    }

                    $scope.isAtLowerBounds =
                        newValue === undefined || newValue === null || newValue === MIN_VALUE;
                    $scope.isAtUpperBounds = maxValue !== null && newValue === maxValue;
                }
            }
        };
    }
];
