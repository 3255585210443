export default {
    BULK_CREATE: 'studio-price-sheet-item-bulk-create',
    BULK_CREATED: 'studio-price-sheet-item-bulk-created',
    BULK_DELETE: 'studio-price-sheet-item-bulk-delete',
    BULK_DELETED: 'studio-price-sheet-item-bulk-deleted',
    BULK_UPDATE: 'studio-price-sheet-item-bulk-update',
    BULK_UPDATED: 'studio-price-sheet-item-bulk-updated',
    BULK_UPDATE_ERROR: 'studio-price-sheet-item-bulk-update-error',
    CREATE: 'studio-price-sheet-item-create',
    CREATED: 'studio-price-sheet-item-created',
    DELETE: 'studio-price-sheet-item-delete',
    DELETE_CONFLICT: 'studio-price-sheet-item-delete-conflict',
    DELETE_MODAL: 'studio-price-sheet-item-delete-modal',
    DELETED: 'studio-price-sheet-item-deleted',

    DIGITAL: {
        UPDATE: 'studio-price-sheet-edit-digital-item-update',
        UPDATED: 'studio-price-sheet-edit-digital-item-updated'
    },

    DIGITAL_ATTRIBUTE: {
        UPDATE: 'studio-price-sheet-edit-digital-attribute-update',
        UPDATED: 'studio-price-sheet-edit-digital-attribute-updated'
    },

    GET: 'studio-price-sheet-item-get',
    GOT: 'studio-price-sheet-item-got',

    ITEM: {
        UPDATE: 'studio-price-sheet-item-update',
        UPDATED: 'studio-price-sheet-item-updated'
    },

    IMAGE: {
        BULK_UPDATE: 'studio-price-sheet-item-image-bulk-update',
        BULK_UPDATED: 'studio-price-sheet-item-image-bulk-updated',
        CREATE: 'studio-price-sheet-item-image-create',
        CREATED: 'studio-price-sheet-item-image-created',
        DELETE: 'studio-price-sheet-item-image-delete',
        DELETED: 'studio-price-sheet-item-image-deleted',
        ERROR: 'studio-price-sheet-item-image-error',
        GET: 'studio-price-sheet-item-image-get',
        GOT: 'studio-price-sheet-item-image-got',
        LIST: 'studio-price-sheet-item-image-list',
        LISTED: 'studio-price-sheet-item-image-listed',
        UPDATE: 'studio-price-sheet-item-image-update',
        UPDATED: 'studio-price-sheet-item-image-updated'
    },

    LIST: 'studio-price-sheet-item-list',
    LISTED: 'studio-price-sheet-item-listed',

    PACKAGE: {
        DELETE: 'studio-price-sheet-package-item-delete'
    },

    PENDING_ITEMS_SAVE: 'studio-price-sheet-pending-items-save'
};
