/**
 * @ngdoc directive
 * @name sp.common.directive:spSvg
 * @requires $cacheFactory
 * @requires $http
 * @requires spSvg:config
 * @restrict E
 *
 * @description
 * Interprets an svg name into an actual SVG element.
 *
 * @param {string} document Path to the SVG. Has a default if not specified.
 * @param {string} hash Name of the SVG
 */
export default [
    '$cacheFactory',
    '$http',
    'spSvg:config',
    function spSvgDirective($cacheFactory, $http, config) {
        const $cache = $cacheFactory('spSvg');
        const $svgDefs = angular
            .element('<svg xmlns="https://www.w3.org/2000/svg">')
            .css({ display: 'none' });

        document.querySelector('body').appendChild($svgDefs[0]);

        return {
            restrict: 'E',
            scope: {
                classStyle: '@?'
            },
            link: function spSvgController($scope, $element, $attrs) {
                const svgDocumentPath = $attrs.document || config.defaultSVGDocument;

                initialize();

                function initialize() {
                    if (!svgDocumentPath) {
                        return;
                    }

                    const $svg = angular.element('<svg xmlns="https://www.w3.org/2000/svg">');

                    $element.replaceWith($svg);
                    $http({ url: svgDocumentPath, cache: $cache }).then(({ data }) => {
                        const getCachedDocument = getFromCache(
                            `svg:${svgDocumentPath}`,
                            (svgDocument) => angular.element(svgDocument)
                        );
                        const svgDocument = getCachedDocument(data);

                        $scope.$watch(
                            () => $attrs.hash,
                            (hash) => {
                                const svgData = getSvgData(svgDocument, hash);
                                const classStyle = $scope.classStyle ? $scope.classStyle : '';

                                if (svgData) {
                                    $svg.attr('class', `sp-svg sp-svg-${hash} ${classStyle}`);
                                    $svg.html(svgData.innerHTML);
                                    $svg[0].setAttribute('viewBox', svgData.viewBox);
                                }
                            }
                        );
                    });
                }

                function getFromCache(cacheKey, onCacheMiss) {
                    return (value) => {
                        const cachedValue = $cache.get(cacheKey);

                        if (cachedValue) {
                            return cachedValue;
                        }

                        const valueToCache = onCacheMiss(value);

                        $cache.put(cacheKey, valueToCache);

                        return valueToCache;
                    };
                }

                function getSvgData(svgDocument, svgHash) {
                    const cacheKey = `${svgDocumentPath}#${svgHash}`;
                    const getCachedSvgData = getFromCache(cacheKey, (svgDocument) => {
                        let svgData;

                        angular.forEach(svgDocument.find('symbol'), (svgElement) => {
                            const { firstChild } = svgElement;

                            if (firstChild.tagName === 'defs') {
                                svgElement.removeChild(firstChild);
                                $svgDefs.append(firstChild);
                            }

                            const svgDataToCache = {
                                innerHTML: svgElement.innerHTML,
                                viewBox: svgElement.getAttribute('viewBox')
                            };

                            $cache.put(cacheKey, svgDataToCache);

                            if (svgElement.id === svgHash) {
                                svgData = svgDataToCache;
                            }
                        });

                        return svgData;
                    });

                    return getCachedSvgData(svgDocument);
                }
            }
        };
    }
];
