export default [
    'SPDataObjectsProvider',
    function PackageConfig(SPDataObjectsProvider) {
        SPDataObjectsProvider.defineDataObject('Package', Package);
    }
];

function Package(data) {
    this.cartItemId = data.cartItemId;
    this.isPackage = data.isPackage;
    this.items = data.items;
    this.name = data.name;
    this.price = data.price;
    this.priceSheetItemId = data.priceSheetItemId;
}
