export default [
    '$rootScope',
    function spFieldDirective($rootScope) {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                labelText: '@',
                isRecessed: '@?'
            },
            templateUrl: $rootScope.getDirectiveTemplateUrl('newcommon', 'sp-field')
        };
    }
];
