/* eslint-disable func-names, padding-line-between-statements, one-var */
import { computeFocalPointImageValues } from 'ts/common/utils';

export default [
    '$timeout',
    '$window',
    function spPhotoFocalPointDirective($timeout, $window) {
        var windowElement = angular.element($window);
        return {
            restrict: 'A',
            link: function spPhotoFocalPointController($scope, $element, $attributes) {
                if (
                    typeof $attributes.spPhotoFocalPoint !== 'string' ||
                    !$attributes.spPhotoFocalPoint.length
                ) {
                    return;
                }

                var photo = angular.fromJson($attributes.spPhotoFocalPoint);
                var focalPoint = {
                    x: 50.0,
                    y: 50.0
                };

                if (typeof photo.focalPoint === 'object') {
                    focalPoint = photo.focalPoint;
                }

                var positionImage = function positionImage() {
                    var containerWidth = $element.width();
                    var containerHeight = $element.height();

                    if (photo.thumb) {
                        $element.css({
                            'background-size': 'auto ' + containerHeight + 'px',
                            'background-position': 'center center',
                            'background-repeat': 'no-repeat'
                        });
                    } else {
                        const { width, height, offsetX, offsetY } = computeFocalPointImageValues(
                            containerWidth,
                            containerHeight,
                            photo.width,
                            photo.height,
                            focalPoint
                        );

                        $element.css({
                            'background-size': width + 'px ' + height + 'px',
                            'background-position': offsetX + 'px ' + offsetY + 'px'
                        });
                    }
                };

                // on load
                $timeout(function () {
                    positionImage();
                });

                // on resize
                windowElement.on('resize', positionImage);

                $scope.$on('$destroy', function () {
                    windowElement.off('resize', positionImage);
                });
            }
        };
    }
];
