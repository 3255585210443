export default [
    '$scope',
    '$routeParams',
    '$window',
    'SPBrandData',
    function BrandOrderBundleController($scope, $routeParams, $window, SPBrandData) {
        $scope.credentials = {};

        $scope.authenticateOrderBundle = function authenticateOrderBundle() {
            if (!$scope.email) {
                return;
            }

            SPBrandData.orderBundleAuth(
                $routeParams.orderId,
                $routeParams.orderDownloadId,
                $scope.email,
                $scope.credentials.doesAcceptTerms,
                function withSuccessResponse(response) {
                    if (typeof response.zipBundleData === 'object') {
                        $scope.zipBundleData = response.zipBundleData;
                    }
                },
                function withErrorResponse(response) {
                    if (typeof response.errors === 'object') {
                        $scope.errors = response.errors;
                    }
                }
            );
        };

        $scope.downloadFile = function downloadFile(zipBundleFile) {
            zipBundleFile.downloaded = true;

            SPBrandData.logOrderBundleDownloaded(
                $scope.zipBundleData.id,
                function withSuccessResponse() {
                    // Success
                },
                function withErrorResponse() {
                    // Error
                }
            );

            $window.location.href = zipBundleFile.url;
        };
    }
];
