/**
 * @ngdoc directive
 * @name sp.common.directive:spCharacterCount
 * @requires $compile
 * @requires $timeout
 * @restrict A
 *
 * @description
 * Displays the remaining character count for the attached to textarea.
 */
var template =
    '<span ' +
    'class="sp-character-count-remaining-count" ' +
    'data-testid="characterCountRemaining" ' +
    'ng-bind=" getRemainingCharacterCount() ">' +
    '</span>';

export default [
    '$compile',
    '$timeout',
    function spCharacterCountDirective($compile, $timeout) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function spCharacterCountController($scope, $element, $attrs, ngModelController) {
                $scope.getRemainingCharacterCount = getRemainingCharacterCount;

                $element.on('blur', function onElementBlur() {
                    if (
                        $element
                            .parent()
                            .next()
                            .hasClass('sp-form-element-error-text')
                    ) {
                        getRemainingCharacterCount() < 0
                            ? $element
                                  .parent()
                                  .next()
                                  .css('display', 'block')
                            : $element
                                  .parent()
                                  .next()
                                  .css('display', 'none');
                    }
                });

                $element.on('focus', function onElementFocus() {
                    if (
                        $element
                            .parent()
                            .next()
                            .hasClass('sp-form-element-error-text')
                    ) {
                        $element
                            .parent()
                            .next()
                            .css('display', 'none');
                    }
                });

                $element.on('input', function setMaxScrollTopOnTextareaWhenAppendingText() {
                    if (
                        getRemainingCharacterCount() < 0 &&
                        $element
                            .parent()
                            .next()
                            .hasClass('sp-form-element-error-text')
                    ) {
                        $element
                            .parent()
                            .next()
                            .css('display', 'none');
                    }

                    // Allows IE11 to set element.selectionStart to new caret position.
                    $timeout(function setMaxScrollTop() {
                        var element = $element[0];

                        if (getCharacterCount() === element.selectionStart) {
                            var scrollableDistance = element.scrollHeight - element.clientHeight;

                            element.scrollTop = scrollableDistance;
                        }
                    });
                });

                $element.after($compile(template)($scope));

                function getCharacterCount() {
                    var modelViewValue = ngModelController.$viewValue;

                    if (modelViewValue) {
                        return modelViewValue.length;
                    }

                    return 0;
                }

                function getRemainingCharacterCount() {
                    return $attrs.ngMaxlength - getCharacterCount();
                }
            }
        };
    }
];
