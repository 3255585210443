import Api from 'Sp/Api';

/**
 * The purpose of this service is to be an interface to all server endpoints that interact
 * with the current visitor's cart.
 */
export default [
    'SPDataSourcePrototype',
    'SPDataObjects',
    'SPPhoto',
    'spAppData',
    'spCartAdapter',
    '$rootScope',
    function SPCartDataService(
        SPDataSourcePrototype,
        SPDataObjects,
        SPPhoto,
        spAppData,
        spCartAdapter,
        $rootScope
    ) {
        SPDataSourcePrototype.attachPrototype(this);

        var eventData = spAppData.get('eventData');

        this._getEndpointUri = function getEndpointUri(endpoint) {
            return '/gallery/' + eventData.uriId + '/' + endpoint;
        };

        this._hydrateResponse = function hydrateResponse(response) {
            if (typeof response.cart === 'object') {
                response.cart.items = [];
                response.cart.packageList = [];

                if (typeof response.cart.groupedItems === 'object') {
                    const itemGroups = window.SPObject.convertToArray(response.cart.groupedItems);

                    delete response.cart.groupedItems;

                    itemGroups.forEach(function flattenCartItems(filterGroups) {
                        filterGroups = window.SPObject.convertToArray(filterGroups);

                        filterGroups.forEach(function buildCartItems(itemData) {
                            const cartItem = SPDataObjects.factory('CartItem', itemData);

                            cartItem.__initCrop(SPPhoto);
                            response.cart.items.push(cartItem);
                        });
                    });
                }

                if (typeof response.cart.packages === 'object') {
                    const packageList = response.cart.packages;

                    delete response.cart.packages;

                    packageList.forEach(function flattenPackageItems(packageObject) {
                        packageObject.items.forEach(function buildPackageItems(item, itemIndex) {
                            const packageItem = SPDataObjects.factory('CartItem', item);

                            packageItem.__initCrop(SPPhoto);
                            packageObject.items[itemIndex] = packageItem;
                        });

                        response.cart.packageList.push(
                            SPDataObjects.factory('CartItem', packageObject).packageCartItem
                        );
                    });
                }
            }

            if (typeof response.userState === 'object') {
                if (typeof response.userState.hasUserEmail === 'boolean') {
                    $rootScope.userState.hasUserEmail = response.userState.hasUserEmail;
                }

                if (typeof response.userState.hasEventPassword === 'boolean') {
                    $rootScope.userState.hasEventPassword = response.userState.hasEventPassword;
                }

                if (typeof response.userState.isVisitorEventContact === 'boolean') {
                    $rootScope.userState.isVisitorEventContact =
                        response.userState.isVisitorEventContact;
                }

                if (typeof response.userState.isAdminModeEnabled === 'boolean') {
                    $rootScope.userState.isAdminModeEnabled = response.userState.isAdminModeEnabled;
                }

                if (typeof response.userState.numFavorites === 'number') {
                    $rootScope.userState.numFavorites = response.userState.numFavorites;
                }

                if (typeof response.userState.numCartItems === 'number') {
                    $rootScope.userState.numCartItems = response.userState.numCartItems;
                }

                if (typeof response.userState.cartPreDiscountSubtotal === 'number') {
                    $rootScope.userState.cartPreDiscountSubtotal =
                        response.userState.cartPreDiscountSubtotal;
                }

                if (typeof response.userState.cartPreDiscountSubtotalFormatted === 'string') {
                    $rootScope.userState.cartPreDiscountSubtotalFormatted =
                        response.userState.cartPreDiscountSubtotalFormatted;
                }

                if (typeof response.userState.cartPostDiscountSubtotal === 'number') {
                    $rootScope.userState.cartPostDiscountSubtotal =
                        response.userState.cartPostDiscountSubtotal;
                }

                if (typeof response.userState.cartPostDiscountSubtotalFormatted === 'string') {
                    $rootScope.userState.cartPostDiscountSubtotalFormatted =
                        response.userState.cartPostDiscountSubtotalFormatted;
                }

                if (typeof response.userState.cartHasDiscounts === 'boolean') {
                    $rootScope.userState.cartHasDiscounts = response.userState.cartHasDiscounts;
                }
            }
        };

        this.getCartItems = function getCartItems(successCallback, errorCallback) {
            return this.makePostRequest('cart/items', {}, successCallback, errorCallback);
        };

        this.emptyCart = function emptyCart(successCallback, errorCallback) {
            return this.makePostRequest('cart/clear', {}, successCallback, errorCallback);
        };

        this.prepareCheckout = function prepareCheckout(successCallback, errorCallback) {
            return this.makeGetRequest('cart/preparecheckout', {}, successCallback, errorCallback);
        };

        this.addToCart = function addToCart(
            boundItem,
            photos,
            albumId,
            fromFavorites,
            email,
            dataProcessingAndStorageConsent,
            successCallback,
            errorCallback
        ) {
            const cartParameters = {
                albumId,
                boundItem,
                dataProcessingAndStorageConsent,
                email,
                photos,
                fromFavorites
            };
            const cartData = spCartAdapter.serializeCartData(cartParameters);
            let dataObjects =
                boundItem.type === Api.PRICE_SHEET_ITEM_PACKAGE_TYPE ? [cartData] : [boundItem];

            return this.makePostRequest(
                'cart/additem',
                cartData,
                function onSuccess(response) {
                    boundItem.inCart = true;
                    boundItem.cartQuantity = boundItem.quantity;

                    if (photos) {
                        photos.forEach(function markPhotosAsInCart(photo) {
                            photo.isInCart = true;
                        });
                    }

                    if (typeof successCallback === 'function') {
                        successCallback(response);
                    }
                },
                errorCallback,
                dataObjects,
                ['addToCart', 'updateCart']
            );
        };

        this.launchEditor = function launchEditor(
            { templateId: template_id, whccProductId: whcc_product_id },
            photo_id,
            context,
            context_id,
            successCallback,
            errorCallback
        ) {
            return this.makePostRequest(
                'cart/launcheditor',
                {
                    context,
                    context_id,
                    photo_id,
                    template_id,
                    whcc_product_id
                },
                successCallback,
                errorCallback
            );
        };

        this.removeFromCart = function removeFromCart(
            boundItem,
            photos,
            successCallback,
            errorCallback
        ) {
            return this.makePostRequest(
                'cart/removeitem',
                {
                    photo_id: photos
                        .map(function extractPhotoIds(photo) {
                            return photo.id;
                        })
                        .join(','),
                    cart_item_id: boundItem.cartItemId
                },
                function onSuccess(response) {
                    boundItem.inCart = false;
                    boundItem.cartQuantity = boundItem.quantity = 0;

                    if (typeof successCallback === 'function') {
                        successCallback(response);
                    }
                },
                errorCallback,
                [boundItem],
                ['removeFromCart', 'updateCart']
            );
        };

        this.removePackageFromCart = function removePackageFromCart(
            packages,
            successCallback,
            errorCallback
        ) {
            return this.makePostRequest(
                'cart/removeitem',
                {
                    cart_item_id: packages.cartItemId,
                    is_package: packages.isPackage ? 1 : 0
                },
                function onSuccess(response) {
                    packages.inCart = false;
                    packages.cartQuantity = packages.quantity = 0;

                    if (typeof successCallback === 'function') {
                        successCallback(response);
                    }
                },
                errorCallback,
                [packages],
                ['removeFromCart', 'updateCart']
            );
        };

        this.addPromoCode = function addPromoCode(promoCode, successCallback, errorCallback) {
            return this.makePostRequest(
                'cart/promocode',
                {
                    promo_code: promoCode,
                    promo_code_action: 'add'
                },
                successCallback,
                errorCallback
            );
        };

        this.removePromoCode = function removePromoCode(promoCode, successCallback, errorCallback) {
            return this.makePostRequest(
                'cart/promocode',
                {
                    promo_code: promoCode,
                    promo_code_action: 'delete'
                },
                successCallback,
                errorCallback
            );
        };
    }
];
