import Braintree from 'Sp/Braintree';

/**
 * @ngdoc directive
 * @name sp.common.directive:spBraintreeChallengeFlow
 * @restrict E
 *
 * @description
 * Handles Braintree 3DS Flow
 *
 * @param {number} chargeAmount Amount to charge card
 * @param {object} creditCardDetails Credit card data to use in flow
 * @param {function} onComplete Handler when flow is complete
 * @param {function} onError Handler for flow error
 * @param {function} onReset Handler for when flow is reset
 * @param {string} resetText Text show on reset action
 */
export default {
    template: `
        <sp-ui-braintree-challenge
            error-message=" $ctrl.errorMessage | translate "
            has-error=" $ctrl.hasError "
            on-reset=" $ctrl.onReset "
            open-subscribe=" $ctrl.onBraintreeChallengeSubscribe "
            reset-text=" $ctrl.resetText ">
        </sp-ui-braintree-challenge>
    `,
    bindings: {
        chargeAmount: '<',
        creditCardDetails: '<',
        onComplete: '<',
        onError: '<',
        onReset: '<',
        onShowChallenge: '<',
        resetText: '<'
    },
    controller: [
        '$timeout',
        'spBraintree3DSecure',
        function spBraintreeChallengeFlowController($timeout, spBraintree3DSecure) {
            const $ctrl = this;
            let openFlow;
            let renderChallenge;
            let closeFlow;

            $ctrl.onBraintreeChallengeSubscribe = onBraintreeChallengeSubscribe;
            $ctrl.onBraintreeInjectIFrame = onBraintreeInjectIFrame;
            $ctrl.$onInit = onInit;

            function onInit() {
                $ctrl.hasError = false;
                $ctrl.errorMessage = '';

                spBraintree3DSecure
                    .verifyCard(
                        $ctrl.creditCardDetails,
                        $ctrl.chargeAmount,
                        onBraintreeInjectIFrame
                    )
                    .then((data) => {
                        const { creditCard, paymentMethodNonce, verified, verifiedStatus } = data;

                        if (!verified) {
                            const verificationMessage = verifiedStatus
                                ? Braintree.getBraintreeVerificationCodeMessage(verifiedStatus)
                                : undefined;

                            showError(verificationMessage);

                            return;
                        }

                        return $ctrl.onComplete({ paymentMethodNonce, creditCard });
                    })
                    .catch((error) => {
                        const verificationMessage = error && error.code
                            ? Braintree.getBraintreeVerificationCodeMessage(error.code)
                            : undefined;

                        showError(verificationMessage);
                    })
                    .finally(() => {
                        closeFlow();
                    });
            }

            function onBraintreeInjectIFrame(iframe) {
                $timeout(() => $ctrl.onShowChallenge && $ctrl.onShowChallenge());

                openFlow();
                renderChallenge(iframe);
            }

            function onBraintreeChallengeSubscribe({ open, update, close }) {
                openFlow = open;
                renderChallenge = update;
                closeFlow = close;
            }

            function showError(message) {
                $ctrl.onError && $ctrl.onError(message);

                $timeout(() => {
                    $ctrl.hasError = true;
                    $ctrl.errorMessage = message;
                });
            }
        }
    ]
};
