import { TITLE_BAR_EVENTS } from 'Sp/Angular/Events/Common';
import { keyboardKeys } from 'Sp/Utility';

/**
 * @ngdoc directive
 * @name sp.common.directive:spTitleBar
 * @requires $rootScope
 * @requires $timeout
 * @restrict E
 *
 * @description
 * Sets up the title bar present on every screen in the Studio Panel.
 *
 * @param {string} titleText Text to display in the title label
 * @param {array<object>} actions Array of objects describing the buttons on the title bar
 * @param {string} actions.text Text for the action. Is translated
 * @param {function} actions.click The function called when the action is clicked
 * @param {string=} back Function to invoke when back is clicked
 * @param {string=} close Function to invoke when close is clicked
 * @param {boolean=} titleEditable Determines if the title can be edited in the title bar
 * @param {integer=} titleMaxLength Sets the max length for the title input. Defaults to 100
 * @param {string=} titleClass CSS class attached to the title label
 * @param {boolean=} titleSkipTranslation If true, will skip translation filter on title
 * @param {string=} subTitleText Text to display in the subtitle label
 * @param {string=} subTitleClass CSS class attached to the subtitle label
 * @param {boolean=} subTitleSkipTranslation If true, will skip translation filter on subtitle
 * @param {object=} subtitleLink A link to be displayed next to the subtitle text
 * @param {string} subtitleLink.text The display text for the subtitle link
 * @param {function} subtitleLink.click Will be called when the subtitle link is clicked
 * @param {boolean=} showHelp Determines if the show help button should be displayed. Defaults to true.
 * @param {string=} asideButtonText Text to display on the aside button
 * @param {string=} asideButtonTextClass CSS class attached to the aside button
 * @param {array<object>=} helpMenuLinks FAQ links associated with the current page.  Set up
        in Studio Admin, acquired automatically from the FAQ_GET event in StudioRoot.
 * @param {string} helpMenuLinks.path The path for the article in the knowledge base
 * @param {string} helpMenuLinks.text The text for the link
 * @param {boolean=} isPrimaryButtonDisabled Determines if the primary, blue button is disabled.
 * @param {boolean=} isLocked Determines if the bar is locked to the top of the screen. Defaults to false.
 * @param {object=} smallScreen Contains all titlebar properties to display for mobile. Any missing properties
 *      default to the main title bar value.
 * @param {boolean=} enableRecaptcha Determines if recaptcha should be ran on the main action button. Defaults to false.

 */
export default [
    '$rootScope',
    '$timeout',
    'spAppData',
    function spTitleBarDirective($rootScope, $timeout, spAppData) {
        return {
            restrict: 'E',
            scope: {
                titleText: '=',
                actions: '=',
                back: '=?',
                close: '=?',
                titleEditable: '=?',
                titleMaxLength: '=?',
                titleClass: '@?',
                titleSkipTranslation: '=?',
                subTitleText: '@?',
                subTitleClass: '@?',
                subTitleSkipTranslation: '=?',
                subtitleLink: '=?spTitleBarSubtitleLink',
                showHelp: '=?',
                asideButtonText: '@?',
                asideButtonTextClass: '@?',
                helpMenuLinks: '=?',
                isPrimaryButtonDisabled: '=?',
                isLocked: '=?',
                smallScreen: '=?',
                enableRecaptcha: '=?'
            },
            templateUrl: $rootScope.getDirectiveTemplateUrl('newcommon', 'sp-title-bar'),
            link: function spTitleBarController($scope, $element) {
                const $titleTextInput = $element.find('input[type="text"]');

                window.recaptchaCallback = recaptchaCallback;

                // If the show-help attribute is not defined, we assume
                // help should be enabled.
                if ($scope.showHelp === undefined) {
                    $scope.showHelp = true;
                }

                if ($scope.titleEditable === undefined) {
                    $scope.titleEditable = false;
                }

                if (!$scope.titleMaxLength) {
                    $scope.titleMaxLength = 100;
                }

                $scope.help = {};
                $scope.editMode = {
                    titleText: '',
                    enabled: false
                };
                $scope.additionalActionOptions = [];
                $scope.primarySmallScreenAction = null;
                $scope.secondarySmallScreenAction = null;
                $scope.additionalSmallScreenActionOptions = [];

                $scope.doAdditionalAction = function doAdditionalAction(action) {
                    $scope.additionalActionsMenuShown = false;
                    action.click();
                };

                $scope.editTitle = function editTitle() {
                    if ($scope.titleEditable) {
                        $scope.editMode.titleText = $scope.titleText;
                        $scope.editMode.enabled = true;

                        $timeout(() => {
                            $titleTextInput[0].select();
                        });
                    }
                };

                $scope.updateTitleText = function updateTitleText($event, doSaveAndDisable) {
                    $scope.titleText = $scope.editMode.titleText;

                    if ($event.which === keyboardKeys.RETURN || doSaveAndDisable) {
                        $scope.$emit(TITLE_BAR_EVENTS.TITLE_TEXT_EDITED, $scope.titleText);
                    }

                    if ($event.which === keyboardKeys.RETURN || doSaveAndDisable) {
                        $scope.editMode.enabled = false;
                    }
                };

                $scope.hasAdditionalActionsMenu = function hasAdditionalActionsMenu() {
                    return $scope.actions && $scope.actions.length > 3;
                };

                $scope.hasAdditionalSmallScreenActionsMenu =
                    function hasAdditionalSmallScreenActionsMenu() {
                        if ($scope.smallScreen && $scope.smallScreen.actions) {
                            return $scope.smallScreen.actions.length > 2;
                        }

                        return $scope.actions && $scope.actions.length > 2;
                    };

                $scope.hasOneSmallScreenAction = function hasOneSmallScreenAction() {
                    if ($scope.smallScreen && $scope.smallScreen.actions) {
                        return $scope.smallScreen.actions.length === 1;
                    }

                    return $scope.actions && $scope.actions.length === 1;
                };

                $scope.shouldSkipSmallScreenTitleTranslation =
                    function shouldSkipSmallScreenTitleTranslation() {
                        return $scope.smallScreen &&
                            $scope.smallScreen.hasOwnProperty('titleSkipTranslation')
                            ? $scope.smallScreen.titleSkipTranslation
                            : $scope.titleSkipTranslation;
                    };

                $scope.shouldSkipSmallScreenSubTitleTranslation =
                    function shouldSkipSmallScreenSubTitleTranslation() {
                        return $scope.smallScreen &&
                            $scope.smallScreen.hasOwnProperty('subTitleSkipTranslation')
                            ? $scope.smallScreen.subTitleSkipTranslation
                            : $scope.subTitleSkipTranslation;
                    };

                $scope.toggleAdditionalActionsMenu = function toggleAdditionalActionsMenu() {
                    $scope.additionalActionsMenuShown = !$scope.additionalActionsMenuShown;
                };

                $scope.toggleHelpMenu = function toggleHelpMenu() {
                    $scope.helpMenuShown = !$scope.helpMenuShown;
                };

                function recaptchaCallback() {
                    $scope.actions[0].click();
                }

                $scope.mainAction = function mainAction() {
                    if (
                        $scope.enableRecaptcha &&
                        'grecaptcha' in window &&
                        spAppData.get('studioData')?.isInTrial
                    ) {
                        window.grecaptcha.ready(() => {
                            window.grecaptcha.execute();
                        });
                    } else {
                        $scope.actions[0].click();
                    }
                };

                $scope.mainSmallScreenAction = function mainSmallScreenAction() {
                    if (
                        $scope.enableRecaptcha &&
                        'grecaptcha' in window &&
                        spAppData.get('studioData')?.isInTrial
                    ) {
                        window.grecaptcha.ready(() => {
                            window.grecaptcha.execute();
                        });
                    } else {
                        $scope.primarySmallScreenAction.click();
                    }
                };

                $scope.$on('$routeChangeSuccess', function onRouteChangeSuccess() {
                    $scope.help = {};
                    $scope.editMode.enabled = false;
                    $scope.additionalActionsMenuShown = false;
                    $scope.helpMenuShown = false;
                    $scope.titleMaxLength = 100;
                });

                $scope.$watch('actions', function setAdditionalActions(actions) {
                    if (actions) {
                        $scope.additionalActionOptions = actions.slice(2).map(toOption);
                    }
                });

                $scope.$watch(
                    function getSmallScreenActions() {
                        return ($scope.smallScreen && $scope.smallScreen.actions) || $scope.actions;
                    },
                    function setAdditionalSmallScreenActions(actions) {
                        if (actions) {
                            $scope.primarySmallScreenAction = actions[0];

                            if (actions.length > 1) {
                                $scope.secondarySmallScreenAction = actions[1];
                            } else {
                                $scope.secondarySmallScreenAction = null;
                            }

                            $scope.additionalSmallScreenActionOptions = actions
                                .slice(1)
                                .map(toOption);
                        } else {
                            $scope.primarySmallScreenAction = null;
                            $scope.secondarySmallScreenAction = null;
                        }
                    }
                );

                $scope.$watch(
                    function watchIsLocked() {
                        return $scope.isLocked === true;
                    },
                    function isLockedChange(isLocked) {
                        $element.toggleClass('sp-title-bar-locked', isLocked);
                    }
                );

                $scope.$watch(
                    function watchEnableRecaptcha() {
                        return $scope.enableRecaptcha === true;
                    },
                    function enableRecaptchaChange(enableRecaptcha) {
                        const isInTrial = spAppData.get('studioData')?.isInTrial;

                        if (enableRecaptcha && isInTrial) {
                            const recaptchaKey = spAppData.get('studioData')?.recaptchaKey;

                            window.grecaptcha.ready(() => {
                                try {
                                    window.grecaptcha.render('recaptcha-container', {
                                        callback: 'recaptchaCallback',
                                        sitekey: recaptchaKey,
                                        size: 'invisible'
                                    });
                                } catch {
                                    // Its already rendered so just try resetting.
                                    if ('reset' in window.grecaptcha) {
                                        window.grecaptcha.reset();
                                    }
                                }
                            });
                        }
                    }
                );

                function toOption(action) {
                    return {
                        text: action.text,
                        value: action.click
                    };
                }
            }
        };
    }
];
