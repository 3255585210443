import Invoice from 'Sp/Invoice';

/**
 * @ngdoc filter
 * @name sp.common.filter:spInvoiceRetainerDueLabel
 * @requires translateFilter
 *
 * @description
 * Displays the appropriate "Retainer Due" label text based on the given invoice retainer label slug.
 *
 * @param {string} invoiceRetainerLabel The invoice retainer label slug.
 *
 * @returns {string} The appropriate "Retainer Due" label text.
 */
export default [
    'translateFilter',
    function spInvoiceRetainerDueLabelFilter(translateFilter) {
        return function filterspInvoiceRetainerDueLabel(invoiceRetainerLabel) {
            let retainerDueLabelText;

            switch (invoiceRetainerLabel) {
                case Invoice.RETAINER_LABEL.DEPOSIT:
                    retainerDueLabelText = 'common.invoice.retainerDueLabel.deposit';

                    break;
                case Invoice.RETAINER_LABEL.NON_REFUNDABLE_PAYMENT:
                    retainerDueLabelText = 'common.invoice.retainerDueLabel.nonRefundablePayment';

                    break;
                case Invoice.RETAINER_LABEL.RETAINER:
                    retainerDueLabelText = 'common.invoice.retainerDueLabel.retainer';

                    break;
            }

            return translateFilter(retainerDueLabelText);
        };
    }
];
