import React from 'react';
import { Box, H1, styled } from 'sp-ui';
import { useBrandTheme } from './BrandThemeContext';
import Footer from './Footer';
import Header from './Header';
import { IAppDataBrandData } from './types';

interface IAboutPageProps {
    $location: SpAngularJs.ILocation;
    $route: SpAngularJs.IRoute;
    spAppData: SpAngularJs.ISpAppData;
    spApiHttp: SpAngularJs.ISpApiHttp;
    translateFilter: SpAngularJs.ITranslateFilter;
}

const AboutPageContainer = styled(Box)(
    ({ theme }) => `
        width: 100%;
        height: 100%;
        padding-top: 49px;
        display: flex;
        flex-direction: column;
        min-height: 100vh;

        p {
            text-align: center;
            font-family; 'OpenSans Regular';
            color: #a5a5a5;
        }

        ${theme.responsive.smallScreenOnly`
            padding-top: 21px;
        `}
    `
);
const StyledHeading = styled(H1)<{ color: string }>(
    ({ color }) => `
        color: #${color};
        text-align: center;
        font-size: 28px;
        letter-spacing: 3px;
        font-weight: 700;
        line-height: 36px;
        padding: 48px 0;
        text-transform: uppercase;

    `
);
const StyledPageContent = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'backgroundOptionId'
})<{ backgroundOptionId: number }>(
    ({ backgroundOptionId }) => `
        width: 596px;
        height: 100%;
        flex: 1;
        margin: auto;
        text-align: left;
        border-bottom: 1px solid ${
            backgroundOptionId === 2 ? 'rgba(255, 255, 255, 0.2);' : 'rgba(0, 0, 0, 0.2);'
        }
`
);
const StyledAboutText = styled(Box)`
    width: 596px;
    flex: 1;
    text-align: left;
`;

const AboutPage: React.FC<IAboutPageProps> = ({
    $location,
    $route,
    spAppData,
    translateFilter
}) => {
    const { brandTheme } = useBrandTheme();
    const { backgroundOptionId, primaryColor } = brandTheme;
    const brandData = spAppData.get<IAppDataBrandData>('brandData');

    return (
        <AboutPageContainer>
            <Header
                $location={$location}
                $route={$route}
                translateFilter={translateFilter}
                spAppData={spAppData}
            />
            <StyledPageContent backgroundOptionId={backgroundOptionId}>
                <StyledHeading data-testid="brand-about-page-heading" color={primaryColor}>
                    {translateFilter('aboutHeader')}
                </StyledHeading>
                <StyledAboutText
                    data-testid="brand-about-text"
                    dangerouslySetInnerHTML={{
                        __html: brandData.aboutText.replace(
                            /<p>/gm,
                            '<p style="text-align: left;">'
                        )
                    }}
                />
            </StyledPageContent>
            <Footer spAppData={spAppData} />
        </AboutPageContainer>
    );
};

export default AboutPage;
