import React from 'react';
import { Box, H5, styled } from 'sp-ui';
import { useBrandTheme } from './BrandThemeContext';
import { IAppDataBrandData } from './types';

interface IBrandLogo {
    spAppData: SpAngularJs.ISpAppData;
}

const StyledBrandLogo = styled(Box)(
    ({ theme }) => `
        display: block;
        position: relative;
        margin: 0 auto 30px;
        text-align: center;
        
        img {
            max-width: 300px;
            max-height: 225px;
            ${theme.responsive.mediumScreenDown`
                max-width: 220px;
                max-height: 165px;
            `}
            vertical-align: middle;
            border: 0;
        }
    `
);
const StyledHeader = styled(H5, {
    shouldForwardProp: (prop) => prop !== 'color'
})<{ color: string }>(
    ({ color, theme }) => `
        font-size: 12px;
        padding: 10px 0 7px 0;
        text-transform: uppercase;
        font-family: 'OpenSans-SemiBold';
        color: #${color};

        ${theme.responsive.mediumScreenUp`
            font-size: 14px;
        `}
    `
);

const BrandLogo: React.FC<IBrandLogo> = ({ spAppData }) => {
    const { brandTheme } = useBrandTheme();
    const { primaryColor, eventLogo } = brandTheme;
    const logoUrl = eventLogo ? eventLogo.url : '';
    const { name } = spAppData.get<IAppDataBrandData>('brandData');

    return (
        <StyledBrandLogo data-testid="brand-logo">
            {logoUrl ? (
                <img src={logoUrl} alt={name} data-testid="brand-logo-image" />
            ) : (
                <StyledHeader color={primaryColor} data-testid="brand-logo-header">
                    {name}
                </StyledHeader>
            )}
        </StyledBrandLogo>
    );
};

export default BrandLogo;
