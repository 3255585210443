/**
 * @ngdoc filter
 * @name sp.common.filter:spAddressLastLine
 *
 * @description
 * Formats the last line of a mailing address
 *
 * @param {object} address An object with address information
 * @param {string=} address.city The address city
 * @param {string=} address.state The address state
 * @param {string=} address.zip The address postal code
 *
 * @returns {string} Some or all of: City, State 00000
 */
export default [
    function spAddressLastLineFilter() {
        return function filterAddressToAddressLastLine(address) {
            var lastLine = '';

            if (!address) {
                return lastLine;
            }

            if (address.city) {
                lastLine += address.city;

                if (address.state) {
                    lastLine += ', ';
                }
            }

            if (address.state) {
                lastLine += address.state;
            }

            if (address.zip) {
                if (lastLine) {
                    lastLine += ' ';
                }

                lastLine += address.zip;
            }

            return lastLine;
        };
    }
];
