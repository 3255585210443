import { SIGNATURE_EVENTS } from 'Sp/Angular/Events/Common';
import Api from 'Sp/Api';

export default [
    '$rootScope',
    'spApiHttp',
    'spApiHypermedia',
    function SignatureDataStore($rootScope, spApiHttp, spApiHypermedia) {
        $rootScope.$on(SIGNATURE_EVENTS.CREATE, (_, signature) => {
            angular.extend(signature, { id: null, type: Api.SIGNATURE_TYPE });

            spApiHypermedia
                .getLink([{ type: Api.SIGNATURE_TYPE }])
                .then(({ href }) => spApiHttp.post(href, signature, true))
                .then(({ data: signature }) => {
                    $rootScope.$broadcast(SIGNATURE_EVENTS.CREATED, signature);
                });
        });
    }
];
