import { DATE_PICKER_EVENTS } from 'Sp/Angular/Events/Common';

/**
 * @ngdoc directive
 * @name sp.common.directive:spDatePicker
 * @requires $rootScope
 * @restrict E
 *
 * @description
 * Creates the ShootProof date picker. Uses moment.js.
 *
 * @param {string} currentDatePicked Current date picked
 * @param {string} minimumDate The minimum date that can be picked
 * @param {string} maximumDate The maximum date that can be picked
 **/
export default [
    '$rootScope',
    function spDatePickerDirective($rootScope) {
        return {
            restrict: 'E',
            scope: {
                currentDatePicked: '=',
                minimumDate: '=?',
                maximumDate: '=?'
            },
            templateUrl: $rootScope.getDirectiveTemplateUrl('newcommon', 'sp-date-picker'),
            link: function spDatePickerController($scope) {
                const today = new Date();

                $scope.today = today;
                $scope.daysOfWeek = moment.weekdaysShort();

                $scope.displayMonthForToday = function displayMonthForToday() {
                    $scope.datePickerDisplayDateMoment = moment();

                    generateDatePicker();
                };

                $scope.displayPreviousMonth = function displayPreviousMonth() {
                    $scope.datePickerDisplayDateMoment.subtract(1, 'month');

                    generateDatePicker();
                };

                $scope.displayNextMonth = function displayNextMonth() {
                    $scope.datePickerDisplayDateMoment.add(1, 'month');

                    generateDatePicker();
                };

                $scope.pickDay = function pickDay(day) {
                    if (!day.isValid) {
                        return;
                    }

                    const pickedDay = moment({
                        date: day.date,
                        month: day.month,
                        year: day.year
                    }).utc();

                    $scope.$emit(DATE_PICKER_EVENTS.DATE_PICKED, new Date(pickedDay));
                };

                $scope.$watch('currentDatePicked', function onCurrentDatePickedUpdated(date) {
                    $scope.datePickerDisplayDateMoment = moment(date ? date : today);

                    generateDatePicker();
                });

                function generateDatePicker() {
                    const calendarMonthDate = $scope.datePickerDisplayDateMoment.clone();
                    const calendarMonth = calendarMonthDate.month();
                    const dayOfWeekOfFirstOfMonth = calendarMonthDate.startOf('month').day();
                    const dayOfWeekOfLastOfMonth = calendarMonthDate.endOf('month').day();
                    const lastDayOfMonth = calendarMonthDate.daysInMonth();
                    const pickedMoment = moment($scope.currentDatePicked);
                    const todayMoment = moment(today);
                    const weeks = [];
                    let days = [];

                    if (dayOfWeekOfFirstOfMonth !== 0) {
                        const previousMonthDate = calendarMonthDate.clone().subtract(1, 'month');
                        const previousMonth = previousMonthDate.month();
                        const lastDayOfPreviousMonth = previousMonthDate.daysInMonth();

                        for (
                            let dateOffset = dayOfWeekOfFirstOfMonth - 1;
                            dateOffset >= 0;
                            dateOffset--
                        ) {
                            let lastMonthDate = lastDayOfPreviousMonth - dateOffset;

                            addDay(lastMonthDate, previousMonth, previousMonthDate.year());
                        }
                    }

                    for (let date = 1; date <= lastDayOfMonth; date++) {
                        addDay(date, calendarMonth, calendarMonthDate.year());
                    }

                    if (dayOfWeekOfLastOfMonth !== 6) {
                        const nextMonthDate = calendarMonthDate.clone().add(1, 'month');
                        const nextMonth = nextMonthDate.month();

                        for (
                            let nextDate = 1;
                            nextDate <= 6 - dayOfWeekOfLastOfMonth + 1;
                            nextDate++
                        ) {
                            addDay(nextDate, nextMonth, nextMonthDate.year());
                        }
                    } else {
                        weeks.push({ days: days });
                    }

                    $scope.weeks = weeks;

                    function addDay(date, month, year) {
                        const dayIsValid =
                            isGreaterThanMinimumRequiredDate() && isLessThanMaximumRequiredDate();
                        let day;

                        if (days.length === 7) {
                            weeks.push({ days: days });

                            days = [];
                        }

                        day = {
                            date: date,
                            month: month,
                            year: year,
                            isToday: isSameDate(todayMoment),
                            isPicked: isSameDate(pickedMoment),
                            isValid: dayIsValid
                        };

                        days.push(day);

                        function isGreaterThanMinimumRequiredDate() {
                            if (!($scope.minimumDate instanceof Date)) {
                                return true;
                            }

                            const dayMoment = moment({
                                date: date,
                                month: month,
                                year: year
                            });

                            return dayMoment.isSameOrAfter($scope.minimumDate);
                        }

                        function isLessThanMaximumRequiredDate() {
                            if (!($scope.maximumDate instanceof Date)) {
                                return true;
                            }

                            const dayMoment = moment({
                                date: date,
                                month: month,
                                year: year
                            });

                            return dayMoment.isSameOrBefore($scope.maximumDate);
                        }

                        function isSameDate(moment) {
                            return (
                                date === moment.date() &&
                                month === moment.month() &&
                                year === moment.year()
                            );
                        }
                    }
                }
            }
        };
    }
];
