/* eslint-disable func-names, guard-for-in, no-unused-vars, eqeqeq */

/**
 * Class MissingEndpointResolverException
 *
 * thrown when <datasource service>. _getEndpointUri is not defined.
 * <datasource service>. _getEndpointUri is used to resolve endpoints
 *
 * @return undefined
 */
function MissingEndpointResolverException() {
    this.name = 'FAILURE_TO_DEFINE_ENDPOINT_RESOLVER';
    this.message =
        'Cannot resolve endpoint uri due to method ' +
        "<datasource service>'_getEndpointUri' not being defined";
}

MissingEndpointResolverException.prototype = new Error();
MissingEndpointResolverException.prototype.constructor = MissingEndpointResolverException;

/**
 * Class SPDataSourcePrototype
 *
 * Prototype for all dataSource services, provides http functionality w/ hydration,
 * pre, and post processing built in.
 *
 * @return undefined
 */
function SPDataSourcePrototype($window, $http, $httpParamSerializer, spAppData) {
    /**
     * Resolves provided endpoint string into an endpoiut uri
     *
     * Gets overridden by child data services
     * EXAMPLE:
        this._getEndpointUri = function (endpoint) {
            return '/gallery/' + $rootScope.eventData.uriId  + '/' + endpoint;
        }
     *
     * @param endpoint mixed endpoint value provided when request was made,
     *  not validated, exactly what was passed in
     * @return valid endpoint url for http request
     * @required
     * @abstract
     */
    this._getEndpointUri = false;

    /**
     * Allows you to modify or handle the response as it comes in, before successCallback
     * gets called
     *
     * Gets overridden by child data services
     * EXAMPLE:
        this._hydrateResponse = function (response) {

            if (typeof response.photos === 'object') {

                response.photos = response.photos.map(function (photoData) {
                    // Do stuff or something.. whatever
                });

            }
        }
     *
     * @param response object response data returned from server
     * @return undefined
     * @abstract
     */
    this._hydrateResponse = false;

    /**
     * Allows you to handle the response when an error is generated
     *
     * Gets overridden by child data services
     * EXAMPLE:
        this._commonErrorHandler = function (response) {
            $rootScope.$broadcast('SPClientData:error', response);
        }
     *
     * @param response object response data returned from server
     * @return undefined
     * @abstract
     */
    this._commonErrorHandler = false;

    /**
     * Once this prototype service has been injected into your datasource service, Call this
     * method to modify its prototype into inherting functionality.
     *
     * @param $dataSourceService reference to the target service object.
     *  eg.. SPDataSourcePrototype.attachPrototype(this);
     * @return true
     */
    this.attachPrototype = function attachPrototype($dataSourceService) {
        Object.setPrototypeOf($dataSourceService, this);

        return true;
    };

    /**
     * Calls <callback> for each permutation of dataObjects and dataObjectActions.
     *
     * @param dataObjects An array of objects produced by SPDataObjects
     * @param dataObjectActions An array of strings used as action keys
     * @param callback This is called for each permutation of dataObjects and dataObjectActions.
     * @return bool if callback does not return strict true at any point, We exit the loop and
     *  abort.
     */
    function forEachDataObjectAction(dataObjects, dataObjectActions, callback) {
        if (
            typeof dataObjects !== 'object' ||
            !(dataObjects instanceof Array) ||
            typeof dataObjectActions !== 'object' ||
            !(dataObjectActions instanceof Array)
        ) {
            // No dataobjects or actions were provided
            return true;
        }

        for (var dataObjectIndex in dataObjects) {
            var dataObject = dataObjects[dataObjectIndex];

            for (var dataObjectActionIndex in dataObjectActions) {
                var dataObjectAction = dataObjectActions[dataObjectActionIndex];
                var returnValue = callback(dataObject, dataObjectAction);

                if (returnValue !== true) {
                    return false;
                }
            }
        }

        return true;
    }

    /**
     * Sets provided actions as in pending state for provided dataObjects
     *
     * @param dataObjects An array of objects produced by SPDataObjects
     * @param dataObjectActions An array of strings used as action keys
     * @return bool if callback does not return strict true at any point, We exit the loop and
     *  abort.
     */
    function setActionsPending(dataObjects, dataObjectActions) {
        return forEachDataObjectAction(dataObjects, dataObjectActions, function(
            dataObject,
            dataObjectAction
        ) {
            return dataObject.setActionPending(dataObjectAction);
        });
    }

    /**
     * Unsets provided actions as in pending state for provided dataObjects
     *
     * @param dataObjects An array of objects produced by SPDataObjects
     * @param dataObjectActions An array of strings used as action keys
     * @return bool if callback does not return strict true at any point, We exit the loop and
     *  abort.
     */
    function unsetActionsPending(dataObjects, dataObjectActions) {
        return forEachDataObjectAction(dataObjects, dataObjectActions, function(
            dataObject,
            dataObjectAction
        ) {
            return dataObject.unsetActionPending(dataObjectAction);
        });
    }

    /**
     * Handles each request when it first comes in, Performs sanity checks and adds in
     * requestData that we use universally
     *
     * @param requestData An object w/ params to be passed along the request
     * @param dataObjects An array of objects produced by SPDataObjects
     * @param dataObjectActions An array of strings used as action keys
     * @param $dataSourceService The child service that is making the request
     * @return bool Should the request continue, or was there an issue
     */
    function preProcessRequest(requestData, dataObjects, dataObjectActions, $dataSourceService) {
        if (typeof $dataSourceService._getEndpointUri !== 'function') {
            throw new MissingEndpointResolverException();
        }

        if (!setActionsPending(dataObjects, dataObjectActions)) {
            return false;
        }

        requestData.cr = spAppData.get('cr');

        return true;
    }

    /**
     * Produces a fake server response that is aligned with how the server would respond with
     * an error message.
     *
     * @param errorMessage Message to use
     * @return object<MockErrorResponse>
     */
    function mockErrorResponseFactory(errorMessage) {
        function MockErrorResponse(errorMessage) {
            this.status = 'error';
            this.msg = errorMessage;
        }

        return new MockErrorResponse(errorMessage);
    }

    /**
     * Handles processing the request once its setup
     *
     * @param $httpPromise Promise generated by the $http service when setting up the request
     * @param successCallback Gets called when the request is successful with the rest body
     * @param errorCallback Gets called in any error state that gets handled by datasource
     * @param dataObjects An array of objects produced by SPDataObjects
     * @param dataObjectActions An array of strings used as action keys
     * @param $dataSourceService The child service that is making the request
     * @return undefined
     */
    function handleResponse(
        $httpPromise,
        successCallback,
        errorCallback,
        dataObjects,
        dataObjectActions,
        $dataSourceService
    ) {
        $httpPromise
            .then(({ data }) => {
                unsetActionsPending(dataObjects, dataObjectActions);

                if (typeof data !== 'object') {
                    data = {};
                }

                if (typeof $dataSourceService._hydrateResponse === 'function') {
                    $dataSourceService._hydrateResponse(data);
                }

                // Validate status code
                switch (data.status) {
                    case 'success':
                        if (typeof successCallback == 'function') {
                            successCallback(data);
                        }

                        return;
                    case 'reload':
                        /**
                         * If the server says reload, we reload and dont do anything else
                         */
                        $window.location.reload();

                        return;
                    case 'auth_error':
                    case 'album_auth_error':
                    case 'album_not_found':
                    case 'error':
                    case 'form_errors':
                        break;
                    default:
                        data = mockErrorResponseFactory(
                            'Something went wrong. Please try again later.'
                        );

                        break;
                }

                if (typeof $dataSourceService._commonErrorHandler === 'function') {
                    $dataSourceService._commonErrorHandler(data);
                }

                if (typeof errorCallback == 'function') {
                    errorCallback(data);
                }
            })
            .catch(({ data }) => {
                unsetActionsPending(dataObjects, dataObjectActions);

                if (data?.status === 0) {
                    data = mockErrorResponseFactory(
                        'Your computer isn’t connected to the Internet.'
                    );
                } else if (typeof data !== 'object') {
                    data = mockErrorResponseFactory(
                        'Something went wrong. Please try again later.'
                    );
                }

                if (typeof errorCallback == 'function') {
                    errorCallback(data);
                }
            });
    }

    /**
     * Makes an http get request to provided endpoint
     *
     * @param endpoint string that gets passed to child <datasource service>._getEndpointUri
     *  to resolve the endpoint uri to call
     * @param requestData An object w/ params to be passed along the request
     * @param successCallback Gets called when the request is successful with the rest body
     * @param errorCallback Gets called in any error state that gets handled by datasource
     *  service
     * @param dataObjects An array of objects produced by SPDataObjects
     * @param dataObjectActions An array of strings used as action keys
     * @return undefined
     */
    this.makeGetRequest = function makeGetRequest(
        endpoint,
        requestData,
        successCallback,
        errorCallback,
        dataObjects,
        dataObjectActions
    ) {
        if (typeof requestData !== 'object') {
            requestData = {};
        }

        if (!preProcessRequest(requestData, dataObjects, dataObjectActions, this)) {
            return false;
        }

        return handleResponse(
            $http.get(this._getEndpointUri(endpoint), {
                params: requestData
            }),
            successCallback,
            errorCallback,
            dataObjects,
            dataObjectActions,
            this
        );
    };

    /**
     * Makes an http post request to provided endpoint
     *
     * @param endpoint string that gets passed to child <datasource service>._getEndpointUri
     *  to resolve the endpoint uri to call
     * @param requestData An object w/ params to be passed along the request
     * @param successCallback Gets called when the request is successful with the rest body
     * @param errorCallback Gets called in any error state that gets handled by datasource
     *  service
     * @param dataObjects An array of objects produced by SPDataObjects
     * @param dataObjectActions An array of strings used as action keys
     * @return undefined
     */
    this.makePostRequest = function makePostRequest(
        endpoint,
        requestData,
        successCallback,
        errorCallback,
        dataObjects,
        dataObjectActions
    ) {
        if (typeof requestData !== 'object') {
            requestData = {};
        }

        if (!preProcessRequest(requestData, dataObjects, dataObjectActions, this)) {
            return false;
        }

        return handleResponse(
            $http.post(this._getEndpointUri(endpoint), $httpParamSerializer(requestData), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }),
            successCallback,
            errorCallback,
            dataObjects,
            dataObjectActions,
            this
        );
    };
}

export default [
    '$window',
    '$http',
    '$httpParamSerializer',
    'spAppData',
    function SPDataSourcePrototypeService($window, $http, $httpParamSerializer, spAppData) {
        return new SPDataSourcePrototype($window, $http, $httpParamSerializer, spAppData);
    }
];
