/**
 * @ngdoc directive
 * @name sp.common.directive:spFocusable
 * @restrict A
 * @requires spFocus
 *
 * @description
 * Makes an element focusable. If `spFocusableFocusOnInit` is `true`, it will focus the element when
 * rendered. Allows for the programmatic focusing of elements via the `spFocus` service.
 */
export default [
    'spFocus',
    function spFocusableDirective(spFocus) {
        return {
            restrict: 'A',
            link: function spFocusableController($scope, $element, $attrs) {
                // Ensures parsed `id` expression is used
                $element[0].id = $attrs.id;

                spFocus.register($element[0]);

                if ($attrs.spFocusableFocusOnInit === 'true') {
                    $scope.$evalAsync(function focus() {
                        $element[0].focus();
                    });
                }

                $scope.$on('$destroy', function deregister() {
                    spFocus.deregister($element[0]);
                });
            }
        };
    }
];
