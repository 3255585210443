/**
 * @ngdoc directive
 * @name sp.common.directive:spMenuToggle
 * @requires sp.common.service:spMenuRegistry
 * @restrict A
 *
 * @description
 * Attaches a given event listener to it's `$parent` element and shows and hides the
 * content of the directive. Gives an optional fixed position at the mouse coordinates.
 *
 * @example
 * <pre>
        <p
            sp-menu-toggle
            sp-menu-toggle-event="click"
            sp-menu-toggle-position=" positionType ">

                <div>When you click, you'll see this text!</div>

        </p>
 * </pre>
 *
 * @param {boolean=} isUniqueWhenOpen Will only render the most recently opened menu when true
 * @param {string} spMenuToggleEvent DOM-level event that triggers the menu display
 * @param {string=} spMenuTogglePosition Position type that will also adjust the
 * display location to the event's X and Y coordinates. Position otherwise relies on 
 * the element's CSS.
 */
export default [
    'spMenuRegistry',
    function spMenuToggleDirective(spMenuRegistry) {
        return {
            restrict: 'A',
            link: function onLink($scope, $element, $attrs) {
                const $parent = $element.parent();

                $scope.hideMenu = hideMenu;

                $parent.on($attrs.spMenuToggleEvent, renderMenu);
                $element.on('click', hideAndStopPropagation);
                $scope.$on('$destroy', () => {
                    spMenuRegistry.deregister($scope);
                });
                initialize();

                function initialize() {
                    hideMenu();

                    spMenuRegistry.register($scope);
                }

                function hideMenus() {
                    spMenuRegistry.hideAll();
                }

                function hideMenu() {
                    $element.addClass('ng-hide');
                }

                function hideAndStopPropagation($event) {
                    hideMenus();
                    $event.stopPropagation();
                }

                function renderMenu($event) {
                    if ($element.hasClass('ng-hide')) {
                        $event.stopPropagation();
                        $event.preventDefault();

                        if ($attrs.isUniqueWhenOpen === 'true') {
                            hideMenus();
                        }

                        if ($attrs.spMenuTogglePosition) {
                            $element.css({
                                position: $attrs.spMenuTogglePosition,
                                top: `${$event.clientY}px`,
                                left: `${$event.clientX}px`
                            });
                        }

                        $element.removeClass('ng-hide');
                    } else {
                        hideMenus();
                    }
                }
            }
        };
    }
];
