import CreditCard from 'Sp/CreditCard';

/**
 * @ngdoc directive
 * @name sp.common.directive:spCreditCardInput
 * @requires $timeout
 * @restrict A
 * @require ngModel
 *
 * @description
 * Allows an input field to handle and validate credit card numbers.
 *
 * @param {string} cardType Credit card type (amex, etc)
 **/
export default [
    '$timeout',
    function spCreditCardInputDirective($timeout) {
        return {
            require: 'ngModel',
            restrict: 'A',
            scope: {
                cardType: '=spCreditCardInputCardType'
            },
            link: function spCreditCardInputController($scope, element, $attributes, ngModel) {
                ngModel.$formatters.push(function withValue(value) {
                    if (typeof value === 'number') {
                        value = value.toString();
                    }

                    if (typeof value === 'string' && value.length) {
                        value = value.replace(/[^0-9]/g, '');

                        return creditCardNumberFormat(value);
                    }

                    return '';
                });

                ngModel.$parsers.unshift(function withValue(value) {
                    if (typeof value === 'string' && value.length) {
                        value = value.replace(/[^0-9]/g, '');

                        $scope.cardType = CreditCard.getCardType(value);

                        const formattedNumber = creditCardNumberFormat(value);

                        ngModel.$viewValue = formattedNumber;
                        ngModel.$render();

                        $timeout(function renderNgModel() {
                            ngModel.$render();
                        });

                        return formattedNumber.replace(/[^0-9]/g, '');
                    }

                    $scope.cardType = false;

                    return value;
                });

                ngModel.$validators.cardNumber = function validateCardNumber(value) {
                    if (typeof value === 'string' && value.length) {
                        value = value.replace(/[^0-9]/g, '');

                        if (value.length === 15 || value.length === 16) {
                            return checkSum(value);
                        }
                    }

                    return false;
                };

                function creditCardNumberFormat(cardNumberString) {
                    const cardType = CreditCard.getCardType(cardNumberString);

                    if (typeof cardType !== 'string') {
                        return cardNumberString;
                    }

                    switch (cardType) {
                        case 'amex':
                            return cardNumberString
                                .match(/^([0-9]{1,4})?([0-9]{1,6})?([0-9]{1,5})?/)
                                .slice(1, 4)
                                .join(' ')
                                .trim();
                        default:
                            return cardNumberString
                                .match(/^([0-9]{1,4}){1,4}/g)[0]
                                .match(/[0-9]{1,4}/g)
                                .join(' ');
                    }
                }

                function checkSum(num) {
                    const arr = [];
                    const dblarr = [];
                    let dbl = false;
                    let sum = 0;

                    for (let i = 0; i < num.length; i++) {
                        arr.push(num.slice(i, i + 1));
                    }

                    for (let i = arr.length - 1; i >= 0; i--) {
                        if (dbl) {
                            dblarr.push(arr[i] * 2);
                            dbl = false;
                        } else {
                            dblarr.push(arr[i]);
                            dbl = true;
                        }
                    }

                    for (let i = 0; i < dblarr.length; i++) {
                        let incVal;

                        if (dblarr[i] >= 10) {
                            incVal = dblarr[i] - 9;
                        } else {
                            incVal = dblarr[i];
                        }

                        sum += parseInt(incVal, 10);
                    }

                    if (sum % 10) {
                        return false;
                    }

                    return true;
                }
            }
        };
    }
];
