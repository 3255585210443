import { KNOWLEDGE_BASE_ARTICLE_LIST_API_PATH } from 'Sp/Api';
import { SUGGESTION_EVENTS } from 'Sp/Angular/Events/Common';
import Route from 'Sp/Route';

export default [
    '$rootScope',
    'spApiHttp',
    function SuggestionDataStore($rootScope, spApiHttp) {
        $rootScope.$on(SUGGESTION_EVENTS.LIST, (_, phrase) => {
            spApiHttp
                .get(Route.get(KNOWLEDGE_BASE_ARTICLE_LIST_API_PATH, { phrase }))
                .then(({ data: suggestions }) => {
                    $rootScope.$broadcast(SUGGESTION_EVENTS.LISTED, suggestions);
                });
        });
    }
];
