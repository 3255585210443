/* eslint-disable func-names */

export default [
    function spLocalNavigationDirective() {
        return {
            restrict: 'A',
            link: function spLocalNavigationController($scope, $element) {
                $element.on('click', function($event) {
                    if ($element.attr('href') === undefined) {
                        return;
                    }

                    // Allow open in newTab
                    if ($event.metaKey) {
                        return;
                    }

                    $event.preventDefault();

                    $scope.$apply(function() {
                        $scope.spLocalNavigation('/' + $element.attr('href'));
                    });
                });
            }
        };
    }
];
