/**
 * @ngdoc service
 * @name sp.common.service:spEventBus
 * @requires $rootScope
 *
 * @description
 * Provides a messaging layer for areas of the application to communicate
 */
export default [
    '$rootScope',
    function spEventBusService($rootScope) {
        return {
            emit: (...args) => $rootScope.$emit(...args),
            broadcast: (...args) => $rootScope.$broadcast(...args),
            on: (eventName, handler) => $rootScope.$on(eventName, stripEventParam(handler)),
            once,
            symmetricalOnce: (onceMap) => {
                const callOffs = () => offs.forEach((off) => off());
                const offs = Object.entries(onceMap).map(([event, callback]) =>
                    once(event, (data) => {
                        callback(data);
                        callOffs();
                    })
                );
            }
        };

        function once(eventName, handler) {
            return $rootScope.$once(eventName, stripEventParam(handler));
        }

        function stripEventParam(handler) {
            return (_, ...eventData) => handler(...eventData);
        }
    }
];
