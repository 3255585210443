import spPageMarkerTemplate from '../../templates/components/sp-page-marker.nghtml';

/**
 * @ngdoc component
 * @name sp.newcommon.component:spPageMarker
 * @requires $window
 *
 * @description
 * Creates a page marker to determine which input/teaxtarea is currently in focus
 *
 * @param {object} containingElementId The id of the containing element for the page marker
 * @param {object} currentInput The current input object in focus
 * @param {boolean=} isPageMarkerHidden Determines if the page marker is hidden
 */
export default {
    template: spPageMarkerTemplate,
    bindings: {
        containingElementId: '<',
        currentInput: '<',
        isPageMarkerHidden: '<?'
    },
    controller: [
        '$window',
        function spPageMarkerController($window) {
            const $ctrl = this;

            $ctrl.$onChanges = $onChanges;
            $ctrl.$onDestroy = $onDestroy;

            function $onChanges({ currentInput }) {
                if (currentInput) {
                    const { currentValue } = currentInput;

                    if (currentValue) {
                        $ctrl.currentInput = currentValue;

                        setPageMarkerLocation();
                    }
                }
            }

            angular.element($window).on('resize', onResize);

            function $onDestroy() {
                angular.element($window).off('resize', onResize);
            }

            function hasCurrentElementAndIsPageMarkerNotHidden() {
                return !$ctrl.isPageMarkerHidden && $ctrl.currentInput;
            }

            function onResize() {
                if (
                    hasCurrentElementAndIsPageMarkerNotHidden() &&
                    $ctrl.windowWidth !== $window.innerWidth
                ) {
                    $ctrl.windowWidth = $window.innerWidth;
                    setPageMarkerLocation();
                }
            }

            function setPageMarkerLocation() {
                const marginRight = 15;
                const contractMarker = document.getElementById('page-marker');
                const { top } = document
                    .getElementById($ctrl.currentInput.id)
                    .getBoundingClientRect();
                const { right } = document
                    .getElementById($ctrl.containingElementId)
                    .getBoundingClientRect();
                const { scrollY } = $window;
                const { right: bodyRight } = document.body.getBoundingClientRect();

                Object.assign(contractMarker.style, {
                    top: `${top + scrollY}px`,
                    right: `${bodyRight - right - marginRight}px`
                });
            }
        }
    ]
};
