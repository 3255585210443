import {
    CONTRACT_SIGNATURE_EVENTS,
    SIGNATURE_CAPTURE_MODAL_EVENTS,
    SIGNATURE_EVENTS
} from 'Sp/Angular/Events/Common';
import COMMON_MODALS from 'Sp/Angular/Modals/Common';

export default [
    '$scope',
    '$window',
    '$timeout',
    function SignatureCaptureController($scope, $window, $timeout) {
        const $windowElement = angular.element($window);
        let capturedSignature;
        let bypassDataStore;

        $scope.MODAL_NAME = COMMON_MODALS.SIGNATURE_CAPTURE;
        $scope.SIGNATURE_CREATED_EVENT = SIGNATURE_EVENTS.CREATED;
        $scope.signatureNotSet = true;
        $scope.isStoreable = true;

        $scope.initializeEmptySignatureIfNotSet = function initializeEmptySignatureIfNotSet() {
            if ($scope.signatureNotSet) {
                $timeout(initializeEmptySignature);
            }

            $windowElement.on('resize', function onResize() {
                if ($scope.signatureNotSet) {
                    $scope.$apply(function setDefaultSignature() {
                        if (!$scope.signature) {
                            $scope.signature = {};
                        }

                        $scope.signature.svgViewbox = getDefaultSignatureViewBox();
                    });
                }
            });
        };

        $scope.save = function save() {
            if (bypassDataStore) {
                captureSignature();

                $scope.$emit(SIGNATURE_EVENTS.CREATED, $scope.signature);
            } else {
                $scope.$emit(SIGNATURE_EVENTS.CREATE, $scope.signature);
            }
        };

        $scope.clear = initializeEmptySignature;

        $scope.resetSignature = function resetSignature() {
            if (capturedSignature) {
                setSignature(capturedSignature);
            }

            $windowElement.off('resize');
        };

        $scope.$on(CONTRACT_SIGNATURE_EVENTS.SET_SIGNATURE, (_, signature) => {
            setSignature(signature);
        });

        $scope.$on(SIGNATURE_EVENTS.CREATED, captureSignature);

        $scope.$on(CONTRACT_SIGNATURE_EVENTS.REQUIRE_CONSENT, () => {
            $scope.requireConsent = true;
        });

        $scope.$on(CONTRACT_SIGNATURE_EVENTS.BYPASS_DATA_STORE, () => {
            bypassDataStore = true;
            $scope.isStoreable = false;
        });

        $scope.$on(SIGNATURE_CAPTURE_MODAL_EVENTS.CLEAR_SIGNATURE, () => {
            $scope.signatureNotSet = true;
        });

        $scope.$on('$destroy', function unbindResizeOnWindow() {
            $windowElement.off('resize');
        });

        function initializeEmptySignature() {
            $scope.signature = {
                signaturePaths: '',
                svgViewbox: getDefaultSignatureViewBox()
            };

            $scope.signatureNotSet = true;
        }

        function getDefaultSignatureViewBox() {
            let { clientWidth, clientHeight } = document.querySelector(
                '#signature-capture-modal-signature'
            );

            clientWidth = clientWidth > 0 ? clientWidth : '412';
            clientHeight = clientHeight > 0 ? clientHeight : '154';

            return `0 0 ${clientWidth} ${clientHeight}`;
        }

        function setSignature(signature) {
            capturedSignature = signature;

            $scope.signatureNotSet = false;
            $scope.signature = signature;
        }

        function captureSignature(event, signature) {
            if (signature) {
                setSignature(signature);
            } else {
                setSignature($scope.signature);
            }
        }
    }
];
