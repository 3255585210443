/**
 * @ngdoc service
 * @name sp.newcommon.service:spScrollToElement
 *
 * @description
 * Manages scrolling to specific HTML elements while accounting for addtional
 * header and footer heights as needed.
 *
 */
export default [
    function spScrollToElement() {
        const service = this;

        Object.assign(service, {
            scrollToElement,
            getScrollElementHeight
        });

        return service;

        /**
         * @ngdoc method
         * @name scrollToElement
         * @methodOf sp.newcommon.service:spScrollToElement
         *
         * @description
         * Scrolls to an off screen element and brings it into view
         *
         * @param {string} elementId An HTML element's id
         * @param {string} headerId Additional height element id to check for to see if
         * it can obstruct the current focused element
         * @param {string} desktopFooterId Additional desktop footer element id to check for to see if
         * it can obstruct the current focused element
         * @param {string} mobileFooterId Additional mobile footer element id to check for to see if
         * it can obstruct the current focused element
         */
        function scrollToElement(elementId, headerId, desktopFooterId, mobileFooterId) {
            const element = document.getElementById(elementId);
            const boundingRectangle = element.getBoundingClientRect();
            const { height: headerHeight } = this.getScrollElementHeight(headerId);
            const { top, left, bottom, right, height } = boundingRectangle;
            const { height: desktopFooterHeight } = this.getScrollElementHeight(desktopFooterId);
            const { height: mobileFooterHeight } = this.getScrollElementHeight(mobileFooterId);
            const footerHeight = mobileFooterHeight || desktopFooterHeight;
            let outside = {
                top: top - (headerHeight + height) < 0,
                left: left < 0,
                bottom:
                    bottom + footerHeight >
                    (window.innerHeight || document.documentElement.clientHeight),
                right: right > (window.innerWidth || document.documentElement.clientWidth)
            };

            if (outside.top || outside.left || outside.bottom || outside.right) {
                const elementOffset = element.offsetTop + height;

                window.scrollTo(0, elementOffset + footerHeight);
            }
        }

        /**
         * @ngdoc method
         * @name getScrollElementHeight
         * @methodOf sp.newcommon.service:spScrollToElement
         *
         * @description
         * Gets the height of a given element if its id exists, if not it will return
         * an object with a height attribute set to 0
         *
         * @param {string} elementId An element's id
         */
        function getScrollElementHeight(elementId) {
            const element = document.getElementById(elementId);

            return element ? element.getBoundingClientRect() : { height: 0 };
        }
    }
];
