import { AUTHORIZATION_TOKEN_EVENTS } from 'Sp/Angular/Events/Client/Gallery';
import { SP_API_HTTP_EVENTS } from 'Sp/Angular/Events/Common';

export default [
    '$scope',
    '$location',
    'translateFilter',
    'SPBrandData',
    function BrandHomeController($scope, $location, translateFilter, SPBrandData) {
        let pageNumber = 1;
        let widgetFormElement;

        $scope.pagination = {
            hasMore: false,
            loading: false
        };
        $scope.loginRequest = {
            email: null,
            password: null
        };
        $scope.showInvalidEmailAddressMessage = false;
        $scope.showInvalidGalleryPasswordMessage = false;
        $scope.widget = {
            form: null,
            selectedEvent: undefined
        };

        $scope.handleWidgetFormSubmit = handleWidgetFormSubmit;
        $scope.loadMore = loadMore;

        $scope.$on(AUTHORIZATION_TOKEN_EVENTS.CREATED, () => {
            widgetFormElement.submit();
        });

        $scope.$on(AUTHORIZATION_TOKEN_EVENTS.ERRORS.UNAUTHORIZED, () => {
            $scope.showInvalidGalleryPasswordMessage = true;

            unsubmitWidgetForm();
        });

        $scope.$on(SP_API_HTTP_EVENTS.BAD_REQUEST, () => {
            $scope.showInvalidEmailAddressMessage = true;

            unsubmitWidgetForm();
        });

        $scope.translateFilter = translateFilter;

        initialize();

        function initialize() {
            // We'll always try to load a set of events, in both
            // galleries and categories layouts.  For the categories
            // layout, we're asking for any Events that are not in
            // Event Categories because they'll be appended to the
            // end of the category list.
            getEvents();
        }

        function finishLoading() {
            $scope.pagination.loading = false;
        }

        function getEvents() {
            const { layout } = $scope.homepageData;

            if (layout === 'events') {
                startLoading();
                SPBrandData.getBrandEvents(
                    pageNumber,
                    null,
                    setEventsAndPaginationInfo,
                    finishLoading
                );
            } else if (layout === 'categories') {
                startLoading();
                SPBrandData.getBrandEventsWithoutCategory(
                    pageNumber,
                    setEventsAndPaginationInfo,
                    finishLoading
                );
            }
        }

        function handleWidgetFormSubmit($event) {
            $scope.showInvalidEmailAddressMessage = false;
            $scope.showInvalidGalleryPasswordMessage = false;

            if ($scope.loginRequest.email || $scope.loginRequest.password) {
                const { email, password } = $scope.loginRequest;
                const { id: eventId } = $scope.widget.selectedEvent;

                widgetFormElement = $event.currentTarget;

                $event.preventDefault();
                $scope.$emit(AUTHORIZATION_TOKEN_EVENTS.CREATE, { email, eventId, password });
                $scope.widget.form.$setSubmitted();
            }
        }

        function loadMore() {
            pageNumber++;

            getEvents();
        }

        function setEventsAndPaginationInfo({ events, meta }) {
            finishLoading();

            if ($scope.events) {
                $scope.events = $scope.events.concat(events);
            } else {
                $scope.events = events;
            }

            $scope.pagination.hasMore = meta.currentPage < meta.totalPages;
            pageNumber = meta.currentPage;
        }

        function startLoading() {
            $scope.pagination.loading = true;
        }

        function unsubmitWidgetForm() {
            $scope.widget.form.$submitted = false;
        }
    }
];
