/**
 * @ngdoc service
 * @name sp.client.common.service:spHiddenAlbum
 * @requires $rootScope
 * @requires $http
 * @requires spAppData
 *
 * @description
 * Handles information about and flow persistence around hidden albums
 */
export default [
    '$rootScope',
    '$http',
    'spAppData',
    function spHiddenAlbumService($rootScope, $http, spAppData) {
        var initialAlbumData = spAppData.get('albumData');
        var hiddenAlbumId = spAppData.get('hiddenAlbumId');
        var isInHiddenAlbumFlow = spAppData.get('isInHiddenAlbumFlow');
        var persistIsInHiddenAlbumFlow = false;

        if (isInHiddenAlbumFlow) {
            var deregisterOnViewContextLoaded = $rootScope.$on(
                '$viewContentLoaded',
                function parseHiddenAlbumFlowPersistence() {
                    if (!persistIsInHiddenAlbumFlow) {
                        isInHiddenAlbumFlow = false;

                        $http.get(
                            '/gallery/' + $rootScope.eventData.uriId + '/clearhiddenalbumflow'
                        );
                    }

                    deregisterOnViewContextLoaded();
                }
            );
        }

        return {
            /**
             * @ngdoc property
             * @name sp.client.common.service:spHiddenAlbum#isInitialViewAHiddenAlbum
             * @propertyOf sp.client.common.service:spHiddenAlbum
             * @returns {boolean} True if the initial page request is for a hidden album
             */
            isInitialViewAHiddenAlbum: initialAlbumData ? initialAlbumData.isHidden : false,
            /**
             * @ngdoc property
             * @name sp.client.common.service:spHiddenAlbum#id
             * @propertyOf sp.client.common.service:spHiddenAlbum
             * @returns {string|undefined} Will return the id of the album if in a hidden album flow
             */
            id: hiddenAlbumId,
            /**
             * @ngdoc method
             * @name sp.client.common.service:spHiddenAlbum#isInFlow
             * @methodOf sp.client.common.service:spHiddenAlbum
             * @returns {boolean} True if user is currently in a hidden album flow
             */
            isInFlow: function isInFlow() {
                return isInHiddenAlbumFlow;
            },
            /**
             * @ngdoc method
             * @name sp.client.common.service:spHiddenAlbum#persistFlow
             * @methodOf sp.client.common.service:spHiddenAlbum
             * @description
             * If invoked before the current view has finished loading, it will keep the user in a
             * hidden album flow if they are currently in it
             */
            persistFlow: function persistFlow() {
                persistIsInHiddenAlbumFlow = true;
            }
        };
    }
];
