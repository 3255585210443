const KEYBOARD_KEYS = {
    DASH: 189,
    DOWN_ARROW: 40,
    ESCAPE: 27,
    LEFT_ARROW: 37,
    RETURN: 13,
    RIGHT_ARROW: 39,
    SPACE: 32,
    TAB: 9,
    UP_ARROW: 38
};
const MIME_TYPES = {
    CSV: 'text/csv',
    JPEG: 'image/jpeg',
    HTML: 'text/html',
    MULTIPART_MIXED: 'multipart/mixed',
    PDF: 'application/pdf',
    SHOOTPROOF_JSON: 'application/vnd.shootproof+json'
};
const FILE_SIZE_BYTES = {
    MB: 1048576 // 1024^2
};
const HEADERS = {
    CONTENT_DISPOSITION: 'Content-Disposition',
    CONTENT_TYPE: 'Content-Type'
};
const CONTENT_DISPOSITION = {
    ATTACHMENT: 'attachment',
    FILENAME: 'filename',
    INLINE: 'inline'
};
const TAG_REGEX = /^[a-zA-Z0-9\s\_\-]*$/;
const noop = () => undefined;

export {
    CONTENT_DISPOSITION,
    FILE_SIZE_BYTES,
    HEADERS,
    KEYBOARD_KEYS as keyboardKeys,
    MIME_TYPES,
    TAG_REGEX,
    noop
};
