/* eslint-disable func-names */

/**
 * spTimeout accepts the following params:
 *
 * @param sp-triggered-timeout expression this will execute after requested delay
 * @param sp-triggered-timeout-trigger expression when truthy, will start timer
 * @param sp-triggered-timeout-delay string delay time after trigger
 */
var directiveName = 'spTriggeredTimeout';

export default [
    '$timeout',
    function spTriggeredTimeoutDirective($timeout) {
        return {
            restrict: 'A',
            scope: {
                timeoutCallback: '&' + directiveName,
                trigger: '=' + directiveName + 'Trigger',
                delay: '@' + directiveName + 'Delay'
            },
            link: function spTriggeredTimeoutController($scope) {
                $scope.$watch('trigger', function(timeoutTriggered) {
                    if (timeoutTriggered) {
                        var delay;

                        if (typeof $scope.delay === 'string') {
                            delay = parseInt($scope.delay, 10);
                        }

                        $timeout(function() {
                            $scope.timeoutCallback();
                        }, delay);
                    }
                });
            }
        };
    }
];
