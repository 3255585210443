/* eslint-disable func-names */

export default [
    '$window',
    function spKeyboardDirective($window) {
        var windowElement = angular.element($window);

        return {
            restrict: 'E',
            scope: {
                isDisabled: '&disabled',
                onDigitFive: '&onDigitFive',
                onDigitFour: '&onDigitFour',
                onDigitOne: '&onDigitOne',
                onDigitSix: '&onDigitSix',
                onDigitThree: '&onDigitThree',
                onDigitTwo: '&onDigitTwo',
                onEscape: '&onEscape',
                onForwardSlash: '&onForwardSlash',
                onLeft: '&onLeft',
                onLetterF: '&onLetterF',
                onLetterH: '&onLetterH',
                onLetterM: '&onLetterM',
                onRight: '&onRight',
                onSpace: '&onSpace',
                onQuestionMark: '&onQuestionMark'
            },
            link: function spKeyboardController($scope) {
                windowElement.on('keydown', keyboardEvent);

                $scope.$on('$destroy', () => {
                    windowElement.off('keydown', keyboardEvent);
                });

                function keyboardEvent(event) {
                    if (event.originalEvent.repeat) {
                        return;
                    }

                    if ($scope.isDisabled()) {
                        return;
                    }

                    $scope.$apply(() => {
                        switch (event.key) {
                            case 'Escape':
                            case 'Esc':
                                $scope.onEscape();
                                break;

                            case ' ':
                                $scope.onSpace();
                                break;

                            case 'ArrowLeft':
                            case 'Left':
                                $scope.onLeft();
                                break;

                            case 'ArrowRight':
                            case 'Right':
                                $scope.onRight();
                                break;

                            case '1':
                                $scope.onDigitOne();
                                break;

                            case '2':
                                $scope.onDigitTwo();
                                break;

                            case '3':
                                $scope.onDigitThree();
                                break;

                            case '4':
                                $scope.onDigitFour();
                                break;

                            case '5':
                                $scope.onDigitFive();
                                break;

                            case '6':
                                $scope.onDigitSix();
                                break;

                            case 'f':
                            case 'F':
                                $scope.onLetterF();
                                break;

                            case 'h':
                            case 'H':
                                $scope.onLetterH();
                                break;

                            case 'm':
                            case 'M':
                                $scope.onLetterM();
                                break;

                            case '/':
                                $scope.onForwardSlash();
                                break;

                            case '?':
                                $scope.onQuestionMark();
                                break;

                            default:
                                return;
                        }
                    });
                }
            }
        };
    }
];
