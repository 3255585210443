/**
 * @ngdoc directive
 * @name sp.common.directive:spPopover
 * @requires $rootScope
 * @restrict E
 *
 * @description
 * Displays a Popover dropdown below and relative to its parent.  Can be
 * left or right justified.  For it to show/hide on click, must be used with
 * spMenuToggle.
 *
 * @example
 * <pre>
        <sp-popover
            sp-popover-list=" myPopoverList "
            sp-popover-item=" myRelatePopoverItem "
            sp-popover-location="below"
            sp-popover-arrow-location="left"
            sp-menu-toggle
            sp-menu-toggle-event="click">
        </sp-popover>
 * </pre>
 *
 * @param {any} spPopoverItem Item that the instance is informed by in its fully
 *     available context
 * @param {array<object>} spPopoverList List of items to display in the popover
 * @param {string} spPopoverList[].name The text to be displayed for the popover item. Takes
 *     priority over `spPopoverList[].text`
 * @param {string} spPopoverList[].text The text to be displayed for the popover item
 * @param {function} spPopoverList[].action The function called when the popover item is clicked
 * @param {boolean=} spPopoverList[].isDisabled Renders the action as disabled when true
 * @param {string} spPopoverLocation Indicates whether the popup should display 'above' or 'below'
 *     its parent
 * @param {string} spPopoverArrowLocation Indicates whether the arrow (and text) should
 *     be left or right justified with the strings 'left' or 'right'
 */
export default [
    '$rootScope',
    function spPopoverDirective($rootScope) {
        return {
            restrict: 'E',
            templateUrl: $rootScope.getDirectiveTemplateUrl('newcommon', 'sp-popover'),
            scope: {
                spPopoverItem: '=',
                spPopoverList: '='
            },
            link: function spPopoverController($scope, $element, $attrs) {
                $scope.getPositioning = getPositioning;

                function getPositioning() {
                    let classNames = '';

                    if ($attrs.spPopoverArrowLocation === 'left') {
                        classNames = 'left-arrow';

                        $element.addClass('left-align');
                    } else {
                        classNames = 'right-arrow';

                        $element.addClass('right-align');
                    }

                    if ($attrs.spPopoverLocation === 'below') {
                        classNames += ' below';
                    } else {
                        classNames += ' above';
                    }

                    return classNames;
                }
            }
        };
    }
];
