import COMMON_MODALS from 'Sp/Angular/Modals/Common';
import STUDIO_COMMON_ROUTES from 'Sp/Angular/Routes/Studio/Common';
import Route from 'Sp/Route';

export default [
    '$scope',
    '$http',
    function HelpController($scope, $http) {
        $scope.MODAL_NAME = COMMON_MODALS.HELP;
        $scope.help = {};

        $scope.resetHelp = resetHelp;
        $scope.send = send;

        function resetHelp() {
            $scope.help = {};
        }

        function send() {
            $http({
                method: 'POST',
                url: Route.get(STUDIO_COMMON_ROUTES.HELP),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                data: `question=${$scope.help.question}&details=${$scope.help.details}`
            }).then(function success(response) {
                resetHelp();
                $scope.showHoverMessage(response.data.msg);
            }, resetHelp);
        }
    }
];
