/* eslint-disable func-names */

export default [
    function spAllowContextMenuDirective() {
        return {
            restrict: 'A',
            link: function spAllowContextMenuController($scope, $element) {
                $($element[0]).on('contextmenu', function($event) {
                    $event.stopPropagation();

                    return true;
                });
            }
        };
    }
];
