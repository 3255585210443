import { SP_API_HTTP_EVENTS } from 'Sp/Angular/Events/Common';

/**
 * @ngdoc directive
 * @name sp.common.directive:spBasicModal
 * @requires $rootScope
 * @requires spModal
 * @restrict E
 *
 * @description
 * Creates a standard modal with up to 2 button actions: primary and secondary ("negative").
 * Also includes a close 'X' on the top right of the modal.  Must be opened using the spModal
 * service.
 * <pre>
       $scope.spModal.open(Sp.modals.MY_MODAL_NAME);
 * </pre>
 *
 * @param {string} name Name of the modal, required to open it on the controller
 * @param {string} heading Text for the title heading
 * @param {string=} subheading Text for the sub heading
 * @param {boolean=} hasCancel Indicates whether the secondary button is a Cancel button. Mutually
 *     exclusive to negativeAction and negativeActionText.
 * @param {function=} negativeAction Invoked when the "negative" action button is clicked
 * @param {string=} negativeActionText Text on the "negative" action button, which closes the modal
 * @param {function=} primaryAction Invoked when the "primary" action button is clicked
 * @param {string=} primaryActionText Text on the "primary" action button, which invokes specified
 * custom function
 * @param {string=} customTestIdAction Text on the wrapper div to trigger specific test ids if needed, this avoids
 * to replace the current test id spBasicModalPrimaryAction
 * @param {boolean=} isPrimaryActionDestructive Dictates whether the modal will be deleting,
 *     canceling, or removing a resource. Turns the primary button red
 * @param {boolean=} isPrimaryActionDisabled Dictates whether the primary action button is disabled
 * @param {boolean=} showSmallViewOnSmallScreen Determines whether the small screen should display smaller
 *     (not full-screen) version
 * @param {boolean=} hasBack Dictates whether or not to display the back arrow.
 * @param {function=} onBack Invoked when the back arrow is clicked.
 * @param {function=} onOpen Invoked when the modal is opened
 * @param {Array=} spBasicModalPreLoadingEvents List of events that must be completed before removing
 *      the preLoading spinner. Pairs well with onOpen.
 * @param {function=} onClose Invoked when the modal is closed
 * @param {boolean=} closeOnSubmit If true, does not dismiss the modal when the primary action is clicked
 * @param {string=} closeOnEvent If present, does not dimiss the modal when the primary action is
 *     clicked and will wait until the provided event has been broadcasted to close
 * @param {array<string>=} cancelLoadingOnEvents If present, will cancel the loading state whenever
 *     any event in this array is broadcasted after the primary action has been clicked
 */
export default [
    '$rootScope',
    'spModal',
    function spBasicModalDirective($rootScope, spModal) {
        return {
            restrict: 'E',
            scope: {
                name: '=',
                heading: '=',
                subheading: '@?',
                hasCancel: '=?',
                negativeAction: '=?',
                customTestIdAction: '=?',
                negativeActionText: '=?',
                primaryAction: '=?',
                primaryActionText: '=?',
                isPrimaryActionDestructive: '=?',
                isPrimaryActionDisabled: '=?',
                showSmallViewOnSmallScreen: '=?',
                hasBack: '=?',
                onBack: '=?',
                onOpen: '=?',
                preLoadingEvents: '=?spBasicModalPreLoadingEvents',
                onClose: '=?',
                closeOnSubmit: '=?',
                closeOnEvent: '@?',
                cancelLoadingOnEvents: '=?'
            },
            templateUrl: $rootScope.getDirectiveTemplateUrl('newcommon', 'sp-basic-modal'),
            transclude: true,
            link: function spBasicModalController($scope) {
                let unwatchesToCancelLoadingOnEvent = [];
                let unwatchEventToCloseOn;

                $scope.isOpen = false;
                $scope.isLoading = false;

                $scope.clearIsLoading = clearIsLoading;
                $scope.close = close;
                $scope.getNegativeActionText = getNegativeActionText;
                $scope.onNegativeActionClick = onNegativeActionClick;
                $scope.onSubmit = onSubmit;

                $scope.$on(SP_API_HTTP_EVENTS.BAD_REQUEST, clearIsLoading);
                $scope.$on(SP_API_HTTP_EVENTS.TOO_MANY_REQUESTS, clearIsLoading);

                initialize();

                function initialize() {
                    if ($scope.closeOnSubmit === undefined) {
                        $scope.closeOnSubmit = true;
                    }

                    $scope.$parent.form = $scope.spBasicModalForm;

                    spModal.register($scope);
                }

                function clearIsLoading() {
                    $scope.isLoading = false;
                }

                function close() {
                    if ($scope.onClose) {
                        $scope.onClose();
                    }

                    $scope.spBasicModalForm.$setPristine();
                    $scope.isOpen = false;

                    clearIsLoading();
                }

                function getNegativeActionText() {
                    if ($scope.hasCancel) {
                        return 'cancelButton';
                    } else if ($scope.negativeActionText) {
                        return $scope.negativeActionText;
                    }
                }

                function onNegativeActionClick() {
                    if ($scope.negativeAction) {
                        $scope.spBasicModalForm.$setPristine();

                        $scope.negativeAction();
                    } else {
                        close();
                    }
                }

                function onSubmit() {
                    if ($scope.spBasicModalForm.$invalid) {
                        return;
                    }

                    if ($scope.closeOnEvent) {
                        $scope.isLoading = true;

                        if (unwatchEventToCloseOn) {
                            clearWatchOnEventToCloseOn();
                        }

                        unwatchEventToCloseOn = $rootScope.$on(
                            $scope.closeOnEvent,
                            function closeAndUnwatch() {
                                close();
                                clearWatchOnEventToCloseOn();
                            }
                        );
                    }

                    if ($scope.primaryAction) {
                        $scope.primaryAction();
                    }

                    if ($scope.cancelLoadingOnEvents) {
                        $scope.isLoading = true;

                        unwatchesToCancelLoadingOnEvent.forEach((unwatch) => unwatch());

                        unwatchesToCancelLoadingOnEvent = $scope.cancelLoadingOnEvents.map(
                            function cancelLoadingOn(event) {
                                return $scope.$on(event, function cancelLoading() {
                                    $scope.isLoading = false;
                                });
                            }
                        );
                    }

                    if ($scope.closeOnSubmit && !$scope.closeOnEvent) {
                        close();
                    } else {
                        $scope.spBasicModalForm.$setPristine();
                    }

                    function clearWatchOnEventToCloseOn() {
                        unwatchEventToCloseOn();

                        unwatchEventToCloseOn = null;
                    }
                }
            }
        };
    }
];
