/**
 * @ngdoc service
 * @name sp.common.service:spFocus
 *
 * @description
 * Manages focusable HTML elements and allows for programmatic HTML element focusing.
 *
 * @requires $timeout
 */
export default [
    '$timeout',
    function spFocusService($timeout) {
        const service = this;
        const focusables = {};

        Object.assign(service, {
            blur,
            deregister,
            focus,
            on: focus,
            register,
            triggerEventHandler
        });

        return service;

        /**
         * @ngdoc method
         * @name blur
         * @methodOf sp.common.service:spFocus
         *
         * @description
         * Blurs the elements with the given identifier
         *
         * @param {string} id An HTML element's id
         */
        function blur(id) {
            const $focusable = focusables[id];

            if ($focusable) {
                $timeout($focusable.blur());
            }
        }

        /**
         * @ngdoc method
         * @name on
         * @methodOf sp.common.service:spFocus
         *
         * @description
         * Focuses the element with the given id
         *
         * @param {string} id An HTML element's id
         */
        function focus(id) {
            const $focusable = focusables[id];

            if ($focusable) {
                $timeout($focusable.focus());
            }
        }

        /**
         * @ngdoc method
         * @name triggerEventHandler
         * @methodOf sp.common.service:spFocus
         *
         * @description
         * Handles triggering a pass-through event on a focusable element
         *
         * @param {string} id An HTML element's id
         * @param {object} $event jqLite event
         */
        function triggerEventHandler(id, $event) {
            const $focusable = focusables[id];

            if ($focusable) {
                $timeout(() => {
                    const element = angular.element($focusable);

                    element.triggerHandler($event);
                });
            }
        }

        /**
         * @ngdoc method
         * @name register
         * @methodOf sp.common.service:spFocus
         *
         * @description
         * Adds an element to the focusable elements pool
         *
         * @param {HTMLElement} focusable An HTML element
         */
        function register(focusable) {
            focusables[focusable.id] = focusable;
        }

        /**
         * @ngdoc method
         * @name deregister
         * @methodOf sp.common.service:spFocus
         *
         * @description
         * Removes an element from the focusable elements pool
         *
         * @param {HTMLElement} focusable An HTML element
         */
        function deregister(focusable) {
            delete focusables[focusable.id];
        }
    }
];
