export default [
    '$rootScope',
    'spAppData',
    function getPartialURLFacade($rootScope, spAppData) {
        var urls = spAppData.get('urls');

        $rootScope.getPartialURL = function getPartialURL(partialName) {
            return urls.angularRoot + '/templates/partials/' + partialName + '.nghtml';
        };
    }
];
