/* eslint-disable func-names */

var directiveName = 'spImageOnLoad';

export default [
    function spImageOnLoadDirective() {
        return {
            restrict: 'A',
            scope: {
                onloadHandler: '&' + directiveName
            },
            link: function spImageOnLoadController($scope, $element) {
                $element.on('load', function() {
                    $scope.$apply(function() {
                        $scope.onloadHandler();
                    });
                });
            }
        };
    }
];
