import COMMON_MODALS from 'Sp/Angular/Modals/Common';

export default [
    '$rootScope',
    '$scope',
    '$timeout',
    function SpToastController($rootScope, $scope, $timeout) {
        const TOAST_DISPLAY_ACTIONABLE_TIME = 3000;
        const TOAST_DISPLAY_TIME = 2000;
        const TOAST_TRANSITION_TIME = 300;
        const queuedToasts = [];
        const rootScopeToastListener = $rootScope.$on(
            COMMON_MODALS.TOAST.OPEN_TOAST,
            (event, toast) => {
                if ($scope.currentToast) {
                    queuedToasts.push(toast);
                } else {
                    $scope.currentToast = toast;

                    showToast();
                }
            }
        );
        const rootScopeCloseToastListener = $rootScope.$on(COMMON_MODALS.TOAST.CLOSE_TOAST, () => {
            $scope.closeToast();
        });

        $scope.closeToast = closeToast;
        $scope.hideToast = hideToast;
        $scope.performAction = performAction;

        $scope.$on('$destroy', function removeListeners() {
            rootScopeToastListener();
            rootScopeCloseToastListener();
        });

        function hideToast() {
            if ($scope.isToastVisible && !$scope.currentToast.manualDismissal) {
                $timeout(closeToast, TOAST_TRANSITION_TIME);
            }
        }

        function closeToast() {
            delete $scope.currentToast;
            $scope.isToastVisible = false;

            if (queuedToasts.length > 0) {
                $scope.currentToast = queuedToasts.shift();

                showToast();
            }
        }

        function performAction() {
            if ($scope.currentToast.action) {
                $scope.currentToast.action.click();

                hideToast();
            }
        }

        function showToast() {
            $scope.isToastVisible = true;

            if ($scope.currentToast.action) {
                $timeout(
                    hideToast,
                    TOAST_TRANSITION_TIME + TOAST_DISPLAY_TIME + TOAST_DISPLAY_ACTIONABLE_TIME
                );
            } else {
                $timeout(hideToast, TOAST_TRANSITION_TIME + TOAST_DISPLAY_TIME);
            }
        }
    }
];
