var appData;

export default function spAppDataProvider() {
    var spAppDataService = new SPAppDataService();

    if (!appData) {
        appData = window.angularAppData || {};

        delete window.angularAppData;
    }

    this.get = function get(key) {
        return spAppDataService.get(key);
    };

    this.isFlagOn = function isFlagOn(flagKey) {
        return spAppDataService.isFlagOn(flagKey);
    };

    this.$get = function $get() {
        /**
         * @ngdoc service
         * @name sp.common.service:spAppData
         *
         * @description
         * Gets and handles a user's application data from the window
         */
        return spAppDataService;
    };

    function SPAppDataService() {
        /**
         * @ngdoc method
         * @name get
         * @methodOf sp.common.service:spAppData
         *
         * @description
         * Gets a specific value from app data
         *
         * @param {string} key Key associated with the required value
         * @returns {object} The requested value
         */
        this.get = function get(key) {
            return angular.copy(this.getRef(key));
        };

        /**
         * @ngdoc method
         * @name isFlagOn
         * @methodOf sp.common.service:spAppData
         *
         * @description
         * Checks the active state of a feature flag
         *
         * @param {string} flagKey flag key
         * @returns {bool} Flag's active state
         */
        this.isFlagOn = function isFlagOn(flagKey) {
            const flags = this.get('flags') || {};

            return Boolean(flags[flagKey]);
        };

        this.getRef = function getRef(key) {
            if (
                typeof appData[key] === 'object' &&
                appData[key] instanceof Array &&
                appData[key].length === 0
            ) {
                return false;
            }

            return appData[key];
        };
    }
}
