import React, { useLayoutEffect, useState } from 'react';
import { Box, styled } from 'sp-ui';
import { scrollTo } from 'Sp/SmoothScroll';
import { ReactComponent as IconArrowRight } from 'svg/client/brand/icon-arrow-right.svg';
import { useBrandTheme } from './BrandThemeContext';

const BackToTopContainer = styled(Box, {
    shouldForwardProp: (prop: string) => !['arrowColor', 'isDarkTheme'].includes(prop)
})<{ arrowColor: string; isDarkTheme: boolean }>(
    ({ arrowColor, isDarkTheme }) => `
        position: fixed;
        top: 10%;
        right: 18px;
        height: 56px;
        width: 56px;
        border-radius: 28px;
        text-align: center;
        cursor: pointer;
        opacity: 0;
        pointer-events: none;
        background-color: #${isDarkTheme ? '3a3a3a' : 'ffffff'};
        box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.5);
        transition: opacity 0.3s ease-in-out;

        &:hover {
            box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.5);
        }

        &.back-to-top-active {
            opacity: 1;
            pointer-events: auto;
        }

        svg {
            margin-top: 20px;
            width: 20px;
            height: 14px;
            fill: #${arrowColor};
            transform: rotate(-90deg);
        }
    `
);

const BackToTop: React.FC = () => {
    const { brandTheme } = useBrandTheme();
    const { backgroundOptionId, primaryColor } = brandTheme;
    const isDarkTheme = backgroundOptionId === 2;
    const [showBackToTop, setShowBackToTop] = useState(false);

    const scrollWindowToTop = () => {
        scrollTo(0);
    };

    useLayoutEffect(() => {
        let lastWindowScrollPosition: number = window.pageYOffset;

        const isWindowScrollingUp = (scrollY: number) => {
            return scrollY < lastWindowScrollPosition;
        };

        const isWindowVerticallyScrolledEnough = (scrollY: number, innerHeight: number) => {
            return scrollY > innerHeight;
        };

        const handleScroll = ({ target }) => {
            const { clientHeight: height, scrollTop } = target.scrollingElement;

            if (
                isWindowVerticallyScrolledEnough(scrollTop, height) &&
                isWindowScrollingUp(scrollTop)
            ) {
                setShowBackToTop(true);
            } else {
                setShowBackToTop(false);
            }

            lastWindowScrollPosition = scrollTop;
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <BackToTopContainer
            data-testid="back-to-top-container"
            arrowColor={primaryColor}
            className={showBackToTop ? 'back-to-top-active' : ''}
            id="back-to-top-container"
            isDarkTheme={isDarkTheme}
            onClick={scrollWindowToTop}
        >
            <IconArrowRight data-testid="back-to-top-icon-arrow" />
        </BackToTopContainer>
    );
};

export default BackToTop;
