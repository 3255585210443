import COMMON_MODALS from 'Sp/Angular/Modals/Common';
import { SUGGESTION_EVENTS } from 'Sp/Angular/Events/Common';

/**
 * @ngdoc directive
 * @name sp.common.directive:spHelpDropDown
 * @requires $rootScope
 * @requires $timeout
 * @requires sp.common.service:spModal
 * @restrict E
 *
 * @description
 * Creates the Help dropdown in the upper-right corner
 * of all Studio screens. Pulls in suggestions from
 * the knowledgebase based on queries from user-entered
 * input.  Otherwise displays pre-filled knowledgebase
 * articles entered in admin.
 */
export default [
    '$rootScope',
    '$timeout',
    'spAppData',
    'spModal',
    function spHelpDropDownDirective($rootScope, $timeout, spAppData, spModal) {
        return {
            restrict: 'E',
            templateUrl: $rootScope.getDirectiveTemplateUrl('newcommon', 'sp-help-drop-down'),
            link: function spHelpDropDownController($scope) {
                let searchTimer = $timeout();

                $scope.queryResults = undefined;
                $scope.search = { query: '' };
                $scope.hasCommunityLink = spAppData.get('studioData').hasCommunityLink;

                $scope.getSuggestions = getSuggestions;
                $scope.openHelpModal = openHelpModal;
                $scope.showSupportPhone = showSupportPhone;

                $scope.$on(SUGGESTION_EVENTS.LISTED, (_, suggestions) => {
                    $scope.queryResults = suggestions;
                });

                function getSuggestions() {
                    $timeout.cancel(searchTimer);

                    if ($scope.search.query.length === 0) {
                        $scope.queryResults = undefined;
                    } else {
                        searchTimer = $timeout(() => {
                            $scope.$emit(SUGGESTION_EVENTS.LIST, $scope.search.query);
                        }, 1000);
                    }
                }

                function openHelpModal() {
                    spModal.open(COMMON_MODALS.HELP);
                }

                function showSupportPhone() {
                    return $rootScope.showSupportPhone();
                }
            }
        };
    }
];
