import { AUTHORIZATION_TOKEN_EVENTS } from 'ts/common/constants/auth';

const DIGITAL_RULE_EVENTS = {
    LIST: 'client-gallery-digital-rule-list',
    LIST_ERROR: 'client-gallery-digital-rule-list-error',
    LISTED: 'client-gallery-digital-rule-listed'
};
const EVENT_ALBUM_EVENTS = {
    GET: 'client-gallery-event-album-get',
    GOT: 'client-gallery-event-album-got'
};
const EVENT_EVENTS = {
    GET: 'client-gallery-event-get',
    GOT: 'client-gallery-event-got'
};
const EVENT_TAG_EVENTS = {
    GET: 'client-gallery-event-tags-get',
    GOT: 'client-gallery-event-tags-got'
};
const GOOGLE_PHOTOS_EXPORT_EVENTS = {
    CREATE: 'client-gallery-google-photos-export-create',
    CREATED: 'client-gallery-google-photos-export-created',
    GET: 'client-gallery-google-photos-export-get',
    GOT: 'client-gallery-google-photos-export-got',
    ERRORS: {
        UNAUTHORIZED: 'client-gallery-google-photos-export-errors-unauthorized',
        UNKNOWN: 'client-gallery-google-photos-export-errors-unknown'
    }
};
const PACKAGE_EVENTS = {
    SELECT: 'client-gallery-package-select',
    PHOTO_ASSIGN: 'client-gallery-package-photo-assign',
    PHOTO_LOCATION_RESET: 'client-gallery-package-photo-location-reset'
};
const PHOTO_DOWNLOAD_EVENTS = {
    CREATE: 'client-gallery-photo-download-create',
    CREATED: 'client-gallery-photo-download-created',
    ERRORS: {
        ALBUM: 'client-gallery-photo-download-errors-album',
        PIN: 'client-gallery-photo-download-errors-pin',
        TOKEN_INVALID: 'client-gallery-photo-download-errors-token-invalid'
    }
};
const PRICE_SHEET_ITEM_EVENTS = {
    LIST: 'client-gallery-price-sheet-item-list',
    LISTED: 'client-gallery-price-sheet-item-listed'
};
const ZIP_BUNDLE_EVENTS = {
    CREATE: 'client-gallery-zip-bundle-create',
    CREATED: 'client-gallery-zip-bundle-created',
    GET: 'client-gallery-zip-bundle-get',
    GOT: 'client-gallery-zip-bundle-got',
    ERRORS: {
        ALBUM: 'client-gallery-zip-bundle-errors-album',
        PIN: 'client-gallery-zip-bundle-errors-pin',
        TOKEN_INVALID: 'client-gallery-zip-bundle-errors-token-invalid'
    }
};

export {
    AUTHORIZATION_TOKEN_EVENTS,
    DIGITAL_RULE_EVENTS,
    EVENT_ALBUM_EVENTS,
    EVENT_EVENTS,
    EVENT_TAG_EVENTS,
    GOOGLE_PHOTOS_EXPORT_EVENTS,
    PACKAGE_EVENTS,
    PHOTO_DOWNLOAD_EVENTS,
    PRICE_SHEET_ITEM_EVENTS,
    ZIP_BUNDLE_EVENTS
};

export default {
    AUTHORIZATION_TOKEN_EVENTS,
    DIGITAL_RULE_EVENTS,
    EVENT_ALBUM_EVENTS,
    EVENT_EVENTS,
    EVENT_TAG_EVENTS,
    GOOGLE_PHOTOS_EXPORT_EVENTS,
    PACKAGE_EVENTS,
    PHOTO_DOWNLOAD_EVENTS,
    PRICE_SHEET_ITEM_EVENTS,
    ZIP_BUNDLE_EVENTS
};
