/**
 * @ngdoc service
 * @name sp.common.service:spModal
 *
 * @description
 * Handles registering, opening, and closing of all modals.
 */
export default [
    function spModalService() {
        return new SPModal();

        function SPModal() {
            var VIEW_LOCKED_CLASS = 'sp-screen-view-locked';
            var registeredModalScopes = {};
            var bodyElement = angular.element(document.querySelector('body'));

            bodyElement.on('keyup', function onBodyKeyUpForSpModal($event) {
                if ($event.which === 27) {
                    closeAll(true);
                }
            });

            /**
             * @ngdoc method
             * @name open
             * @methodOf sp.common.service:spModal
             *
             * @description
             * Looks for the modal's scope in the currently registered modals,
             * sets that modal to open, listens for any given preLoading events,
             * and invokes any requested functions onOpen.
             *
             * @param {string} name A modal's unique name
             */
            this.open = function open(name) {
                var modalScope = registeredModalScopes[name];

                if (modalScope) {
                    if (modalScope.form) {
                        modalScope.form.$setPristine();
                    }

                    modalScope.isOpen = true;

                    modalScope.$watch('isOpen', function removeViewLockWhenClosed(isOpen) {
                        if (!isOpen) {
                            removeViewLockedClassOnBody();
                        }
                    });

                    if (modalScope.preLoadingEvents) {
                        var remainingListenersCount = modalScope.preLoadingEvents.length;

                        modalScope.isLoading = true;
                        modalScope.preLoadingEvents.forEach(function callAllEvents(
                            eventToListenFor
                        ) {
                            var unlisten = modalScope.$on(
                                eventToListenFor,
                                function decrementListenerCount() {
                                    unlisten();
                                    remainingListenersCount--;

                                    if (remainingListenersCount === 0) {
                                        modalScope.isLoading = false;
                                    }
                                }
                            );
                        });
                    }

                    if (modalScope.onOpen) {
                        modalScope.onOpen();
                    }

                    bodyElement.addClass(VIEW_LOCKED_CLASS);
                }
            };

            /**
             * @ngdoc method
             * @name close
             * @methodOf sp.common.service:spModal
             *
             * @description
             * Looks for the modal's scope in the currently registered modals
             * and invokes any requested functions on modal close.
             *
             * @param {string} name A modal's unique name
             */
            this.close = close;

            /**
             * @ngdoc method
             * @name closeAll
             * @methodOf sp.common.service:spModal
             *
             * @description
             * For every registered scope, call spModal.close
             * on each.
             *
             * @param {string} name A modal's unique name
             */
            this.closeAll = closeAll;

            /**
             * @ngdoc method
             * @name register
             * @methodOf sp.common.service:spModal
             *
             * @description
             * If the modal is not registered, add the given scope
             * to the local registeredModalScopes array. If the modal
             * is already registered, remove the registered scope.
             *
             * @param {object} modalScope A modal's scope
             */
            this.register = function register(modalScope) {
                var name = modalScope.name;

                if (isRegistered()) {
                    delete registeredModalScopes[name];
                }

                registeredModalScopes[name] = modalScope;

                function isRegistered() {
                    return registeredModalScopes[name];
                }
            };

            /**
             * @ngdoc method
             * @name isAnyModalOpen
             * @methodOf sp.common.service:spModal
             *
             * @description
             * Determines if any modal is currently open.
             *
             * @returns {boolean} If any modal is currently open.
             */
            this.isAnyModalOpen = function isAnyModalOpen() {
                return Object.keys(registeredModalScopes).reduce(function findAnyOpenModal(
                    isModalOpen,
                    modalKey
                ) {
                    return isModalOpen || registeredModalScopes[modalKey].isOpen;
                },
                false);
            };

            /**
             * @ngdoc method
             * @name isAnyModalOpen
             * @methodOf sp.common.service:spModal
             *
             * @description
             * Determines if the given modal modal is currently open.
             *
             * @param {string} modalName Name of the modal to be checked
             *
             * @returns {boolean} If the given modal is currently open.
             */
            this.isModalOpen = function isModalOpen(modalName) {
                return registeredModalScopes[modalName] && registeredModalScopes[modalName].isOpen;
            };

            function close(name) {
                var modalScope = registeredModalScopes[name];

                if (modalScope) {
                    modalScope.close();

                    removeViewLockedClassOnBody();
                }
            }

            function closeAll(shouldDigest) {
                angular.forEach(registeredModalScopes, function forEachModalScope(modalScope) {
                    if (modalScope.isOpen) {
                        modalScope.close();

                        if (shouldDigest) {
                            modalScope.$digest();
                        }
                    }
                });

                removeViewLockedClassOnBody();
            }

            function removeViewLockedClassOnBody() {
                bodyElement.removeClass(VIEW_LOCKED_CLASS);
            }
        }
    }
];
