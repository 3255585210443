import React from 'react';
import { P } from 'sp-ui';

export interface IBrandContactInfoProps {
    address?: string;
    phone?: string;
}

const BrandContactInfo: React.FC<IBrandContactInfoProps> = ({ address, phone }) => {
    if (!address && !phone) {
        return null;
    }

    return (
        <P data-testid="brand-contact-info-container" textAlign="center">
            {address && (
                <span
                    data-testid="brand-contact-info-address"
                    dangerouslySetInnerHTML={{
                        __html: address
                    }}
                />
            )}
            {phone && (
                <>
                    <br />
                    <a data-testid="brand-contact-info-phone" href={`tel:${phone}`}>
                        {phone}
                    </a>
                </>
            )}
        </P>
    );
};

export default BrandContactInfo;
