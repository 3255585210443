var directiveName = 'spExpandableText';

export default [
    '$sanitize',
    function spExpandableTextDirective($sanitize) {
        return {
            restrict: 'E',
            scope: {
                textHtml: '=' + directiveName + 'TextHtml',
                linkText: '@' + directiveName + 'LinkText',
                heightThreshold: '@' + directiveName + 'HeightThreshold',
                trust: '=' + directiveName + 'Trust'
            },
            link: function spExpandableTextController($scope, $element) {
                // Include the given text in its own container.
                var textContainer = angular.element(
                    '<div class="sp-expandable-text-text-html-container"></div>'
                );

                if (!$scope.trust) {
                    textContainer.html($sanitize($scope.textHtml));
                } else {
                    textContainer.html($scope.textHtml);
                }

                $element.html(textContainer);

                var height = $element.prop('offsetHeight');

                // If the total height is greater than the given
                // threshold, set a maximum on the text container, and
                // append the text link below the text container.
                if (height > $scope.heightThreshold) {
                    textContainer.css({
                        'max-height': $scope.heightThreshold + 'px',
                        overflow: 'hidden'
                    });

                    var readMoreLink = angular.element(
                        '<a ' +
                            'href="#" ' +
                            'class="sp-expandable-text-link theme-color-primary">' +
                            $sanitize($scope.linkText) +
                            '</a>'
                    );

                    $element.append(readMoreLink);

                    // When the text link is clicked, remove the link
                    // itself, and the max-height constraint.
                    readMoreLink.on('click', function expandText() {
                        readMoreLink.remove();
                        textContainer.css('max-height', 'none');
                    });
                }
            }
        };
    }
];
