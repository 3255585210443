import React from 'react';
import { Box, styled } from 'sp-ui';
import BrandContactInfo, { IBrandContactInfoProps } from './BrandContactInfo';
import SocialNetworkLinks from './SocialNetworkLinks';
import { IAppDataBrandData } from './types';

interface IFooterProps {
    spAppData: SpAngularJs.ISpAppData;
}
const FooterContainer = styled(Box)`
    width: 100%;
    height: 200px;
    padding: 40px 20px;
    bottom: 0;
    position: static;

    p {
        font-family; 'OpenSans Regular';
        color: #a5a5a5;
    }
`;
const SocialContainer = styled(Box)`
    margin-bottom: 20px;
`;

const Footer: React.FC<IFooterProps> = ({ spAppData }) => {
    const brandData = spAppData.get<IAppDataBrandData>('brandData');
    const { address, phone } = brandData.contact;
    const contactInfoProps: IBrandContactInfoProps = {
        ...(address && { address }),
        ...(phone && { phone })
    };

    return (
        <FooterContainer as="footer" data-testid="footer-container">
            <SocialContainer data-testid="social-container">
                <SocialNetworkLinks spAppData={spAppData} />
            </SocialContainer>
            <BrandContactInfo {...contactInfoProps} />
        </FooterContainer>
    );
};

export default Footer;
