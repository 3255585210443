import Invoice from 'Sp/Invoice';

/**
 * @ngdoc filter
 * @name sp.common.filter:spInvoiceInstallmentAmount
 * @function
 * @requires currencyFilter
 * @requires translateFilter
 *
 * @description
 *
 * @param {object} invoiceInstallment An invoice installment
 * @param {object} options Display options for the invoice installment amount filter
 * @param {string=} options.currencySymbol Overrides the default label from `currencyFilter`
 * @param {int=} options.invoiceRetainerLabel Determines what text to use for Retainer when the
 *     invoice installment is a retainer
 * @param {float=} options.retainerPercent Appends (percent%) when present and greater than 0
 *
 * @returns {string} A representation of what the invoice installment amount is
 *
 * @example
    <example module="sp.common">
        <file name="script.js">
            angular.module('sp.common').controller('Controller', function Controller($scope) {
                $scope.retainerInstallment = {
                    amount: 25,
                    isRetainer: true
                };
                $scope.installment = {
                    amount: 25
                };
            });
        </file>
        <file name="index.html">
            <div ng-controller="Controller">
                <pre>{{ retainerInstallment | spInvoiceInstallmentAmount }}</pre>
                <pre>{{ retainerInstallment | spInvoiceInstallmentAmount : { currencySymbol: '£' } }}</pre>
                <pre>{{ retainerInstallment | spInvoiceInstallmentAmount : { currencySymbol: '£', invoiceRetainerLabel: 'deposit' } }}</pre>
                <pre>{{ retainerInstallment | spInvoiceInstallmentAmount : { currencySymbol: '£', invoiceRetainerLabel: 'retainer', retainerPercent: 50 } }}</pre>
                <pre>{{ installment | spInvoiceInstallmentAmount }}</pre>
                <pre>{{ installment | spInvoiceInstallmentAmount : { currencySymbol: '£' } }}</pre>
                <pre>{{ installment | spInvoiceInstallmentAmount : { currencySymbol: '£', invoiceRetainerLabel: 'deposit' } }}</pre>
                <pre>{{ installment | spInvoiceInstallmentAmount : { currencySymbol: '£', invoiceRetainerLabel: 'retainer', retainerPercent: 50 } }}</pre>
            </div>
        </file>
    </example>
 */
export default [
    'currencyFilter',
    'translateFilter',
    function spInvoiceInstallmentAmountFilter(currencyFilter, translateFilter) {
        return function filterSpInvoiceInstallmentAmount(invoiceInstallment, options) {
            if (!invoiceInstallment) {
                return '';
            }

            if (!options) {
                options = {};
            }

            const amount = currencyFilter(invoiceInstallment.amount, options.currencySymbol);

            if (invoiceInstallment.isRetainer) {
                const retainer = getRetainer();
                const { retainerPercent } = options;

                if (retainerPercent > 0) {
                    return `${amount} ${retainer} (${retainerPercent}%)`;
                }

                return `${amount} ${retainer}`;
            }

            return amount;

            function getRetainer() {
                switch (options.invoiceRetainerLabel) {
                    case Invoice.RETAINER_LABEL.DEPOSIT:
                        return translateFilter('common.invoice.retainerLabel.deposit');
                    case Invoice.RETAINER_LABEL.NON_REFUNDABLE_PAYMENT:
                        return translateFilter('common.invoice.retainerLabel.nonRefundablePayment');
                    case Invoice.RETAINER_LABEL.RETAINER:
                        return translateFilter('common.invoice.retainerLabel.retainer');
                }
            }
        };
    }
];
