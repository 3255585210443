/**
 * @ngdoc directive
 * @name sp.common.directive:spContractSignatures
 * @requires $rootScope
 * @restrict E
 *
 * @description
 * Displays the actual signatures on a contract and dates the contracts were
 * signed for both the client and the studio. If the contract is not yet signed,
 * a placeholder with the client and/or studio names is seen instead
 * of the signature.
 *
 * @param {string} studioName Name of the studio
 * @param {string} studioSignature Actual signature object of the studio
 * @param {array<object>} contacts Array of contacts
 * @param {array<object>} contactSignatures Current signature data for contract
 */
export default [
    '$rootScope',
    function spContractSignaturesDirective($rootScope) {
        return {
            restrict: 'E',
            scope: {
                studioName: '=',
                studioSignature: '=',
                contacts: '=',
                contactSignatures: '='
            },
            templateUrl: $rootScope.getDirectiveTemplateUrl('newcommon', 'sp-contract-signatures'),
            link: function spContractSignaturesController($scope) {
                $scope.contactsWithSignatures = getContactsWithSignatures();

                $scope.$watch('contacts', () => {
                    $scope.contactsWithSignatures = getContactsWithSignatures();
                });

                function getContactsWithSignatures() {
                    const { contactSignatures = [], contacts = [] } = $scope;

                    return contacts.map((contact) => {
                        return {
                            ...contact,
                            signature: getSignatureForContact(contact, contactSignatures)
                        };
                    });
                }
            }
        };

        function getSignatureForContact(contact, contactSignatures) {
            return contactSignatures.find(({ contactId: signatureContactId, signerEmail }) => {
                if (signerEmail) {
                    return signerEmail === contact.email;
                }

                if (signatureContactId && contact.id) {
                    return signatureContactId === contact.id;
                }

                return false;
            });
        }
    }
];
