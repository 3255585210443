import Invoice from 'Sp/Invoice';

/**
 * @ngdoc filter
 * @name sp.common.filter:spInvoiceStatus
 * @requires translateFilter
 *
 * @description
 * Filters an Invoice to it's human-readble Invoice status.
 *
 * @param {object} invoice An Invoice
 *
 * @returns {string} A human-readable Invoice status of the given Invoice
 */
export default [
    'translateFilter',
    function spInvoiceStatusFilter(translateFilter) {
        return function filterInvoiceStatus(invoice) {
            let filteredInvoiceStatus;

            if (Invoice.isOverdue(invoice)) {
                filteredInvoiceStatus = 'common.invoice.status.overdue';
            } else {
                switch (invoice.invoiceStatus) {
                    case Invoice.STATUS.ARCHIVED:
                        filteredInvoiceStatus = 'common.invoice.status.archived';

                        break;
                    case Invoice.STATUS.CANCELED:
                        filteredInvoiceStatus = 'common.invoice.status.canceled';

                        break;
                    case Invoice.STATUS.PAID_IN_FULL:
                        filteredInvoiceStatus = 'common.invoice.status.paidInFull';

                        break;
                    case Invoice.STATUS.PARTIALLY_PAID:
                        filteredInvoiceStatus = 'common.invoice.status.partiallyPaid';

                        break;
                    case Invoice.STATUS.UNPAID:
                        filteredInvoiceStatus = 'common.invoice.status.unpaid';

                        break;
                }
            }

            return translateFilter(filteredInvoiceStatus);
        };
    }
];
