import EMAIL_EVENTS from 'Sp/Angular/Events/Studio/Email/Email';

/**
 * @ngdoc directive
 * @name sp.common.directive:spEmailList
 * @requires $parse
 * @restrict A
 *
 * @description
 * Validates a comma separated list of emails with or without a max length
 *
 * @param {string} listMaximum Maximum count of items in list
 */
export default [
    'translateFilter',
    'spEventBus',
    function spEmailListValidatorDirective(translate, spEventBus) {
        var EMAIL_REGEX = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
        var DEFAULT_SEPARATOR = ',';

        return {
            restrict: 'A',
            require: 'ngModel',
            scope: {
                emailListMaximum: '@',
                emailListVerification: '<',
                emailListOnVerifySent: '<'
            },
            link: function spEmailListController($scope, $element, $attrs, ngModel) {
                var listMaximum = parseInt($scope.emailListMaximum, 10);
                let triggerVerifyLink;

                $scope.$watch('emailListVerification', handleEmailListVerificationChange);

                ngModel.$viewChangeListeners.push(handleModelChange);
                ngModel.$validators.exceedMaximumRecipients = validateMaxRecipients;
                ngModel.$validators.invalidEmailList = validateEmailList;

                function handleEmailListVerificationChange({
                    isVerified = null,
                    challengeLink
                } = {}) {
                    if (isVerified === false) {
                        setupUnverifiedEmailNotice(challengeLink);
                        ngModel.$validators.unverifiedEmail = validateUnverifiedEmailLimit;
                    }
                }

                function handleModelChange() {
                    if (!Boolean(triggerVerifyLink)) {
                        return;
                    }

                    setLinkDisplay();
                }

                function setLinkDisplay() {
                    if (ngModel.$error.unverifiedEmail === true) {
                        triggerVerifyLink.addClass('sp-form-element-error-text');
                    } else {
                        triggerVerifyLink.removeClass('sp-form-element-error-text');
                    }
                }

                function setupUnverifiedEmailNotice(challengeLink) {
                    triggerVerifyLink = angular.element(
                        `<span>${translate('studio.email.new.unverifiedEmailNotice', {
                            challengeLink
                        })}</span>`
                    );
                    triggerVerifyLink.addClass('sp-email-list-unverified-email-notice');
                    triggerVerifyLink.attr('data-testid', 'unverifiedEmailNotice');

                    triggerVerifyLink.on('click', (event) => {
                        event.preventDefault();

                        spEventBus.once(EMAIL_EVENTS.VERIFICATION.SENT, () => {
                            $scope.emailListOnVerifySent && $scope.emailListOnVerifySent();
                        });

                        spEventBus.emit(EMAIL_EVENTS.VERIFICATION.SEND, challengeLink);
                    });

                    $element.after(triggerVerifyLink);
                }

                function validateMaxRecipients(value) {
                    var list = getList(value).filter(withoutEmptyItems);

                    return list.length <= listMaximum;
                }

                function validateEmailList(value) {
                    var allValid = true;
                    var list = getList(value);

                    angular.forEach(list, function testItemIsEmail(item) {
                        if (item !== '' && !EMAIL_REGEX.test(item)) {
                            allValid = false;
                        }
                    });

                    return allValid;
                }

                function validateUnverifiedEmailLimit(value) {
                    return !value.includes(DEFAULT_SEPARATOR);
                }

                function withoutEmptyItems(item) {
                    return item !== '';
                }
            }
        };

        function getList(listString) {
            return (listString || '').split(DEFAULT_SEPARATOR).map(withTrimming);
        }

        function withTrimming(item) {
            return item.trim();
        }
    }
];
