var directiveName = 'spValid';

export default [
    function spValidDirective() {
        return {
            require: 'ngModel',
            restrict: 'A',
            scope: {
                isValid: '&' + directiveName
            },
            link: function spValidController($scope, $element, $attributes, ngModel) {
                if (!ngModel) {
                    return;
                }

                ngModel.$validators.spValid = function spValid(value) {
                    return (
                        $scope.isValid({
                            $value: value
                        }) === true
                    );
                };
            }
        };
    }
];
