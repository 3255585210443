/* eslint-disable func-names */

export default [
    function spDisableContextMenuDirective() {
        return {
            restrict: 'A',
            link: function spDisableContextMenuController($scope, $element) {
                $element.bind('contextmenu', function($event) {
                    $event.preventDefault();
                });
            }
        };
    }
];
