import React from 'react';
import { Box, Link, styled } from 'sp-ui';
import BRAND_ROUTES from 'Sp/Angular/Routes/Client/Brand';
import Route from 'Sp/Route';
import { useBrandTheme } from './BrandThemeContext';
import { IAppDataBrandData } from './types';

interface INavContainerProps {
    backgroundOptionId: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    theme?: any;
}

interface INavProps {
    /**
     * AngularJS location object
     */
    $location: SpAngularJs.ILocation;

    /**
     * Application Data
     */
    spAppData: SpAngularJs.ISpAppData;

    /**
     * AngularJS route object
     */
    $route: SpAngularJs.IRoute;

    /**
     * Translation filter object from AngularJS
     */
    translateFilter: SpAngularJs.ITranslateFilter;
}

const NavContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'backgroundOptionId'
})(
    ({ theme, backgroundOptionId }: INavContainerProps) => `
        width: 100%;
        ${theme.responsive.mediumScreenDown`
            width: calc(100% - 32px);
        `}
        max-width: 1200px;
        text-align: center;
        border-bottom: 1px solid ${
            backgroundOptionId === 2 ? 'rgba(255, 255, 255, 0.2);' : 'rgba(0, 0, 0, 0.2);'
        };
        padding: 10px 0;
        margin: 0 auto 24px auto;
    `
);
const StyledLink = styled(Link, {
    shouldForwardProp: (prop) => prop !== 'color'
})<{ color: string }>(
    ({ color }) => `
        color: #${color};
        text-decoration: none;
        text-underline-offset: 12px;
        text-transform: uppercase;
        margin: 0 20px;
        font-family: "Brandon Grotesque", sans-serif;
        letter-spacing: 0.07em;
        margin: 0 20px;

        &:active, &:hover, &:link, &:visited {
            color: #${color};
        }

        &:hover, &:focus, &.selected {
            text-decoration: underline;
            text-decoration-thickness: 2px;
            font-weight: 700;
        }

        &:nth-of-type(1) {
            margin: 0 20px 0 0;
        }

        &:last-child {
            margin: 0 0 0 20px;
        }
    `
);

const Nav: React.FC<INavProps> = ({ $location, $route, spAppData, translateFilter }) => {
    const brandData = spAppData.get<IAppDataBrandData>('brandData');
    const { brandTheme } = useBrandTheme();
    const categoryRoute = Route.get(BRAND_ROUTES.CATEGORY, {
        categoryId: $route.current.params.categoryId
    });
    const aboutRoute = Route.get(BRAND_ROUTES.ABOUT);
    const contactRoute = Route.get(BRAND_ROUTES.CONTACT);
    const homeRoute = Route.get(BRAND_ROUTES.HOME);

    const navigate = (newLocation: string) => {
        $location.path(newLocation);
    };

    const { primaryColor, backgroundOptionId } = brandTheme;

    const selected = (routeToMatch: string) => {
        return $location.path() === routeToMatch;
    };

    const navItems = [
        {
            name: 'home',
            route: homeRoute
        }
    ];

    if (brandData.aboutText && brandData.aboutText !== '') {
        navItems.push({
            name: 'about',
            route: aboutRoute
        });
    }

    if (brandData.homepage.enableContactForm) {
        navItems.push({
            name: 'contact',
            route: contactRoute
        });
    }

    return (
        <NavContainer data-testid="nav-container" backgroundOptionId={backgroundOptionId}>
            {navItems.map((item) => (
                <StyledLink
                    key={item.name}
                    data-testid={`${item.name}-link`}
                    color={primaryColor}
                    href={item.route}
                    onClick={(event) => {
                        event.preventDefault();
                        navigate(item.route);
                    }}
                    className={
                        selected(item.route) ||
                        (selected(categoryRoute) && item.route === homeRoute)
                            ? 'selected'
                            : ''
                    }
                >
                    {translateFilter(item.name)}
                </StyledLink>
            ))}
        </NavContainer>
    );
};

export default Nav;
