/* eslint-disable func-names */

var directiveName = 'spBackgroundImageOnLoad';

export default [
    function spBackgroundImageOnLoadDirective() {
        return {
            restrict: 'A',
            scope: {
                onloadHandler: '&' + directiveName,
                imageUrl: '=' + directiveName + 'Url'
            },
            link: function spBackgroundImageOnLoadController($scope, $element) {
                $scope.$watch('imageUrl', function(imageUrl) {
                    if (imageUrl === null) {
                        $scope.onloadHandler();
                        $scope.digest();
                    }

                    $element.css('backgroundImage', 'url(' + imageUrl + ')');

                    angular
                        .element('<img>')
                        .attr('src', imageUrl)
                        .on('load', function() {
                            $scope.$apply(function() {
                                $scope.onloadHandler();
                            });
                        })
                        .on('error', function() {
                            $scope.$apply(function() {
                                $scope.onloadHandler();
                            });
                        });
                });
            }
        };
    }
];
