export default [
    '$provide',
    function getPartialTemplateUrlDecorator($provide) {
        $provide.decorator('$rootScope', [
            '$delegate',
            'spAppData',
            function decorate($delegate, spAppData) {
                var staticUrl = spAppData.get('urls').static;

                $delegate.getPartialTemplateUrl = function getPartialTemplateUrl(
                    module,
                    name,
                    location
                ) {
                    if (location) {
                        return (
                            staticUrl +
                            '/' +
                            module +
                            '/templates/' +
                            location +
                            '/' +
                            name +
                            '.nghtml'
                        );
                    }

                    return staticUrl + '/' + module + '/templates/partials/' + name + '.nghtml';
                };

                return $delegate;
            }
        ]);
    }
];
