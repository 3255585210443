/* eslint-disable func-names */

export default [
    'SPDataObjectsProvider',
    function BoundConfig(SPDataObjectsProvider) {
        SPDataObjectsProvider.defineDataObject('Bound', function Bound(data, photo) {
            var self = this;

            this.name = data.bounds_name;
            this.description = data.description;
            this.digitalType = data.digital_type;

            this.boundItems = data.items.map(function(boundItem) {
                return self.dataObjectFactory('BoundItem', boundItem, photo);
            });

            this.getMinItemPrice = function getMinItemPrice() {
                return this.boundItems.reduce(function(previousPrice, boundItem) {
                    if (!previousPrice || boundItem.price < previousPrice) {
                        return boundItem.price;
                    }

                    return previousPrice;
                }, false);
            };

            this.getMaxItemPrice = function getMaxItemPrice() {
                return this.boundItems.reduce(function(previousPrice, boundItem) {
                    if (!previousPrice || boundItem.price > previousPrice) {
                        return boundItem.price;
                    }

                    return previousPrice;
                }, false);
            };

            this.itemsAreAllTheSamePrice = function itemsAreAllTheSamePrice() {
                return (
                    this.boundItems.length >= 1 && this.getMinItemPrice() === this.getMaxItemPrice()
                );
            };

            this.getTotalItemQuantity = function getTotalItemQuantity() {
                return this.boundItems.reduce(function(previousPrice, boundItem) {
                    return previousPrice + boundItem.cartQuantity;
                }, 0);
            };
        });
    }
];
