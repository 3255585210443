import CreditCard from 'Sp/CreditCard';

/**
 * @ngdoc directive
 * @name sp.common.directive:spCreditCardCvvInput
 * @requires $timeout
 * @restrict A
 * @require ngModel
 *
 * @description
 * Validates the CVV# field for a credit card (the 3 or 4 digit thingy on the cards).
 *
 * @param {string=} cardNumber CVV number for the credit card
 **/
export default [
    '$timeout',
    function spCreditCardCvvInputDirective($timeout) {
        return {
            require: 'ngModel',
            restrict: 'A',
            scope: {
                cardNumber: '=spCreditCardCvvInputCardNumber'
            },
            link: function spCreditCardCvvInputController($scope, element, $attributes, ngModel) {
                ngModel.$parsers.unshift(function withValue(value) {
                    if (typeof value !== 'string') {
                        return '';
                    }

                    let filteredValue = value.replace(/[^0-9]/g, '');

                    if (filteredValue.length > 4) {
                        filteredValue = filteredValue.substr(0, 4);
                    }

                    ngModel.$viewValue = filteredValue;
                    ngModel.$render();

                    $timeout(function renderNgModel() {
                        ngModel.$render();
                    });

                    return filteredValue;
                });

                ngModel.$validators.cardNumberCvv = function validateCardNumberCvv(value) {
                    if (typeof value !== 'string') {
                        return false;
                    }

                    const cardType = CreditCard.getCardType($scope.cardNumber);
                    let targetLength = 3;

                    if (cardType === 'amex') {
                        targetLength = 4;
                    }

                    if (value.length === targetLength) {
                        return true;
                    }

                    return false;
                };
            }
        };
    }
];
