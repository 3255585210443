/**
 * @ngdoc directive
 * @name sp.common.directive:spContainedScroll
 * @restrict A
 *
 * @description
 * Stops scrolling events from propagating to parent elements.
 */
export default [
    function spContainedScrollDirective() {
        return {
            restrict: 'A',
            link: function spContainedScrollController(_, $element) {
                const element = $element[0];
                let touchStartY;

                $element.on('touchstart', ({ originalEvent }) => {
                    touchStartY = getTouchY(originalEvent);
                });

                $element.on('scroll touchmove wheel', ({ originalEvent }) => {
                    const delta = getDelta();
                    const scrollableDistance = element.scrollHeight - element.offsetHeight;
                    const scrollDistance = $element.scrollTop();
                    const isScrolledToBottom = delta < 0 && scrollDistance >= scrollableDistance;
                    const isScrolledToTop = delta > 0 && scrollDistance <= 0;

                    if (originalEvent.cancelable && (isScrolledToBottom || isScrolledToTop)) {
                        return false;
                    }

                    function getDelta() {
                        let touchDelta;

                        if (originalEvent.touches) {
                            touchDelta = touchStartY - getTouchY(originalEvent);
                        }

                        return originalEvent.wheelDelta || -originalEvent.detail || -touchDelta;
                    }
                });

                function getTouchY(event) {
                    return event.touches[0].clientY;
                }
            }
        };
    }
];
