/**
 * @ngdoc filter
 * @name sp.common.filter:spHtmlEncode
 *
 * @description
 * encodes special characters into strings to HTML entities
 * @param {string} stringIn String to be encoded
 *
 * @returns {string} Encoded string
 */
export default [
    function spHtmlEncode() {
        return function filterSpHtmlEncode(stringIn) {
            stringIn = stringIn || '';

            if (!angular.isString(stringIn)) {
                return stringIn;
            }

            return stringIn.replace(/{/g, '&#123;').replace(/}/g, '&#125;');
        };
    }
];
