import { SP_API_HTTP_EVENTS } from 'Sp/Angular/Events/Common';

/**
 * @ngdoc service
 * @name sp.common.service:spApiHttpProvider
 *
 * @description
 * Provider for ShootProof's `$http` wrapper, `spApiHttp`
 */
export default function spApiHttpProvider() {
    let apiRoot;
    let authorizationHeader;
    let contentTypeHeader;
    let isHandlingUnauthorizedResponses = true;
    let unauthorizedRedirectUrl;

    /**
     * @ngdoc method
     * @name disableUnauthorizedResponseHandling
     * @methodOf sp.common.service:spApiHttpProvider
     *
     * @description
     * Allows the application to handle unauthorized (401) responses
     */
    this.disableUnauthorizedResponseHandling = function disableUnauthorizedResponseHandling() {
        isHandlingUnauthorizedResponses = false;
    };

    /**
     * @ngdoc method
     * @name setApiRoot
     * @methodOf sp.common.service:spApiHttpProvider
     *
     * @description
     * Sets the root url
     *
     * @param {string} url The root url
     */
    this.setApiRoot = function setApiRoot(url) {
        apiRoot = url;
    };

    /**
     * @ngdoc method
     * @name setAuthorizationHeader
     * @methodOf sp.common.service:spApiHttpProvider
     *
     * @description
     * Sets the "Authorization" header
     *
     * @param {string} header A value for the "Authorization" header
     */
    this.setAuthorizationHeader = function setAuthorizationHeader(header) {
        authorizationHeader = header;
    };

    /**
     * @ngdoc method
     * @name setContentTypeHeader
     * @methodOf sp.common.service:spApiHttpProvider
     *
     * @description
     * Sets the "Content-Type" header
     *
     * @param {string} header A value for the "Content-Type" header
     */
    this.setContentTypeHeader = function setContentTypeHeader(header) {
        contentTypeHeader = header;
    };

    /**
     * @ngdoc method
     * @name setUnauthorizedRedirectUrl
     * @methodOf sp.common.service:spApiHttpProvider
     *
     * @description
     * Sets a url to redirect to when an API responds with a status code of 403
     *
     * @param {string} url A url to redirect to
     */
    this.setUnauthorizedRedirectUrl = function setUnauthorizedRedirectUrl(url) {
        unauthorizedRedirectUrl = url;
    };

    this.$get = [
        '$rootScope',
        '$q',
        '$http',
        '$window',
        /**
         * @ngdoc service
         * @kind function
         * @name sp.common.service:spApiHttp
         * @requires $rootScope
         * @requires $q
         * @requires $http
         * @requires $window
         *
         * @description
         * ShootProof's `$http` wrapper
         *
         * @param {object} requestConfig Object is passed to the underlying $http service, so any
         *     valid parameter of that service is valid here. The only new property is the optional
         *     `ignoreRequestQueue` property
         *
         * @returns {Promise} `$http` Promise
         */
        function spApiHttpService($rootScope, $q, $http, $window) {
            let httpRequestsQueued = [];

            /**
             * @ngdoc function
             * @name get
             * @methodOf sp.common.service:spApiHttp
             *
             * @description
             * Wraps `$http#get`.
             *
             * @param {string|object} uriOrRequestConfig The uri of the API or a configuration
             *     object that will be used for the request
             * @param {string} uriOrRequestConfig.url The URL to request
             * @param {boolean=} uriOrRequestConfig.ignoreRequestQueue If true, will not add this
             *     request to the monitored request queue
             * @param {boolean=} ignoreRequestQueue If true, will not add this request to the
             *     monitored request queue
             *
             * @returns {Promise} `$http` Promise
             */
            function spApiHttp(requestConfig) {
                return submitHttpRequest(requestConfig);
            }

            /**
             * @ngdoc method
             * @name get
             * @methodOf sp.common.service:spApiHttp
             *
             * @description
             * Wraps `$http#get`.
             *
             * @param {string|object} uriOrRequestConfig The uri of the API or a configuration
             *     object that will be used for the request
             * @param {string} uriOrRequestConfig.url The URL to request
             * @param {boolean=} uriOrRequestConfig.ignoreRequestQueue If true, will not add this
             *     request to the monitored request queue
             * @param {boolean=} ignoreRequestQueue If true, will not add this request to the
             *     monitored request queue
             *
             * @returns {Promise} `$http` Promise
             */
            spApiHttp.get = function get(uriOrRequestConfig, ignoreRequestQueue) {
                const config = {
                    method: 'GET'
                };

                if (angular.isObject(uriOrRequestConfig)) {
                    angular.extend(config, uriOrRequestConfig);
                } else {
                    config.url = uriOrRequestConfig;
                    config.ignoreRequestQueue = ignoreRequestQueue;
                }

                return spApiHttp(config);
            };

            /**
             * @ngdoc method
             * @name delete
             * @methodOf sp.common.service:spApiHttp
             *
             * @description
             * Wraps `$http#delete`.
             *
             * @param {string|object} uriOrRequestConfig The uri of the API or a configuration
             *     object that will be used for the request
             * @param {string} uriOrRequestConfig.url The URL to request
             * @param {boolean=} uriOrRequestConfig.ignoreRequestQueue If true, will not add this
             *     request to the monitored request queue
             * @param {boolean=} ignoreRequestQueue If true, will not add this request to the
             *     monitored request queue
             *
             * @returns {Promise} `$http` Promise
             */
            spApiHttp.delete = function _delete(uriOrRequestConfig, ignoreRequestQueue) {
                const config = {
                    method: 'DELETE'
                };

                if (angular.isObject(uriOrRequestConfig)) {
                    angular.extend(config, uriOrRequestConfig);
                } else {
                    config.url = uriOrRequestConfig;
                    config.ignoreRequestQueue = ignoreRequestQueue;
                }

                return spApiHttp(config);
            };

            /**
             * @ngdoc method
             * @name post
             * @methodOf sp.common.service:spApiHttp
             *
             * @description
             * Wraps `$http#post`.
             *
             * @param {string|object} uriOrRequestConfig The uri of the API or a configuration
             *     object that will be used for the request
             * @param {string} uriOrRequestConfig.url The URL to request
             * @param {boolean=} uriOrRequestConfig.ignoreRequestQueue If true, will not add this
             *     request to the monitored request queue
             * @param {boolean=} ignoreRequestQueue If true, will not add this request to the
             *     monitored request queue
             *
             * @returns {Promise} `$http` Promise
             */
            spApiHttp.post = function post(uriOrRequestConfig, data, ignoreRequestQueue) {
                const config = {
                    method: 'POST'
                };

                if (angular.isObject(uriOrRequestConfig)) {
                    angular.extend(config, uriOrRequestConfig);
                } else {
                    config.url = uriOrRequestConfig;
                    config.data = data;
                    config.ignoreRequestQueue = ignoreRequestQueue;
                }

                return spApiHttp(config);
            };

            /**
             * @ngdoc method
             * @name put
             * @methodOf sp.common.service:spApiHttp
             *
             * @description
             * Wraps `$http#put`.
             *
             * @param {string|object} uriOrRequestConfig The uri of the API or a configuration
             *     object that will be used for the request
             * @param {string} uriOrRequestConfig.url The URL to request
             * @param {boolean=} uriOrRequestConfig.ignoreRequestQueue If true, will not add this
             *     request to the monitored request queue
             * @param {boolean=} uriOrRequestConfig.broadcastUploadProgressComplete If true, will
             *     broadcast an event once the request has been received
             * @param {*=} uriOrRequestConfig.uploadProgressCompletePayload Will be broadcast as
             *     data of the upload progress complete event when the request has been received
             * @param {boolean=} ignoreRequestQueue If true, will not add this request to the
             *     monitored request queue
             *
             * @returns {Promise} `$http` Promise
             */
            spApiHttp.put = function put(uriOrRequestConfig, data, ignoreRequestQueue) {
                const config = {
                    method: 'PUT'
                };

                if (angular.isObject(uriOrRequestConfig)) {
                    angular.extend(config, uriOrRequestConfig);

                    if (config.broadcastUploadProgressComplete) {
                        config.uploadEventHandlers = {
                            progress: function withEvent(event) {
                                const isUploadComplete = event.loaded / event.total === 1;

                                if (isUploadComplete) {
                                    $rootScope.$broadcast(
                                        SP_API_HTTP_EVENTS.UPLOAD_PROGRESS_COMPLETE,
                                        config.uploadProgressCompletePayload || {}
                                    );
                                }
                            }
                        };
                    }
                } else {
                    config.url = uriOrRequestConfig;
                    config.data = data;
                    config.ignoreRequestQueue = ignoreRequestQueue;
                }

                return spApiHttp(config);
            };

            /**
             * @ngdoc method
             * @name patch
             * @methodOf sp.common.service:spApiHttp
             *
             * @description
             * Wraps `$http#patch`.
             *
             * @param {string|object} uriOrRequestConfig The uri of the API or a configuration
             *     object that will be used for the request
             * @param {string} uriOrRequestConfig.url The URL to request
             * @param {boolean=} uriOrRequestConfig.ignoreRequestQueue If true, will not add this
             *     request to the monitored request queue
             * @param {boolean=} ignoreRequestQueue If true, will not add this request to the
             *     monitored request queue
             *
             * @returns {Promise} `$http` Promise
             */
            spApiHttp.patch = function patch(uriOrRequestConfig, data, ignoreRequestQueue) {
                const config = {
                    method: 'PATCH'
                };

                if (angular.isObject(uriOrRequestConfig)) {
                    angular.extend(config, uriOrRequestConfig);
                } else {
                    config.url = uriOrRequestConfig;
                    config.data = data;
                    config.ignoreRequestQueue = ignoreRequestQueue;
                }

                return spApiHttp(config);
            };

            /**
             * @ngdoc method
             * @name hasPendingRequests
             * @methodOf sp.common.service:spApiHttp
             *
             * @returns {boolean} It returns if there are pending http requests in-flight
             */
            spApiHttp.hasPendingRequests = function hasPendingRequests() {
                return httpRequestsQueued.length > 0;
            };

            /**
             * @ngdoc method
             * @name cancelPendingRequests
             * @methodOf sp.common.service:spApiHttp
             *
             * @description
             * Cancels all pending http requests.
             */
            spApiHttp.cancelPendingRequests = function cancelPendingRequests() {
                httpRequestsQueued = httpRequestsQueued.filter(function cancelPendingHttpRequests(
                    maybeCancelHttpRequest
                ) {
                    maybeCancelHttpRequest.resolve();
                    return false;
                });
            };

            return spApiHttp;

            function submitHttpRequest(requestConfig) {
                const headers = {};
                const maybeHandleHttpResponse = $q.defer();
                const maybeCancelHttpRequest = $q.defer();

                if (authorizationHeader) {
                    headers.Authorization = authorizationHeader;
                }

                if (requestConfig.verb !== 'GET') {
                    if (contentTypeHeader) {
                        headers['Content-Type'] = contentTypeHeader;
                    } else {
                        headers['Content-Type'] = 'application/vnd.shootproof.unknown+json';
                    }
                }

                if (requestConfig.url.indexOf('http') !== 0 && apiRoot) {
                    requestConfig.url = apiRoot + requestConfig.url;
                }

                requestConfig.headers = angular.extend({}, headers, requestConfig.headers);

                requestConfig.timeout = maybeCancelHttpRequest.promise;

                submitHttpRequestToSpApi();

                return maybeHandleHttpResponse.promise;

                function submitHttpRequestToSpApi() {
                    $http(requestConfig)
                        .then(function onSuccess(response) {
                            maybeHandleHttpResponse.resolve(response);
                        })
                        .catch(function onError(response) {
                            const { data, status } = response;

                            if (!data) {
                                return $rootScope.$broadcast(
                                    SP_API_HTTP_EVENTS.TIMEOUT,
                                    submitHttpRequestToSpApi
                                );
                            }

                            if (status === 400) {
                                $rootScope.$broadcast(SP_API_HTTP_EVENTS.BAD_REQUEST, data);
                            } else if (status === 401) {
                                if (isHandlingUnauthorizedResponses) {
                                    return $window.location.reload();
                                }
                            } else if (status === 403) {
                                if (unauthorizedRedirectUrl) {
                                    return $window.location.replace(unauthorizedRedirectUrl);
                                }
                            } else if (status === 409) {
                                $rootScope.$broadcast(SP_API_HTTP_EVENTS.CONFLICT, data);
                            } else if (status === 429) {
                                $rootScope.$broadcast(SP_API_HTTP_EVENTS.TOO_MANY_REQUESTS, data);
                            } else if (status === 500) {
                                $rootScope.$broadcast(SP_API_HTTP_EVENTS.INTERNAL, data);
                            } else if (status === 503) {
                                $rootScope.$broadcast(SP_API_HTTP_EVENTS.SERVICE_UNAVAILABLE, data);
                            }

                            maybeHandleHttpResponse.reject(response);
                        })
                        .finally(function popHttpRequestFromQueue() {
                            if (!requestConfig.ignoreRequestQueue) {
                                httpRequestsQueued.pop();
                            }
                        });

                    if (!requestConfig.ignoreRequestQueue) {
                        httpRequestsQueued.push(maybeCancelHttpRequest);
                    }
                }
            }
        }
    ];
}
