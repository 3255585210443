export default [
    'SPDataObjectsProvider',
    function PhotoConfig(SPDataObjectsProvider) {
        SPDataObjectsProvider.defineDataObject('Photo', function Photo(data) {
            this.id = data.id;
            this.width = data.w;
            this.height = data.g;
            this.key = data.key;
            this.legacy = data.l;
            this.externalUrl = data.externalUrl;
            this.filename = data.n;
            this.isFavorite = data.f;
            this.isHidden = data.h;
            this.isInCart = data.c;
            this.filterBlackAndWhite = typeof data.lbw === 'boolean' && data.lbw;
            this.tags = data.t ? data.t : [];
            this.favFromAlbumId = data.a;

            this.hasTag = function hasTag(tag) {
                return this.tags.indexOf(tag.id) !== -1;
            };
        });
    }
];
