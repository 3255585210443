/**
 * @ngdoc service
 * @name sp.common.service:spBraintree3DSecure
 *
 * @requires sp.common.provider:braintreeWeb
 *
 * @description
 * Service to handle Braintree 3DS verification
 */
export default [
    'braintreeWeb',
    function spBraintree3DSecure(braintreeWeb) {
        let renderChallenge;
        const VERIFIED_STATUS_SUCCESS = 'authenticate_successful';
        const VERIFIED_STATUS_UNAVAILABLE = 'authentication_unavailable';
        const { threeDSecure } = braintreeWeb;

        return Object.assign(this, {
            verifyCard
        });

        function checkVerifiedResponse({ nonce: verifiedNonce, threeDSecureInfo, details }) {
            const { status: verifiedStatus } = threeDSecureInfo;
            const isVerified = [VERIFIED_STATUS_SUCCESS, VERIFIED_STATUS_UNAVAILABLE].includes(
                verifiedStatus
            );

            return {
                creditCard: details,
                paymentMethodNonce: isVerified ? verifiedNonce : null,
                verified: isVerified,
                verifiedStatus
            };
        }

        function createBrainTreeClient() {
            return braintreeWeb.client.create({
                authorization: braintreeWeb.authorization
            });
        }

        function create3DSecureClient(standardClient) {
            return threeDSecure
                .create({
                    client: standardClient,
                    version: '2-inline-iframe'
                })
                .then((secureClient) => {
                    secureClient.on('authentication-iframe-available', (event, next) => {
                        const { element } = event;

                        renderChallenge(element);
                        next();
                    });

                    return secureClient;
                });
        }

        function handleVerificationFlow(secureClient, paymentMethodNonce, bin, chargeAmount) {
            return secureClient
                .verifyCard({
                    nonce: paymentMethodNonce,
                    bin: bin,
                    amount: chargeAmount,
                    onLookupComplete: (_, next) => next()
                })
                .then(checkVerifiedResponse);
        }

        function parsePaymentMethodNonce(tokenizedCreditCardData) {
            const { creditCards } = tokenizedCreditCardData;
            const [{ nonce }] = creditCards;

            return nonce;
        }

        function tokenizeCreditCard(client, creditCard) {
            return client.request({
                endpoint: 'payment_methods/credit_cards',
                method: 'post',
                data: {
                    creditCard
                }
            });
        }

        /**
         * @ngdoc method
         * @name verifyCard
         * @methodOf sp.common.service:spBraintree3DSecure
         *
         * @description
         * Handles 3DS verification flow
         *
         * @param   {object} creditCard Credit card information to verify
         * @param   {number} chargeAmount Amount to submit for verification
         * @param   {function} render Helper function for rendering the
         *     Braintree iframe
         * @returns {object} Verification results
         */
        function verifyCard(creditCard, chargeAmount, render) {
            let standardClient;
            let paymentMethodNonce;
            let bin;

            renderChallenge = render;

            return createBrainTreeClient()
                .then((client) => (standardClient = client))
                .then(() => tokenizeCreditCard(standardClient, creditCard))
                .then((tokenizedResponse) => {
                    bin = tokenizedResponse.creditCards[0].details.bin;
                    return parsePaymentMethodNonce(tokenizedResponse);
                })
                .then((nonce) => paymentMethodNonce = nonce)
                .then(() => create3DSecureClient(standardClient))
                .then((secureClient) =>
                    handleVerificationFlow(secureClient, paymentMethodNonce, bin, chargeAmount)
                );
        }
    }
];
