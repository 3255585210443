export const SESSION_STORAGE_EMAIL_KEY = 'spClientCredentialsEmail';
export const SESSION_STORAGE_ZIP_BUNDLE_KEY = 'spClientCredentialsZipBundleKey';

export const AUTHORIZATION_TOKEN_EVENTS = {
    // The CREATE and CREATED events fire for all authorization tokens:
    CREATE: 'client-gallery-authorization-token-create',
    CREATED: 'client-gallery-authorization-token-created',
    // The CREATED_EVENT_TOKEN event fires only for event-level authorization tokens:
    CREATED_EVENT_TOKEN: 'client-gallery-event-authorization-token-created',
    ERRORS: {
        UNAUTHORIZED: 'client-gallery-authorization-token-errors-unauthorized'
    }
};
