/* eslint-disable func-names */

var directiveName = 'spDelayRemove';

export default [
    '$timeout',
    function spDelayRemoveDirective($timeout) {
        return {
            restrict: 'A',
            scope: {
                time: '=' + directiveName,
                array: '=' + directiveName + 'Array'
            },
            link: function spDelayRemoveController($scope, $element) {
                $timeout(function() {
                    $element.slideUp(400, function() {
                        if (typeof $scope.array === 'object') {
                            $scope.array.splice($scope.$index, 1);
                        } else {
                            $element.remove();
                        }
                    });
                }, $scope.time);
            }
        };
    }
];
