export default [
    'SPDataObjectsProvider',
    function PackageToCartConfig(SPDataObjectsProvider) {
        SPDataObjectsProvider.defineDataObject('PackageToCart', PackageToCart);
    }
];

function PackageToCart({
    cart_item_id,
    photo_id,
    photo_filter_id,
    price_sheet_item_id,
    quantity,
    crop_data,
    albumId,
    email,
    dataProcessingAndStorageConsent,
    packageItems
}) {
    this.cart_item_id = cart_item_id;
    this.photo_id = photo_id;
    this.photo_filter_id = photo_filter_id;
    this.price_sheet_item_id = price_sheet_item_id;
    this.quantity = quantity;
    this.crop_data = crop_data;
    this.album_id = albumId ? albumId : '';
    this.email = email;
    this.doesAcceptTerms = dataProcessingAndStorageConsent ? 1 : 0;
    this.package_items = packageItems;
}
