export default [
    '$provide',
    function onceDecorator($provide) {
        $provide.decorator('$rootScope', [
            '$delegate',
            function decorate($delegate) {
                $delegate.$once = function $once(event, handler) {
                    const destroy = $delegate.$on(event, (...args) => {
                        destroy();

                        handler.apply(this, args);
                    });

                    return destroy;
                };

                return $delegate;
            }
        ]);
    }
];
