export default [
    '$provide',
    function getPageIdDecorator($provide) {
        $provide.decorator('$rootScope', [
            '$delegate',
            function decorate($delegate) {
                var controllerName;

                $delegate.getPageId = function getPageId() {
                    return 'page' + controllerName;
                };

                $delegate.$on('$routeChangeSuccess', function withRoute(event, $route) {
                    controllerName =
                        $route.controller || ($route.$$route && $route.$$route.componentName);
                });

                return $delegate;
            }
        ]);
    }
];
