import React from 'react';
import { ExpandableText } from 'sp-ui';

interface IExpandableHtmlProps {
    html: string;
    maxLineCount: number;
}

const ExpandableHtml: React.FC<IExpandableHtmlProps> = ({ html, maxLineCount }) => (
    <ExpandableText maxLineCount={maxLineCount}>
        <div dangerouslySetInnerHTML={{ __html: html }} />
    </ExpandableText>
);

export default ExpandableHtml;
