/* global initialData */

import '../../common/js/jquery-2.1.4';
import '../common/lib/modernizr-3.5.0-custom';
import '../v2_common_lib/js/polyfill';
import '../../common/js/ip';

import angular from 'angular';
import angularSanitizeModule from 'angular-sanitize';
import { AboutPage, BackToTop, ContactPage, Footer, Header, Nav } from 'ts/client/brand';
import { react2angular } from 'react2angular';
import spCommonModule from '../../newcommon/js/sp.common';
import spClientCommonModule from '../common/sp.client.common';
import { withCommon } from 'sp-ui';
import { withBrandTheme } from 'ts/client/brand/BrandThemeContext';

const spClientBrandApp = angular.module('SPBrandApp', [
    angularSanitizeModule,
    spCommonModule.name,
    spClientCommonModule
]);

spClientBrandApp.config([
    '$sceDelegateProvider',
    '$routeProvider',
    '$locationProvider',
    'spAppDataProvider',
    'spSvg:config',
    function configureSpClientBrandApp(
        $sceDelegateProvider,
        $routeProvider,
        $locationProvider,
        spAppDataProvider,
        spSvgConfig
    ) {
        const release = spAppDataProvider.get('release');
        const urls = spAppDataProvider.get('urls');

        // Configure spSvg to know where its default document is
        spSvgConfig.defaultSVGDocument = `/content/${release}/svg.svg`;

        $sceDelegateProvider.resourceUrlWhitelist([
            'self',
            'http://static.shootproof.com/**',
            'https://static.shootproof.com/**',
            'http://static.*.shootproof.com/**',
            'https://static.*.shootproof.com/**',
            'https://d1icb03h9nte03.cloudfront.net/**',
            'https://d2eiu0aj1vcuc8.cloudfront.net/**',
            "https://d26jca83quavpd.cloudfront.net/**",
            'https://d2j6j20uzcmhjg.cloudfront.net/**',
            'https://dgrtyzfesrbxq.cloudfront.net/**'
        ]);

        $locationProvider.html5Mode(true);

        $routeProvider
            .when('/', {
                controller: 'BrandHome',
                templateUrl: getPageURL('brandHome')
            })
            .when('/about', {
                template: '<brand-about-page/>'
            })
            .when('/contact', {
                template: '<brand-contact-page/>'
            })
            .when('/category/:categoryId', {
                controller: 'BrandCategory',
                templateUrl: getPageURL('brandCategory')
            })
            .when('/order/:orderId/downloadview/:orderDownloadId', {
                controller: 'BrandOrderBundle',
                templateUrl: getPageURL('brandOrderBundle')
            })
            // Work-around for the rare occasion when a user links to a
            // gallery from a link in their Brand description
            .when('/gallery/:eventId', {
                resolve: {
                    goTo: [
                        '$window',
                        function refreshCurrentUrl($window) {
                            $window.location.assign($window.location.href);
                        }
                    ]
                }
            })
            .otherwise({
                templateUrl: getPageURL('blank'),
                controller: [
                    '$window',
                    '$httpParamSerializer',
                    function redirectTo404($window, $httpParamSerializer) {
                        const targetPath = $window.location.pathname;

                        $window.location.replace(
                            `/app/${initialData.eventData.id}/error/404?${$httpParamSerializer({
                                url: targetPath
                            })}`
                        );
                    }
                ]
            });

        function getPageURL(pageName) {
            return `${urls.angularRoot}/templates/pages/${pageName}.nghtml`;
        }
    }
]);

import SPBrandDataService from './js/services/SPBrandData';

spClientBrandApp.service('SPBrandData', SPBrandDataService);

spClientBrandApp.run([
    '$window',
    '$rootScope',
    'spAppData',
    'SPSupport',
    function decorateRootScope($window, $rootScope, spAppData, SPSupport) {
        if (!SPSupport.isBrowserSupported()) {
            $window.location.href = '/unsupported';
        }

        const urls = spAppData.get('urls');

        $rootScope.getPartialURL = function getPartialURL(partialName) {
            return `${urls.angularRoot}/templates/partials/${partialName}.nghtml`;
        };

        $rootScope.brandData = spAppData.get('brandData');
        $rootScope.themeData = spAppData.get('themeData');
        $rootScope.homepageData = spAppData.get('homepageData');
        $rootScope.events = spAppData.get('events');
        $rootScope.categories = spAppData.get('categories');
        $rootScope.categoryEvents = spAppData.get('categoryEvents');
        $rootScope.nonCategoryEvents = spAppData.get('nonCategoryEvents');
        $rootScope.userState = spAppData.get('userState');
    }
]);

import BrandCategoryController from './js/controllers/BrandCategory';
import BrandHomeController from './js/controllers/BrandHome';
import BrandOrderBundleController from './js/controllers/BrandOrderBundle';
import BrandRootController from './js/controllers/BrandRoot';

spClientBrandApp.component(
    'brandAboutPage',
    react2angular(
        withCommon(withBrandTheme(AboutPage)),
        [],
        ['$location', '$route', 'spAppData', 'translateFilter']
    )
);

spClientBrandApp.component(
    'backToTop',
    react2angular(withCommon(withBrandTheme(BackToTop)), [], ['spAppData'])
);

spClientBrandApp.component(
    'brandContactPage',
    react2angular(
        withCommon(withBrandTheme(ContactPage)),
        [],
        ['$location', '$route', '$window', 'spAppData', 'spApiHttp', 'translateFilter']
    )
);

spClientBrandApp.component('brandFooter', react2angular(withCommon(Footer), [], ['spAppData']));

spClientBrandApp.component(
    'mainNav',
    react2angular(
        withCommon(withBrandTheme(Nav)),
        ['themeData', 'translateFilter'],
        ['$location', '$route', 'spAppData']
    )
);

spClientBrandApp.component(
    'header',
    react2angular(
        withCommon(withBrandTheme(Header)),
        [],
        ['$location', '$route', 'spAppData', 'translateFilter']
    )
);

spClientBrandApp.controller('BrandCategory', BrandCategoryController);
spClientBrandApp.controller('BrandHome', BrandHomeController);
spClientBrandApp.controller('BrandOrderBundle', BrandOrderBundleController);
spClientBrandApp.controller('BrandRoot', BrandRootController);

export default spClientBrandApp.name;
