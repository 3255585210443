import { DATE_PICKER_EVENTS } from 'Sp/Angular/Events/Common';
import bowser from 'bowser';
import moment from 'moment';

/**
 * @ngdoc directive
 * @name sp.common.directive:spFormInputDate
 * @requires $rootScope
 * @requires $compile
 * @requires $parse
 * @requires $timeout
 * @requires dateFilter
 * @requires spDateFilter
 * @restrict A
 *
 * @description
 * Adds a calendar icon. Displays a date picker when focused on non-tablet, non-mobile devices.
 *
 * @param {boolean} forceUtc When true, will force picked dates to be chosen and displayed in UTC
 */
export default [
    '$rootScope',
    '$compile',
    '$parse',
    '$timeout',
    'dateFilter',
    'spDateFilter',
    function spFormInputDateDirective(
        $rootScope,
        $compile,
        $parse,
        $timeout,
        dateFilter,
        spDateFilter
    ) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function spFormInputDateController($scope, $element, $attrs, ngModelController) {
                const $datePickerLabelElement = $compile(
                    `<label
                        for="${$attrs.id}">

                        <sp-svg
                            hash="iconCalendar">
                        </sp-svg>

                    </label>`
                )($scope);
                const forceUtc = $attrs.forceUtc === 'true';
                let $clearLinkElement;
                let $datePickerElement;

                $scope.getModelValue = getModelValue;

                $scope.getMinimumDate = function getMinimumDate() {
                    return $attrs.ngMin;
                };

                $scope.getMaximumDate = function getMaximumDate() {
                    return $attrs.ngMax;
                };

                $scope.clear = function clear() {
                    assignModel(null);

                    $timeout(function focusElement() {
                        $element[0].focus();
                    });
                };

                $datePickerElement = $compile(
                    `<sp-date-picker
                        current-date-picked=" getModelValue() "
                        minimum-date=" getMinimumDate() "
                        maximum-date=" getMaximumDate() ">
                    </sp-date-picker>`
                )($scope);

                $clearLinkElement = $compile(
                    `<a
                        ng-click=" clear() "
                        translate="clearLink">
                    </a>`
                )($scope);

                $element.on('mousedown touchend', openDatePickerOnDesktop);
                $datePickerElement.on('mousedown touchend', ($event) => $event.stopPropagation());
                $datePickerLabelElement.on('mousedown touchend', openDatePickerOnDesktop);

                $scope.$on(DATE_PICKER_EVENTS.DATE_PICKED, (_, datePicked) => {
                    if (forceUtc) {
                        const momentPicked = moment(datePicked);

                        datePicked = moment
                            .utc({
                                date: momentPicked.date(),
                                month: momentPicked.month(),
                                year: momentPicked.year()
                            })
                            .toDate();
                    }

                    assignModel(datePicked);

                    const $datePicker = $compile($datePickerElement[0])($scope);

                    $datePickerElement.remove();
                    $datePickerLabelElement.after($datePicker);
                });

                $element.after($datePickerElement);
                $element.after($clearLinkElement);
                $element.after($datePickerLabelElement);

                if (!bowser.tablet && !bowser.mobile) {
                    $element.addClass('sp-form-input-date-enable-sp-date-picker');
                }

                ngModelController.$formatters.unshift(function formatDate() {
                    const FORMAT = 'yyyy-MM-dd';
                    const value = ngModelController.$modelValue;

                    return forceUtc
                        ? dateFilter(value, FORMAT, 'UTC')
                        : spDateFilter(value, FORMAT);
                });

                function getModelValue() {
                    return getModel()($scope);
                }

                function assignModel(date) {
                    getModel().assign($scope, date);
                }

                function getModel() {
                    return $parse($attrs.ngModel);
                }

                function openDatePickerOnDesktop($event) {
                    if (!bowser.tablet && !bowser.mobile) {
                        $event.preventDefault();
                        $timeout(() => $element[0].focus());
                    }
                }
            }
        };
    }
];
