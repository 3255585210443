import React from 'react';
import { A, Box, styled } from 'sp-ui';
import { ReactComponent as SocialNetworkIconEmail } from 'svg/client/brand/social-network-icon-email.svg';
import { ReactComponent as SocialNetworkIconFacebook } from 'svg/client/brand/social-network-icon-facebook.svg';
import { ReactComponent as SocialNetworkIconInstagram } from 'svg/client/brand/social-network-icon-instagram.svg';
import { ReactComponent as SocialNetworkIconPinterest } from 'svg/client/brand/social-network-icon-pinterest.svg';
import { ReactComponent as SocialNetworkIconTwitter } from 'svg/client/brand/social-network-icon-twitter.svg';
import { ReactComponent as SocialNetworkIconWebsite } from 'svg/client/brand/social-network-icon-website.svg';
import { IAppDataBrandData } from './types';

enum SocialNetworkIconType {
    Facebook = 'facebook',
    Twitter = 'twitter',
    Instagram = 'instagram',
    Pinterest = 'pinterest',
    Website = 'website',
    Email = 'email'
}
interface ISocialNetworkIconProps {
    destination: string;
    height: string;
    type: SocialNetworkIconType;
}

const StyledSocialIcon = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'height'
})<{ height: string }>(
    ({ height }) => `
        margin: 0 20px;
        
        svg {
            height: ${height};
            fill: #a5a5a5;
        }
    `
);

const SocialNetworkIcon: React.FC<ISocialNetworkIconProps> = ({ type, height, destination }) => {
    let Icon;

    switch (type) {
        case SocialNetworkIconType.Facebook:
            Icon = SocialNetworkIconFacebook;

            break;
        case SocialNetworkIconType.Twitter:
            Icon = SocialNetworkIconTwitter;

            break;
        case SocialNetworkIconType.Instagram:
            Icon = SocialNetworkIconInstagram;

            break;
        case SocialNetworkIconType.Pinterest:
            Icon = SocialNetworkIconPinterest;

            break;
        case SocialNetworkIconType.Website:
            Icon = SocialNetworkIconWebsite;

            break;
        case SocialNetworkIconType.Email:
            Icon = SocialNetworkIconEmail;

            break;
    }

    return (
        <StyledSocialIcon height={height} data-testid="social-icon-container">
            <A href={destination}>{Icon && <Icon data-testid="social-icon" />}</A>
        </StyledSocialIcon>
    );
};

interface ISocialNetworkLinks {
    spAppData: SpAngularJs.ISpAppData;
}

const StyledSocialIcons = styled(Box)`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
`;

const SocialNetworkLinks: React.FC<ISocialNetworkLinks> = ({ spAppData }) => {
    const { contact } = spAppData.get<IAppDataBrandData>('brandData');
    const { email, facebookUrl, instagramUrl, pinterestUrl, twitterUrl, website } = contact;

    return (
        <StyledSocialIcons data-testid="social-network-links-container">
            {facebookUrl && (
                <SocialNetworkIcon
                    type={SocialNetworkIconType.Facebook}
                    height="21px"
                    destination={facebookUrl}
                />
            )}
            {twitterUrl && (
                <SocialNetworkIcon
                    type={SocialNetworkIconType.Twitter}
                    height="18px"
                    destination={twitterUrl}
                />
            )}
            {instagramUrl && (
                <SocialNetworkIcon
                    type={SocialNetworkIconType.Instagram}
                    height="21px"
                    destination={instagramUrl}
                />
            )}
            {pinterestUrl && (
                <SocialNetworkIcon
                    type={SocialNetworkIconType.Pinterest}
                    height="21px"
                    destination={pinterestUrl}
                />
            )}
            {website && (
                <SocialNetworkIcon
                    type={SocialNetworkIconType.Website}
                    height="21px"
                    destination={website}
                />
            )}
            {email && (
                <SocialNetworkIcon
                    type={SocialNetworkIconType.Email}
                    height="17px"
                    destination={`mailto:${email}`}
                />
            )}
        </StyledSocialIcons>
    );
};

export default SocialNetworkLinks;
