/**
 * @ngdoc directive
 * @name sp.common.directive:spTranslateWithLinks
 * @requires $compile
 * @requires $parse
 * @requires translateFilter
 * @restrict A
 *
 * @description
 * Allows 1+ variables to optionally appear as links in translated strings.
 * <pre>
    <p
        sp-translate-with-links="Today will be {{weather}} with a high of {{tempHigh}} and getting down to {{tempLow}}! Direct {{questions}} to our friendly staff."
        sp-translate-with-links-values="{
            weather: {
                text: 'Sunny',
                href: 'http://weather.com/today'
            },
            tempHigh: {
                text: '75'
            },
            tempLow: {
                text: '60'
            },
            questions: {
                text: 'questions',
                href: 'http://google.com'
            }
        }">
    </p>
 * </pre>
 *
 * @param {string} spTranslateWithLinks Text with variables to be replaced
 * @param {object} spTranslateWithLinksValues Object with keys matching the variables in spTranslateWithLinks
 * @param {string} spTranslateWithLinksValues.text The string that will replace all instances of this key in spTranslateWithLinks
 * @param {string} spTranslateWithLinksValues.href Optional href property to enable a link that will open in a new window
 */
export default [
    '$compile',
    '$parse',
    'translateFilter',
    function spTranslateWithLinksDirective($compile, $parse, translateFilter) {
        return {
            restrict: 'A',
            link: function spTranslateWithLinksController($scope, $element, $attrs) {
                var spTranslateWithLinks = $attrs.spTranslateWithLinks;
                var translationValues = {};

                if ($attrs.spTranslateWithLinksValues) {
                    var spTranslateWithLinksValues = $parse($attrs.spTranslateWithLinksValues)(
                        $scope
                    );

                    translationValues = Object.keys(spTranslateWithLinksValues).reduce(
                        function createTranslationValues(translationValues, key) {
                            var spTranslateWithLinksValue = spTranslateWithLinksValues[key];

                            if (spTranslateWithLinksValue.href) {
                                translationValues[key] = createAnchorHtml(
                                    spTranslateWithLinksValue.text,
                                    spTranslateWithLinksValue.href
                                );
                            } else {
                                translationValues[key] = spTranslateWithLinksValue.text;
                            }

                            return translationValues;
                        },
                        {}
                    );
                }

                $element[0].innerHTML = translateFilter(spTranslateWithLinks, translationValues);

                function createAnchorHtml(text, href) {
                    return (
                        '<a href="' +
                        href +
                        '" target="_blank" rel="noopener noreferrer">' +
                        text +
                        '</a>'
                    );
                }
            }
        };
    }
];
