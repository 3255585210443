import { AUTHORIZATION_TOKEN_EVENTS } from 'Sp/Angular/Events/Client/Gallery';

export default [
    '$q',
    'spEventBus',
    function spAuthorizationTokenService($q, spEventBus) {
        const service = this;

        service.getAuthorizationTokenForEvent = getAuthorizationTokenForEvent;

        function getAuthorizationTokenForEvent(forceRefresh = false, request) {
            const deferred = $q.defer();

            if (!request?.albumId) {
                spEventBus.once(
                    AUTHORIZATION_TOKEN_EVENTS.CREATED_EVENT_TOKEN,
                    onGetAuthorizationTokenForEvent
                );
            }

            spEventBus.once(AUTHORIZATION_TOKEN_EVENTS.CREATED, onGetAuthorizationTokenForEvent);
            spEventBus.emit(AUTHORIZATION_TOKEN_EVENTS.CREATE, request, forceRefresh);

            return deferred.promise;

            function onGetAuthorizationTokenForEvent(token) {
                deferred.resolve(token);
            }
        }
    }
];
