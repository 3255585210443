/* eslint-disable func-names */

export default [
    function spQuotesFilter() {
        return function(input) {
            if (typeof input !== 'string') {
                return input;
            }

            return '"' + input + '"';
        };
    }
];
