/**
 * @ngdoc filter
 * @name sp.common.filter:spDate
 * @requires dateFilter
 *
 * @description
 * Wraps the angular's dateFilter. Applies timezone from moment's currently set timezone.
 *
 * @param {Date|number|string} date Date to format either as Date object, milliseconds (string or
 *     number) or various ISO 8601 datetime string formats (e.g. yyyy-MM-ddTHH:mm:ss.sssZ and its
 *     shorter versions like yyyy-MM-ddTHH:mmZ, yyyy-MM-dd or yyyyMMddTHHmmssZ). If no timezone is
 *     specified in the string input, the time is considered to be in the local timezone.
 * @param {string=} format Formatting rules (see angular's docs for `dateFilter`). If not specified,
 *     `mediumDate` is used.
 *
 * @returns {string} Formatted string or the input if input is not recognized as date/millis.
 */
export default [
    'dateFilter',
    function spDateFilter(dateFilter) {
        return function filterSpDate(date, format) {
            var tz = moment.tz(date, moment().tz()).format('ZZ');

            return dateFilter(date, format, tz);
        };
    }
];
