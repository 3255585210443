import spRadioButtonGroupTemplate from '../../templates/components/sp-radio-button-group.nghtml';

/**
 * @ngdoc component
 * @name sp.common.component:spRadioButtonGroup
 *
 * @description
 * Creates a "normal" radio button with ShootProof styling. Supports a transclude element that will
 * render below the selected radio input.
 *
 *
 * @param {array<object>} radioData Array of objects representing each radio button in the group
 * @param {string} radioData.labelText Text to display in the label next to the radio
 * @param {string} radioData.value Value of the radio button
 * @param {boolean} radioData.isDisabled Determines if the radio button should be disabled
 * @param {string} radioName The name of the radio button group
 * @param {string} radioModel The name of the associated ngModel
 */
export default {
    bindings: {
        radioData: '=',
        radioName: '<',
        radioModel: '='
    },
    template: spRadioButtonGroupTemplate,
    transclude: true
};
