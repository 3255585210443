export default [
    function pageTemplateUrlProvider() {
        this.angularRoot = '';

        this.setAngularRoot = function setAngularRoot(rootUrl) {
            this.angularRoot = rootUrl;
        };

        this.get = function get(module, name) {
            return this.angularRoot + module + '/templates/pages/' + name + '.nghtml';
        };

        this.$get = function $get() {
            return { get: this.get };
        };
    }
];
