import { CONTRACT_SIGNATURE_EVENTS } from 'Sp/Angular/Events/Common';
import Api from 'Sp/Api';

export default [
    '$rootScope',
    'spApiHttp',
    'spApiHypermedia',
    function ContractSignatureDataStore($rootScope, spApiHttp, spApiHypermedia) {
        $rootScope.$on(CONTRACT_SIGNATURE_EVENTS.CREATE, function withContractSignature(
            _,
            contractSignature,
            fromPortal
        ) {
            let linkPath;
            let requestBody;

            if (fromPortal) {
                requestBody = contractSignature;
                linkPath = [
                    {
                        type: Api.CONTRACT_TYPE,
                        id: contractSignature.contractPublicId,
                        email: contractSignature.email
                    },
                    { type: Api.CONTRACT_SIGNATURE_TYPE }
                ];
            } else {
                requestBody = null;
                linkPath = [
                    { type: Api.BRAND_TYPE, id: $rootScope.brand.id },
                    { type: Api.CONTRACT_TYPE, id: contractSignature.contractId },
                    { type: Api.CONTRACT_SIGNATURE_TYPE, id: contractSignature.signatureId }
                ];
            }

            spApiHypermedia
                .getLink(linkPath)
                .then(function withLink({ href }) {
                    return spApiHttp.put(href, requestBody, true);
                })
                .then(function withResponse({ data: contract }) {
                    $rootScope.$broadcast(CONTRACT_SIGNATURE_EVENTS.CREATED, contract);
                })
                .catch(function withError({ status }) {
                    if (status === 409) {
                        $rootScope.$broadcast(CONTRACT_SIGNATURE_EVENTS.CREATION_ERROR_CONFLICT);
                    } else {
                        $rootScope.$broadcast(CONTRACT_SIGNATURE_EVENTS.CREATION_ERROR_OTHER);
                    }
                });
        });
    }
];
