/**
 * @ngdoc directive
 * @name sp.common.directive:spTranslateLink
 * @requires $compile
 * @requires $parse
 * @requires translateFilter
 * @restrict A
 *
 * @description
 * Allows variables as links to appear in translated strings.
 * <pre>
    <p
        sp-translate-link="Today's weather is {{weather}}. Check it out {{link}}!"
        sp-translate-link-text="here"
        sp-translate-link-values="{ weather: 'Sunny' }"
        sp-translate-link-click=" getFullWeatherReport() ">
    </p>
 * </pre>
 */
export default [
    '$compile',
    '$parse',
    'translateFilter',
    function spTranslateLinkDirective($compile, $parse, translateFilter) {
        return {
            restrict: 'A',
            link: function onLink($scope, $element, $attrs) {
                var fullText = $attrs.spTranslateLink;
                var linkText = translateFilter($attrs.spTranslateLinkText);
                var translateValues = {};

                if ($attrs.spTranslateLinkValues) {
                    translateValues = $parse($attrs.spTranslateLinkValues)($scope);
                }

                var REPLACE_AND_SPLIT_ON = '***link***';
                var translatedText = translateFilter(
                    fullText,
                    angular.extend({ link: REPLACE_AND_SPLIT_ON }, translateValues)
                );
                var translatedTextSubstrings = translatedText.split(REPLACE_AND_SPLIT_ON);
                var anchorTag = angular.element('<a>');

                anchorTag.attr('ng-click', $attrs.spTranslateLinkClick);
                anchorTag.html(linkText);

                $element[0].innerHTML = translatedTextSubstrings[0];
                $element.append(anchorTag);
                $element[0].innerHTML += translatedTextSubstrings[1];

                $compile($element.find('a'))($scope);
            }
        };
    }
];
