/* eslint-disable func-names, eqeqeq */

var directiveName = 'spPointSlide';

export default [
    '$window',
    '$timeout',
    function spPointSlideDirective($window, $timeout) {
        var windowElement = angular.element($window);

        return {
            restrict: 'A',
            scope: {
                selected: '=' + directiveName,
                array: '=' + directiveName + 'Array'
            },
            link: function spPointSlideController($scope, element) {
                function getSelectedIndex() {
                    var index = $scope.array.indexOf($scope.selected);

                    if (index == -1) {
                        return false;
                    }

                    return index;
                }

                function getChildrenWidth() {
                    var childrenWidth = 0;

                    element.children().each(function() {
                        childrenWidth += angular.element(this).outerWidth();
                    });

                    return childrenWidth;
                }

                function moveToPoint() {
                    var index = getSelectedIndex();

                    if (index === false) {
                        return;
                    }

                    var children = element.children();
                    var lastNode = children.last();
                    var pointNode = children.eq(index);

                    if (pointNode.length === 0) {
                        return;
                    }

                    var containerWidth = element.outerWidth();

                    // Anytime this method is called, if the children are
                    // narrower than the container, always position the
                    // container to the left side.
                    if (getChildrenWidth() <= containerWidth) {
                        element.css('left', 0);

                        return;
                    }

                    var containerOffset =
                        -pointNode.position().left + (containerWidth - pointNode.outerWidth()) / 2;

                    // Bounding
                    if (containerOffset > 0) {
                        containerOffset = 0;
                    }

                    var maxOffset =
                        -lastNode.position().left + (containerWidth - lastNode.outerWidth());

                    if (containerOffset < maxOffset) {
                        containerOffset = maxOffset;
                    }

                    element.css('left', containerOffset);
                }

                $scope.$watch('selected', function() {
                    moveToPoint();
                });

                $scope.$parent[directiveName + 'HasNext'] = function() {
                    var index = getSelectedIndex();

                    if (index === false) {
                        return false;
                    }

                    return index < $scope.array.length - 1;
                };

                $scope.$parent[directiveName + 'HasPrevious'] = function() {
                    var index = getSelectedIndex();

                    if (index === false) {
                        return false;
                    }

                    return index > 0;
                };

                function updateIndex(indexOffset) {
                    var index = getSelectedIndex();

                    if (index === false) {
                        return false;
                    }

                    var newIndex = index + indexOffset;

                    if (newIndex < 0) {
                        newIndex = 0;
                    }

                    if (newIndex > $scope.array.length - 1) {
                        newIndex = $scope.array.length - 1;
                    }

                    if (newIndex === index) {
                        return;
                    }

                    $scope.selected = $scope.array[newIndex];
                }

                $scope.$parent[directiveName + 'Next'] = function() {
                    updateIndex(1);
                };

                $scope.$parent[directiveName + 'Previous'] = function() {
                    updateIndex(-1);
                };

                var currentlyWindowed = false;

                function checkIfNeedsWindowing() {
                    var childrenWidth = getChildrenWidth();
                    var containerWidth = element.parent().outerWidth();

                    if (childrenWidth > containerWidth) {
                        if (!currentlyWindowed) {
                            currentlyWindowed = true;
                            element.parent().addClass('windowed');

                            moveToPoint();
                        }
                    } else {
                        if (currentlyWindowed) {
                            currentlyWindowed = false;
                            element.parent().removeClass('windowed');

                            moveToPoint();
                        }
                    }
                }

                windowElement.on('resize', checkIfNeedsWindowing);

                $scope.$on('$destroy', function() {
                    windowElement.off('resize', checkIfNeedsWindowing);
                });

                $timeout(function() {
                    checkIfNeedsWindowing();
                });
            }
        };
    }
];
