import React, { Attributes, FC, useEffect, useMemo, useRef, useState } from 'react';
import { Box, useBreakpointValue } from '@chakra-ui/react';
import { ResizeObserver } from '@juggle/resize-observer';
import Constants from 'Sp/Gallery';
import type { IImage } from 'ts/client/gallery/components';
import type { IPhotoDimensions } from 'ts/client/types';
import getPhotoDimensionsMap from 'ts/common/components/gallery/util/getPhotoDimensionsMap';
import PhotoGridItem from './PhotoGridItem';

const layoutTypes = ['hmason', 'vmason'] as const;

type ThumbnailSize = 's' | 'm' | 'l';

interface Props<OverlayProps> {
    /** An optional React component to supply for the overlay for each item in the grid */
    PhotoOverlayComponent?: FC<OverlayProps>;
    /** An optional element for the container object, used for the scroll listener (when used in a modal for example) */
    containerElement?: Nullable<HTMLElement>;
    /** The type of layout to use for rendering the grid's photos */
    layoutType: typeof layoutTypes[number];
    /** Callback when a photo is clicked */
    onPhotoClick?: (photo: IImage) => void;
    /** The array of photos to render */
    photos: IImage[];
    /** The optional function to provide the props to the Overlay component, if supplied */
    getPhotoOverlayProps?: (photo: IImage) => OverlayProps;
    /** Called on mount, to scroll to the target photo */
    scrollToTargetPhoto?: () => unknown;
    /** The size to use for spacing between photos on large screens */
    thumbnailGutterWidth: ThumbnailSize;
    /** The size to use for rendering thumbnails */
    thumbnailSize: ThumbnailSize;
}

const PhotoGrid = <OverlayProps extends Attributes>({
    PhotoOverlayComponent,
    containerElement,
    getPhotoOverlayProps,
    layoutType,
    onPhotoClick,
    photos,
    scrollToTargetPhoto,
    thumbnailGutterWidth,
    thumbnailSize
}: Props<OverlayProps>) => {
    if (!layoutTypes.includes(layoutType)) {
        throw new Error(`PhotoGrid received unexpected layout type: ${layoutType}`);
    }

    const commonHeight: number =
        useBreakpointValue({
            lg: Constants.CLIENT_THUMBNAIL_SIZE_MAP.HMASON[thumbnailSize]
        }) ?? Constants.CLIENT_THUMBNAIL_SIZES.HMASON.SMALL;
    const commonWidth: number =
        useBreakpointValue({
            lg: Constants.CLIENT_THUMBNAIL_SIZE_MAP.VMASON[thumbnailSize]
        }) ?? Constants.CLIENT_THUMBNAIL_SIZES.VMASON.MEDIUM;
    const padding: number =
        useBreakpointValue({
            lg: Constants.CLIENT_THUMBNAIL_GUTTER_MAP[thumbnailGutterWidth]
        }) ?? Constants.CLIENT_GUTTER_SIZES.SMALL;

    const containerElementRef = useRef<HTMLDivElement>(null);

    const [containerTop, setContainerTop] = useState(0);
    const [windowHeight, setWindowHeight] = useState(0);
    const [photoDimensionsMap, setPhotoDimensionsMap] = useState<Map<number, IPhotoDimensions>>();

    const windowVirtualTop = useBreakpointValue({ lg: -0.5 * windowHeight }) ?? -windowHeight;
    const windowVirtualBottom = useBreakpointValue({ lg: 1.5 * windowHeight }) ?? 2 * windowHeight;

    const rootHeight = useMemo(() => {
        return photos.reduce((result, photo) => {
            const photoDimensions = photoDimensionsMap?.get(photo.id);

            if (!photoDimensions) {
                return result;
            }

            const height = photoDimensions.top + photoDimensions.height;
            return Math.max(height, result);
        }, 0);
    }, [photos, photoDimensionsMap]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            if (!containerElementRef.current) {
                return;
            }

            const nextPhotoDimensionsMap = getPhotoDimensionsMap({
                commonDimension: layoutType === 'hmason' ? commonHeight : commonWidth,
                containerWidth: containerElementRef.current.offsetWidth,
                layoutType,
                padding,
                photos
            });

            setPhotoDimensionsMap(nextPhotoDimensionsMap);
        });

        if (containerElementRef.current) {
            resizeObserver.observe(containerElementRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [commonHeight, commonWidth, layoutType, padding, photos]);

    useEffect(() => {
        if (scrollToTargetPhoto) {
            scrollToTargetPhoto();
        }
    }, [scrollToTargetPhoto]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowHeight(window.innerHeight);
        };

        const handleWindowScroll = () => {
            if (!containerElementRef.current) {
                return;
            }

            const nextContainerTop = containerElementRef.current.getBoundingClientRect().top;
            setContainerTop(nextContainerTop);
        };

        handleWindowResize();
        handleWindowScroll();

        window.addEventListener('resize', handleWindowResize);
        window.addEventListener('scroll', handleWindowScroll);

        if (containerElement) {
            containerElement.addEventListener('scroll', handleWindowScroll);
        }

        return () => {
            window.removeEventListener('resize', handleWindowResize);
            window.removeEventListener('scroll', handleWindowScroll);

            if (containerElement) {
                containerElement.removeEventListener('scroll', handleWindowScroll);
            }
        };
    }, [containerElement]);

    return (
        <Box data-testid="photo-grid" height={`${rootHeight}px`}>
            <Box ref={containerElementRef} position="relative">
                {photos.map((photo) => {
                    const photoDimensions = photoDimensionsMap?.get(photo.id);

                    if (!photoDimensions) {
                        return null;
                    }

                    const photoActualTop = containerTop + photoDimensions.top;
                    const photoActualBottom = photoActualTop + photoDimensions.height;

                    const topAboveView = photoActualTop < windowVirtualTop;
                    const topBelowView = photoActualTop > windowVirtualBottom;
                    const topInView = !topAboveView && !topBelowView;
                    const bottomAboveView = photoActualBottom < windowVirtualTop;
                    const bottomBelowView = photoActualBottom > windowVirtualBottom;
                    const bottomInView = !bottomAboveView && !bottomBelowView;
                    const isInView = topInView || bottomInView || (topAboveView && bottomBelowView);

                    return (
                        <PhotoGridItem
                            key={photo.id}
                            PhotoOverlayComponent={PhotoOverlayComponent as FC<Attributes>}
                            isInView={isInView}
                            onClick={onPhotoClick}
                            photo={photo}
                            photoDimensions={photoDimensions}
                            getPhotoOverlayProps={getPhotoOverlayProps}
                        />
                    );
                })}
            </Box>
        </Box>
    );
};

export default PhotoGrid;
