import InvoiceDiscount from 'Sp/Invoice/Discount';

/**
 * @ngdoc filter
 * @name sp.studio.invoice.filter:spInvoiceDiscountTotalDisplay
 * @requires currencyFilter
 * @requires translateFilter
 *
 * @description
 * Formats the discount value for display
 *
 * @param {string} discountType The discount type
 * @param {number} value The discount value
 * @param {string} currencySymbol The currency symbol to use for fixed types
 *
 * @returns {string} The formatted value by discount type
 */
export default [
    '$filter',
    function spInvoiceDiscountTotalDisplayFilter($filter) {
        const currencyFilter = $filter('currency');
        const translateFilter = $filter('translate');

        return function filterToDiscountTotal(discountType, value, currencySymbol) {
            switch (discountType) {
                case InvoiceDiscount.DISCOUNT_TYPE.FIXED:
                    return translateDisplayValue(currencyFilter(value, currencySymbol));
                case InvoiceDiscount.DISCOUNT_TYPE.PERCENT:
                    return translateDisplayValue(`${value}%`);
            }
        };

        function translateDisplayValue(displayValue) {
            return translateFilter('common.invoice.discount.displayValue', { displayValue });
        }
    }
];
