import {
    AUTHORIZATION_TOKEN_EVENTS,
    PHOTO_DOWNLOAD_EVENTS,
    ZIP_BUNDLE_EVENTS
} from 'Sp/Angular/Events/Client/Gallery';

export default [
    '$rootScope',
    '$window',
    'spApiHttp',
    function AuthorizationTokenDataStore($rootScope, $window, spApiHttp) {
        const BASE_STORAGE_KEY = 'clientGalleryAuthorizationTokenFor';
        const sessionStorage = $window.sessionStorage;

        $rootScope.$on(AUTHORIZATION_TOKEN_EVENTS.CREATE, createAuthorizationToken);
        $rootScope.$on(PHOTO_DOWNLOAD_EVENTS.ERRORS.TOKEN_INVALID, clearTokenAndReauthorize);
        $rootScope.$on(ZIP_BUNDLE_EVENTS.ERRORS.TOKEN_INVALID, clearTokenAndReauthorize);

        function broadcastAuthorizationToken(authorizationToken, { isEventToken } = {}) {
            $rootScope.$broadcast(AUTHORIZATION_TOKEN_EVENTS.CREATED, authorizationToken);

            if (isEventToken) {
                $rootScope.$broadcast(
                    AUTHORIZATION_TOKEN_EVENTS.CREATED_EVENT_TOKEN,
                    authorizationToken
                );
            }
        }

        function clearTokenAndReauthorize(_, albumId) {
            const eventId = $rootScope.eventData.id;
            const itemKey = albumId ? getAlbumStorageKey(albumId) : getEventStorageKey(eventId);

            sessionStorage.removeItem(itemKey);
            $rootScope.$broadcast(AUTHORIZATION_TOKEN_EVENTS.ERRORS.UNAUTHORIZED);
        }

        function createAuthorizationToken(_, request = {}, doForceCreation) {
            const eventId = request.eventId || $rootScope.eventData.id;
            const albumId = request.albumId;
            const eventAuthorizationToken = sessionStorage.getItem(getEventStorageKey(eventId));

            if (!doForceCreation) {
                if (albumId) {
                    const albumAuthorizationToken = sessionStorage.getItem(
                        getAlbumStorageKey(albumId)
                    );

                    if (albumAuthorizationToken) {
                        return broadcastAuthorizationToken(albumAuthorizationToken, {
                            isEventToken: false
                        });
                    }
                } else if (eventAuthorizationToken) {
                    return broadcastAuthorizationToken(eventAuthorizationToken, {
                        isEventToken: true
                    });
                }
            }

            const postData = {
                grant_type: 'https://developer.shootproof.com/auth#event-grant',
                event_id: eventId,
                album_id: albumId,
                email: request.email,
                password: request.password
            };

            if (request.google_oauth_refresh_token_cache_key) {
                postData.google_oauth_refresh_token_cache_key =
                    request.google_oauth_refresh_token_cache_key;
            }

            const headers = {};

            if (eventAuthorizationToken && eventAuthorizationToken !== 'undefined') {
                headers['Authorization'] = `Bearer ${eventAuthorizationToken}`;
            }

            spApiHttp
                .post({
                    url: '/authorization/token',
                    data: postData,
                    headers
                })
                .then(({ data }) => {
                    const { access_token: authorizationToken } = data;

                    if (albumId) {
                        sessionStorage.setItem(getAlbumStorageKey(albumId), authorizationToken);
                    } else {
                        sessionStorage.setItem(getEventStorageKey(eventId), authorizationToken);
                    }

                    broadcastAuthorizationToken(authorizationToken, {
                        isEventToken: !albumId
                    });
                })
                .catch((response) => {
                    if (response.status === 401 || response.status === 403) {
                        $rootScope.$broadcast(
                            AUTHORIZATION_TOKEN_EVENTS.ERRORS.UNAUTHORIZED,
                            response
                        );
                    }
                });
        }

        function getAlbumStorageKey(albumId) {
            return `${BASE_STORAGE_KEY}Album-${albumId}`;
        }

        function getEventStorageKey(eventId) {
            return `${BASE_STORAGE_KEY}Event-${eventId}`;
        }
    }
];
