import BraintreeWebClient from 'braintree-web/client';
import BraintreeWebDataCollector from 'braintree-web/data-collector';
import Braintree3DSecure from 'braintree-web/three-d-secure';
import 'core-js/stable';
import moment from 'moment';
import 'moment-timezone';
import { react2angular } from 'react2angular';
import 'regenerator-runtime/runtime';
import {
    BraintreeChallenge,
    InfoIcon,
    P,
    withAngularJsTranslate,
    withCommon,
    withStripe
} from 'sp-ui';
import { ExpandableHtml, LoadingIndicator, PaymentRequestButton } from 'ts/common/components';

window.moment = moment;

import Sp from './lib/Sp';

if (window.Sp) {
    for (let property in window.Sp) {
        if (window.Sp[property]) {
            Sp[property] = window.Sp[property];
        }
    }
}

window.Sp = Sp;

import angularRouteModule from 'angular-route';
import 'angular-truncate-2';
import spTranslateModule from './sp.translate';

const spCommonModule = angular
    .module('sp.common', [angularRouteModule, 'truncate', spTranslateModule.name])
    .config([
        '$compileProvider',
        'spAppDataProvider',
        'translateFilterProvider',
        function configureStripePaymentRequestButton(
            $compileProvider,
            spAppData,
            translateFilterProvider
        ) {
            // Defer until after config phase
            setTimeout(() => {
                const stripePublicKey = spAppData.get('stripePublicKey');
                const translateFilter = translateFilterProvider.$get();

                if (stripePublicKey) {
                    $compileProvider.component(
                        'paymentRequestButton',
                        react2angular(
                            withCommon(withStripe(PaymentRequestButton, stripePublicKey), {
                                translateService: translateFilter
                            }),
                            [
                                'amount',
                                'description',
                                'onLoaded',
                                'onPaymentMethodCaptureFailed',
                                'onPaymentMethodCaptured',
                                'onPaymentMethod',
                                'onShippingAddressChange',
                                'onShippingOptionChange',
                                'requestShipping',
                                'shippingOptions',
                                'theme',
                                'type'
                            ]
                        )
                    );
                }
            });
        }
    ]);

spCommonModule.constant('braintreeWeb', {
    authorization: null,
    client: BraintreeWebClient,
    threeDSecure: Braintree3DSecure,
    dataCollector: BraintreeWebDataCollector
});
spCommonModule.constant('spSvg:config', {});

import pageTemplateUrlProvider from './providers/pageTemplateUrl';
import spApiHttpProvider from './providers/spApiHttp';
import spBraintreeDeviceDataProvider from './providers/spBraintreeDeviceData';

spCommonModule.provider('pageTemplateUrl', pageTemplateUrlProvider);
spCommonModule.provider('spApiHttp', spApiHttpProvider);
spCommonModule.provider('spBraintreeDeviceData', spBraintreeDeviceDataProvider);

import getDirectiveTemplateUrl$rootScopeDecorator from './decorators/$rootScope/getDirectiveTemplateUrl';
import getImageUrl$rootScopeDecorator from './decorators/$rootScope/getImageUrl';
import getPageId$rootScopeDecorator from './decorators/$rootScope/getPageId';
import getPartialTemplateUrl$rootScopeDecorator from './decorators/$rootScope/getPartialTemplateUrl';
import once$rootScopeDecorator from './decorators/$rootScope/once';
import showSupportPhone$rootScopeDecorator from './decorators/$rootScope/showSupportPhone';
import spModal$rootScopeDecorator from './decorators/$rootScope/spModal';
import trustAsHtml$rootScopeDecorator from './decorators/$rootScope/trustAsHtml';

spCommonModule.config(getDirectiveTemplateUrl$rootScopeDecorator);
spCommonModule.config(getImageUrl$rootScopeDecorator);
spCommonModule.config(getPageId$rootScopeDecorator);
spCommonModule.config(getPartialTemplateUrl$rootScopeDecorator);
spCommonModule.config(once$rootScopeDecorator);
spCommonModule.config(showSupportPhone$rootScopeDecorator);
spCommonModule.config(spModal$rootScopeDecorator);
spCommonModule.config(trustAsHtml$rootScopeDecorator);
spCommonModule.config([
    '$routeProvider',
    ($routeProvider) => {
        $routeProvider.eagerInstantiationEnabled(false);
    }
]);

import ContractSignatureDataStore from './data_stores/ContractSignature';
import SignatureDataStore from './data_stores/Signature';
import SuggestionDataStore from './data_stores/Suggestion';

spCommonModule.run(ContractSignatureDataStore);
spCommonModule.run(SignatureDataStore);
spCommonModule.run(SuggestionDataStore);

import spContractService from './services/spContract';
import spEventBusService from './services/spEventBus.js';
import spApiHypermediaService from './services/spApiHypermedia';
import spBraintree3DSecureService from './services/spBraintree3DSecure';
import spFocusService from './services/spFocus';
import spInvoicePaymentService from './services/spInvoicePayment';
import spMenuRegistryService from './services/spMenuRegistry';
import spModalService from './services/spModal';
import spPackageService from './services/spPackage';
import spScrollToElementService from './services/spScrollToElement';
import spSlideRegistryService from './services/spSlideRegistry';

spCommonModule.factory('spContract', spContractService);
spCommonModule.factory('spEventBus', spEventBusService);
spCommonModule.factory('spApiHypermedia', spApiHypermediaService);
spCommonModule.factory('spBraintree3DSecure', spBraintree3DSecureService);
spCommonModule.factory('spFocus', spFocusService);
spCommonModule.factory('spInvoicePayment', spInvoicePaymentService);
spCommonModule.factory('spMenuRegistry', spMenuRegistryService);
spCommonModule.factory('spModal', spModalService);
spCommonModule.factory('spPackage', spPackageService);
spCommonModule.factory('spScrollToElement', spScrollToElementService);
spCommonModule.factory('spSlideRegistry', spSlideRegistryService);

import spBraintreeChallengeFlowComponent from './components/spBraintreeChallengeFlow';
import spFormSelectComponent from './components/spFormSelect';
import spPageMarkerComponent from './components/spPageMarker';
import spRadioButtonComponent from './components/spRadioButton';
import spRadioButtonGroupComponent from './components/spRadioButtonGroup';
import spSignatureComponent from './components/spSignature';
import spToggleFormElementComponent from './components/spToggleFormElement';

spCommonModule.component(
    'loadingIndicator',
    react2angular(withCommon(LoadingIndicator), ['squareClassName'])
);
spCommonModule.component('spBraintreeChallengeFlow', spBraintreeChallengeFlowComponent);
spCommonModule.component('spFormSelect', spFormSelectComponent);
spCommonModule.component('spPageMarker', spPageMarkerComponent);
spCommonModule.component('spRadioButton', spRadioButtonComponent);
spCommonModule.component('spRadioButtonGroup', spRadioButtonGroupComponent);
spCommonModule.component('spSignature', spSignatureComponent);
spCommonModule.component('spToggleFormElement', spToggleFormElementComponent);
spCommonModule.component(
    'spUiInfoIcon',
    react2angular(withCommon(InfoIcon), ['placement', 'tooltipLabel'])
);
spCommonModule.component(
    'spUiBraintreeChallenge',
    react2angular(withCommon(BraintreeChallenge), [
        'errorMessage',
        'hasError',
        'onReset',
        'openSubscribe',
        'resetText'
    ])
);
spCommonModule.component(
    'spUiExpandableHtml',
    react2angular(
        withCommon(withAngularJsTranslate(ExpandableHtml)),
        ['html', 'maxLineCount'],
        ['translateFilter']
    )
);
spCommonModule.component(
    'spUiP',
    react2angular(
        withCommon(withAngularJsTranslate(P)),
        ['children', 'maxLineCount'],
        ['translateFilter']
    )
);

import ToastController from './controllers/CommonToast';
import HelpController from './controllers/modals/Help';
import SignatureCapture from './controllers/modals/SignatureCapture';

spCommonModule.controller('CommonToast', ToastController);
spCommonModule.controller('Help', HelpController);
spCommonModule.controller('SignatureCapture', SignatureCapture);

import hasRequiredFieldsFilter from './filters/hasRequiredFields';
import spAddressLastLineFilter from './filters/spAddressLastLine';
import spCapitalizeFirstFilter from './filters/spCapitalizeFirst';
import spDateFilter from './filters/spDate';
import spInvoiceDiscountTotalDisplayFilter from './filters/spInvoiceDiscountTotalDisplay';
import spInvoiceInstallmentAmountFilter from './filters/spInvoiceInstallmentAmount';
import spInvoiceRetainerDueLabelFilter from './filters/spInvoiceRetainerDueLabel';
import spInvoiceStatusFilter from './filters/spInvoiceStatus';
import spHtmlEncodeFilter from './filters/spHtmlEncode.js';

spCommonModule.filter('hasRequiredFields', hasRequiredFieldsFilter);
spCommonModule.filter('spAddressLastLine', spAddressLastLineFilter);
spCommonModule.filter('spCapitalizeFirst', spCapitalizeFirstFilter);
spCommonModule.filter('spDate', spDateFilter);
spCommonModule.filter('spInvoiceDiscountTotalDisplay', spInvoiceDiscountTotalDisplayFilter);
spCommonModule.filter('spInvoiceInstallmentAmount', spInvoiceInstallmentAmountFilter);
spCommonModule.filter('spInvoiceRetainerDueLabel', spInvoiceRetainerDueLabelFilter);
spCommonModule.filter('spInvoiceStatus', spInvoiceStatusFilter);
spCommonModule.filter('spHtmlEncode', spHtmlEncodeFilter);

import spBasicModalDirective from './directives/spBasicModal';
import spBoundedScrollDirective from './directives/spBoundedScroll';
import spCarouselDirective from './directives/spCarousel';
import spCharacterCountDirective from './directives/spCharacterCount';
import spContainedScrollDirective from './directives/spContainedScroll';
import spContractSignaturesDirective from './directives/spContractSignatures';
import spCreditCardCvvInputDirective from './directives/spCreditCardCvvInput';
import spCreditCardFormFieldsDirective from './directives/spCreditCardFormFields';
import spCreditCardInputDirective from './directives/spCreditCardInput';
import spDatePickerDirective from './directives/spDatePicker';
import spDropDownListDirective from './directives/spDropDownList';
import spEmptyValueDirective from './directives/spEmptyValue';
import spFieldDirective from './directives/spField';
import spFocusableDirective from './directives/spFocusable';
import spFormElementDirective from './directives/spFormElement';
import spFormInputAddDirective from './directives/spFormInputAdd';
import spFormInputDateDirective from './directives/spFormInputDate';
import spFormInputGroupTextDirective from './directives/spFormInputGroupText';
import spFormInputSearchDirective from './directives/spFormInputSearch';
import spFormSelectDirective from './directives/spFormSelect';
import spHelpDropDownDirective from './directives/spHelpDropDown';
import spIncrementSelectorDirective from './directives/spIncrementSelector';
import spInputAutofill from './directives/spInputAutofill';
import spLightboxVimeoDirective from './directives/spLightboxVimeo';
import spMenuToggleDirective from './directives/spMenuToggle';
import spPopoverDirective from './directives/spPopover';
import spPercentMaskDirective from './directives/spPercentMask';
import spSidePanelDirective from './directives/spSidePanel';
import spSignatureEditableDirective from './directives/spSignatureEditable';
import spStatusBarDirective from './directives/spStatusBar';
import spSvgDirective from './directives/spSvg';
import spSwitchDirective from './directives/spSwitch';
import spTitleBarDirective from './directives/spTitleBar';
import spTransitionalHeightDirective from './directives/spTransitionalHeight';
import spTranslateLinkDirective from './directives/spTranslateLink';
import spTranslateWithLinksDirective from './directives/spTranslateWithLinks';
import spWholeNumberMaskDirective from './directives/spWholeNumberMask';

spCommonModule.directive('spBasicModal', spBasicModalDirective);
spCommonModule.directive('spBoundedScroll', spBoundedScrollDirective);
spCommonModule.directive('spCarousel', spCarouselDirective);
spCommonModule.directive('spCharacterCount', spCharacterCountDirective);
spCommonModule.directive('spContainedScroll', spContainedScrollDirective);
spCommonModule.directive('spContractSignatures', spContractSignaturesDirective);
spCommonModule.directive('spCreditCardCvvInput', spCreditCardCvvInputDirective);
spCommonModule.directive('spCreditCardFormFields', spCreditCardFormFieldsDirective);
spCommonModule.directive('spCreditCardInput', spCreditCardInputDirective);
spCommonModule.directive('spDatePicker', spDatePickerDirective);
spCommonModule.directive('spDropDownList', spDropDownListDirective);
spCommonModule.directive('spEmptyValue', spEmptyValueDirective);
spCommonModule.directive('spField', spFieldDirective);
spCommonModule.directive('spFocusable', spFocusableDirective);
spCommonModule.directive('spFormElement', spFormElementDirective);
spCommonModule.directive('spFormInputAdd', spFormInputAddDirective);
spCommonModule.directive('spFormInputDate', spFormInputDateDirective);
spCommonModule.directive('spFormInputGroupText', spFormInputGroupTextDirective);
spCommonModule.directive('spFormInputSearch', spFormInputSearchDirective);
spCommonModule.directive('spFormSelectDirective', spFormSelectDirective);
spCommonModule.directive('spHelpDropDown', spHelpDropDownDirective);
spCommonModule.directive('spIncrementSelector', spIncrementSelectorDirective);
spCommonModule.directive('spInputAutofill', spInputAutofill);
spCommonModule.directive('spLightboxVimeo', spLightboxVimeoDirective);
spCommonModule.directive('spMenuToggle', spMenuToggleDirective);
spCommonModule.directive('spPopover', spPopoverDirective);
spCommonModule.directive('spPercentMask', spPercentMaskDirective);
spCommonModule.directive('spSidePanel', spSidePanelDirective);
spCommonModule.directive('spSignatureEditable', spSignatureEditableDirective);
spCommonModule.directive('spStatusBar', spStatusBarDirective);
spCommonModule.directive('spSvg', spSvgDirective);
spCommonModule.directive('spSwitch', spSwitchDirective);
spCommonModule.directive('spTitleBar', spTitleBarDirective);
spCommonModule.directive('spTransitionalHeight', spTransitionalHeightDirective);
spCommonModule.directive('spTranslateLink', spTranslateLinkDirective);
spCommonModule.directive('spTranslateWithLinks', spTranslateWithLinksDirective);
spCommonModule.directive('spWholeNumberMask', spWholeNumberMaskDirective);

import spDuplicateValidatorDirective from './directives/validators/spDuplicate';
import spSelectMinValidatorDirective from './directives/validators/spSelectMin';
import spEmailListValidatorDirective from './directives/validators/spEmailList';

spCommonModule.directive('spDuplicate', spDuplicateValidatorDirective);
spCommonModule.directive('spSelectMin', spSelectMinValidatorDirective);
spCommonModule.directive('spEmailList', spEmailListValidatorDirective);

export default spCommonModule;
