export default [
    '$provide',
    function trustAsHtmlDecorator($provide) {
        $provide.decorator('$rootScope', [
            '$delegate',
            '$sce',
            function decorate($delegate, $sce) {
                $delegate.trustAsHtml = function trustAsHtml(html) {
                    return $sce.trustAsHtml(html);
                };

                return $delegate;
            }
        ]);
    }
];
