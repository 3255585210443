/* eslint-disable func-names, no-unused-vars */

export default [
    '$interval',
    '$window',
    function spSafariAutofillDirective($interval, $window) {
        function getElementValue(element) {
            if (element.attr('type') === 'radio') {
                return element.attr('checked');
            }

            return element.val();
        }

        return {
            restrict: 'E',
            link: function spSafariAutofillController($scope, element, $attrs) {
                var domNode = element.get(0);
                var currentValue;

                function updateValue() {
                    currentValue = getElementValue(element);
                }

                domNode.addEventListener('keyup', updateValue);
                domNode.addEventListener('change', updateValue);
                updateValue();

                function checkForViewValueChange(type) {
                    if (currentValue === getElementValue(element)) {
                        return;
                    }

                    updateValue();

                    // Manufacture fake change event
                    var event = $window.document.createEvent('HTMLEvents');

                    event.initEvent('change', true, true);
                    domNode.dispatchEvent(event);
                }

                var intervalPromise = $interval(checkForViewValueChange, 500, 0, false);

                $scope.$on('$destroy', function() {
                    $interval.cancel(intervalPromise);
                });
            }
        };
    }
];
