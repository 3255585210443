/**
 * @ngdoc directive
 * @name sp.common.directive:spEmptyValue
 * @requires ngModel
 * @restrict A
 *
 * @description
 * It prevents an empty string input value by populating a default value on blur
 *
 * @param {string|number=} emptyValue Default "empty" value to populate in the input model after a blur event
 *
 * @example
 <pre>
    <input
        type="text"
        ng-model=" numericValue "
        sp-empty-value=" 0 "/>
</pre>
 */

export default [
    function spEmptyValue() {
        return {
            restrict: 'A',
            require: 'ngModel',
            scope: {
                spEmptyValue: '<?'
            },
            link: function spEmptyValueController($scope, $element, _, ngModelController) {
                $element.on('blur', function onBlurEmptyValueInput() {
                    if (ngModelController.$viewValue.trim() === '') {
                        ngModelController.$setViewValue($scope.spEmptyValue || 0);
                    }

                    ngModelController.$render();
                });
            }
        };
    }
];
