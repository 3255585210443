export default [
    '$rootScope',
    '$route',
    '$templateRequest',
    '$compile',
    '$timeout',
    '$q',
    function SPModalService($rootScope, $route, $templateRequest, $compile, $timeout, $q) {
        var DEFAULT_NODE_CLASS = 'dialog';
        var bodyElement = angular.element('body');
        var service = new SPModal();
        let closingTimeout = null;

        const queue = [];

        $rootScope.SPModal = service;

        return service;

        function SPModal() {
            var self = this;
            var state = {};

            function resetState(options) {
                if (typeof state.node === 'object') {
                    state.node.remove();
                }

                if (typeof state.overlayNode === 'object') {
                    state.overlayNode.remove();
                }

                if (typeof state.removeRouteChangeHook === 'function') {
                    state.removeRouteChangeHook();
                }

                if (typeof state.options === 'object') {
                    if (typeof state.options.scope === 'object') {
                        state.options.scope.$destroy();
                    }
                }

                state = {};

                if (typeof options !== 'object') {
                    options = {};
                }

                state.options = options;
                state.node = false;
                state.overlayNode = false;
                state.removeRouteChangeHook = false;
            }

            resetState();

            function getModalNode() {
                if (state.node) {
                    return state.node;
                }

                var routeControllerName = $route.current.$$route.controller;

                state.overlayNode = angular
                    .element('<sp-modal-overlay />')
                    .on('click', self.close.bind(self))
                    .appendTo(bodyElement);

                state.node = angular
                    .element('<sp-modal />')
                    .attr('id', 'modal' + routeControllerName)
                    .attr('class', state.options.type ? state.options.type : DEFAULT_NODE_CLASS)
                    .appendTo(bodyElement);

                // If you try to navigate away from the current page with a modal open, close
                // the modal automagically
                state.removeRouteChangeHook = $rootScope.$on('$routeChangeStart', function reset() {
                    if (typeof $rootScope.enableBodyScrolling === 'function') {
                        $rootScope.enableBodyScrolling();
                    }

                    resetState();
                });

                return state.node;
            }

            this.isOpen = function isOpen() {
                return state.node !== false;
            };

            this.expand = function expand() {
                if (state.node.hasClass('fullscreen')) {
                    return;
                }

                state.node.addClass('fullscreen');
            };

            this.shrink = function shrink() {
                state.node.removeClass('fullscreen');
            };

            this.open = function open(actionName, options, overrideOpen) {
                $timeout.cancel(closingTimeout);

                if (overrideOpen !== true && this.isOpen()) {
                    if (options.queue === true) {
                        queue.push({ name: actionName, options });
                    }

                    return;
                }

                if (typeof options !== 'object') {
                    options = {};
                }

                if (typeof options.partialDirectory !== 'string') {
                    options.partialDirectory = $route.current.$$route.controller.toLowerCase();
                } else {
                    options.partialDirectory = options.partialDirectory.toLowerCase();
                }

                if (typeof options.modalData !== 'object') {
                    options.modalData = undefined;
                }

                if (typeof $rootScope.disableBodyScrolling === 'function') {
                    $rootScope.disableBodyScrolling();
                }

                resetState(options);
                getModalNode();
                self.navigate(actionName);

                $timeout(function applyNodeClass() {
                    var nodeClass = DEFAULT_NODE_CLASS;

                    if (state.options.type) {
                        nodeClass = state.options.type;
                    }

                    state.node.attr('class', nodeClass + ' open');

                    if (options.fullscreen) {
                        self.expand();
                    }
                });
            };

            this.popQueue = function popQueue() {
                if (queue.length > 0) {
                    const { name, options } = queue.pop();
                    self.open(name, options);
                }
            };

            this.close = function close() {
                var deferredClose = $q.defer();
                var nodeClass = DEFAULT_NODE_CLASS;

                if (!this.isOpen()) {
                    deferredClose.resolve();

                    self.popQueue();

                    return deferredClose.promise;
                }

                if (typeof $rootScope.enableBodyScrolling === 'function') {
                    $rootScope.enableBodyScrolling();
                }

                if (state.options.type) {
                    nodeClass = state.options.type;
                }

                state.node.attr('class', nodeClass);

                closingTimeout = $timeout(function reset() {
                    resetState();
                    deferredClose.resolve();

                    self.popQueue();
                }, 300);

                return deferredClose.promise;
            };

            this.getTemplatePath = function getTemplatePath(actionName) {
                if (typeof state.options.partialDirectory !== 'string') {
                    return;
                }

                return $rootScope.getPartialURL(
                    'modals/' + state.options.partialDirectory + '/' + actionName
                );
            };

            this.navigate = function navigate(actionName) {
                if (!this.isOpen()) {
                    return;
                }

                $templateRequest(self.getTemplatePath(actionName)).then(function withTemplate(
                    template
                ) {
                    if (typeof state.options.scope !== 'object') {
                        state.options.scope = $route.current.scope.$new();

                        if (typeof state.options.modalData === 'object') {
                            angular.extend(state.options.scope, state.options.modalData);
                        }
                    }

                    state.node.empty().append('<div class="dialog-content">' + template + '</div>');

                    $compile(state.node)(state.options.scope);
                });
            };

            this.getScope = function getScope() {
                return state.options.scope;
            };
        }
    }
];
