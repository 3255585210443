/* global setContent */
/* eslint-disable func-names */

export default [
    '$rootScope',
    '$templateRequest',
    '$compile',
    function spContentDirective($rootScope, $templateRequest, $compile) {
        // This accounts for SPMobileApp having to get its partials by
        // template ID (from a <script> tag), and everything else getting
        // partials by full URL.
        // TODO: these values should be passed on the directive, rather
        // than this directive relying on root scope methods.
        var stateTemplateURLs;

        if (typeof $rootScope.getPartialTemplateId === 'function') {
            stateTemplateURLs = {
                loading: $rootScope.getPartialTemplateId('contentstates/loading'),
                empty: $rootScope.getPartialTemplateId('contentstates/empty'),
                error: $rootScope.getPartialTemplateId('contentstates/error')
            };
        } else {
            stateTemplateURLs = {
                loading: $rootScope.getPartialURL('contentstates/loading'),
                empty: $rootScope.getPartialURL('contentstates/empty'),
                error: $rootScope.getPartialURL('contentstates/error')
            };
        }

        // prefetch the loading template
        $templateRequest(stateTemplateURLs['loading'], true);

        return {
            restrict: 'E',
            scope: {
                contentData: '=contentData'
            },
            transclude: true,
            link: function spContentController(
                $scope,
                $element,
                $attributes,
                controller,
                $transclude
            ) {
                var currentState;
                var setStateContent = function setStateContent(stateKey) {
                    currentState = stateKey;

                    var templateUrl = stateTemplateURLs[stateKey];

                    if (typeof templateUrl !== 'string') {
                        return setContent('');
                    }

                    $templateRequest(templateUrl, true).then(function (template) {
                        if (currentState !== stateKey) {
                            return;
                        }

                        var templateElements = angular.element(template);

                        $element.empty().append(templateElements);

                        const pageId = $rootScope.getPageId();
                        $scope.page = pageId.slice(4); // slice off the "page" from the beginning
                        $compile(templateElements)($scope);
                    });
                };

                $scope.$watch('contentData', function () {
                    if ($scope.contentData === undefined || $scope.contentData === false) {
                        setStateContent('loading');

                        return;
                    }

                    if (
                        typeof $scope.contentData === 'object' &&
                        $scope.contentData instanceof Array &&
                        $scope.contentData.length === 0
                    ) {
                        setStateContent('empty');

                        return;
                    }

                    currentState = undefined;

                    // Content is ready and available
                    $transclude(function (content) {
                        $element.empty().append(content);
                    });
                });
            }
        };
    }
];
