/**
 * @ngdoc directive
 * @name sp.common.directive:spDropDownList
 * @requires $rootScope
 * @requires filterFilter
 * @restrict E
 *
 * @description
 * Creates the ShootProof-view of the expanded drop-down list.
 * Items to note:
 * 1. This does not create the main selector element
 * 2. This is *always* used in conjunction with spFormSelect
 * 3. This does not display on mobile views - we default to OS specific
 *
 * @param {string} items List of items to display in the dropdown
 * @param {function} onSelect Invoked when an item is selected
 * @param {function} hideSelectCallback Callback function to hide the containing sp-form-select element
 * @param {boolean=} isDefaultOptionSelectable Determines whether the given default option should be selectable. Defaults to true
 * @param {boolean=} isFilterable Determines whether an input element should be displayed for the user to filter/search items. Defaults to false
 */
export default [
    '$rootScope',
    'filterFilter',
    'spHtmlEncodeFilter',
    function spDropDownListDirective($rootScope, filterFilter, spHtmlEncodeFilter) {
        return {
            restrict: 'E',
            scope: {
                inputId: '<',
                items: '=',
                onSelect: '=',
                hideSelectCallback: '<',
                isDefaultOptionSelectable: '<?',
                isFilterable: '<?'
            },
            templateUrl: $rootScope.getDirectiveTemplateUrl('newcommon', 'sp-drop-down-list'),
            link: function onLink($scope, $element) {
                var dropDownList = $element.find('ul');
                var filteredItems;
                var listItems = [];

                if ($scope.isDefaultOptionSelectable === undefined) {
                    $scope.isDefaultOptionSelectable = true;
                }

                $scope.filter = { input: '' };

                $scope.getFilteredItems = getFilteredItems;

                $scope.highlightLastItem = function highlightLastItem() {
                    var lastVisibleItemIndex = getFilteredItems().length - 1;

                    if ($scope.selectedItemIndex === undefined) {
                        $scope.selectedItemIndex = lastVisibleItemIndex;
                    } else {
                        $scope.selectedItemIndex--;
                    }

                    if ($scope.selectedItemIndex < 0) {
                        $scope.selectedItemIndex = lastVisibleItemIndex;
                    }

                    if (dropDownList[0]) {
                        checkAndAdjustScrollTop();
                    }
                };

                $scope.highlightNextItem = function highlightNextItem() {
                    if ($scope.selectedItemIndex === undefined) {
                        $scope.selectedItemIndex = 0;
                    } else {
                        $scope.selectedItemIndex++;
                    }

                    if ($scope.selectedItemIndex >= getFilteredItems().length) {
                        $scope.selectedItemIndex = 0;
                    }

                    if (dropDownList[0]) {
                        checkAndAdjustScrollTop();
                    }
                };

                $scope.itemSelected = function itemSelected(item) {
                    if (!item) {
                        item = getFilteredItems()[$scope.selectedItemIndex];
                    }

                    $scope.onSelect(item);

                    delete $scope.selectedItemIndex;

                    $scope.filter.input = '';
                };

                $scope.unfocusFormSelect = function unfocusFormSelect() {
                    if ($scope.hideSelectCallback) {
                        $scope.hideSelectCallback();
                    }
                };

                $scope.$watch('items', function onItemsChange() {
                    listItems = setupListItems();
                });

                $element.on('keydown', function onKeyDown($event, which) {
                    var UP = 38;
                    var DOWN = 40;
                    var RETURN = 13;
                    var TAB = 9;

                    switch (which) {
                        case UP:
                            $scope.highlightLastItem();

                            break;
                        case DOWN:
                            $scope.highlightNextItem();

                            break;
                        case RETURN:
                        case TAB:
                            $scope.itemSelected();

                            break;
                        default:
                            delete $scope.selectedItemIndex;

                            break;
                    }

                    $scope.$digest();
                });

                function setupListItems() {
                    return ($scope.items || []).map(function encodeItems(item) {
                        return angular.extend({}, item, { text: spHtmlEncodeFilter(item.text) });
                    });
                }

                function getFilteredItems() {
                    filteredItems = filterFilter(
                        filterFilter(listItems, { text: $scope.filter.input }),
                        filterOutDefaultOption
                    );

                    return filteredItems;

                    function filterOutDefaultOption(item) {
                        if (!$scope.isDefaultOptionSelectable && item.isDefaultOption) {
                            return false;
                        }

                        return true;
                    }
                }

                function checkAndAdjustScrollTop() {
                    var dropDownListHeight = dropDownList[0].clientHeight;
                    var dropDownListItems = dropDownList.children('li');
                    var dropDownListItemsHeight = 0;
                    var dropDownListItemsHeightUntilAfterSelected;
                    var dropDownListScrollTopRequired;

                    for (var i = 0; i < dropDownListItems.length; i++) {
                        var dropDownListItem = dropDownListItems[i];

                        dropDownListItemsHeight += dropDownListItem.clientHeight;

                        if ($scope.selectedItemIndex === i) {
                            dropDownListItemsHeightUntilAfterSelected = dropDownListItemsHeight;
                        }
                    }

                    dropDownListScrollTopRequired =
                        dropDownListItemsHeightUntilAfterSelected - dropDownListHeight;

                    dropDownList[0].scrollTop = Math.max(dropDownListScrollTopRequired, 0);
                }
            }
        };
    }
];
