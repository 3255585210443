/**
 * @ngdoc directive
 * @name sp.common.directive:spTransitionalHeight
 * @restrict A
 * @requires $timeout
 * @requires $window
 *
 * @description
 * Transitions smoothly between changes in the element's height.
 */
export default [
    '$timeout',
    '$window',
    function spTransitionalHeightDirective($timeout, $window) {
        return {
            restrict: 'A',
            link: function spTransitionalHeightController($scope, $element) {
                const HEIGHT_TRANSITION_TIME_IN_MS = 250;
                let lastContentHeight;
                let maybeUnsetElementHeight;
                let maybeSetLastContentHeight;

                $scope.$watch(
                    function getContentHeight() {
                        return Array.prototype.slice
                            .call($element.children())
                            .reduce(function calculateTotalChildClientHeight(
                                totalClientHeight,
                                child
                            ) {
                                const style = $window.getComputedStyle(child);

                                if (style.position === 'absolute') {
                                    return totalClientHeight;
                                }

                                return totalClientHeight + child.clientHeight;
                            },
                            2);
                    },
                    function withContentHeight(contentHeight) {
                        if (contentHeight > $window.innerHeight) {
                            return;
                        }

                        if (contentHeight < lastContentHeight) {
                            $scope.$applyAsync(shrink);
                        } else {
                            $scope.$applyAsync(grow);
                        }

                        updateHeight();

                        function shrink() {
                            $element.css('height', lastContentHeight);

                            if (maybeUnsetElementHeight) {
                                $timeout.cancel(maybeUnsetElementHeight);
                            }

                            maybeUnsetElementHeight = $timeout(function unsetElementHeight() {
                                $element.css('height', 'auto');

                                maybeUnsetElementHeight = undefined;
                            }, HEIGHT_TRANSITION_TIME_IN_MS);
                        }

                        function grow() {
                            $element.css('height', 'auto');

                            if (maybeUnsetElementHeight) {
                                $timeout.cancel(maybeUnsetElementHeight);
                            }
                        }

                        function updateHeight() {
                            $element.css('max-height', contentHeight);

                            if (maybeSetLastContentHeight) {
                                $timeout.cancel(maybeSetLastContentHeight);
                            }

                            maybeSetLastContentHeight = $timeout(function setLastContentHeight() {
                                lastContentHeight = contentHeight;

                                maybeSetLastContentHeight = undefined;
                            });
                        }
                    }
                );
            }
        };
    }
];
