import React, { useState } from 'react';
import {
    Box,
    Button,
    Column,
    Flex,
    Form,
    FormElement,
    FormElementVariant,
    H1,
    LabelError,
    P,
    Row,
    styled,
    yup
} from 'sp-ui';
import { useBrandTheme } from './BrandThemeContext';
import Footer from './Footer';
import Header from './Header';
import { IAppDataBrandData } from './types';

interface IContactFormValues {
    contactFirstName: string;
    contactLastName: string;
    contactEmail: string;
    message: string;
}

interface IContactPageProps {
    $location: SpAngularJs.ILocation;
    $route: SpAngularJs.IRoute;
    spAppData: SpAngularJs.ISpAppData;
    spApiHttp: SpAngularJs.ISpApiHttp;
    translateFilter: SpAngularJs.ITranslateFilter;
    initialFormValues?: IContactFormValues;
}

interface ISpApiError {
    data: { info: { errors: [string, string][] } };
}

const FormContainer = styled(Row)(
    ({ theme }) => `
        width: 100%;
        padding: 0 24px;

        label:first-of-type {
            font-family; 'OpenSans Regular';
            color: #919191;
        }

        ${theme.responsive.mediumScreenUp`
            max-width: 592px;
            padding: 0;
        `}
    `
);
const StyledCount = styled(LabelError, {
    shouldForwardProp: (prop) => prop !== 'isError'
})<{ isError: boolean }>(
    ({ isError }) => `
        position: absolute;
        bottom: ${isError ? '0' : '-27px'};;
        right: -8px;
    `
);
const StyledFormElement = styled(FormElement, {
    shouldForwardProp: (prop) => prop !== 'isDarkTheme'
})<{ isDarkTheme: boolean }>(
    ({ isDarkTheme }) => `
        background: ${isDarkTheme ? '#242424' : '#FFFFFF'};
        color: ${isDarkTheme ? '#FFFFFF' : '#7F7F7F'};
    `
);
const StyledTextArea = styled(FormElement, {
    shouldForwardProp: (prop) => prop !== 'isDarkTheme'
})<{ isDarkTheme: boolean }>(
    ({ isDarkTheme, theme }) => `
        border: 1px solid ${theme.colors.gray[200]};
        background: ${isDarkTheme ? '#242424' : '#FFFFFF'};
        color: ${isDarkTheme ? '#FFFFFF' : '#7F7F7F'};
        box-shadow: none !important;

        &:hover {
            border-color: ${theme.colors.gray[400]} !important;
        }
    `
);
const StyledSubmitButton = styled(Button, {
    shouldForwardProp: (prop: string) => !['color', 'hoverColor', 'isDarkTheme'].includes(prop)
})<{ color: string; hoverColor: string; isDarkTheme: boolean }>(
    ({ color, hoverColor, isDarkTheme }) => `
        background: #${color} !important;
        color: ${isDarkTheme ? '#242424' : '#FFFFFF'};
        margin-top: 28px;
        margin-bottom: 28px;
        min-width: 180px;

        &:active {
            background: #${hoverColor};
        }
        &:hover  {
            background: #${hoverColor} !important;
        }
    `
);
const MediumScreenUpRow = styled(Row)(
    ({ theme }) => theme.responsive.smallScreenOnly`
        display: none;
    `
);
const SmallScreenOnlyRow = styled(Row)(
    ({ theme }) => theme.responsive.mediumScreenUp`
        display: none;
    `
);
const ContactPageContainer = styled(Box)(
    ({ theme }) => `
        width: 100%;
        height: 100%;
        padding-top: 49px;
        display: flex;
        flex-direction: column;
        min-height: 100vh;

        p {
            text-align: center;
            font-family; 'OpenSans Regular';
            color: #a5a5a5;
        }

        ${theme.responsive.smallScreenOnly`
            padding-top: 21px;
        `}
    `
);
const StyledHeading = styled(H1)<{ color: string }>(
    ({ color }) => `
        color: #${color};
        text-align: center;
        font-size: 28px;
        letter-spacing: 3px;
        font-weight: 700;
        line-height: 36px;
        padding: 48px 0;
        text-transform: uppercase;
    `
);
const StyledPageContent = styled(Box)`
    width: 100%;
    height: 100%;
    flex: 1;
`;

const ContactPage: React.FC<IContactPageProps> = ({
    $location,
    $route,
    spApiHttp,
    spAppData,
    translateFilter,
    initialFormValues = {
        contactFirstName: '',
        contactLastName: '',
        contactEmail: '',
        message: ''
    }
}) => {
    const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
    const { brandTheme } = useBrandTheme();
    const { backgroundOptionId, primaryColor, primaryColorHover } = brandTheme;
    const brandData = spAppData.get<IAppDataBrandData>('brandData');
    const isDarkTheme = backgroundOptionId === 2;

    const onFormSubmit = async (formValues, formHelpers) => {
        const { contactEmail, contactFirstName, contactLastName, message } = formValues;

        try {
            await spApiHttp.post({
                url: `/brand/${brandData.id}/email`,
                data: {
                    type: 'email',
                    contactFirstName,
                    contactLastName,
                    contactEmail,
                    message
                }
            });

            return setIsFormSubmitted(true);
        } catch (error) {
            const errors = (error as ISpApiError).data.info.errors;

            if (errors) {
                const mappedErrors = Object.entries(errors).map(([field, error]) => {
                    if (typeof error === 'object' && error !== null) {
                        const errorMessage = Object.values(error);

                        return [field, errorMessage];
                    }

                    return [field, ''];
                });
                const errorsObject = Object.fromEntries(mappedErrors);

                return formHelpers.setErrors(errorsObject);
            }
        }
    };

    const validationSchema = yup.object().shape({
        contactFirstName: yup.string().required(translateFilter('contactFormRequiredMessage')),
        contactLastName: yup.string().required(translateFilter('contactFormRequiredMessage')),
        contactEmail: yup
            .string()
            .email(translateFilter('contactFormValidEmailMessage'))
            .required(translateFilter('contactFormRequiredMessage')),
        message: yup.string().required(translateFilter('contactFormRequiredMessage'))
    });

    return (
        <ContactPageContainer>
            <Header
                $location={$location}
                $route={$route}
                translateFilter={translateFilter}
                spAppData={spAppData}
            />
            <StyledPageContent>
                <StyledHeading data-testid="brand-contact-page-heading" color={primaryColor}>
                    {translateFilter('contactHeader')}
                </StyledHeading>
                {!isFormSubmitted ? (
                    <FormContainer>
                        <Form<IContactFormValues>
                            initialValues={initialFormValues}
                            onSubmit={onFormSubmit}
                            validationSchema={validationSchema}
                        >
                            {({ isSubmitting, values, touched, errors }) => (
                                <>
                                    <SmallScreenOnlyRow>
                                        <StyledFormElement
                                            data-testid="brand-contact-first-name"
                                            label={translateFilter('contactFormFirstName')}
                                            isDarkTheme={isDarkTheme}
                                            isRequired
                                            name="contactFirstName"
                                            showValidationIcons={false}
                                        />
                                    </SmallScreenOnlyRow>
                                    <SmallScreenOnlyRow>
                                        <StyledFormElement
                                            data-testid="brand-contact-last-name"
                                            label={translateFilter('contactFormLastName')}
                                            isDarkTheme={isDarkTheme}
                                            isRequired
                                            name="contactLastName"
                                            showValidationIcons={false}
                                        />
                                    </SmallScreenOnlyRow>
                                    <MediumScreenUpRow>
                                        <Column size={6}>
                                            <StyledFormElement
                                                data-testid="brand-contact-first-name-large"
                                                label={translateFilter('contactFormFirstName')}
                                                isDarkTheme={isDarkTheme}
                                                isRequired
                                                name="contactFirstName"
                                                showValidationIcons={false}
                                            />
                                        </Column>
                                        <Column size={6}>
                                            <StyledFormElement
                                                data-testid="brand-contact-last-name-large"
                                                label={translateFilter('contactFormLastName')}
                                                isDarkTheme={isDarkTheme}
                                                isRequired
                                                name="contactLastName"
                                                showValidationIcons={false}
                                            />
                                        </Column>
                                    </MediumScreenUpRow>
                                    <Row>
                                        <StyledFormElement
                                            data-testid="brand-contact-email"
                                            label={translateFilter('contactFormEmail')}
                                            isDarkTheme={isDarkTheme}
                                            isRequired
                                            name="contactEmail"
                                            showValidationIcons={false}
                                        />
                                    </Row>
                                    <Row>
                                        <StyledTextArea
                                            data-testid="brand-contact-message"
                                            label={translateFilter('contactFormMessage')}
                                            isDarkTheme={isDarkTheme}
                                            isRequired
                                            maxLength={2000}
                                            name="message"
                                            resize="vertical"
                                            variant={FormElementVariant.TextArea}
                                        />
                                        <StyledCount
                                            isError={!!touched.message && !!errors.message}
                                        >
                                            {values.message.length}/2000
                                        </StyledCount>
                                    </Row>
                                    <Flex width="100%" alignItems="center" justifyContent="center">
                                        <StyledSubmitButton
                                            color={primaryColor}
                                            hoverColor={primaryColorHover}
                                            isDarkTheme={isDarkTheme}
                                            isDisabled={isSubmitting}
                                        >
                                            {translateFilter('contactSubmitButton')}
                                        </StyledSubmitButton>
                                    </Flex>
                                </>
                            )}
                        </Form>
                    </FormContainer>
                ) : (
                    <P data-testid="brand-contact-form-thank-you-message">
                        {translateFilter('contactThankYouMessage')}
                    </P>
                )}
            </StyledPageContent>
            <Footer spAppData={spAppData} />
        </ContactPageContainer>
    );
};

export default ContactPage;
