/**
 * @ngdoc service
 * @name sp.common.spMenuRegistry
 * @requires $document
 *
 * @description
 * Holds a registry of context menus which allows hiding all context menus
 */
export default [
    '$document',
    function spMenuRegistryService($document) {
        var registry = [];
        var $body = $document.find('body');

        $body.on('click', hideAll);

        return {
            register: register,
            deregister: deregister,
            hideAll: hideAll
        };

        /**
         * @ngdoc function
         * @name sp.common.spMenuRegistry#register
         * @methodOf sp.common.spMenuRegistry
         *
         * @description
         * Adds this scope to the registry.
         *
         * @param {object} scope Scope to add to the registry
         */
        function register(scope) {
            registry.push(scope);
        }

        /**
         * @ngdoc function
         * @name sp.common.spMenuRegistry#deregister
         * @methodOf sp.common.spMenuRegistry
         *
         * @description
         * Removes this scope from the registry.
         *
         * @param {object} scope Scope to remove from the registry
         */
        function deregister(scopeToDeregister) {
            registry = registry.filter(function filterRegistry(scope) {
                return scope.$id !== scopeToDeregister.$id;
            });
        }

        /**
         * @ngdoc function
         * @name sp.common.spMenuRegistry#hideAll
         * @methodOf sp.common.spMenuRegistry
         *
         * @description
         * Hides all of the menus in the registry.
         */
        function hideAll() {
            angular.forEach(registry, function forEachRegistryEntry(entry) {
                entry.hideMenu();
            });
        }
    }
];
