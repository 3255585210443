/**
 * @ngdoc directive
 * @name sp.common.directive:spLightboxVimeo
 * @requires $rootScope
 * @requires spModal
 * @restrict E
 *
 * @description
 * Creates a modal lightbox for Vimeo videos to sit inside.
 *
 * @param {string} name Name of modal
 * @param {string} vimeoId ID of the Vimeo video
 */
export default [
    '$rootScope',
    'spModal',
    function spLightboxVimeoDirective($rootScope, spModal) {
        return {
            restrict: 'E',
            scope: {
                name: '=',
                vimeoId: '@'
            },
            templateUrl: $rootScope.getDirectiveTemplateUrl('newcommon', 'sp-lightbox-vimeo'),
            transclude: true,
            link: function onLink($scope) {
                $scope.isOpen = false;
                $scope.vimeoUrl = 'https://player.vimeo.com/video/' + $scope.vimeoId;

                spModal.register($scope);

                $scope.close = function close() {
                    $scope.isOpen = false;
                };
            }
        };
    }
];
