import Invoice from 'Sp/Invoice';

/**
 * @ngdoc service
 * @name sp.common.service:spInvoicePayment
 * @requires currencyFilter
 * @requires translateFilter
 * @requires sp.common.filter:spDate
 *
 * @description
 * Common functionality regarding payments for an invoice.
 */
export default [
    '$filter',
    function spInvoicePaymentService($filter) {
        const currencyFilter = $filter('currency');
        const translateFilter = $filter('translate');
        const spDateFilter = $filter('spDate');

        return {
            /**
             * @ngdoc method
             * @name getSuggestions
             * @methodOf sp.common.service:spInvoicePayment
             *
             * @description
             * Returns an array of payment suggestions for a given invoice
             *
             * @param {object} invoice An invoice
             *
             * @returns {array<object>} The payment suggestions
             */
            getSuggestions: function getSuggestions(invoice) {
                const overdueBalance = Invoice.getOverdueBalance(invoice);
                const installmentForCurrentPayPeriod = Invoice.getInstallmentForCurrentPayPeriod(
                    invoice
                );

                return [
                    getOverdueSuggestion(),
                    getCurrentPayPeriodSuggestion(),
                    getRemainingBalanceSuggestion()
                ].filter((isPresent) => isPresent);

                function getOverdueSuggestion() {
                    if (overdueBalance > 0) {
                        return getSuggestion(
                            'common.invoicePayment.suggestion.overdue',
                            overdueBalance
                        );
                    }
                }

                function getCurrentPayPeriodSuggestion() {
                    if (
                        installmentForCurrentPayPeriod &&
                        installmentForCurrentPayPeriod.invoiceInstallmentStatus !==
                            Invoice.INSTALLMENT_STATUS.PAID_IN_FULL
                    ) {
                        return getSuggestion(
                            'common.invoicePayment.suggestion.currentPayPeriod',
                            Invoice.getAmountDueForInstallment(
                                invoice,
                                installmentForCurrentPayPeriod
                            ) + overdueBalance,
                            installmentForCurrentPayPeriod.dueDate
                        );
                    }
                }

                function getRemainingBalanceSuggestion() {
                    const onLastInstallment =
                        invoice.installments.indexOf(installmentForCurrentPayPeriod) ===
                        invoice.installments.length - 1;

                    if (invoice.grandTotal !== invoice.paidTotal && !onLastInstallment) {
                        return getSuggestion(
                            'common.invoicePayment.suggestion.balance',
                            invoice.grandTotal - invoice.paidTotal
                        );
                    }
                }

                function getSuggestion(text, amount, dueDate) {
                    return {
                        name: translateFilter(text, {
                            amount: currencyFilter(amount, invoice.currencySymbol),
                            dueDate: spDateFilter(dueDate)
                        }),
                        amount: amount
                    };
                }
            }
        };
    }
];
