/* eslint-disable func-names, padding-line-between-statements */

export default [
    '$timeout',
    '$window',
    function spScrollFixedTopDirective($timeout, $window) {
        var windowElement = angular.element($window);

        return {
            restrict: 'E',
            transclude: true,
            template:
                '<sp-scroll-fixed-top-spacer></sp-scroll-fixed-top-spacer>' +
                '<sp-scroll-fixed-top-content></sp-scroll-fixed-top-content>',
            link: function spScrollFixedTopController(
                $scope,
                element,
                $attributes,
                controller,
                $transclude
            ) {
                var spacerNode = element.children('sp-scroll-fixed-top-spacer');
                var contentNode = element.children('sp-scroll-fixed-top-content');

                $transclude(function (content) {
                    contentNode.append(content);
                });

                var setFixed = (function () {
                    var isFixed = false;

                    return function setFixed(newValue) {
                        // Sanitize input
                        newValue = newValue === true;

                        if (isFixed === newValue) {
                            return;
                        }

                        isFixed = newValue;

                        if (newValue) {
                            element.addClass('fixed');
                        } else {
                            element.removeClass('fixed');
                        }
                    };
                })();

                var topOffset;

                var onScroll = function onScroll() {
                    if (typeof topOffset !== 'number') {
                        return;
                    }

                    setFixed(windowElement.scrollTop() >= topOffset);
                };

                windowElement.on('scroll', onScroll);

                $scope.$on('$destroy', function () {
                    windowElement.off('scroll', onScroll);
                });

                var onResize = function onResize() {
                    topOffset = element.offset().top;

                    /**
                     * Lock the spacer node height to the height of the content, When content
                     * becomes fixed the spacer node maintains spacing so content does not jump
                     */
                    spacerNode.height(contentNode.height());
                };

                windowElement.on('resize', onResize);

                $scope.$on('$destroy', function () {
                    windowElement.off('resize', onResize);
                });

                $timeout(function () {
                    onResize();
                    onScroll();
                }, 500);
            }
        };
    }
];
