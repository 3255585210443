import spDataConsentTemplate from '../../templates/components/sp-data-consent.nghtml';

/**
 * @ngdoc directive
 * @name sp.client.gallery.directive:spDataConsent
 * @restrict E
 *
 * @description
 * Renders a checkbox for data terms and privacy policy acceptance to accompany personal data submission
 *
 * @param {boolean} spDataConsentDoesAcceptTerms A boolean respresentation of granted consent by form submitter
 * @param {string} spDataConsentErrorMessage If provided, the error message that will be displayed
 * @param {boolean} spDataConsentRequire If truthy, the checkbox will be displayed even if otherwise not required
 * @param {string} spDataConsentInputName If provided, the checkbox input field name that will be used. Otherwise, defaulted to `isDataProcessingAndStorageConsentGiven`
 * @param {expression=} onChange Is called with `doesAcceptTerms` when accepting or unaccepting GDPR
 */
export default {
    template: spDataConsentTemplate,
    bindings: {
        doesAcceptTerms: '=spDataConsentDoesAcceptTerms',
        errorMessage: '=spDataConsentErrorMessage',
        isRequired: '=?spDataConsentRequire',
        customInputName: '@?spDataConsentInputName',
        onChange: '&?'
    },
    controller: [
        'spAppData',
        function spDataConsentController(spAppData) {
            var $ctrl = this;

            $ctrl.isSubjectToGdpr = function isSubjectToGdpr() {
                return spAppData.get('isSubjectToGdpr') || $ctrl.isRequired;
            };

            $ctrl.inputName = function inputName() {
                return $ctrl.customInputName || 'isDataProcessingAndStorageConsentGiven';
            };
        }
    ]
};
