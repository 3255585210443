/* eslint-disable func-names */

export default [
    'SPDataObjectsProvider',
    function BoundItemConfig(SPDataObjectsProvider) {
        SPDataObjectsProvider.defineDataObject('BoundItem', function BoundItem(data, photo) {
            this.priceSheetItemId = data.priceSheetItemId;
            this.priceSheetItem = data.priceSheetItem;
            this.parentPriceSheetItemId = data.parentCartItemId;
            this.templateId = data.whcc_id;
            this.whccProductId = data.whcc_product_id;
            this.cartItemId = data.cartItemId;
            this.albumId = data.albumId;
            this.name = data.name;
            this.boundsName = data.boundsName;
            this.cartDisplayName = data.cartDisplayName;
            this.description = data.description;
            this.descriptionText = data.description_text;
            this.descriptionImages = undefined;
            this.parentPriceSheetItemId = Number(data.parent_price_sheet_item_id);

            const disableRotating = this.priceSheetItem?.group?.groupType === 'ornaments';

            if (typeof data.description_images === 'object') {
                this.descriptionImages = data.description_images.map(function (imageData) {
                    return {
                        thumbnail: imageData.t,
                        large: imageData.l
                    };
                });
            }

            this.usage = {
                individual: function individual() {
                    return data.is_individual === true;
                },
                album: function album() {
                    return data.is_album === true;
                },
                all: function all() {
                    return data.is_event === true;
                }
            };
            this.isDigitalDownload = data.isDigitalDownload;
            this.price = Number(data.price);

            // if this is a card product, multiply the price by 25 units
            if (data.whcc_product_id) {
                this.price *= 25;
            }

            this.discountedPrice = Number(data.discountedPrice);
            this.unitPrice = Number(data.unitPrice);
            this.quantity = data.quantity ? Number(data.quantity) : 0;
            this.inCart = typeof this.cartItemId !== undefined && this.quantity >= 1;
            this.cartQuantity = data.quantity ? Number(data.quantity) : 0;
            this.clientComment = data.clientComment || null;
            this.cartClientComment = data.clientComment || null;
            this.clientCommentSaved = undefined;
            this.whccEditorId = data.whccEditorId || null;
            this.whccEditorProductImages = data.whccEditorProductImages || {};
            this.productMasks = data.productMasks || { clipImageUrl: '', overImageUrl: '' };
            this.frameProperties = data.frameProperties ?? null;

            this.isSelfFulfilled = Boolean(data.isSelfFulfilled);
            this.labCatalogProductId = data.labCatalogProductId ?? null;
            this.labName = data.labName ?? null;

            this.__initCrop = function __initCrop(SPPhoto) {
                if (typeof photo !== 'object') {
                    return;
                }

                if (typeof data.boundsData === 'object') {
                    if (typeof data.cropData === 'object') {
                        this.crop = SPPhoto.getCrop(
                            photo,
                            data.boundsData,
                            data.cropData,
                            data.boundsName
                        );
                    } else {
                        this.resetCrop(SPPhoto);
                    }
                }
            };

            this.resetCrop = function resetCrop(SPPhoto) {
                if (typeof photo !== 'object') {
                    return;
                }

                if (typeof data.boundsData === 'object') {
                    this.crop = SPPhoto.getMaxCrop(
                        photo,
                        data.boundsData,
                        data.boundsName,
                        disableRotating
                    );

                    return this.crop;
                }
            };
        });
    }
];
