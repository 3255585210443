import Api from 'Sp/Api';

/**
 * @ngdoc service
 * @name sp.client.common.service:spCartAdapter
 *
 * @description
 * Responsible for serializing the item/package state to and from the V2
 * cart API specification
 */
export default [
    'SPDataObjects',
    function spCartAdapterService(SPDataObjects) {
        const service = this;

        service.serializeCartData = serializeCartData;

        /**
         * @ngdoc method
         * @name sp.client.common.service:spCartAdapter#serializeCartData
         * @methodOf sp.client.common.service:spHiddenAlbum
         *
         * @description
         * Serialize the cart data from our client format to the expected format
         * for integrating with the V2 cart API
         *
         * @param {object} cartData Collection of data necessary to populate the
         * API request
         * @param {string} cartData.albumId Photo album identifier for target
         * photo
         * @param {object} cartData.boundItem Item detail for target item
         * @param {boolean} cartData.dataProcessingAndStorageConsent Indicator
         * for whether the user has given GDPR consent
         * @param {string} cartData.email Target user email address
         * @param {photos} cartData.photos Array of photos associated with the
         * item
         * @returns {object} Formatted cart data ready for the target cart
         * endpoint
         */
        function serializeCartData({
            albumId,
            boundItem,
            dataProcessingAndStorageConsent,
            email,
            photos,
            fromFavorites
        }) {
            const { cartItemId, clientComment, crop, priceSheetItemId, quantity, selectionState } =
                boundItem;
            const photo_id = photos && photos.map(({ id }) => id).join(',');
            const crop_data = typeof crop === 'object' && crop.isChanged ? crop.getCsv() : '';
            const photo_filter_id =
                photos && photos.length === 1 && photos[0].filterBlackAndWhite ? 2 : 1;

            if (boundItem.type === Api.PRICE_SHEET_ITEM_PACKAGE_TYPE) {
                const { albumId, id } = boundItem;
                const packageItems = serializeCartPackageItems(selectionState);

                return SPDataObjects.factory('PackageToCart', {
                    photo_id,
                    photo_filter_id,
                    price_sheet_item_id: id,
                    quantity: 1,
                    crop_data,
                    album_id: albumId ? albumId : '',
                    email,
                    dataProcessingAndStorageConsent,
                    packageItems: JSON.stringify(packageItems)
                });
            }

            return {
                cart_item_id: cartItemId || null,
                photo_id,
                photo_filter_id,
                price_sheet_item_id: priceSheetItemId,
                quantity,
                crop_data,
                album_id: albumId ? albumId : '',
                email,
                doesAcceptTerms: dataProcessingAndStorageConsent ? 1 : 0,
                favorites: fromFavorites ? 1 : 0,
                client_comment: clientComment
            };
        }

        /**
         * Iterates through the package items selection content
         */
        function serializeCartPackageItems(selectionState) {
            const itemIdKeys = Object.keys(selectionState);
            const itemPhotos = [];

            for (const itemId of itemIdKeys) {
                for (const itemMetadata of selectionState[itemId]) {
                    const serializedItem = serializeCartPackageMetadata({
                        priceSheetItemId: itemId,
                        ...itemMetadata
                    });

                    itemPhotos.push(serializedItem);
                }
            }

            return itemPhotos;
        }

        /**
         * Serializes a cart package item entry (where entries map 1:1 with each item quantity)
         */
        function serializeCartPackageMetadata({ albumId, cropData, photo, priceSheetItemId }) {
            return {
                album_id: albumId ? albumId : '',
                crop_data: cropData,
                photo_filter_id: photo.filterBlackAndWhite ? 2 : 1,
                photo_id: photo.id,
                price_sheet_item_id: priceSheetItemId,
                quantity: 1
            };
        }
    }
];
