export const VERIFICATION_CODES = {
    AUTHENTICATE_ATTEMPT_SUCCESSFUL: 'authenticate_attempt_successful',
    AUTHENTICATE_ERROR: 'authenticate_error',
    AUTHENTICATE_FAILED: 'authenticate_failed',
    AUTHENTICATE_FAILED_ACS_ERROR: 'authenticate_failed_acs_error',
    AUTHENTICATE_REJECTED: 'authenticate_rejected',
    AUTHENTICATE_FRICTIONLESS_FAILED: 'authenticate_frictionless_failed',
    AUTHENTICATE_SIGNATURE_VERIFICATION_FAILED: 'authenticate_signature_verification_failed',
    AUTHENTICATE_SUCCESSFUL: 'authenticate_successful',
    AUTHENTICATE_SUCCESSFUL_ISSUER_NOT_PARTICIPATING:
        'authenticate_successful_issuer_not_participating',
    AUTHENTICATE_UNABLE_TO_AUTHENTICATE: 'authenticate_unable_to_authenticate',
    AUTHENTICATION_BYPASSED: 'authentication_bypassed',
    AUTHENTICATION_UNAVAILABLE: 'authentication_unavailable',
    CHALLENGE_REQUIRED: 'challenge_required',
    DATA_ONLY_SUCCESSFUL: 'data_only_successful',
    LOOKUP_BYPASSED: 'lookup_bypassed',
    LOOKUP_ENROLLED: 'lookup_enrolled',
    LOOKUP_ERROR: 'lookup_error',
    LOOKUP_FAILED_ACS_ERROR: 'lookup_failed_acs_error',
    LOOKUP_NOT_ENROLLED: 'lookup_not_enrolled',
    UNSUPPORTED_ACCOUNT_TYPE: 'unsupported_account_type',
    UNSUPPORTED_CARD: 'unsupported_card',
    UNSUPPORTED_THREE_D_SECURE_VERSION: 'unsupported_three_d_secure_version'
};

/**
 * Helper function for mapping Braintree verification failure codes to
 * human-friendly error messages
 */
export function getBraintreeVerificationCodeMessage(code) {
    switch (code) {
        case VERIFICATION_CODES.AUTHENTICATE_ERROR:
        case VERIFICATION_CODES.AUTHENTICATE_SIGNATURE_VERIFICATION_FAILED:
            return 'common.braintreeStatusCodes.systemError';
        case VERIFICATION_CODES.AUTHENTICATE_FAILED:
        case VERIFICATION_CODES.CHALLENGE_REQUIRED:
            return 'common.braintreeStatusCodes.3dsError';
        case VERIFICATION_CODES.AUTHENTICATE_UNABLE_TO_AUTHENTICATE:
            return 'common.braintreeStatusCodes.bankSystemError';
        case VERIFICATION_CODES.AUTHENTICATE_REJECTED:
        case VERIFICATION_CODES.AUTHENTICATE_FRICTIONLESS_FAILED:
        case VERIFICATION_CODES.AUTHENTICATE_FAILED_ACS_ERROR:
            return 'common.braintreeStatusCodes.creditCardBankError';
        default:
            return 'common.braintreeStatusCodes.genericError';
    }
}

export default {
    getBraintreeVerificationCodeMessage,
    VERIFICATION_CODES
};
