/**
 * @ngdoc directive
 * @name sp.common.directive:spWholeNumberMask
 * @restrict A
 *
 * @description
 * Only allows whole numbers to be inputted for an <input type="number" />
 */
export default [
    function spWholeNumberMaskDirective() {
        return {
            restrict: 'A',
            link: function onLink($scope, $element, $attrs) {
                if ($attrs.type === 'number') {
                    $element.on('keydown', function preventPeriodsAndCommas($event) {
                        var COMMA = 188;
                        var DECIMAL = 110;
                        var PERIOD = 190;

                        if (
                            $event.which === COMMA ||
                            $event.which === DECIMAL ||
                            $event.which === PERIOD
                        ) {
                            $event.preventDefault();
                        }
                    });
                }
            }
        };
    }
];
