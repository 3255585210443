/**
 * @ngdoc directive
 * @name sp.common.directive:spStatusBar
 * @restrict E
 *
 * @description
 * Div wrapping either an error or important info.  A
 * span is typically put inside containing message text.
 * <pre>
    <sp-status-bar
        severity="'error'">

        <span
            translate="There has been an error.  Please try again.">
        </span>

    </sp-status-bar>
 * </pre>
 *
 * @param {string} severity Severity level, corresponding to color. Defaults to 'info'.
 *                  'info' is yellow
 *                  'error' is red
 *                  'success' is green
 *                  'notice' is blue
 */
export default [
    '$rootScope',
    function spStatusBarDirective($rootScope) {
        return {
            restrict: 'E',
            transclude: true,
            templateUrl: $rootScope.getDirectiveTemplateUrl('newcommon', 'sp-status-bar'),
            scope: {
                severity: '@?'
            },
            link: function spStatusBarController($scope) {
                if (!$scope.severity) {
                    $scope.severity = 'info';
                }
            }
        };
    }
];
