/* eslint-disable func-names */

export default [
    function sp1KPlusFilter() {
        return function(input) {
            if (input > 999) {
                return '1k+';
            }

            return input;
        };
    }
];
