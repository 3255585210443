/* global SPObject */
export default [
    'SPDataObjectsProvider',
    function CartItemConfig(SPDataObjectsProvider) {
        SPDataObjectsProvider.defineDataObject('CartItem', CartItem);
    }
];

function CartItem(data) {
    const self = this;

    if (data.photo) {
        if (typeof data.photo === 'object') {
            self.photo = self.dataObjectFactory('Photo', data.photo);
        }

        self.boundItems = [];

        if (data.boundGroups) {
            const boundGroups = SPObject.convertToArray(data.boundGroups);

            boundGroups.forEach((boundGroup) => {
                boundGroup.items.forEach((boundItem) => {
                    self.boundItems.push(
                        self.dataObjectFactory('BoundItem', boundItem, self.photo)
                    );
                });
            });
        } else {
            self.boundItems.push(self.dataObjectFactory('BoundItem', data, self.photo));
        }

        self.__initCrop = function __initCrop(SPPhoto) {
            self.boundItems.forEach((boundItem) => {
                boundItem.__initCrop(SPPhoto);
            });
        };
    } else {
        self.packageCartItem = self.dataObjectFactory('Package', data);
    }

    this.priceSheetItemId = data.priceSheetItemId;
}
