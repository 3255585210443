import { CAROUSEL_EVENTS } from 'Sp/Angular/Events/Common';

/**
 * @ngdoc directive
 * @name sp.newcommon.directive:spCarousel
 * @restrict E
 * @requires $rootScope
 * @requires $timeout
 * @requires $interval
 * @requires $window
 *
 * @description
 * Controls drag and touch swipe interaction on a horizontal scrollable region.
 *
 * @param {string} spCarouselInitialItemIndex Index of the initially selected carousel item
 * @param {string} spCarouselBackgroundImage URL of background image for the modal
 * @param {array} spCarouselItems Array of carousel items to display
 */
export default [
    '$rootScope',
    '$timeout',
    '$interval',
    '$window',
    function spCarouselDirective($rootScope, $timeout, $interval, $window) {
        return {
            restrict: 'E',
            templateUrl: $rootScope.getDirectiveTemplateUrl('newcommon', 'sp-carousel'),
            scope: {
                spCarouselInitialItemIndex: '=',
                spCarouselBackgroundImage: '=',
                spCarouselItems: '='
            },
            link: function spCarouselController($scope, $element) {
                var $windowElement = angular.element($window);
                var items = [];
                var initX = 0;
                var deltaX = 0;
                var lastX = 0;
                var isDragging = false;
                var offset = 125;
                var currentPosition = $scope.spCarouselInitialItemIndex || 0;
                var photoX = -offset * currentPosition;
                var modalBackground;
                var tween;
                var velocity;

                $scope.onRightArrowClick = function onRightArrowClick() {
                    startTween(currentPosition + 1);
                };

                $scope.onLeftArrowClick = function onLeftArrowClick() {
                    startTween(currentPosition - 1);
                };

                $scope.$on(
                    CAROUSEL_EVENTS.CAROUSEL_POSITION_UPDATED,
                    function onCarouselPositionUpdated(event, position) {
                        currentPosition = position;
                    }
                );

                $windowElement
                    .on('mouseup touchend', tweenToClosestItem)
                    .on('mousemove touchmove', updateX);

                $element
                    .on('mousedown touchstart', function onDragStart($event) {
                        var pageX = $event.pageX || $event.originalEvent.touches[0].pageX;

                        if (!isDragging) {
                            deltaX = 0;
                            initX = pageX;
                            lastX = pageX - photoX;
                        }

                        isDragging = true;
                    })
                    .on('$destroy', function reset() {
                        cancelTween();
                        resetBackgroundImage();

                        $windowElement
                            .off('mouseup touchend', tweenToClosestItem)
                            .off('mousemove touchmove', updateX);

                        function resetBackgroundImage() {
                            angular.element(modalBackground).css({
                                'background-image': 'initial',
                                'background-position': 'initial',
                                'background-size': 'initial'
                            });
                        }
                    });

                $timeout(function afterDigest() {
                    modalBackground = document.getElementsByClassName('sp-basic-modal-content');

                    carouselChangeX(photoX);
                });

                function startTween(goToPosition) {
                    var origin;
                    var negativeSideThreshold;
                    var positiveSideThreshold;

                    if ((goToPosition === undefined) & (deltaX === 0)) {
                        return;
                    }

                    cancelTween();

                    origin = photoX;

                    if (goToPosition !== undefined) {
                        if (goToPosition > currentPosition) {
                            // so you wanna go to the next image?

                            // set threshold from currentPosition
                            positiveSideThreshold = currentPosition * -offset;

                            // to - 1 * offset
                            negativeSideThreshold = positiveSideThreshold - offset;

                            // position inside (less than currentPosition)
                            photoX--;

                            // set direction and velocity
                            deltaX = -1000;
                        } else {
                            // so you wanna go to the last image?

                            // set threshold from currentPosition
                            negativeSideThreshold = currentPosition * -offset;

                            // to + 1 * offset
                            positiveSideThreshold = negativeSideThreshold + offset;

                            // position inside (greater than currentPosition)
                            photoX++;

                            // set direction and velocity
                            deltaX = 1000;
                        }
                    } else {
                        if (photoX > 0) {
                            negativeSideThreshold = photoX - (photoX % offset);
                            positiveSideThreshold = negativeSideThreshold + offset;
                        } else {
                            positiveSideThreshold = photoX - (photoX % offset);
                            negativeSideThreshold = positiveSideThreshold - offset;
                        }
                    }

                    if (negativeSideThreshold < (items.length - 1) * -offset) {
                        positiveSideThreshold = (items.length - 1) * -offset;
                        photoX = items.length * -offset;
                        origin = items.length * -offset;
                    }

                    if (positiveSideThreshold > 0) {
                        negativeSideThreshold = 0;
                        photoX = offset;
                        origin = offset;
                    }

                    if (Math.abs(deltaX) > 100) {
                        velocity = 4;
                    } else {
                        velocity = 2;
                    }

                    tween = $interval(function setTweenInterval() {
                        if (origin > 0) {
                            decrementPhotoX();
                        } else if (origin < (items.length - 1) * -offset) {
                            incrementPhotoX();
                        } else if (deltaX < 0) {
                            decrementPhotoX();
                        } else if (deltaX > 0) {
                            incrementPhotoX();
                        }

                        if (photoX >= positiveSideThreshold) {
                            photoX = positiveSideThreshold;

                            $rootScope.$broadcast(
                                CAROUSEL_EVENTS.CAROUSEL_POSITION_UPDATED,
                                Math.abs(photoX / offset)
                            );
                            cancelTween();
                        }

                        if (photoX <= negativeSideThreshold) {
                            photoX = negativeSideThreshold;

                            $rootScope.$broadcast(
                                CAROUSEL_EVENTS.CAROUSEL_POSITION_UPDATED,
                                Math.abs(photoX / offset)
                            );
                            cancelTween();
                        }

                        checkClosestPosition(photoX);
                        carouselChangeX(photoX);
                    }, 33);

                    function incrementPhotoX() {
                        photoX = photoX + velocity * 3.333;
                    }

                    function decrementPhotoX() {
                        photoX = photoX - velocity * 3.333;
                    }
                }

                function cancelTween() {
                    $interval.cancel(tween);
                }

                function checkClosestPosition(photoX) {
                    var closestPosition;
                    var carouselX = -photoX;
                    var positionPassed = (carouselX - (carouselX % offset)) / offset;

                    if (carouselX % offset > offset / 2) {
                        closestPosition = positionPassed + 1;
                    } else {
                        closestPosition = positionPassed;
                    }

                    if (currentPosition !== closestPosition * offset && inValidRange()) {
                        $rootScope.$broadcast(
                            CAROUSEL_EVENTS.CAROUSEL_POSITION_UPDATED,
                            closestPosition
                        );
                    }

                    function inValidRange() {
                        if (items.length) {
                            return photoX <= 0 && photoX >= (items.length - 1) * -offset;
                        }
                    }
                }

                function carouselChangeX(photoX) {
                    positionCarousel(photoX);
                    updateModalBackground(photoX);
                    updateClasses(photoX);

                    function positionCarousel(desiredPositionX) {
                        items = document.getElementsByClassName('sp-carousel-item');

                        Array.prototype.forEach.call(items, function forEachCarouselItem(
                            item,
                            index
                        ) {
                            var translateX;
                            var photoReferencePointX = desiredPositionX + offset * index;

                            if (photoReferencePointX > 0) {
                                translateX = photoReferencePointX;
                            } else {
                                translateX = photoReferencePointX / 2;
                            }

                            angular.element(item).css({
                                transform:
                                    'translateX(' +
                                    translateX +
                                    'px) scale(' +
                                    scaleCurve(photoReferencePointX) +
                                    ')',
                                opacity: (offset - Math.abs(photoReferencePointX)) / 100
                            });
                        });

                        function scaleCurve(photoX) {
                            if (Math.abs(photoX) > (offset * 2) / 5) {
                                return (
                                    Math.exp(
                                        ((offset * 6) / 5 - Math.abs(photoX)) / ((offset * 6) / 5)
                                    ) /
                                    ((offset * 41) / 2500)
                                );
                            }

                            return (1000 - Math.abs(photoX)) / 1000;
                        }
                    }

                    function updateModalBackground(desiredPositionX) {
                        var backgroundOffset = (-desiredPositionX * 100) / (offset * items.length);

                        angular.element(modalBackground).css({
                            'background-image': 'url(' + $scope.spCarouselBackgroundImage + ')',
                            'background-position': backgroundOffset + '% 0',
                            'background-size': 'cover'
                        });
                    }

                    function updateClasses(desiredPositionX) {
                        var percentagePositionX =
                            (-desiredPositionX * 100) / (offset * (items.length - 1));

                        $element.removeClass('suppress-arrow-left');
                        $element.removeClass('suppress-arrow-right');

                        if (percentagePositionX <= 0) {
                            $element.addClass('suppress-arrow-left');
                        }

                        if (percentagePositionX >= 100) {
                            $element.addClass('suppress-arrow-right');
                        }
                    }
                }

                function tweenToClosestItem($event) {
                    var pageX = $event.pageX || $event.originalEvent.changedTouches[0].pageX;

                    if (isDragging) {
                        deltaX = pageX - initX;
                        initX = 0;
                        lastX = photoX - pageX;

                        startTween();
                    }

                    isDragging = false;
                }

                function updateX($event) {
                    var pageX = $event.pageX || $event.originalEvent.touches[0].pageX;

                    if (isDragging) {
                        cancelTween();

                        photoX = pageX - lastX;

                        checkClosestPosition(photoX);
                        carouselChangeX(photoX);
                    }
                }
            }
        };
    }
];
