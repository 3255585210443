/**
 * @ngdoc directive
 * @name sp.common.directive:spSignatureEditable
 * @restrict A
 *
 * @description
 * Dictates when a signature can be edited and facilitates
 * editing of an SVG via mouse click to dragg and generate lines.
 *
 * @param {string} signature The actual signature object
 * @param {boolean} editable Dictates whether the signature can be edited
 * @param {string} pathProperty Path to the signature SVG
 */
export default [
    function spSignatureEditableDirective() {
        return {
            restrict: 'A',
            scope: {
                signature: '=',
                editable: '=',
                pathProperty: '@'
            },
            link: function spSignatureEditableDirective($scope, $element) {
                if ($element[0].tagName !== 'svg') {
                    return;
                }

                $element
                    .on('mousedown touchstart', down)
                    .on('mousemove touchmove', move)
                    .on('mouseup touchend mouseout', up);

                var isDown;

                function down(event) {
                    updatePath('M' + getCoordinates(event) + ' ');

                    isDown = true;

                    if (isTouchEvent(event)) {
                        event.preventDefault();
                    }
                }

                function move(event) {
                    if (isDown) {
                        updatePath('L' + getCoordinates(event) + ' ');
                    }

                    if (isTouchEvent(event)) {
                        event.preventDefault();
                    }
                }

                function up(event) {
                    isDown = false;

                    if (isTouchEvent(event)) {
                        event.preventDefault();
                    }
                }

                function updatePath(path) {
                    if (!$scope.editable) {
                        return;
                    }

                    $scope.$apply(function updateModel() {
                        $scope.signature[$scope.pathProperty] += path;
                    });
                }

                function getCoordinates(event) {
                    var signatureSvgOffset = $element[0].getBoundingClientRect();
                    var xCoordinate = -signatureSvgOffset.left;
                    var yCoordinate = -signatureSvgOffset.top;

                    if (isTouchEvent(event)) {
                        if (event.originalEvent) {
                            event = event.originalEvent;
                        }

                        xCoordinate += event.targetTouches[0].clientX;
                        yCoordinate += event.targetTouches[0].clientY;
                    } else {
                        xCoordinate += event.clientX;
                        yCoordinate += event.clientY;
                    }

                    return xCoordinate + ',' + yCoordinate;
                }

                function isTouchEvent(event) {
                    return event.type.match(/^touch/);
                }
            }
        };
    }
];
