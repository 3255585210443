/**
 * @ngdoc service
 * @name sp.common.service:spApiHypermedia
 * @requires $q
 * @requires sp.common.service:spApiHttp
 *
 * @description
 * Service to retrieve hypermedia from ShootProof's API.
 */
import uriTemplate from 'uri-template';

export default [
    '$q',
    'spApiHttp',
    function spApiHypermediaService($q, spApiHttp) {
        var rootPromise;
        var subRootPromises = {};

        return {
            /**
             * @ngdoc method
             * @name getAllPages
             * @methodOf sp.common.service:spApiHypermedia
             *
             * @description
             * Helper function for fetching all of the remaining pages for a given paginated resource using the meta data provided
             *
             * @param {function} promiseTarget Represents an operation returning a promise (data store event handler)
             * @param {number} totalPages Total number of pages for a given resource
             * @param {number} currentPage Current page which has already been retrieved for a resource
             * @param {object=} meta Metadata to include for a resource request
             */
            getAllPages: function getAllPages(promiseTarget, totalPages, currentPage, meta = {}) {
                const promises = [];
                let nextPage = currentPage + 1;

                for (let page = nextPage; page <= totalPages; page++) {
                    promises.push(
                        promiseTarget({
                            ...meta,
                            page
                        })
                    );
                }

                if (promises.length) {
                    return $q.all(promises);
                }

                return $q.resolve();
            },
            /**
             * @ngdoc method
             * @name getLink
             * @methodOf sp.common.service:spApiHypermedia
             *
             * @description
             * Fetches an arbitrarily nested link to a resource or collection.
             *
             * @param {array<object>} resources Array of path to the resource object
             * @param {string} resources[].type Resource type key to fetch
             * @param {number=} resources[].id ID to the specific resource being fetched.
             *     Omit this property to return the link to the collection
             * @param {boolean=} ignoreRequestQueue When true, prevents any spApiHttp requests from
             *     this service to not be added to the loading request queue
             *
             * @returns {Promise} Resolves with the link for the requested resource.
             */
            getLink: function getLink(resources, ignoreRequestQueue) {
                var subresources = resources.slice(0, -1);
                var lastResource = resources[resources.length - 1];
                var maybeGetResourceLink = subresources.reduce(function withSubresource(
                    maybeGetParent,
                    subresource
                ) {
                    return maybeGetParent.then(function withParent(parent) {
                        var subresourceURL = parseLink(
                            parent.data.links[subresource.type],
                            subresource
                        ).href;

                        if (!subRootPromises[subresourceURL]) {
                            subRootPromises[subresourceURL] = spApiHttp.get(
                                subresourceURL,
                                ignoreRequestQueue
                            );
                        }

                        return subRootPromises[subresourceURL];
                    });
                },
                maybeGetRootResourceLink());

                return maybeGetResourceLink.then(function withResult(result) {
                    return $q.resolve(
                        parseLink(result.data.links[lastResource.type], lastResource)
                    );
                });

                function maybeGetRootResourceLink() {
                    if (rootPromise) {
                        return rootPromise;
                    }

                    rootPromise = spApiHttp
                        .get('', ignoreRequestQueue)
                        .then(function withGETResponse(response) {
                            if (response.data.links) {
                                return response;
                            }

                            return $q.reject();
                        });

                    return rootPromise;
                }
            }
        };

        function parseLink(link, resource) {
            if (link.templated) {
                return {
                    href: uriTemplate.parse(link.href).expand(resource)
                };
            }

            return link;
        }
    }
];
