export default [
    '$rootScope',
    'SPRoute',
    function getViewControllerNameFacade($rootScope, SPRoute) {
        var viewControllerName;

        $rootScope.$on('$routeChangeSuccess', function setViewControllerName() {
            viewControllerName = SPRoute.getControllerName();
        });

        $rootScope.getViewControllerName = function getViewControllerName() {
            return viewControllerName;
        };
    }
];
